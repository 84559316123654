import React from 'react';
import Paper from '../paper';
import SceneWrapper from '../scene-wrapper';

export default function Account () {
  return (
    <SceneWrapper>
      <Paper style={{ alignSelf: 'center', width: '100%', maxWidth: 680 }}>
        <h1>Terms of Service</h1>

        GENERAL TERMS AND CONDITIONS FOR USING THE SERVICES AVAILABLE THROUGH Skilldrop WEBSITE

        Last Updated on 2020-04-08
        ACCEPTANCE OF THE TERMS

        Thank you for using Skilldrop. These General Terms of Service (the “Terms”) are intended to make you aware of your legal rights and responsibilities with respect to your access to and use of the Skilldrop website at skilldrop.io (the “Website”), including but not limited to delivery of information via the Website.

        Please read these Terms carefully.

        By accessing or using the Website, you are agreeing to these Terms and concluding a legally binding contract with StackLab AB, a company incorporated and existing under the laws of the Kingdom of Sweden. Do not access or use the Website if you are unwilling or unable to be bound by the Terms.

        You can accept the Terms by:

        Clicking to accept or agree to the Terms, where it is made available to you in the user interface of the Website; or
        Actually using the Services. In this case, you understand and agree that Skilldrop will treat your use of the Services as an acceptance of the Terms.
        DEFINITIONS

        We
        "We" or "us" refers to StackLab AB.

        Skilldrop
        “Skilldrop” is a web-based Internet platform, owned by StackLab AB, a company incorporated and existing under the laws of the Kingdom of Sweden, registered under number 559251-4474 in the Swedish Bolagsverket, having its seat and registered office at Lokstallsgatan 21, 21646 Limhamn, Sweden, which enables users to use the Services of the Website that are the subject of these Terms of Service.

        Skilldrop Content
        "Skilldrop Content" means content that Skilldrop creates and makes available in connection with the Services, including, but not limited to: visual interfaces, interactive features, graphics, design, computer code, products, software, reports and other usage-related data in connection with the activities associated with your User Account and all other elements and components of the Services excluding Your Content and Third Party content. "Third Party Content" means content that comes from parties other than Skilldrop or its Users and is available in or through the Services.

        Content
        "Content" will include (but is not limited to) images, photos, audio, video, text and all other forms of information or data.

        Services
        "Services" include any and all services, which Skilldrop may offer to the User, through the Website, including but not limited to uploading and/or viewing a Curriculum Vitae (“CV”) uploaded on the Website, making job postings or applying for jobs/vacancies.

        User
        "User" or "you" or "your" refers to you, as a user of the Services. A user is someone who accesses or uses the Services for the purpose of sharing, displaying, hosting, publishing, transacting, uploading information and includes other persons jointly participating in using the Services, such as companies or other entities who sign up for a Company User Account with Skilldrop.

        User Account
        "User Account" is a separate part of the Website, containing User information required by Skilldrop during registration. The User Account is accessed by the User through entering an email and a password. Depending on the type of the User Account - for Individuals or Companies - a User may have access to different features and Services, offered on and through the Website.

        Your Content
        "Your Content" or "User Content" is the content, submitted by you, as a User of the Website. It includes, but is not limited to what you upload, share or transmit to, through or in connection with the Services, including but not limited to CVs and User Account information.

        USING THE SERVICES

        Eligibility
        You hereby represent and warrant that you are at least eighteen (18) years of age or above and are fully able and competent to understand and agree with the terms and conditions, obligations, affirmations, representations, and warranties set forth in those Terms.
        Compliance with the laws
        You are responsible for complying with all laws and regulations in the country in which you live when you access and use the Services. You agree to use the Services only in compliance with these Terms and the applicable law and in a manner that does not violate our legal rights or those of any third parties.
        Website Availability
        The Website may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.
        User Accounts
        You must create a User Account and provide certain information in order to use (some of) the Services that are offered through the Website. You are responsible for maintaining the confidentiality of your User Account password. You are also responsible for all activities that occur in connection with your User Account. You agree to notify us immediately of any unauthorized use of your User Account. We reserve the right to close your User Account at any time for any or no reason.

        In creating your User Account, we ask that you provide complete and accurate information about yourself or your company. If you are an individual, you can read our Privacy Policy to understand how your data is collected, processed and stored by StackLab AB.

        You hereby acknowledge that the responsibility for any activity taking place under either an Individual’s or Company’s User Account lies with the owner/creator of that User Account.

        Communications from Skilldrop
        By creating a User Account, you agree to receive certain communications in connection with the Website.
        CHANGES TO THE TERMS

        Skilldrop may amend, change or update these Terms of Service, from time to time, entirely at its own discretion. You shall be responsible for checking these Terms, from time to time, and ensuring continued compliance with these Terms. Your use of the Services after any such amendment or change in the Terms shall be deemed as your express acceptance to such amended/changed Terms and an assent to be bound by such changed/amended Terms.
        TRANSLATION OF THE TERMS

        Skilldrop may provide a translation of the English version of the Terms into other languages. You understand and agree that any translation of the Terms into other languages is only for your convenience and that the English version shall govern the terms of your relationship with Skilldrop. In case there are any inconsistencies between the English version of the Terms and its translated versions, the English version of the Terms shall prevail.

        CONTENT
        Responsibility for Your Content
        You are responsible for the content you publish, upload and/or delete. You assume all risks associated with the content you publish, including anyone's reliance on its quality, accuracy, or reliability, disclosure or truthfulness of information. You represent that you are competent and entitled to publish and/or upload content. In that sense, you agree that by uploading a CV, you have received the CV’s owner’s consent to process their CV. You may not imply that publishing and/or uploading Your Content is in any way motivated, sponsored or endorsed by Skilldrop. Skilldrop shall not be responsible if you publish any Content, without having obtained the consent for such publishing of its direct owner, i.e. uploading a CV without the permission of its proprietor.

        You may expose yourself to liability if, for example, the Content you publish contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, data privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including hate speech or pornography; harms minors; or violates or advocates the violation of any law or other legal regulation.

        If Your Content is file, including a CV, you represent and warrant that you are explicitly authorised by the author and owner of that CV to dispose with the same and/or to re-upload it freely on the internet. Neither Skilldrop, nor its respective directors, owners, employees, agents, representatives upload CVs by themselves. Skilldrop solely offers an intermediary platform and cannot, in any way, be held responsible for any unauthorised uploading of CVs. For more information about our dedication to protecting User’s and personal data’s privacy, please read our Privacy Policy.

        Whilst Your Content, in particular CVs can be downloaded to your computer or other electronic device from the Website, Skilldrop encourages Users to view, share and handle any CVs within their organization and to view User Content solely online, on the Website. In any case, should such download take place, the User who performed the download undertakes to delete any CVs downloaded on their computer or electronic device, following the conclusion of the relevant hiring and interview process.

        Right to use Your Content.
        By submitting Your Content, you hereby (revocably) grant us a perpetual, non-exclusive, royalty-free right to use the published content on the worldwide. By “use” we mean keeping a copy of such Content on the Website. It is your sole choice and responsibility, as a User of the Website, to decide with whom you decide to share Your Content published or uploaded on the Website.

        You irrevocably waive, any claims and assertions of moral rights with respect to Your Content brought against us, any third-party services and our and their users.

        Ownership of Content
        As between you and Skilldrop, you own Your Content. We own the Skilldrop Content, including, among others, visual interfaces, interactive features, graphics, design, compilations, including (but not limited to) computer code, products, software, and all other elements and components of the Website, excluding User Content and Third Party Content. We also own the copyrights, trademarks, trade names, and other intellectual and proprietary rights ("IP Rights"), associated with the Skilldrop Content, the Website. This means that you may not modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way exploit the Skilldrop Content, in whole or in part, except as expressly authorized by us.

        Content Removal
        We reserve the right, at any time and without prior notice, to remove, block, or disable access to any content that we, for any reason or no reason consider to be objectionable, in violation of the Terms or otherwise harmful to the Services or our Users in our sole discretion.
        RESTRICTIONS

        Without limiting the generality of these Terms, in using the Services, you specifically agree not to post or transmit any content (including reviews) or engage in any activity that, in our sole discretion:

        Violates any third party's rights, including copyright, trademark, patent, trade secret, moral right, data privacy right, right of publicity, or any other intellectual property or proprietary right;
        Violates those Terms;
        Violates any applicable law;
        Threatens, harms, harasses others, or promotes discrimination;
        Promotes a business or other commercial venture or event, or otherwise uses the Website for unlawful commercial purposes;
        Engages in keyword spamming, or otherwise attempts to manipulate the Website's search results;
        Solicits personal information from minors, or submits or transmits pornography.

        Apart from that, the User agrees not to:
        Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works, publicly display, sell, trade, or in any way exploit the Website or its Content.
        Use any robot, spider, site or mobile app search/retrieval application in the Website;
        Make attempts to reverse engineer any portion of the Website;
        Remove or modify any IP rights notice that appears on any portion of the Website or on any materials printed or copied from the Website;
        Record, process, or mine information about other Users and clients;
        Attempt to gain unauthorized access to the Website, the User Accounts, computer systems or networks connected to the Website, through hacking, password mining or any other means;
        Use the Website or any Content to transmit any computer viruses, worms, Trojan horses or other items that could be of a destructive nature (collectively, "Viruses").

        When a particular action is not explicitly referred to in this clause, but is an obvious violation of Article 7, the clause should be interpreted broadly, in favour of Skilldrop, and this action should be considered as if it was included in this clause.
        RELATIONSHIP WITH OTHER GUIDELINES AND POLICIES

        We process your personal data in accordance with our Privacy Policy and cookies in accordance with our Cookie Policy. You can always retrieve the current version of those at […]. In particular these data protection provisions govern and explain the extent to which your personal data is collected, processed, can be seen by other Users and what options you have for controlling its disclosure to other Users and third parties.

        Please have a careful look at our Privacy and Cookie Policies to learn more about the way we process and handle your personal information.
        THIRD PARTY WEBSITES AND CONTENT

        The Services may contain, or you may be sent through the Website to, links to other websites (“Third Party Websites”), as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software and Third Party Content. Such Third Party Websites and Third Party Content are not investigated, monitored or checked for accuracy, appropriateness, safety or completeness by Skilldrop and Skilldrop is not responsible for any Third Party Websites accessed through the Website or any Third Party Content posted on, available through or installed from the Website.

        You agree that we are not responsible for the availability or Third Party Content of such Third Party Websites. Your use of Third Party Websites is solely at your own risk.
        USER FEEDBACK. SUGGESTIONS AND IMPROVEMENTS

        By sending us any ideas, suggestions, documents or proposals ("Feedback"), you agree that (i) your Feedback does not contain the confidential or proprietary information of third parties, (ii) we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, and (iv) you grant us an irrevocable, non-exclusive, royalty-free, perpetual right to use, modify, prepare derivative works, publish, distribute and sublicense the Feedback, and you irrevocably waive, against Skilldrop and its users, any claims and assertions of any moral rights contained in such Feedback.
        PAYMENT

        Payment is done exclusively by credit/debit card. We accept Visa, Mastercard, Maestro, American Express. All payments made to us are processed by Stripe (https://stripe.com/).

        All credit/debit cardholders are subject to validation checks and prior authorisation by the card issuer. By making a payment on or through the Website, you are confirming that you have obtained the express prior permission of the credit/debit cardholder to enter into such transaction. Following clearance, payment will be debited from your account upon receipt of your subscription/order by Skilldrop.

        If the issuer of your payment card refuses to authorise payment to StackLab AB, we will not be able to process your subscription request or order and a message will appear informing you that the transaction cannot be processed. StackLab AB, together with its business partners, takes all reasonable care to ensure that its payment system is secure. All payment card transactions made through the Website are processed using a secure online payment gateway (Secure Socket Layer (SSL) technology) that encrypts your card details in a secure hosting environment. We do not hold your payment card details on the StackLab AB server.
        INDEMNITY

        You hereby agree to indemnify, defend, and hold us, our licensors, licensees, distributors, agents, representatives and other authorized users, and each of the foregoing entities' respective resellers, distributors, service providers and suppliers, and all of the foregoing entities' respective directors, owners, employees, agents, representatives and assigns harmless from and against any and all losses, damages, liabilities and costs (including settlement costs and any legal or other fees and expenses for investigating or defending any actions) incurred by the above mentioned in connection with any claim arising out of any breach by you of these Terms or claims arising from your use of the Website and/or your User Account. You agree to cooperate with us in the defence of any claim. We reserve the right, at our own expense, to employ separate counsel and assume the exclusive defence and control of any matter otherwise subject to indemnification by you. We will use reasonable efforts to notify you of any such claim, action or proceeding.
        DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY

        YOU ACKNOWLEDGE AND AGREE THAT THE SERVICES, ACCESSIBLE ON OR THROUGH THE WEBSITE ARE PROVIDED "AS IS" AND "AS AVAILABLE". TO THE FULLEST EXTENT, PERMITTED BY THE APPLICABLE LAW, StackLab AB DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE FACT THAT: (I) THE SERVICES, ACCESSIBLE ON OR THROUGH THE WEBSITE MAY BE TRADED OR SUITABLE FOR A SPECIFIC PURPOSE, (II) THE SERVICES, ACCESSIBLE ON OR THROUGH THE WEBSITE WILL OPERATE WITHOUT INTERRUPTION AND ERRORS, (III) DEFECTS WILL BE RECTIFIED, (IV) THERE ARE NO BUGS, VIRUSES, TROJAN HORSES OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH  THE SERVICES, FOLLOWING THE ACTIONS OF THIRD PARTIES, (V) THE SECURITY METHODS USED WILL BE SUFFICIENT FOR THE PROTECTION OF THE SERVICES AND THEIR USERS, AND (VI) WITH REGARD TO THE CORRECTNESS, ACCURACY OR RELIABILITY, THERE WILL BE NO LOSS OF DATA, CONTENT OR INFORMATION.

        DOWNLOADING OR OTHERWISE OBTAINING MATERIALS THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER, MOBILE PHONE, TABLET OR OTHER DEVICE OR FOR LOSS OF DATA THAT RESULTS FROM THE DOWNLOADING OF ANY SUCH MATERIAL. Skilldrop WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES RELATED TO THE Skilldrop SERVICES. YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES. THE COMMUNICATION WITH Skilldrop, WHETHER WRITTEN OR ORAL, IN CONNECTION TO, TROUGH THE OR AS A RESULT OF USING THE SERVICES SHALL NOT CREATE ANY WARRANTIES NOT EXPRESSLY STATED IN THE TERMS. UNLESS YOU HAVE BEEN EXPRESSLY AUTHORIZED TO DO SO IN WRITING, YOU AGREE THAT WHEN USING THE SERVICES, YOU WILL NOT USE ANY TRADEMARK, TRADE NAME OR LOGO OF ANY COMPANY OR ORGANIZATION IN A WAY THAT IS LIKELY TO CAUSE CONFUSION ABOUT THE OWNER OF SUCH MARKS, NAMES OR LOGOS.

        Skilldrop MAKES NO PROMISES WITH RESPECT TO ANY THIRD PARTIES OR OTHER USERS THAT CAN BE SEEN OR REGISTERED ON THE WEBSITE. Skilldrop IS NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE FROM THEIR ACTIONS OR OMISSIONS, INCLUDING, FOR EXAMPLE, IF ANOTHER USER OR BUSINESS MISUSES YOUR CONTENT, CV, IDENTITY OR PERSONAL INFORMATION, OR IF YOU HAVE A NEGATIVE EXPERIENCE WITH OUR SERVICES OR ANY OTHER GOODS OR SERVICES PROMOTED OR FEATURED ON THE WEBSITE.

        YOUR PURCHASE AND USE OF PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES THROUGH THE WEBSITE IS DONE AT YOUR OWN DISCRETION AND RISK.

        Skilldrop'S MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE SERVICES IS LIMITED TO THE ACTUAL OUT-OF-POCKET AMOUNT PAID BY YOU TO Skilldrop FOR ACCESSING THE WEBSITE AND THE SERVICES FOR A PERIOD OF UP TO ONE CALENDAR YEAR.

        Skilldrop DISCLAIMS LIABILITY FOR ANY (I) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, (II) LOSS OF PROFITS AND RESULTING DAMAGE, (III) BUSINESS INTERRUPTION, (IV) REPUTATIONAL HARM, OR (V) LOSS OF INFORMATION OR DATA.
        DISPUTE RESOLUTION

        You agree that any and all disputes and claims, arising out of or in connection with these Terms, the Website and the Services shall be resolved individually, without resort to any form of class action.

        Any and all claims, judgments, and awards shall be limited to the actual out-of-pocket amount paid by you to Skilldrop for accessing the Website and the Services for a period of up to one calendar year, but in no event attorneys' fees; and under no circumstances you will be permitted to obtain awards for, and each User hereby waives all rights to claim punitive, incidental, consequential damages or any other damages other than actual out-of-pocket expenses for using the Services of Skilldrop.

        This Agreement and all rights and obligations in connection with it are governed by Swedish law. Any disputes between the Parties arising from this Agreement shall be heard and determined by the competent Court of the Kingdom of Sweden.
        SEVERABILITY

        If any of these terms of use are found unlawful, void, or for any reason unenforceable, then such provisions will be considered severable from the remaining Terms and will not affect the validity and enforceability of the remaining provisions.
        TERMINATION

        You may terminate the Terms at any time by closing your User Account, discontinuing your use of the Website or providing Skilldrop with a notice of termination.
        We may close your User Account, suspend your ability to use certain portions of the Website and/or ban you altogether from using the Services for any or no reason and without notice or liability of any kind. Any such action could prevent you from accessing your User Account, the Website, Your Content, Skilldrop Content and/or any other related information.

        In the event of any termination of these Terms, whether by you or us, Sections 2, 6, 7, 9, 11, 12, 13, 14, 15 and 16 will continue in full force and effect.

        GENERAL TERMS

        We reserve the right to amend, update, or discontinue the Website at our sole discretion, at any time, with or without reason, and without notice or liability.
        We may provide you with notices, including those regarding changes to the Terms, by email or through the Website.
        Except as otherwise stated in Section 9 above, nothing in those Terms is intended, nor will be deemed, to confer rights or remedies upon any third party, especially if you have misused the rights of any such third party.
        The Terms contain the entire agreement between you and Skilldrop regarding the use of the Website.
        The section titles in the Terms are for convenience only and have no legal or contractual effect.

        CONTACT

        In order to address a question, to resolve a complaint regarding the Website or the Skilldrop Services, or to receive further information regarding the Services, please contact Skilldrop using one of the following methods:

        Address: Lokstallsgatan 21, 21646 Limhamn, Sweden

        e-mail: support@skilldrop.com

      </Paper>
    </SceneWrapper>
  );
}
