import React from 'react';
import Paper from '../paper';
import SceneWrapper from '../scene-wrapper';
import Typography from '@material-ui/core/Typography';
import CustomResetPassword from '../utils/custom-reset-password';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  },
  redText: {
    fontSize: 11,
    color: '#f00'
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

export default function ResetPassword ({ location }) {
  const classes = useStyles();

  return (
    <SceneWrapper>
      <Paper style={{ alignSelf: 'center', width: 640, maxWidth: '100%', minHeight: 400 }}>
        <Typography component='h1' className={classes.formTitle}>Reset password</Typography>
        <CustomResetPassword />

        <p style={{ marginTop: 15 }}>
          <small>
            New to Skilldrop? <Link to='/register'>Register new account</Link> | <Link to='/login'>Login</Link>
          </small>
        </p>

      </Paper>
    </SceneWrapper>
  );
}
