import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import LoggedOut from './logged-out';
import firebase from 'firebase/app';
import firebaseApp from '../../firebase-app';

import {
  handleLogin,
  anonymousMoveShared,
  getAnonymousUserData
} from '../../actions/account';

export default function CustomAuth () {
  const [errorMessage, setErrorMessage] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const logUserIn = async (username, password) => {
    try {
      setLoggingIn(true);

      // Check if there was anonymous data attached to the account
      const { annonUserUid, anonData, sharedProfiles } = await getAnonymousUserData();
      if (annonUserUid){
        // We need to delete data at this point to avoid issues with firestore.rules
        await firebaseApp.db.doc(`accounts/${annonUserUid}`).delete();
      }

      // We will try not to login with provided data
      const res = await firebase.auth().signInWithEmailAndPassword(username, password);

      // Check if there was anonymous data and move shared profiles if needed
      if (annonUserUid){
        await anonymousMoveShared(anonData, sharedProfiles, res.user);
      }

      // If all went well, let's attach data to a context
      await handleLogin(res);
      setRedirect('/share');

    } catch (e) {
      setLoggingIn(false);
      setErrorMessage(e.message);
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      <LoggedOut errorMessage={errorMessage} logUserIn={logUserIn} loggingIn={loggingIn} />
    </div>
  );
}
