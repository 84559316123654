import React, {useState, useContext, useCallback} from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Hidden from '@material-ui/core/Hidden';
import Avatar, { SIZE } from '../avatar';
import DialogShareProfile from './dialog-share-profile';
import DialogApplications from './dialog-applications';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  shareProfile,
  deleteProfile,
  deleteProfileLink,
  extendProfileLink
} from '../../actions/account';
import {
  deleteProfile as deleteCandidateProfile,
  shareProfile as shareCandidateProfile,
  deleteProfileLink as deleteCandidateProfileLink,
  extendProfileLink as extendCandidateProfileLink,

  updateProfile as updateCandidateProfile
} from '../../actions/candidate';
import Confirm from '../confirm';
import { SHARE_PROFILE_LINK_EXPIRE_EXTEND_DAYS } from '../../constants/config';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import EditProfileForm from './edit-profile-popup';

const useStyles = makeStyles(theme => ({
  container: {
    borderWidth: 1,
    borderColor: theme.palette.accent.main,
    borderStyle: 'solid',
    borderRadius: 4,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    background: '#fff',
    width: '100%',
    '& > div': {
      width: '100%'
    }
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  tableHead: {
    backgroundColor: theme.palette.contrast.main
  },
  tableHeadCell: {
    color: 'white'
  },
  leftButton: {
    marginLeft: theme.spacing(-1)
  },
  profile: {
    padding: 0
  },
  profileHeader: {
    borderTopWidth: 1,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.palette.accent.main,
    padding: theme.spacing(1)
  },
  description: {
    color: '#7c7c7c'
  },
  margin: {
    marginLeft: theme.spacing(1)
  },
  editLabel: {
    color: theme.palette.accentText.main
  },
  value: {
    color: theme.palette.accentText.main,
    whiteSpace: 'nowrap'
  },
  candidateName: {
    marginBottom: theme.spacing(1),
    color: theme.palette.accentText.main
  },
  candidateTitle: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    color: theme.palette.darkText.main,
    fontSize: 14
  },
  iconButton: {
    marginTop: theme.spacing(-2),
    color: theme.palette.lightText.main
  },
  dropdownMenu: {
    '& li a': {
      textDecoration: 'none',
      display: 'block'
    }
  },
  resetButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent'
    }
  }
}));

const menuId = 'primary-search-account-menu';

export default function Profile ({ match, companyId, profileKey, accountId, displayName, photo, profile, applicationCount, shareCount, handleDeleteClick }) {
  const { handleViewCv } = useContext(DialogsAndToastsContext);
  const classes = useStyles();
  const [shareOpen, setShareOpen] = useState(false);
  const [applicationsOpen, setApplicationsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [deleting, setDeleting] = useState(false);
  const [editProfileOpen, setEditProfileOpen] = React.useState(false);

  const handleMenuOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setShareOpen(true);
  };

  const handleOpenApplications = () => {
    setApplicationsOpen(true);
  }

  const handleShare = (values) => {
    if (companyId) {
      return shareCandidateProfile(accountId, companyId, profileKey, values);
    }
    return shareProfile(accountId, profileKey, values);
  };

  const handleClose = () => {
    setShareOpen(false);
    return true;
  };

  const handleApplicationsClose = () => {
    setApplicationsOpen(false);
    return true;
  };

  const toggleEditProfile = () => {
    setEditProfileOpen(!editProfileOpen);
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.dropdownMenu}
    >
      {profile && <MenuItem>
        <Confirm title={`Are you sure you want to remove profile "${profile.title}?"`}>
          {confirm => (
            <IconButton className={classes.resetButton} aria-label='delete' size='small' onClick={confirm(()=> { handleDeleteClick(accountId, profile.id) })}>
              <Delete /> Delete
            </IconButton>
          )}
        </Confirm>
      </MenuItem> }
    </Menu>
  );

  return (
    <div className={classes.container}>
      <Grid container direction='row' className={classes.profile} alignItems='stretch' alignContent='stretch'>
        {!deleting
          ? <>
            <Grid item>
              <Avatar
                displayName={displayName}
                photo={photo}
                size={SIZE.SMALL}
                style={{ marginRight: 14 }}
              />
            </Grid>

            <Grid item style={{ flex: 1 }}>
              <Grid container justify='space-between'>
                <Grid item>
                  <Hidden smDown>
                    <h1 className={classes.candidateName}>{displayName}</h1>
                  </Hidden>

                  <Hidden mdUp>
                    <h1 className={classes.candidateName}>{displayName}</h1>
                  </Hidden>
                </Grid>
                <Grid>
                  <IconButton onClick={handleMenuOpen} className={classes.iconButton} aria-label='more' size='small'>
                    <MoreHoriz />
                  </IconButton>
                </Grid>
              </Grid>
              {renderMenu}

              {profile && profile.description
                ? <Hidden smDown>
                  <div className={classes.description}>
                    {profile.description}
                  </div>
                </Hidden> : null}

              <Divider className={classes.divider} />

              <Grid container direction='row' justify='space-between' alignItems='center'>
                <Grid item>
                  {profile && profile.edited && profile.edited.toDate
                    ? <Hidden smDown>
                      <>
                        <span className={classes.editLabel}>
                          Last edit: <b className={classes.value}>{moment(profile.edited.toDate()).format('DD MMMM YYYY')}</b>
                        </span>
                      </>
                    </Hidden>
                    : null}
                </Grid>
                <Grid item>
                  {profile ? <Button variant='text' size='small' color='primary' onClick={() => handleViewCv(profile.cvPath, `${displayName} - ${profile.title}`)}>VIEW CV</Button> : null}
                  {profile && profile.applications ? <Button variant='text' size='small' color='primary' className={classes.margin} disabled={Object.keys(profile.applications).length == 0} onClick={handleOpenApplications}>APPLICATIONS ({applicationCount})</Button> : null}
                </Grid>
              </Grid>
            </Grid>
          </>
          : <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <CircularProgress />
          </div>}
      </Grid>

      {profile && profile.applications ?
        <DialogApplications
          displayName={displayName}
          profile={profile}
          handleClose={handleApplicationsClose}
          applications={profile.applications}
          open={applicationsOpen}
        />
      : null}

      {profile ?
        <Dialog open={editProfileOpen} onClose={() => {}} maxWidth='lg' style={{ padding: 0 }} aria-labelledby='form-dialog-title'>
          <DialogContent style={{ padding: 0 }}>
            <EditProfileForm
              companyId={companyId}
              candidateId={accountId}
              profileId={profileKey}
              updateCandidateProfile={updateCandidateProfile}
              toggleEditProfile={toggleEditProfile}
              profile={profile}
            />
          </DialogContent>
        </Dialog>
      : null}
    </div>
  );

}
