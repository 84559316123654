import React, { useState, useContext } from 'react';
import P from 'prop-types';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import * as copy from 'clipboard-copy';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import useForm from '../../hooks/use-form';
import { SHARE_PROFILE_LINK_EXPIRE_IN_DAYS } from '../../constants/config';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: -24,
    marginRight: -24
  },
  dialogRoot: {
    overflowY: 'visible',
    width: '80%'
  },
  dialogTitle: {
    paddingRight: 24 + theme.spacing(6)
  },
  dialogContentRoot: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  table: {
    tableLayout: 'fixed'
  },
  title: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  link: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    direction: 'rtl',
    paddingRight: theme.spacing(1)
  },
  listItem: {
    borderBottomColor: theme.palette.lightText.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    marginBottom: theme.spacing(1)
  },
  helpExpansionRoot: {
    margin: 0
  },
  helpExpansionContent: {
    alignItems: 'center'
  },
  shareDialogPaper: {
    overflowY: 'hidden'
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

const initialValues = {
  title: ''
};

const schema = yup.object().shape({
  title: yup
    .string()
    .required()
});

export function SharedWithTable ({ profile, copy, handleExtendLink, handleDeleteLink }) {
  const classes = useStyles();
  return (
    <Table size='small' className={classes.table}>
      <TableHead className={classes.membersTableHead}>
        <TableRow>
          <TableCell style={{ width: '20%' }}>Title</TableCell>
          <TableCell style={{ width: '20%' }}>Expiration</TableCell>
          <TableCell style={{ width: '20%', whiteSpace: 'nowrap' }}>Accepted by</TableCell>
          <TableCell style={{ width: '40%' }}>Manage Link</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {profile && profile.links ? Object.keys(profile.links).map(linkId => {
          const link = profile.links[linkId];
          if (!link || !link.expires) {
            return null;
          }
          const daysFloat = link.expires && moment.duration(moment(link.expires.toDate()).diff(moment())).asDays();
          const daysLeft = daysFloat >= 2 ? `${Math.ceil(daysFloat)} days` : '1 day';
          const expiresText = daysFloat < 0 ? 'expired' : daysLeft;

          return (
            <TableRow key={linkId}>
              <TableCell className={classes.title} title={link.title}>{link.title}</TableCell>
              <TableCell>{expiresText}</TableCell>
              <TableCell>{link.acceptedBy ? link.acceptedBy.length : 0}</TableCell>
              <TableCell>
                <div className={classes.link}>{link.link}</div>
                <Button onClick={() => { copy(link.link); }}>Copy</Button>
                <Button onClick={() => { handleExtendLink(linkId, link); }}>Extend</Button>
                <Button onClick={() => { handleDeleteLink(linkId); }}>Delete</Button>
              </TableCell>
            </TableRow>
          );
        }) : null}
      </TableBody>
    </Table>
  );
}

export function SharedWithList ({ profile, copy, handleExtendLink, handleDeleteLink }) {
  const classes = useStyles();
  return (
    <div>
      {profile && profile.links ? Object.keys(profile.links).map(linkId => {
        const link = profile.links[linkId];
        if (!link || !link.expires) {
          return null;
        }
        const daysFloat = moment.duration(moment(link.expires.toDate()).diff(moment())).asDays();
        const daysLeft = daysFloat >= 2 ? `${Math.ceil(daysFloat)} days` : '1 day';
        const expiresText = daysFloat < 0 ? 'expired' : daysLeft;

        return (
          <div key={linkId} className={classes.listItem}>
            <b>{link.title}</b><br />
            Expires: {expiresText}<br />
            Accepted by: {link.acceptedBy ? link.acceptedBy.length : 0}<br />
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{link.link}</div>
            <Grid container justify='flex-end'>
              <Grid item><Button onClick={() => { copy(link.link); }}>Copy</Button></Grid>
              <Grid item><Button onClick={() => { handleExtendLink(linkId, link); }}>Extend</Button></Grid>
              <Grid item><Button onClick={() => { handleDeleteLink(linkId); }}>Delete</Button></Grid>
            </Grid>
          </div>
        );
      }) : null}
    </div>
  );
}

export default function DialogShareProfile ({ displayName, profile, handleClose, open, handleShare, handleExtendLink, handleDeleteLink }) {
  const theme = useTheme();
  const { showToast } = useContext(DialogsAndToastsContext);
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [showHelp, setShowHelp] = useState(false);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors, validateAll, clear] = useForm(initialValues, schema);

  const clearAndHandleClose = () => {
    clear();
    handleClose(values);
  };

  const handleAdd = async () => {
    try {
      setLoading(true);
      const link = await handleShare(values);
      copy(link);
      clear();
      setLoading(false);
    } catch (e) {
      showToast({
        message: e.message,
        variant: 'error'
      });
      setLoading(false);
    }
  }

  return (
    <>
      <Dialog
        onClose={clearAndHandleClose}
        open={open}
        fullWidth
        maxWidth='md'
        classes={{
          paper: classes.shareDialogPaper
        }}
      >
        <DialogTitle id='simple-dialog-title' className={classes.dialogTitle}>
          Share Profile - {displayName} - {profile.title}
          <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {profile && profile.links && Object.keys(profile.links).length === 0
            ? <div>
              <p>This profile is complete, but it’s not shared with anyone yet.</p>
              <ol className='px-3'>
                <li>Create a shareable link below. Create one link for each company you will send to.</li>
                <li>Send the links to your contacts instead of sending your CV as an email attachment.</li>
              </ol>
              <p>That is how easy it is to make sure your personal data stays under your control! </p>
            </div>
            : null}

          {profile && profile.links && Object.keys(profile.links).length > 0
            ? <>
              <Typography variant='h6'>Currently shared with</Typography>
              <Hidden smDown>
                <SharedWithTable
                  profile={profile}
                  copy={copy}
                  handleExtendLink={handleExtendLink}
                  handleDeleteLink={handleDeleteLink}
                />
              </Hidden>
              <Hidden mdUp>
                <SharedWithList
                  profile={profile}
                  copy={copy}
                  handleExtendLink={handleExtendLink}
                  handleDeleteLink={handleDeleteLink}
                />
              </Hidden>
            </> : null}
        </DialogContent>

        <DialogActions style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div style={{ flex: 1 }}>
            <div style={{ alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>
              <Typography variant='h6' style={{ margin: 0 }}>Create shareable link</Typography>
              <IconButton
                style={{ margin: 0, padding: 0, marginLeft: 10 }}
                onClick={() => setShowHelp(true)}
                size='small'
              >
                <HelpOutlineIcon color='primary' fontSize='small' />
              </IconButton>
            </div>

            <Grid container justify='space-between' alignItems='center' spacing={isExtraSmall ? 0 : 2}>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  label='Link Title'
                  InputLabelProps={{
                    shrink: true
                  }}
                  name='title'
                  value={values.title}
                  onChange={onValueChange}
                  size='small'
                  required
                  fullWidth
                  helperText={errors.has('title') && errors.get('title').error.message}
                  error={errors.has('title')}
                  variant='outlined'
                  margin='normal'
                  style={{ marginBottom: 15 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Button disabled={!isValid || loading} onClick={handleAdd} variant='contained' color='primary' fullWidth>
                  {!loading ?
                    <>CREATE AND COPY LINK</>
                  : <CircularProgress className={classes.circularIcon} />}
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
      </Dialog>

      {showHelp
        ? <Dialog
          onClose={() => setShowHelp(false)}
          open={showHelp}
          fullWidth
          maxWidth='md'
          >
          <DialogTitle id='simple-dialog-title' className={classes.dialogTitle}>
            Creating and sharing links
            <IconButton aria-label='close' className={classes.closeButton} onClick={() => setShowHelp(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <ol>
              <li>Create a temporary link to this profile by giving it a title.</li>
              <li>Copy the link and send via email to anyone you wish to share it with.</li>
            </ol>
            <p>
              Note: It's recommended to create a new link for each person or company
              you want to share the profile with. The link till expire in {SHARE_PROFILE_LINK_EXPIRE_IN_DAYS}&nbsp;
              days but can be extended.
            </p>
          </DialogContent>
        </Dialog> : null}
    </>
  );
}

DialogShareProfile.propTypes = {
  displayName: P.string.isRequired,
  profile: P.shape({
    title: P.string.isRequired
  }).isRequired,
  handleClose: P.func.isRequired,
  handleShare: P.func.isRequired,
  handleDeleteLink: P.func.isRequired,
  open: P.bool.isRequired
};
