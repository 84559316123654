import React, { useEffect, useState, useContext } from 'react';
import firebase from 'firebase/app';

import { Redirect } from 'react-router-dom';
import { useCollection, useDocumentOnce } from 'react-firebase-hooks/firestore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReplaceLink from '../replace-link';
import firebaseApp from '../../firebase-app';
import Job from '../job';
import CreateJobForm from '../create-job/create-job-form';
import SceneWrapper from '../scene-wrapper';
import LinkButton from '../link-button';
import NoDataInformation from '../../components/no-data-information';
import { AuthContext } from '../../contexts/auth';

function getFilteredJobs (jobs, privateDocsById, done) {
  if (!jobs || jobs.length === 0 || privateDocsById.size === 0) {
    return [];
  }

  const mergedJobs = jobs.map(job => ({ job, privateDoc: privateDocsById.get(job.id) }));
  const filteredJobs = mergedJobs.filter(data => {
    if (data.privateDoc !== undefined && data.privateDoc.data()) {
      return data.privateDoc.data().done === done;
    }

    return false;
  });

  return filteredJobs;
}

export default function JobList ({ match, location }) {
  const { company, companyLoading, account } = useContext(AuthContext);
  const accountData = account && account.data();

  const [tagsDoc] = useDocumentOnce(firebaseApp.db.doc('config/tags'));
  const tags = tagsDoc ? tagsDoc.data() : {};

  const [jobsRef, loading, error] = useCollection(
    accountData ?
      accountData.currentCompanyRole !='viewer' ?
      firebaseApp.db.collection(`companies/${match.params.companyId}/jobs`) :
      firebaseApp.db.collection(`companies/${match.params.companyId}/jobs`).where(firebase.firestore.FieldPath.documentId(), 'in', accountData.jobs[match.params.companyId])
    : null
  );

  const [privateDocsById, setPrivateDocsById] = useState(new Map());

  useEffect(() => {
    if (jobsRef) {
      (async () => {
        const newPrivateDocsById = new Map();
        await Promise.all(jobsRef.docs.map(async jobRef => {
          const privateDoc = await firebaseApp.db.doc(
            `companies/${match.params.companyId}/jobs/${jobRef.id}/private_data/private_document`
          ).get();
          newPrivateDocsById.set(jobRef.id, privateDoc);
        }));
        setPrivateDocsById(newPrivateDocsById);
      })();
    }
  }, [jobsRef, match.params.companyId])

  const getTab = () => {
    if (location.pathname.includes('open')) {
      return 0;
    }
    if (location.pathname.includes('done')) {
      return 1;
    }
    return 0;
  };

  const filteredJobs = getTab() === 0
    ? getFilteredJobs(jobsRef && jobsRef.docs, privateDocsById, false)
    : getFilteredJobs(jobsRef && jobsRef.docs, privateDocsById, true);
  const showForm = jobsRef && filteredJobs.length === 0 && !loading && !error && jobsRef.size === privateDocsById.size;
  const showAddButton = jobsRef && filteredJobs.length > 0 && !loading && !error && jobsRef.size > 0 && jobsRef.size === privateDocsById.size;

  if (location.pathname === match.url) {
    return <Redirect to={`${match.url}/open`} />;
  }

  if (!jobsRef && loading) {
    return (
      <SceneWrapper><CircularProgress /></SceneWrapper>
    );
  }

  if (!jobsRef && error) {
    return (<SceneWrapper>{error.message}</SceneWrapper>);
  }

  return (
    <SceneWrapper>
      <h1>Jobs</h1>
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <Tabs
            value={getTab()}
            aria-label='Pick open or done job listing'
          >
            <Tab label='Open' component={ReplaceLink} to={`${match.url}/open`} />
            <Tab label='Done' component={ReplaceLink} to={`${match.url}/done`} />
          </Tabs>
        </Grid>

        <Grid item>
          {showAddButton && accountData && accountData.currentCompanyRole !='viewer' &&
            <LinkButton
              to='../create-job'
              title='ADD JOB'
            />}
        </Grid>
      </Grid>

      <Divider />

      {filteredJobs.map(({ job, privateDoc }) => (
        <Job
          key={job.id}
          id={job.id}
          {...job.data()}
          companyId={match.params.companyId}
          tags={tags.job}
          {...privateDoc.data()}
        />
      ))}

      {showForm
        ? <div style={{ marginTop: 100, marginBottom: 100 }}>
          <NoDataInformation
            title='Group profiles & collect proposals'
            actionTitle='CREATE JOB'
            subTitle='Share and collaborate with built in GDPR flows!'
            to='../create-job'
            bullets={[
              'Use as an RFP tool - bring in proposals from your <br>list of consulting agencies.',
              'Group profiles from your list of candidates.',
              'Use as a lightweight recruitment software.'
            ]}
            image='/app-img/skilldrop-company.png'
          />
        </div>
        : null}

      {showForm && false
        ? <div style={{ maxWidth: 640, width: '100%', alignSelf: 'center' }}>
          <CreateJobForm match={match} />
        </div> : null}
    </SceneWrapper>
  );
}
