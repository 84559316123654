import React, { useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeProvider } from 'react-stripe-elements';
import './app.css';

import Account from './components/scenes/account';
import Notifications from './components/scenes/notifications';
import PrivateRoute from './private-route';
import PublicRoute from './public-route';
import TopBar from './components/top-bar';
import MuiThemeProvider from './mui-theme-provider';
import { AuthProvider } from './contexts/auth';
import { DialogsAndToastsProvider } from './contexts/dialogs-and-toasts';
import Company from './components/company/company';
import Share from './components/share/share';
import AcceptProfileSharedWithMe from './components/share/accept-profile-shared-with-me';
import CookiePolicy from './components/scenes/cookie-policy';
import PrivacyPolicy from './components/scenes/privacy-policy';
import TermsOfService from './components/scenes/terms-of-service';
import './firebaseui-styling.global.css';
import { runCookieConsent } from './actions/cookie-consent';

// Login-Register mechanyzm
import Login from './components/scenes/login';
import Register from './components/scenes/register';
import ResetPassword from './components/scenes/reset-password';
import VerifyComponent from './components/scenes/verify-scene';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export default function App () {
  useEffect(runCookieConsent, []);

  return (
    <DialogsAndToastsProvider>
      <AuthProvider>
        <MuiThemeProvider>
          <CssBaseline />
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
            <Elements stripe={stripePromise}>
              <div id='app' className='skilldrop'>
                <Router>
                  <TopBar />
                  <div id='content'>
                    <div>
                      <Switch>
                        <Route path='/login' component={Login} />
                        <Route exact path='/register' component={Register} />

                        <PublicRoute exact path='/reset-password' component={ResetPassword} />
                        <Route path='/verify' component={VerifyComponent} />
                        <PrivateRoute exact path='/' component={Account} />
                        <Route path='/companies/:companyId' component={Company} />
                        <PrivateRoute exact path='/account' component={Account} />
                        <PrivateRoute exact path='/notifications' component={Notifications} />
                        <Route
                          exact
                          path='/share/accept-personal-profile-shared-with-me/:accountId/:profileId/:linkId'
                          component={AcceptProfileSharedWithMe}
                        />
                        <Route
                          exact
                          path='/share/accept-candidate-profile-shared-with-me/:companyId/:candidateId/:profileId/:linkId'
                          component={AcceptProfileSharedWithMe}
                        />
                        <Route path='/share' component={Share} />
                        <Route path='/cookie-policy' component={CookiePolicy} />
                        <Route path='/privacy-policy' component={PrivacyPolicy} />
                        <Route path='/terms-of-service' component={TermsOfService} />
                        <Redirect to='/account' />
                      </Switch>
                    </div>
                  </div>
                </Router>
              </div>
            </Elements>
          </StripeProvider>
        </MuiThemeProvider>
      </AuthProvider>
    </DialogsAndToastsProvider>
  );
}
