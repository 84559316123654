import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import firebaseApp from '../../firebase-app';
import Paper from '../paper';
import { handleLogin, SIGN_IN_OPTIONS } from '../../actions/account';
import { touchInvite } from '../../actions/job';

export default function CompanyAcceptInvite ({ match, ...otherProps }) {
  const [redirectToAccount, setRedirectToAccount] = useState(false);

  // Log out any current user on first render of this page
  useEffect(() => {
    firebaseApp.auth.signOut();
  }, [])

  const uiConfig = {
    ...SIGN_IN_OPTIONS,
    signInSuccessUrl: `/companies/${match.params.companyId}`,
    callbacks: {
      ...SIGN_IN_OPTIONS.callbacks,
      signInSuccessWithAuthResult: (res) => {
        const asyncHandler = async () => {
          const account = await handleLogin(res);
          // Touch the invite so that the trigger can convert invitation to membership
          await touchInvite(match.params.companyId, match.params.jobId, account.email);
          setRedirectToAccount(true);
        };
        asyncHandler();
        // Avoid redirects after sign-in.
        return false;
      }
    }
  };

  if (redirectToAccount) {
    return <Redirect to='/account' />;
  }

  return (
    <Paper style={{ alignItems: 'stretch' }}>
      <h1 style={{ textAlign: 'center' }}>Sign in to accept invitation</h1>
      <Typography>
        <b>Please sign in with the email address {match.params.inviteEmail}</b>.<br />
        An account will be automatically created for you when you sign in.
      </Typography>

      <Divider />

      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp.auth} />
    </Paper>
  );
}
