import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import Divider from '@material-ui/core/Divider';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckIcon from '@material-ui/icons/Check';
import Paper from '../paper';
import useForm from '../../hooks/use-form';
import { MODES } from '../../constants';
import Typography from '@material-ui/core/Typography';

const initialValues = {
  title: '',
  description: '',
  cv: null
};

const schema = yup.object().shape({
  title: yup.string()
    .min(3, 'Title needs to be at least 3 characters long'),
  description: yup.string().min(10, 'Title needs to be at least 10 characters long'),
  cv: yup.mixed().required('CV is required')
});

const useStyles = makeStyles((theme) => ({
  formTitle: {
    fontSize: 24,
    marginBottom: 20
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    color: '#4579b4',
    border: '1px solid rgba(69, 121, 180, 0.5)'
  }
}));

export default function CreateProfilePopup ({ candidateId, handleSubmitProfile, toggleCreateProfile }) {
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState(MODES.EDITING);
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors] = useForm(initialValues, schema);

  const validateThenCreateCandidate = async () => {
    setMode(MODES.SAVING);
    if (isValid) {
      try {
        setMode(MODES.SAVING);
        await handleSubmitProfile(values, candidateId);
        setMode(MODES.EDITING);
      } catch (e) {
        setMode(MODES.EDITING);
      }
    }
  }

  return (
    <Paper style={{ margin: 0 }}>
      <Typography component='h1' className={classes.formTitle}>Add a profile</Typography>
      <p style={{ marginBottom: 10 }}>
        A profile targets the type of role you are applying for. E.g. you may have one profile targeting project management roles, and
        another profile targeting business analyst roles. You can share each profile individually.
      </p>

      <Divider />

      <form style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch' }}>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <Typography component='h3' className={classes.formSubtitle}>Profile title*</Typography>
            <small>E.g. "Senior software developer" or "Pysichal therapist"</small>
            <TextField
              name='title'
              value={values.title}
              label='Title'
              placeholder='Title'
              fullWidth
              variant='outlined'
              onChange={onValueChange}
              required
              margin='dense'
              helperText={errors.has('title') && errors.get('title').error.message}
              error={errors.has('title')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component='h3' className={classes.formSubtitle}>Profile summary*</Typography>
            <small>300 characters summary of the CV.</small>
            <TextField
              name='description'
              value={values.description}
              label='Profile description'
              fullWidth
              variant='outlined'
              onChange={onValueChange}
              required
              margin='dense'
              multiline
              rows='3'
              helperText={errors.has('description') && errors.get('description').error.message}
              error={errors.has('description')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <Typography component='h3' className={classes.formSubtitle}>Upload a CV*</Typography>
            <small>PDF files only</small>
            <br />
            <Button
              variant='contained'
              color='primary'
              component='label'
              disabled={mode === MODES.SAVING}
              size='small'
              style={{ alignSelf: 'flex-start', minWidth: 200 }}
              fullWidth={isSmOrSmaller}
            >
              Add CV
              {!!values.cv &&
                <CheckIcon style={{ position: 'absolute', right: 10 }} />}
              <input
                name='cv'
                type='file'
                accept='.pdf'
                onChange={onValueChange}
                style={{ display: 'none' }}
              />
            </Button>
          </Grid>
        </Grid>
        <Divider />

        <Grid container spacing={1} justify='space-between' alignItems='center'>
          <Grid item xs={12} sm md />

          <Grid item xs={12} sm={2} md={1}>
            <Button
              variant='outlined'
              color='primary'
              onClick={toggleCreateProfile}
              disabled={mode === MODES.SAVING}
              fullWidth
            >CANCEL
            </Button>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Button
              onClick={validateThenCreateCandidate}
              variant='contained'
              color='primary'
              disabled={!isValid || mode === MODES.SAVING}
              fullWidth
            >
              Create profile
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
