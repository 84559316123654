import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useForm from '../../hooks/use-form';
import { AuthContext } from '../../contexts/auth';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({

}));

const initialValues = {
  displayName: '',
  email: '',
  role: 'manager'
};

const schema = yup.object().shape({
  displayName: yup.string()
    .min(2, 'Name needs to be at least 3 characters long'),
  email: yup.string()
    .email('Please specify a valid email address')
    .required('You need to supply an email adress.'),
  role: yup.string()
    .oneOf(['administrator', 'manager'])
    .required('Role is required.')
});

export default function AddColleagueForm ({ companyId, onClose, open, addInvite }) {
  const { account } = useContext(AuthContext);
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors, validateAll, clear] = useForm(initialValues, schema);

  const handleClose = () => {
    onClose(values);
    clear();
  };

  const handleAdd = async () => {
    await addInvite(companyId, account, values.displayName, values.email, values.role);
    onClose(values);
    clear();
  };

  return (
    <div>

      <div dividers>
        <form>
          <Grid container>
            <Grid item md={40}>
              <TextField
                label='Contact name'
                name='displayName'
                value={values.displayName}
                onChange={onValueChange}
                required
                fullWidth
                helperText={errors.has('displayName') && errors.get('displayName').error.message}
                error={errors.has('displayName')}
                margin='normal'
                variant='outlined'
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label='Email'
                name='email'
                value={values.email}
                onChange={onValueChange}
                required
                fullWidth
                helperText={errors.has('email') && errors.get('email').error.message}
                error={errors.has('email')}
                margin='normal'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3}>
              <Select
                value={values.role}
                onChange={onValueChange}
                label='Role'
                required
                inputProps={{
                  name: 'role',
                  id: 'role'
                }}
                error={errors.has('role')}
                style={{ marginTop: 16 }}
                fullWidth
              >
                <MenuItem value='administrator'>Administrator</MenuItem>
                <MenuItem value='manager'>Manager</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </form>
      </div>

      <Button disabled={!isValid} onClick={handleAdd} variant='contained' color='primary'>
        Send invite
      </Button>
    </div>
  );
}
