import React, { useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

import {
  handleLogin,
  getAnonymousUserData,
  anonymousMoveShared
} from '../../actions/account';

const useStyles = makeStyles(theme => ({
  socialItemRoot: {
    padding: '0 5px'
  },
  socialItem: {
    border: '1px solid #4579b4',
    borderRadius: 4,
    marginBottom: 10,
    '& button': {
      display: 'block',
      padding: '15px 15px',
      width: '100%',
      background: 'transparent',
      textTransform: 'uppercase',
      border: 0,
      cursor: 'pointer',
      textAlign: 'left',
      color: '#5e6e89',
      '& img': {
        height: 25,
        verticalAlign: 'middle',
        marginRight: 15
      }
    }
  }
}));

export default function GoogleLogin ({ firebaseApp, firebaseAuth, fullWidth }) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const { showToast } = useContext(DialogsAndToastsContext);

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      // Check if there was anonymous data attached to the account
      const { annonUserUid, anonData, sharedProfiles } = await getAnonymousUserData();
      if (annonUserUid){
        // We need to delete data at this point to avoid issues with firestore.rules
        await firebaseApp.db.doc(`accounts/${annonUserUid}`).delete();
      }

      const socialAuthUser = await firebaseApp.doSignInWithGoogle();

      // Check if there was anonymous data and move shared profiles if needed
      if (annonUserUid){
        await anonymousMoveShared(anonData, sharedProfiles, socialAuthUser.user);
      }

      await handleLogin(socialAuthUser);
      setRedirect('/account');
    } catch (e) {
      setError(e);
      showToast({ variant: 'error', message: e.message });
    }

    event.preventDefault();
  };

 	if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Grid item xs={12} sm={fullWidth ? 12 : 6} md={fullWidth ? 12 : 6} className={classes.socialItemRoot}>
      <div className={classes.socialItem}>
        <form onSubmit={onSubmit}>
          <button type='submit'>
            <img alt='Google logo' src='/app-img/social/google.svg' />
            {fullWidth ? <>Sign up with Google</> : <>Sign in with Google</>}
          </button>

          {error && false && <p>{error.message}</p>}
        </form>
      </div>
    </Grid>
  );
}
