import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Avatar, { SIZE } from './avatar';
import Profile from './share/profile';
import NoDataInformation from '../components/no-data-information';
import Hidden from '@material-ui/core/Hidden';

import {
  deleteCandidate
} from '../actions/candidate';
import Confirm from './confirm';

const useStyles = makeStyles(theme => ({
  expansionPanelRoot: {
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: 'none',
    marginTop: '16px !important',
    marginBottom: '16px !important',
    background: 'transparent',
    maxWidth: 992,
    margin: '16px auto !important',
    '&.expanded': {
      margin: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  expansionPanelSummaryRoot: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  expansionPanelSummaryContent: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '&.expanded': {
      marginBottom: 14
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiGrid-container': {
      }
    }
  },
  expansionPanelDetails: {
    display: 'block',
    flexDirection: 'column',
    padding: theme.spacing(4),
    borderTop: '1px solid #d7d7d7',
    paddingTop: 0,
    '& > div > .MuiGrid-container': {
      position: 'relative',
      '& .MuiGrid-item': {
        [theme.breakpoints.down('sm')]: {
          '& .MuiIconButton-sizeSmall': {
            position: 'absolute',
            top: 0,
            right: 0
          }
        }
      }
    },
    '& .MuiGrid-container .MuiAvatar-circle': {
      [theme.breakpoints.down('sm')]: {
      }
    }
  },
  avatar: {
    marginRight: theme.spacing(4)
  },
  candidateName: {
    margin: 0
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.lightText.main
  },
  profileHeader: {
    borderWidth: 1,
    borderRadius: 4,
    //backgroundColor: theme.palette.accentLight.main,
    padding: theme.spacing(1),
    padding: '20px 5px 5px',
    background: 'transparent',
    borderTop: '1px solid #d7d7d7',
  },
  expansionIcon: {
    borderWidth: 0,
    borderRadius: 100,
    color: theme.palette.lightText.main,
    backgroundColor: theme.palette.background.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: theme.spacing(1)
  },
  deleteButton: {
    color: theme.palette.lightText.main
  }
}));

export default function Candidate (props) {
  const classes = useStyles();
  const profiles = props.profiles ? props.profiles : {};
  const profileCount = Object.keys(profiles).length;
  const showNoMyProfile = props.myProfile && Object.keys(profiles).length === 0;
  const { location, canDelete, isAnonymous } = props;
  const [rerender, setRerender] = useState(false);

  const deleteProfileParent = async (profileKey) => {
    await delete profiles[profileKey];
    setRerender(!rerender);
  }

  return (
    <Accordion
      key={props.id}
      onChange={props.setExpanded}
      expanded={props.expanded}
      classes={{
        root: classes.expansionPanelRoot,
        expanded: 'expanded'
      }}
    >
      <AccordionSummary
        expandIcon={props.myProfile ? null : <Hidden only={['xs', 'sm']}><ExpandMoreIcon className={classes.expansionIcon} /></Hidden>}
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent,
          expanded: 'expanded'
        }}
      >
        <Grid container direction='row' alignItems='center' alignContent='center'>
          <Grid item className={classes.avatar}>
            <Avatar
              displayName={props.displayName}
              photo={props.photo}
              size={SIZE.MEGA}
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid item>
            <h1 className={classes.candidateName}>{props.displayName}</h1>
            {props.description ? <div className={classes.description}>{props.description}</div> : null}
          </Grid>
        </Grid>
      </AccordionSummary>

      {isAnonymous &&
        <AccordionDetails
          className={classes.expansionPanelDetails}
        >
          <div style={{ marginTop: 40, marginBottom: 100 }}>
            <NoDataInformation
              title='Do you want to <b>share your CV with someone?</b>'
              actionTitle='Login'
              subTitle='Login or create a free Skilldrop account'
              to={`/login?to=${location.pathname}`}
              image='/app-img/empty-my-profile.png'
            />
          </div>
        </AccordionDetails>}

      {!isAnonymous && showNoMyProfile &&
        <AccordionDetails
          className={classes.expansionPanelDetails}
        >
          <div style={{ marginTop: 40, marginBottom: 100 }}>
            <NoDataInformation
              title='Do you want to <b>share your CV with someone?</b>'
              actionTitle='GET STARTED'
              subTitle='Then you have come to the right place! :)'
              to={`${props.match.path}${props.id}/create-profile`}
              bullets={[
                'Create at least one profile and upload your CV',
                'Share access controlled links'
              ]}
              image='/app-img/empty-my-profile.png'
            />
          </div>
        </AccordionDetails>}

      {!showNoMyProfile &&
        <AccordionDetails
          className={classes.expansionPanelDetails}
        >
          <Grid container justify='space-between' className={classes.profileHeader}>
            <Grid item>
              Profiles ({profileCount})
            </Grid>
            <Grid item>
              <Link to={`${props.match.path}${props.id}/create-profile`} className='action-link'>
                Add profile
              </Link>
            </Grid>
          </Grid>

          {Object.keys(profiles).map(profileKey => {
            const profile = profiles[profileKey];
            const applicationCount = profile.applications ? Object.keys(profile.applications).length : 0;
            const shareCount = profile.links ? Object.keys(profile.links).length : 0;
            return (
              <Profile
                companyId={props.companyId}
                profileKey={profileKey}
                accountId={props.id}
                key={profileKey}
                displayName={props.displayName}
                photo={props.photo}
                profile={profile}
                applicationCount={applicationCount}
                deleteProfileParent={deleteProfileParent}
                shareCount={shareCount}
                match={props.match}
              />
            );
          })}

          {canDelete &&
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Confirm title={`Are you sure you want to remove "${props.displayName}" from Our People?`}>
                {confirm => (
                  <Button
                    size='small'
                    variant='text'
                    onClick={confirm(async () => { await deleteCandidate(props.companyId, props.id); props.handleRerender(); })}
                    className={classes.deleteButton}
                  >Remove person
                  </Button>
                )}
              </Confirm>
            </div>}

        </AccordionDetails>}
    </Accordion>
  );
}
