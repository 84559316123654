import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import LoggedOutResetPassword from './logged-out-reset-password';
import firebase from 'firebase/app';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

export default function CustomResetPassword () {
  const [errorMessage, setErrorMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const { showToast } = useContext(DialogsAndToastsContext);

  const resetUserPassword = async (email, password) => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      showToast({ message: 'Please check your email' });

      setRedirect('/login');
    } catch (e) {
      setErrorMessage(e.message);
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      <LoggedOutResetPassword errorMessage={errorMessage} resetUserPassword={resetUserPassword} />
    </div>
  );
}
