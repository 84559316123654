import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import LoggedOutVerifyNewPassword from './logged-out-verify-new-password';
import LoggedOutVerifyNewUserEmail from './logged-out-new-user-email';
import LoggedOutVerifyNewUserEmailChange from './logged-out-new-user-email-change';
import { verifyResetNewPassword, verifyNewUserEmail, handleRecoverEmail, checkActionCode } from '../../actions/account';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  },
  redText: {
    fontSize: 11,
    color: '#f00'
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

export default function CustomVerifyNewPassword () {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const [disabledInputs, setDisabledInputs] = useState(false);
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');
  const [actionCompleted, setActionCompleted] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await checkActionCode(oobCode);
      } catch (e) {
        setErrorMessage(e.message);
        setDisabledInputs(true);
      }
    };

    fetchData();
  }, [oobCode]);

  const resetUserPassword = async (newPassword, authCode) => {
    try {
      await verifyResetNewPassword(newPassword, authCode);

      setActionCompleted('resetPassword');
    } catch (e) {
      setErrorMessage(e.message);
    }
  }

  const verifyUserEmail = async (authCode) => {
    try {
      await verifyNewUserEmail(authCode);

      setActionCompleted('verifyEmail');
    } catch (e) {
      setErrorMessage(e.message);
    }
  }

  const recoverEmail = async (authCode) => {
    try {
      await handleRecoverEmail(authCode);

      setActionCompleted('recoverEmail');
    } catch (e) {
      setErrorMessage(e.message);
    }
  }

  if (!mode) {
    return <Redirect to='/login' />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <Typography component='h1' className={classes.formTitle}>
        {mode == 'resetPassword' && !actionCompleted ? <span>Verify new password</span> : null}
        {mode == 'verifyEmail' && !actionCompleted ? <span>Verify your email address</span> : null}
        {mode == 'recoverEmail' && !actionCompleted ? <span>Verify email address change</span> : null}
        {actionCompleted ? <span>Success!</span> : null}
      </Typography>
      {disabledInputs
        ? <>
          <div style={{ textAlign: 'center', margin: '25px 0 45px 0' }}>
            <img className={classes.statusIcon} src='/app-img/error_icon.png' alt='Success icon' />
          </div>
          <p style={{ textAlign: 'center' }}>It seems that the code you've provided is either expired or invalid.</p>
          <Grid container spacing={1} justify='center' alignItems='center' style={{ marginBottom: 35 }}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setRedirect('/login')}
            >Login and create new link
            </Button>
          </Grid>
        </>
        : null}
      <div>
        {mode == 'resetPassword' && !disabledInputs && !actionCompleted ? <LoggedOutVerifyNewPassword errorMessage={errorMessage} resetUserPassword={resetUserPassword} disabledInputs={disabledInputs} setErrorMessage={setErrorMessage} /> : null}
        {mode == 'verifyEmail' && !disabledInputs && !actionCompleted ? <LoggedOutVerifyNewUserEmail errorMessage={errorMessage} verifyUserEmail={verifyUserEmail} disabledInputs={disabledInputs} setErrorMessage={setErrorMessage} /> : null}
        {mode == 'recoverEmail' && !disabledInputs && !actionCompleted ? <LoggedOutVerifyNewUserEmailChange errorMessage={errorMessage} handleRecoverEmail={recoverEmail} disabledInputs={disabledInputs} setErrorMessage={setErrorMessage} /> : null}

        {actionCompleted == 'resetPassword'
          ? <>
            <div style={{ textAlign: 'center', margin: '25px 0 45px 0' }}>
              <img className={classes.statusIcon} src='/app-img/success_icon.png' alt='Success icon' />
            </div>
            <p style={{ textAlign: 'center' }}>You have successfully changed your password. Please login.</p>
            <Grid container spacing={1} justify='center' alignItems='center' style={{ marginBottom: 35 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setRedirect('/login')}
              >Back to login
              </Button>
            </Grid>
          </>
          : null}

        {actionCompleted == 'verifyEmail'
          ? <>
            <div style={{ textAlign: 'center', margin: '25px 0 45px 0' }}>
              <img className={classes.statusIcon} src='/app-img/success_icon.png' alt='Success icon' />
            </div>
            <p style={{ textAlign: 'center' }}>You have successfully verified your email address.</p>
            <Grid container spacing={1} justify='center' alignItems='center' style={{ marginBottom: 35 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setRedirect('/account')}
              >Let's get started
              </Button>
            </Grid>
          </>
          : null}

        {actionCompleted == 'recoverEmail'
          ? <>
            <div style={{ textAlign: 'center', margin: '25px 0 45px 0' }}>
              <img className={classes.statusIcon} src='/app-img/success_icon.png' alt='Success icon' />
            </div>
            <p style={{ textAlign: 'center' }}>You have successfully recovered your email address.</p>
            <Grid container spacing={1} justify='center' alignItems='center' style={{ marginBottom: 35 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setRedirect('/login')}
              >Back to login
              </Button>
            </Grid>

          </>
          : null}

      </div>
    </>
  );
}
