import React, { useState } from 'react';
import P from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';

const menuId = 'tag-menu';
const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    width: 150,
    borderRadius: 5,
    marginRight: theme.spacing(1)
  },
  buttonLabel: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export default function Tag ({ tag, tags, handleTagSelected }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const classes = useStyles();

  const handleMenuOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = event => {
    event && event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuItemClicked = (event, t) => {
    event.stopPropagation();
    handleTagSelected(t);
    handleMenuClose();
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {tags.map(t => (
        <MenuItem key={t} selected={tag === t} onClick={event => handleMenuItemClicked(event, t)}>
          {t}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={handleMenuOpen}
        classes={{
          root: classes.buttonRoot,
          label: classes.buttonLabel
        }}
      >
        {tag}
      </Button>

      {renderMenu}
    </>
  );
}

Tag.propTypes = {
  tag: P.string,
  tags: P.arrayOf(P.string),
  handleTagSelected: P.func
};

Tag.defaultProps = {
  tag: 'NO TAG SELECTED',
  tags: [],
  handleTagSelected: () => {}
};
