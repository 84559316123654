import firebase from 'firebase/app';
import firebaseApp from '../firebase-app';
import uuidv4 from 'uuid/v4';

export async function createJob (companyId, job, account) {
  try {
    const jobRef = firebaseApp.db.collection(`companies/${companyId}/jobs`).doc();
    const aclRef = firebaseApp.db.collection(`companies/${companyId}/jobs/${jobRef.id}/private_data/`).doc('private_document');
    const jobPrivRef = firebaseApp.db.collection(`companies/${companyId}/jobs/${jobRef.id}/private_data/`).doc('job_status');
    const accountData = account.data();

    const companyRef = firebaseApp.db.doc(`companies/${companyId}`);
    const companyDoc = await companyRef.get();
    const companyData = companyDoc.data() || {};

    const socialMediaInfo = {
      socialTitle: companyData.name + ' - ' + job.title,
      socialDescription: job.description,
      socialImageLink: companyData.logo
    }

    // Create dynamic link
    const dynamicLink = await firebaseApp.createDynamicLink(
      `/companies/${companyId}/submit-application/${jobRef.id}`, socialMediaInfo
    );

    const batch = firebaseApp.db.batch();
    batch.set(jobRef, {
      ...job,
      applicationCount: 0,
      dynamicLink,
      done: false,
      created: firebase.firestore.FieldValue.serverTimestamp()
    });
    batch.set(aclRef, {
      done: false,
      accounts: {
        [account.id]: {
          displayName: accountData.displayName,
          email: accountData.email,
          photo: accountData.photo
        }
      }
    });
    batch.set(jobPrivRef, {
      done: false
    });

    await batch.commit();

    return { id: jobRef.id, dynamicLink: dynamicLink };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function updateJob (companyId, id, job) {
  const collection = firebaseApp.db.collection(`companies/${companyId}/jobs`);
  try {
    return await collection
      .doc(id)
      .update(job);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteJob (companyId, id) {
  const collection = firebaseApp.db.collection(`companies/${companyId}/jobs`);
  try {
    return await collection
      .doc(id)
      .delete();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function addInvite (companyId, jobId, byAccountRef, email, id) {
  try {
    const byAccount = byAccountRef.data();
    const inviteRefPath = `companies/${companyId}/jobs/${jobId}/invites/${email}`;
    const inviteRef = firebaseApp.db.doc(inviteRefPath);
    await inviteRef.set({
      id,
      email,
      code: uuidv4(),
      invitedBy: {
        displayName: byAccount.displayName,
        photo: byAccount.photo
      },
      created: firebase.firestore.FieldValue.serverTimestamp(),
      touched: firebase.firestore.FieldValue.serverTimestamp()
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function touchInvite (companyId, jobId, email) {
  try {
    const inviteRefPath = `companies/${companyId}/jobs/${jobId}/invites/${email}`;
    const inviteRef = firebaseApp.db.doc(inviteRefPath);
    return inviteRef.update({
      touched: new Date()
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteMember (companyId, jobId, id) {
  const privateDocPath = `companies/${companyId}/jobs/${jobId}/private_data/private_document`;
  try {
    const privateDocRef = firebaseApp.db.doc(privateDocPath);
    return await privateDocRef.update({
      [`accounts.${id}`]: firebase.firestore.FieldValue.delete()
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteInvite (companyId, jobId, email) {
  const invitePath = `companies/${companyId}/jobs/${jobId}/invites/${email}`;
  try {
    return await firebaseApp.db.doc(invitePath).delete();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function markAsDone (companyId, jobId) {
  const batch = firebaseApp.db.batch();
  const jobDocPath = `companies/${companyId}/jobs/${jobId}`;
  const privateDocPath = `companies/${companyId}/jobs/${jobId}/private_data/private_document`;
  const jobPrivDocPath = `companies/${companyId}/jobs/${jobId}/private_data/job_status`;

  try {
    const jobDocRef = firebaseApp.db.doc(jobDocPath);
    const privateDocRef = firebaseApp.db.doc(privateDocPath);
    const jobPrivDocRef = firebaseApp.db.doc(jobPrivDocPath);

    batch.update(jobDocRef, {
      done: true
    });

    batch.set(jobPrivDocRef, {
      done: true
    }, { merge: true });

    batch.set(privateDocRef, {
      done: true
    }, { merge: true });

    return await batch.commit();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function reopenJob (companyId, jobId) {
  const batch = firebaseApp.db.batch();
  const jobDocPath = `companies/${companyId}/jobs/${jobId}`;
  const privateDocPath = `companies/${companyId}/jobs/${jobId}/private_data/private_document`;
  const jobPrivDocPath = `companies/${companyId}/jobs/${jobId}/private_data/job_status`;

  try {
    const jobDocRef = firebaseApp.db.doc(jobDocPath);
    const privateDocRef = firebaseApp.db.doc(privateDocPath);
    const jobPrivDocRef = firebaseApp.db.doc(jobPrivDocPath);

    batch.update(jobDocRef, {
      done: false
    });

    batch.set(jobPrivDocRef, {
      done: false
    }, { merge: true });

    batch.set(privateDocRef, {
      done: false
    }, { merge: true });

    return await batch.commit();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function setTag (companyId, jobId, tag) {
  const privateDocPath = `companies/${companyId}/jobs/${jobId}/private_data/private_document`;
  try {
    const privateDocRef = firebaseApp.db.doc(privateDocPath)
    return await privateDocRef.set({ tag }, { merge: true });
  } catch (e) {
    console.error(e);
    throw e;
  }
}
