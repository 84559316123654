import uuidv4 from 'uuid/v4';
import path from 'path';
import firebase from 'firebase/app';
import firebaseApp from '../firebase-app';
import moment from 'moment';
import { SHARE_PROFILE_LINK_EXPIRE_IN_DAYS, PUBLIC_CACHE_SECONDS } from '../constants/config';

export async function createCandidate (values, companyId, handleProgress, handleError, handleSuccess) {
  const collection = firebaseApp.db.collection(`companies/${companyId}/candidates`);
  try {
    const candidate = collection.doc();
    if (values.photo instanceof FileList) {
      const file = values.photo[0];
      const storageRef = firebaseApp.storage.ref();
      const fileEnding = path.extname(file.name);
      const uniqueId = uuidv4();
      const fullPath = `companies/${companyId}/candidates/${candidate.id}/photo-${uniqueId}${fileEnding}`;
      const uploadTask = storageRef.child(fullPath).put(
        file,
        { cacheControl: `public, max-age=${PUBLIC_CACHE_SECONDS}, immutable` }
      );
      uploadTask.on('state_changed',
        handleProgress,
        handleError,
        handleSuccess
      );
      const upload = await uploadTask;
      values.photo = await upload.ref.getDownloadURL();
      values.photoPath = fullPath;
    }
    await candidate.set({
      ...values,
      created: firebase.firestore.FieldValue.serverTimestamp()
    });
    return candidate.id;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function createProfile (candidateId, companyId, values, handleProgress, handleError, handleSuccess) {
  try {
    const doc = firebaseApp.db.doc(`companies/${companyId}/candidates/${candidateId}`);
    const file = values.cv[0];
    const storageRef = firebaseApp.storage.ref();
    const profileId = uuidv4();
    const fileEnding = path.extname(file.name);
    const uniqueId = uuidv4();
    const fullPath = `companies/${companyId}/candidates/${candidateId}/${profileId}-${uniqueId}${fileEnding}`;
    const uploadTask = storageRef.child(fullPath).put(file);
    uploadTask.on('state_changed',
      handleProgress,
      handleError,
      handleSuccess
    );
    const uploadResult = await uploadTask;
    const cv = await uploadResult.ref.getDownloadURL();
    await doc.update({
      [`profiles.${profileId}`]: {
        title: values.title,
        description: values.description,
        edited: firebase.firestore.FieldValue.serverTimestamp(),
        applications: {},
        links: {},
        cv,
        cvPath: fullPath
      }
    });
    return profileId;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function updateProfile (candidateId, companyId, profileId, values) {
  try {
    const doc = firebaseApp.db.doc(`companies/${companyId}/candidates/${candidateId}`);
    await doc.update({
      [`profiles.${profileId}.title`]: values.title,
      [`profiles.${profileId}.description`]: values.description,
      [`profiles.${profileId}.edited`]: firebase.firestore.FieldValue.serverTimestamp()
    });
    return profileId;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function shareProfile (candidateId, companyId, profileId, values) {
  try {
    const linkId = uuidv4();
    const doc = firebaseApp.db.doc(`companies/${companyId}/candidates/${candidateId}`);
    const docGet = await doc.get();
    const docData = docGet.data() || {};

    const profile = docData.profiles[profileId];

    const nameSeparated = docData.displayName.split(' ');
    let name = nameSeparated[0];

    if (nameSeparated.length > 1) {
      name += ' ' + nameSeparated[1].charAt(0) + '.';
    }
    const profileTitle = profile.title;

    const socialMediaInfo = {
      socialTitle: name + ' - ' + profileTitle,
      socialDescription: profile.description,
      socialImageLink: docData.photo
    }

    const response = await firebaseApp.functions.httpsCallable('createDynamicLinkCallable')({
      link: `/share/accept-candidate-profile-shared-with-me/${companyId}/${candidateId}/${profileId}/${linkId}`,
      socialMediaInfo
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    await firebaseApp.functions.httpsCallable('addDynamicLinkToTheProfile')({
      companyId,
      candidateId,
      profileId,
      linkId,
      title: values.title,
      link: response.data.shortLink,
      expires: SHARE_PROFILE_LINK_EXPIRE_IN_DAYS
    });

    return response.data.shortLink;
  } catch (e) {
    throw new Error(e.message);
  }
}

export async function deleteProfileLink (candidateId, companyId, profileId, linkId) {
  try {
    const candidate = firebaseApp.db.doc(`companies/${companyId}/candidates/${candidateId}`);

    return candidate.update({
      [`profiles.${profileId}.links.${linkId}`]: firebase.firestore.FieldValue.delete()
    });

    return await firebaseApp.functions.httpsCallable('removeDynamicLinkFromTheProfile')({
      companyId,
      candidateId,
      profileId,
      linkId
    });
  } catch (e) {
    throw new Error(e.message);
  }
}

export async function deleteProfile (candidateId, companyId, profileId) {
  const res = await firebaseApp.functions.httpsCallable('deleteProfile')({
    ownerDocPath: `companies/${companyId}/candidates/${candidateId}`,
    profileId
  });
  if (res && res.data && res.data.success) {
    return res.data;
  }
  throw new Error(res.data);
}

export function extendProfileLink (candidateId, companyId, profileId, linkId, newExpires) {
  const candidate = firebaseApp.db.doc(`companies/${companyId}/candidates/${candidateId}`);
  return candidate.update({
    [`profiles.${profileId}.links.${linkId}.expires`]: newExpires
  });
}

export async function deleteCandidateCallable (companyId, candidateId) {
  const res = await firebaseApp.functions.httpsCallable('onCandidateDeletedCallable')({
    companyId,
    candidateId
  });

  if (res && res.data && res.data.success) {
    return res.data;
  }
  throw new Error(res.data);
}

export function deleteCandidate (companyId, candidateId) {
  return firebaseApp.db.doc(`companies/${companyId}/candidates/${candidateId}`).delete();
}
