import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import firebase from 'firebase/app';
import firebaseApp from '../../firebase-app';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import { MODES } from '../../constants';
import { updateCompany } from '../../actions/company';
import { useDocument } from 'react-firebase-hooks/firestore';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

import CompanyDetailsEditForm from './company-details-edit-form';
import SubscribeToProductWrapper from '../stripe/subscribe-to-product';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
  w100: {
    width: '100%'
  },
  textWarning: {
    color: '#ffae42',
    fontSize: 14
  },
  textSuccess: {
    color: '#00b500',
    fontSize: 14
  },
  tableHead: {
    backgroundColor: theme.palette.accent.main
  },
  tableHeadCell: {
    color: theme.palette.contrast.main
  },
  membersTableHead: {
    backgroundColor: theme.palette.contrast.main
  },
  memberTableHeadCell: {
    color: '#fff'
  },
  expansionMargin: {
    marginBottom: '10px !important',
    marginTop: '10px !important',
  },
  expansionMarginFirst: {
    marginTop: '0 !important'
  },
  expansionPanelRoot: {
    borderWidth: 1,
    borderRadius: '0 !important',
    marginBottom: theme.spacing(2),
    marginTop: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&.expanded': {
      margin: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  expansionPanelSummaryRoot: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  expansionPanelSummaryContent: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '&.expanded': {
      marginBottom: 14
    },
    [theme.breakpoints.down('sm')]: {
      '& small': {
        display: 'block'
      }
    }
  },
  expansionPanelDetails: {
    display: 'block',
    flexDirection: 'column',
    padding: theme.spacing(4),
    paddingTop: 0
  },
  avatar: {
    marginRight: theme.spacing(4)
  },
  avatarInner: {
    width: 56,
    height: 56,
    backgroundColor: theme.palette.darkBlue.main,
    borderRadius: '50%',
    padding: 12,
    color: 'white'
  },
  candidateName: {
    margin: 0
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.lightText.main
  },
  profileHeader: {
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: theme.palette.accentLight.main,
    padding: theme.spacing(1)
  },
  expansionIcon: {
    borderWidth: 0,
    borderRadius: 100,
    color: theme.palette.lightText.main,
    backgroundColor: theme.palette.background.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: theme.spacing(1)
  },
  customExpansionPanel: {
    opacity: '1 !important',
    cursor: 'pointer !important'
  },
  py2: {
    padding: '5px 10px'
  }
}));

export default function CompanySettings ({ match }) {
  const classes = useStyles();
  const [mode, setMode] = useState(MODES.VIEWING);

  const { companyId } = match.params;

  const [companyPrivateDocRef] = useDocument(firebaseApp.db.doc(`companies/${companyId}/private_data/private_document`));
  const [companyStripeDocRef] = useDocument(firebaseApp.db.doc(`companies/${companyId}/private_data/stripe`));
  const [companyStripePrivDocRef] = useDocument(firebaseApp.db.doc(`companies/${companyId}/private_data/stripePriv`));

  const { showToast } = useContext(DialogsAndToastsContext);

  const companyPrivateDoc = companyPrivateDocRef ? companyPrivateDocRef.data() : { accounts: {} };
  const companyStripeDoc = companyStripeDocRef ? companyStripeDocRef.data() : { };
  const companyStripePrivDoc = companyStripePrivDocRef ? companyStripePrivDocRef.data() : { };

  const defaultPanel = 'panel3';
  const [expanded, setExpanded] = React.useState(defaultPanel); // Accordion state

  const [companyRef] = useDocument(firebaseApp.db.doc(`companies/${companyId}`));
  const company = companyRef ? companyRef.data() : {};

  const handleAccordionChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleEditClicked = () => {
    setMode(MODES.EDITING);
  }

  const handleCancelClicked = () => {
    setMode(MODES.VIEWING);
  }

  const handleSaveClicked = async (id, values) => {
    try {
      setMode(MODES.SAVING);
      await updateCompany(
        id,
        values,
        snapshot => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        error => {
          // TODO display snackbar error
          console.error(error);
        },
        done => {
          // TODO display upload complete
          console.log('Upload complete');
        });
      setMode(MODES.VIEWING);
      showToast({ message: 'Company data saved', variant: 'success' })
    } catch (e) {
      setMode(MODES.EDITING);
      showToast({ message: `Save failed: ${e.message}`, variant: 'error' })
    }
  }

  return (
    <div>
      <Accordion
        classes={{
          root: classes.expansionPanelRoot,
          expanded: 'expanded'
        }}
        expanded={expanded === 'panel1'}
        onChange={handleAccordionChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<Hidden only={['xs', 'sm']}><ExpandMoreIcon className={classes.expansionIcon} /></Hidden>}
          classes={{
            root: classes.expansionPanelSummaryRoot,
            content: classes.expansionPanelSummaryContent,
            expanded: 'expanded'
          }}
          aria-controls='panel1-content'
          id='panel1-header'
        >
          <Typography component='div' style={{ fontSize: 20 }} className={classes.heading}>
            <Grid container direction='row' alignItems='center' alignContent='center'>
              <Grid item className={classes.avatar}>
                <BusinessIcon className={classes.avatarInner} style={{ fontSize: 40, verticalAlign: 'middle' }} />
              </Grid>
              <Grid item>
                Company Details {company.customerTaxId ? null : <small className={classes.textWarning}>(no valid tax ID is connected to this account)</small>}
              </Grid>
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.w100}>
          <div className={classes.w100}>
            {company && mode === MODES.VIEWING ? (
              <div className='my-4 pl-1'>
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>Company name</b>
                  {company.name}
                </div>
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>Tax id</b>
                  {company.taxId}
                </div>
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>Phone number</b>
                  {company.phone}
                </div>
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>Website</b>
                  {company.website}
                </div>
                <Divider />
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>Address</b>
                  {company.address}
                </div>
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>Address 2</b>
                  {company.address2}
                </div>
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>ZIP</b>
                  {company.zip}
                </div>
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>City</b>
                  {company.city}
                </div>
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>State</b>
                  {company.state}
                </div>
                <div className={classes.py2}>
                  <b style={{ marginRight: 16 }}>Country</b>
                  {company.country}
                </div>

              </div>) : null}
            {company && mode === MODES.VIEWING &&
              <div style={{ margin: '10px 0' }}><Button color='primary' variant='contained' onClick={handleEditClicked}>Edit company</Button></div>}

            {company && (mode === MODES.EDITING || mode === MODES.SAVING) ? (
              <CompanyDetailsEditForm
                id={companyRef.id}
                currentValues={{
                  name: company.name,
                  website: company.website,
                  logo: company.logo || '',
                  taxId: company.taxId || '',
                  phone: company.phone || '',
                  country: company.country || '',
                  state: company.state || '',
                  city: company.city || '',
                  zip: company.zip || '',
                  address: company.address || '',
                  address2: company.address2 || ''
                }}
                handleSaveClicked={handleSaveClicked}
                handleCancelClicked={handleCancelClicked}
                mode={mode}
              />) : null}

          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'}
        onChange={handleAccordionChange('panel3')}
        classes={{
          root: classes.expansionPanelRoot,
        }}
      >
        <AccordionSummary
          expandIcon={<Hidden only={['xs', 'sm']}><ExpandMoreIcon className={classes.expansionIcon} /></Hidden>}
          classes={{
            root: classes.expansionPanelSummaryRoot,
            content: classes.expansionPanelSummaryContent,
            expanded: 'expanded'
          }}
          aria-controls='panel3-content'
          id='panel3-header'
        >
          <Typography component='div' style={{ fontSize: 20 }} className={classes.heading}>
            <Grid container direction='row' alignItems='center' alignContent='center'>
              <Grid item className={classes.avatar}>
                <AccountBalanceIcon className={classes.avatarInner} style={{ fontSize: 40, verticalAlign: 'middle' }} />
              </Grid>
              <Grid item>
                Company plans
                {companyStripeDoc && companyStripeDoc.planData && companyStripeDoc.planData[0] && companyStripeDoc.planData[0].period && companyStripeDoc.planData[0].period.end ?
                  <small className={classes.textSuccess}> Subscription will be renewed on ({moment(companyStripeDoc.planData[0].period.end * 1000).format('D/M/Y')})</small>
                : null}
              </Grid>
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '5px 20px 20px 20px' }}>
          {company.customerId
            ? <SubscribeToProductWrapper
              companyId={companyId}
              companyPlan={companyStripePrivDoc ? companyStripePrivDoc.planId : null}
              companyPlanStatus={companyStripePrivDoc ? companyStripePrivDoc.status : null}
              companyCountry={company.country ? company.country : null}
              customerId={company.customerId ? company.customerId : null}
              companyEmail={company.email}
              onChange={showToast}
              activeUsers={companyPrivateDoc ? Object.keys(companyPrivateDoc.accounts).length : 0}
            /> : 'Please set correct company details.'}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
