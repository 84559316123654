import React from 'react';
import { Accordion } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Avatar, { SIZE } from './avatar';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
  expansionPanelRoot: {
    border: 0,
    borderRadius: 4,
    boxShadow: 'none',
    marginTop: '16px !important',
    marginBottom: '16px !important',
    border: '1px solid #e8e9ea',
    '&.expanded': {
      margin: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    '& > a': {
      textDecoration: 'none',
      color: '#000'
    }
  },
  expansionPanelSummaryRoot: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  expansionPanelSummaryContent: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '&.expanded': {
      marginBottom: 14
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiGrid-container': {
      }
    }
  },
  expansionPanelDetails: {
    display: 'block',
    flexDirection: 'column',
    padding: theme.spacing(4),
    paddingTop: 0,
    '& > div > .MuiGrid-container': {
      position: 'relative',
      '& .MuiGrid-item': {
        [theme.breakpoints.down('sm')]: {
          '& .MuiIconButton-sizeSmall': {
            position: 'absolute',
            top: 0,
            right: 0
          }
        }
      }
    },
    '& .MuiGrid-container .MuiAvatar-circle': {
      [theme.breakpoints.down('sm')]: {
      }
    }
  },
  avatar: {
  },
  candidateName: {
    margin: 0,
    '& small': {
      marginTop: 10,
      fontWeight: 300,
      opacity: 0.8,
      display: 'block'
    }
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.lightText.main
  },
  profileHeader: {
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: theme.palette.accentLight.main,
    padding: theme.spacing(1)
  },
  expansionIcon: {
    borderWidth: 0,
    borderRadius: 100,
    color: theme.palette.lightText.main,
    backgroundColor: theme.palette.background.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: theme.spacing(1)
  },
  deleteButton: {
    color: theme.palette.lightText.main
  },
  expiryDate: {
    color: '#5e6e89',
    '& b': {
      marginLeft: 15
    }
  }
}));

export default function AlgoliaCandidate (props) {
  const classes = useStyles();
  const bindAlgoliaProfile = props.bindAlgoliaProfile;
  const createdAt = props && new Date(props.created).toDateString();

  const localBindFirestoreProfle = () => {
    bindAlgoliaProfile(props.id);
  }

  const profiles = props.profiles;

  let titlesArray = profiles && Object.keys(profiles).map( function(i){ return profiles[i].title });
  let titles = titlesArray && titlesArray.join(', ');
  let titlesShort = titles && titles.substr(0, 50) + '...';

  return (
    <Accordion
      key={props.id}
      onChange={props.setExpanded}
      expanded={props.expanded}
      classes={{
        root: classes.expansionPanelRoot,
        expanded: 'expanded'
      }}
    >
      <Link to='#' onClick={localBindFirestoreProfle}>
        <div
          classes={{
            root: classes.expansionPanelSummaryRoot,
            content: classes.expansionPanelSummaryContent,
            expanded: 'expanded'
          }}
          style={{ padding: 15 }}
        >
          <Grid container direction='row' alignItems='center' alignContent='center'>
            <Grid item className={classes.avatar} md={6}>
              <Grid container direction='row' alignItems='center' alignContent='center'>
                <Grid item className={classes.avatar} style={{ marginRight: 20 }}>
                  <Avatar
                    displayName={props.name}
                    photo={props.photo}
                    size={SIZE.MEGA}
                    style={{ margin: 0 }}
                  />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <h1 className={classes.candidateName}>
                    {props.name}
                    <small>{titlesShort}</small>
                  </h1>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.avatar} md={6}>
              <Grid container direction='row' alignItems='center' alignContent='center'>
                <Hidden smDown>
                  <Grid item className={classes.expiryDate} md={5}>
                    <span style={{ marginRight: 10 }}>Location: <b>{props && props.location ? props.location.description : null}</b></span>
                  </Grid>
                  <Grid item className={classes.expiryDate} md={5}>
                    <span style={{ marginRight: 10 }}>Created: <b>{createdAt}</b></span>
                  </Grid>
                  <Grid item className={classes.expiryDate} md={2}>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Link>
    </Accordion>
  );
}
