import React, { useContext } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import firebaseApp from '../../firebase-app';
import Avatar, { SIZE } from '../avatar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Profile from './profile';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Delete from '@material-ui/icons/Delete';
import {
  deleteCandidateCallable,
  createProfile as createCandidateProfile
} from '../../actions/candidate';
import Confirm from '../confirm';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CreateProfileForm from './create-profile-popup';

import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    '& .MuiDrawer-paper': {
      width: '100%',
      maxWidth: '100%',
      background: '#F3F3F5'
    }
  },
  main: {
    width: '100%',
    maxWidth: '100%',
    padding: 15
  },
  tabsnames: {
    padding: 10,
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  mainInner: {
    maxWidth: 992,
    margin: '20px auto 0 auto',
    '& .MuiGrid-container': {
      width: '100%'
    }
  },
  candidateName: {
    marginBottom: 0,
    marginLeft: 15
  },
  addProfileLink: {
    lineHeight: 1.9,
    color: '#636e89'
  },
  profileHeader: {
    padding: '20px 5px 5px',
    borderTop: '1px solid #d7d7d7',
    background: 'transparent',
    marginTop: 20
  },
  deleteButton: {
    color: '#c0c0c0',
    display: 'none',
    lineHeight: 1
  },
  profileData: {
    '&:hover .MuiButton-sizeSmall': {
      display: 'block'
    }
  }
}));

export default function CustomSlideInCandidate (props) {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [privateDoc, privateDocLoading, privateDocError] = useDocument(
    firebaseApp.db.doc(`companies/${props.companyId}/candidates/${props.candidateId}`)
  );
  const toggleDrawer = props.toggleDrawer;
  const { canDelete } = props;

  const candidate = (privateDoc && privateDoc.data()) || {};
  const profiles = candidate.profiles ? candidate.profiles : {};
  const [customLoading, setCustomLoading] = React.useState(false);
  const [createProfileOpen, setCreateProfileOpen] = React.useState(false);
  const { showToast } = useContext(DialogsAndToastsContext);
  const profileCount = Object.keys(profiles).length;

  const toggleCreateProfile = () => {
    setCreateProfileOpen(!createProfileOpen);
  }

  const handleCreateProfileClose = () => {
    return true;
  }

  const handleSubmitOurPeopleProfile = async (values, candidateId) => {
    try {
      const profile = await createCandidateProfile(
        candidateId,
        props.companyId,
        values,
        progress => console.log(progress),
        error => console.log(error),
        success => console.log('CV uploaded', success)
      );

      if (profile) {
        toggleCreateProfile();
      }
    } catch (e) {
      // do nothing, handled by form
      let message = e.message;

      if (message == 'The caller does not have permission' || message == 'Missing or insufficient permissions.') {
        message = 'You have reached maximum ammount of profiles you can create. Please upgrade your plan!';
      }
      showToast({
        message: message,
        variant: "error"
      });
    }
  }

  if (customLoading) {
    return (
      <SwipeableDrawer
        open={props.show}
        anchor='right'
        onClose={toggleDrawer}
        onOpen={() => {}}
        className={classes.root}
      >
        <Grid container>
          <AppBar position='static' style={{ background: '#fff', boxShadow: 'none' }}>
            <Toolbar>
              <Button onClick={toggleDrawer} style={{ color: '#000' }}><ArrowBackIosIcon /> Back</Button>
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid container className={classes.main}>
          <Grid container className={classes.mainInner}>
            <div className={classes.fullWidth}>
              {customLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div> : null}
              {customLoading
                ? <div style={{ textAlign: 'center' }}>
                  <p>Deleting candidate. Please wait...</p>
                </div>
                : null}
            </div>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    );
  }

  return (
    <React.Fragment key='RIGHT'>
      <SwipeableDrawer
        open={props.show}
        anchor='right'
        onClose={toggleDrawer}
        onOpen={() => {}}
        className={classes.root}
      >
        <Grid container>
          <AppBar position='static' style={{ background: '#fff', boxShadow: 'none' }}>
            <Toolbar>
              <Button onClick={toggleDrawer} style={{ color: '#000' }}><ArrowBackIosIcon /> Back</Button>
            </Toolbar>
          </AppBar>
        </Grid>
        {candidate && !privateDocLoading
          ? <>
            <Grid container className={classes.main}>
              <Grid container className={classes.mainInner}>
                <div className={classes.fullWidth}>
                  <Grid container className={classes.profileData} direction='row' alignItems='center' alignContent='center'>
                    <Grid item className={classes.avatar}>
                      <Avatar
                        displayName={candidate.displayName}
                        photo={candidate.photo}
                        size={SIZE.MEGA}
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item>
                      <h1 className={classes.candidateName}>{candidate.displayName}</h1>
                      {candidate.description ? <div className={classes.description}>{candidate.description}</div> : null}
                    </Grid>
                    <Grid style={{ flex: 1 }} item>
                      {canDelete &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Confirm title={`Are you sure you want to remove "${candidate.displayName}" from Our People?`}>
                            {confirm => (
                              <Button
                                size='small'
                                variant='text'
                                onClick={confirm(async () => { await deleteCandidateCallable(props.companyId, props.candidateId); setCustomLoading(true); showToast({ message: 'Profile successfully deleted.' }); setTimeout(() => { window.location.reload() }, 2000); })}
                                className={classes.deleteButton}
                              >
                                <Delete />
                              </Button>
                            )}
                          </Confirm>
                        </div>}
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center' alignContent='center'>
                    <Grid container justify='space-between' className={classes.profileHeader}>
                      <Grid item>
                        Profiles ({profileCount})
                      </Grid>
                      <Grid item>
                        <Link to='#' onClick={toggleCreateProfile} className='action-link'>
                          Add profile
                        </Link>
                      </Grid>
                    </Grid>

                    {Object.keys(profiles).map((profileId, index) => {
                      const profile = profiles[profileId];
                      const applicationCount = profile.applications ? Object.keys(profile.applications).length : 0;
                      const shareCount = profile.links ? Object.keys(profile.links).length : 0;

                      return (
                        <div
                          index={index}
                          key={profileId}
                          style={{ width: '100%' }}
                        >
                          <Profile
                            companyId={props.companyId}
                            profileKey={profileId}
                            accountId={privateDoc.id}
                            key={profileId}
                            displayName={candidate.displayName}
                            photo={candidate.photo}
                            profile={profile}
                            applicationCount={applicationCount}
                            shareCount={shareCount}
                            match={props.match}
                          />
                        </div>
                      );
                    })}
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Dialog open={createProfileOpen} onClose={handleCreateProfileClose} maxWidth='lg' style={{ padding: 0 }} aria-labelledby='form-dialog-title'>
              <DialogContent style={{ padding: 0 }}>
                <CreateProfileForm
                  candidateId={privateDoc.id}
                  handleSubmitProfile={handleSubmitOurPeopleProfile}
                  toggleCreateProfile={toggleCreateProfile}
                />
              </DialogContent>
            </Dialog>
          </>
          : null}

      </SwipeableDrawer>
    </React.Fragment>
  )
}
