import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useForm from '../../hooks/use-form';
import { AuthContext } from '../../contexts/auth';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    overflowY: 'visible',
    width: '100%'
  },
  dialogContentRoot: {
    overflowY: 'visible'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const initialValues = {
  displayName: '',
  email: '',
  role: 'manager'
};

const schema = yup.object().shape({
  displayName: yup.string()
    .min(2, 'Name needs to be at least 3 characters long'),
  email: yup.string()
    .email('Please specify a valid email address')
    .required('You need to supply an email adress.'),
  role: yup.string()
    .oneOf(['administrator', 'manager', 'viewer'])
    .required('Role is required.')
});

export default function CompanyDialogAddUser ({ companyId, onClose, open, addInvite }) {
  const { account } = useContext(AuthContext);
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors, validateAll, clear] = useForm(initialValues, schema);

  const handleClose = () => {
    onClose(values);
    clear();
  };

  const handleAdd = async () => {
    await addInvite(companyId, account, values.displayName, values.email, values.role);
    onClose(values);
    clear();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      classes={{ paper: classes.dialogRoot }}
    >
      <DialogTitle id='simple-dialog-title'>
        Add a user
        <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        Fill in the contact details below to add a new member.
      </DialogContent>

      <DialogContent classes={{ root: classes.dialogContentRoot }} dividers>
        <form>
          <TextField
            label='Contact name'
            name='displayName'
            value={values.displayName}
            onChange={onValueChange}
            required
            fullWidth
            helperText={errors.has('displayName') && errors.get('displayName').error.message}
            error={errors.has('displayName')}
            margin='normal'
            variant='outlined'
          />

          <TextField
            label='Email'
            name='email'
            value={values.email}
            onChange={onValueChange}
            required
            fullWidth
            helperText={errors.has('email') && errors.get('email').error.message}
            error={errors.has('email')}
            margin='normal'
            variant='outlined'
          />

          <Select
            value={values.role}
            onChange={onValueChange}
            label='Role'
            required
            inputProps={{
              name: 'role',
              id: 'role'
            }}
            error={errors.has('role')}
            style={{ marginTop: 16 }}
            fullWidth
          >
            <MenuItem value='administrator'>Administrator</MenuItem>
            <MenuItem value='manager'>Manager</MenuItem>
            <MenuItem value='viewer'>Job viewer</MenuItem>
          </Select>
        </form>
      </DialogContent>

      <DialogActions>
        <Button disabled={!isValid} onClick={handleAdd} variant='contained' color='primary'>
          Send invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CompanyDialogAddUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
