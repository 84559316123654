import React from 'react';
import P from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useForm from '../../hooks/use-form';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    overflowY: 'visible'
  },
  dialogTitle: {
    paddingRight: 24 + theme.spacing(6)
  },
  dialogContentRoot: {
    overflowY: 'visible'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const schema = yup.object().shape({
  role: yup.string()
    .oneOf(['administrator', 'manager', 'viewer'])
    .required('Role is required.')
});

export default function CompanyDialogEditUser ({ data, onClose, open, editMember }) {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors, validateAll, clear] = useForm({ role: data.role }, schema);

  const handleClose = () => {
    clear();
    onClose(values);
  };

  const handleEditMember = async () => {
    await editMember(data.companyId, data.displayName, data.id, values.role);
    clear();
    onClose(values);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      classes={{ paper: classes.dialogRoot }}
    >
      <DialogTitle id='simple-dialog-title' className={classes.dialogTitle}>
        Edit {data.displayName}
        <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContentRoot }} dividers>
        <form>
          <Select
            value={values.role}
            onChange={onValueChange}
            label='Role'
            required
            inputProps={{
              name: 'role',
              id: 'role'
            }}
            error={errors.has('role')}
            style={{ marginTop: 16 }}
            fullWidth
          >
            {/* TODO only show same or lower role than own role */}
            <MenuItem value='administrator'>Administrator</MenuItem>
            <MenuItem value='manager'>Manager</MenuItem>
            <MenuItem value='viewer'>Job viewer</MenuItem>
          </Select>
        </form>
      </DialogContent>

      <DialogActions>
        <Button disabled={!isValid} onClick={handleEditMember} variant='contained' color='primary'>
          Edit member
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CompanyDialogEditUser.propTypes = {
  data: P.shape({
    role: P.string.isRequired,
    displayName: P.string.isRequired,
    companyId: P.string.isRequired
  }),
  onClose: P.func.isRequired,
  open: P.bool.isRequired,
  editMember: P.func.isRequired
};
