import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
  constructor () {
    this.firebase = firebase.initializeApp(config);
    this._analytics = firebase.analytics(this.firebase);
    this.analytics = () => {
      if (
        window.cookieconsent.consentDebugger.userConsent.acceptedLevels.tracking
      ) {
        this._analytics.setAnalyticsCollectionEnabled(true);
      } else {
        this._analytics.setAnalyticsCollectionEnabled(false);
      }
      return this._analytics;
    };
    this.auth = firebase.auth(this.firebase);
    this.db = firebase.firestore(this.firebase);

    // this.db.enablePersistence({ synchronizeTabs: true }).then(() => {
    //   console.log("Woohoo! Multi-Tab Persistence!");
    // });
    this.storage = firebase.storage(this.firebase);
    if (process.env.REACT_APP_LOCAL_FUNCTIONS === '1') {
      console.info('Using functions emulator');
      this.functions = this.firebase.functions('us-central1');
      this.functions.useFunctionsEmulator('http://localhost:5001');
    } else {
      this.functions = this.firebase.functions('europe-west1');
    }
    if (process.env.REACT_APP_LOCAL_FIRESTORE === '1') {
      console.info('Using firestore emulator');
      // Note that some docs states you should use port 8081, but that causes CORS errors.
      // One must use the 8080 port.
      this.db.settings({
        host: 'localhost:8080',
        ssl: false
      });
    }

    // App Login providers
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');
  }

  isInitialized () {
    return new Promise(resolve => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  doSignInWithFacebook = async () => {
    return await this.auth.signInWithPopup(this.facebookProvider);
  }

  doSignInWithGoogle = async () => {
    return await this.auth.signInWithPopup(this.googleProvider);
  }

  doSignInWithMicrosoft = async () => {
    return await this.auth.signInWithPopup(this.microsoftProvider);
  }

  async createDynamicLink (link, socialMediaInfo = {}) {
    const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${config.apiKey}`;
    const body = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: `https://${process.env.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN}`,
        link: `${process.env.REACT_APP_FIREBASE_DYNAMIC_LINK_TARGET_BASE_URL}${link}`,
        // androidInfo: {
        //   androidPackageName: "com.example.android"
        // },
        // iosInfo: {
        //   iosBundleId: "com.example.ios"
        // },
        socialMetaTagInfo: socialMediaInfo
      },
      suffix: {
        option: 'UNGUESSABLE'
      }
    });
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body
    });
    const data = await res.json();
    if (!data || !data.shortLink) {
      throw new Error('Short link could not be created');
    }
    return data.shortLink;
  }
}

export default new Firebase();
