import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import Divider from '@material-ui/core/Divider';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckIcon from '@material-ui/icons/Check';
import Paper from '../paper';
import useForm from '../../hooks/use-form';
import { MODES } from '../../constants';
import Typography from '@material-ui/core/Typography';

import DialogShareProfile from './dialog-share-profile';
import { useDocument } from 'react-firebase-hooks/firestore';
import firebaseApp from '../../firebase-app';

import {
  shareProfile,
  deleteProfileLink,
  extendProfileLink
} from '../../actions/account';
import { SHARE_PROFILE_LINK_EXPIRE_EXTEND_DAYS } from '../../constants/config';
import {
  shareProfile as shareCandidateProfile,
  deleteProfileLink as deleteCandidateProfileLink,
  extendProfileLink as extendCandidateProfileLink
} from '../../actions/candidate';
import moment from 'moment';

const initialValues = {
  title: '',
  description: '',
  cv: null
};

const schema = yup.object().shape({
  title: yup.string()
    .min(3, 'Title needs to be at least 3 characters long'),
  description: yup.string().min(10, 'Title needs to be at least 10 characters long'),
  cv: yup.mixed().required('CV is required')
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35,
    border: '1px solid rgb(232, 233, 234)'
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  }
}));

export default function CreateProfileForm ({ match, handleSubmitProfile, companyId }) {
  const theme = useTheme();
  const classes = useStyles();

  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  // eslint-disable-next-line no-unused-vars
  const { candidateId } = match.params;
  const [mode, setMode] = useState(MODES.EDITING);
  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors] = useForm(initialValues, schema);
  const [redirect, setRedirect] = useState();
  const [shareOpen, setShareOpen] = useState(false);
  const [profileKey, setProfileKey] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [privateDoc, privateDocLoading, privateDocError] = useDocument(
    firebaseApp.db.doc(`companies/${companyId}/candidates/${candidateId}`)
  );

  const candidate = (privateDoc && privateDoc.data()) || {};

  const validateThenCreateCandidate = async () => {
    setMode(MODES.SAVING);
    if (isValid) {
      try {
        setMode(MODES.SAVING);
        const profileId = await handleSubmitProfile(values, candidateId);
        setMode(MODES.EDITING);

        setProfileKey(profileId);
        handleOpen();
      } catch (e) {
        setMode(MODES.EDITING);
      }
    }
  }

  const redirectBack = () => {
    setRedirect('/share/our-people/');
  }

  const handleOpen = () => {
    setShareOpen(true);
  };
  const handleShare = (values) => {
    if (companyId) {
      return shareCandidateProfile(candidateId, companyId, profileKey, values);
    }
    return shareProfile(candidateId, profileKey, values);
  };

  const handleClose = () => {
    setShareOpen(false);
    redirectBack();
    return true;
  };
  const handleExtendLink = (linkId, link) => {
    if (companyId) {
      return extendCandidateProfileLink(
        candidateId,
        companyId,
        profileKey,
        linkId,
        moment(link.expires.toDate()).add(SHARE_PROFILE_LINK_EXPIRE_EXTEND_DAYS, 'days').toDate()
      );
    }
    return extendProfileLink(
      candidateId,
      profileKey,
      linkId,
      moment(link.expires.toDate()).add(SHARE_PROFILE_LINK_EXPIRE_EXTEND_DAYS, 'days').toDate()
    );
  }
  const handleDeleteLink = async (link) => {
    if (companyId) {
      return deleteCandidateProfileLink(candidateId, companyId, profileKey, link);
    }
    return deleteProfileLink(candidateId, profileKey, link);
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Paper className={classes.root}>
      <Typography component='h1' className={classes.formTitle}>Add a profile</Typography>
      <p style={{ marginBottom: 10 }}>
        A profile targets the type of role you are applying for. E.g. you may have one profile targeting project management roles, and
        another profile targeting business analyst roles. You can share each profile individually.
      </p>

      <Divider />

      <form style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch' }}>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <Typography component='h3' className={classes.formSubtitle}>Profile title*</Typography>
            <TextField
              name='title'
              value={values.title}
              label='E.g. "Senior software developer" or "Physical therapist"'
              placeholder='Title'
              fullWidth
              variant='outlined'
              onChange={onValueChange}
              required
              helperText={errors.has('title') && errors.get('title').error.message}
              error={errors.has('title')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <Typography component='h3' className={classes.formSubtitle}>Profile summary*</Typography>
            <TextField
              name='description'
              value={values.description}
              label='300 characters summary of the CV.'
              fullWidth
              variant='outlined'
              onChange={onValueChange}
              required
              multiline
              rows='3'
              helperText={errors.has('description') && errors.get('description').error.message}
              error={errors.has('description')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <Typography component='h3' className={classes.formSubtitle}>Upload a CV*</Typography>
            <Button
              variant='contained'
              color='primary'
              component='label'
              disabled={mode === MODES.SAVING}
              style={{ alignSelf: 'flex-start', minWidth: 200 }}
              fullWidth={isSmOrSmaller}
            >
              Add CV
              {!!values.cv &&
                <CheckIcon style={{ position: 'absolute', right: 10 }} />}
              <input
                name='cv'
                type='file'
                accept='.pdf'
                onChange={onValueChange}
                style={{ display: 'none' }}
              />
            </Button>
            <small style={{ marginTop: 15, marginBottom: 15, display: 'block' }}>PDF files only</small>
          </Grid>
        </Grid>

        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 15 }}>
          <Grid item xs={12} sm md />

          <Grid item xs={12} sm={4} md={4} style={{ textAlign: 'right' }}>
            <Button
              variant='outlined'
              color='primary'
              onClick={redirectBack}
              className={classes.backButton}
              disabled={mode === MODES.SAVING}
            >CANCEL
            </Button>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={validateThenCreateCandidate}
              variant='contained'
              color='primary'
              disabled={!isValid || mode === MODES.SAVING}
              fullWidth
            >
              Create profile
            </Button>
          </Grid>
        </Grid>
      </form>

      {profileKey && candidate && candidate.profiles && candidate.profiles[profileKey]
        ? <DialogShareProfile
          displayName={privateDoc.data().displayName}
          profile={privateDoc.data().profiles[profileKey]}
          handleClose={handleClose}
          handleShare={handleShare}
          handleDeleteLink={handleDeleteLink}
          handleExtendLink={handleExtendLink}
          open={shareOpen}
        /> : null}

    </Paper>
  );
}
