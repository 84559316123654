import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebaseApp from '../../firebase-app';
import Paper from '../paper';
import { handleLogin, SIGN_IN_OPTIONS } from '../../actions/account';
import SceneWrapper from '../scene-wrapper';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CustomAuth from '../utils/custom-auth';

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';

import GoogleLogin from '../social-login/google-login';
import MiscrosoftLogin from '../social-login/ms-login';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  },
  redText: {
    fontSize: 11,
    color: '#f00'
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

export default function Login ({ location }) {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const { to } = queryString.parse(location.search);

  const uiConfig = {
    ...SIGN_IN_OPTIONS,
    callbacks: {
      ...SIGN_IN_OPTIONS.callbacks,
      signInFailure: async function (error) {
        await SIGN_IN_OPTIONS.callbacks.signInFailure(error);
        setRedirect(to || '/account');
        return true;
      },
      signInSuccessWithAuthResult: (res) => {
        const asyncHandler = async () => {
          await handleLogin(res);
          setRedirect(to || '/share');
        };
        asyncHandler();
        // Avoid redirects after sign-in.
        return false;
      }
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <SceneWrapper>
      <Paper style={{ alignSelf: 'center', width: 640, maxWidth: '100%', minHeight: 400 }}>
        <Typography component='h1' className={classes.formTitle}>Log in</Typography>

        <h2>Recommended</h2>
        <p>Signup securely with your Google or Miscrosoft account.</p>
        <Grid container style={{ margin: '0 -5px' }}>
          <GoogleLogin firebaseApp={firebaseApp} firebaseAuth={firebaseApp.auth} fullWidth={false} />
          <MiscrosoftLogin firebaseApp={firebaseApp} firebaseAuth={firebaseApp.auth} fullWidth={false} />
        </Grid>
        <p className='firebaseui-tos firebaseui-tospp-full-message'>
          <small>
            By continuing, you are indicating that you accept our <a href='/terms-of-service' className='firebaseui-link firebaseui-tos-link' target='_blank'>Terms of Service</a> and <a href='/privacy-policy' className='firebaseui-link firebaseui-pp-link' target='_blank'>Privacy Policy</a>.
          </small>
        </p>

        <Divider />
        <h2>Traditional</h2>
        <CustomAuth />

        <p style={{ marginTop: 15 }}>
          <small>
            New to Skilldrop? <Link to='/register'>Register new account</Link> | <Link to='/reset-password'>Forgot your password?</Link>
          </small>
        </p>

        {false ? <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp.auth} /> : null}
      </Paper>
    </SceneWrapper>
  );
}
