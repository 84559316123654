import React, {useCallback, useState, useContext} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Avatar, { SIZE } from './avatar';
import { DialogsAndToastsContext } from '../contexts/dialogs-and-toasts';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  container: {
    borderWidth: 1,
    borderColor: theme.palette.accent.main,
    borderStyle: 'solid',
    borderRadius: 4,
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    background: '#fff',
    '& h2': {
      marginBottom: '0 !important'
    }
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  tableHead: {
    backgroundColor: theme.palette.contrast.main
  },
  tableHeadCell: {
    color: 'white'
  },
  leftButton: {
    marginLeft: theme.spacing(-1)
  },
  profile: {
    padding: 0
  },
  profileHeader: {
    borderTopWidth: 1,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.palette.accent.main,
    padding: theme.spacing(1)
  },
  description: {
    color: theme.palette.lightText.main
  },
  margin: {
    marginLeft: theme.spacing(1)
  },
  editLabel: {
    color: theme.palette.accentText.main
  },
  value: {
    color: theme.palette.accentText.main,
    whiteSpace: 'nowrap'
  },
  candidateName: {
    marginBottom: theme.spacing(1),
    color: theme.palette.accentText.main
  },
  candidateTitle: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    color: theme.palette.darkText.main,
    fontSize: 14
  },
  iconButton: {
    marginTop: theme.spacing(-2),
    color: theme.palette.lightText.main
  },
  circularIcon: {
    width: '20px !important',
    height: '20px !important'
  },
}));

export default function FilterResult ({ id, profile, selected, handleSelect, handleRemove }) {
  const classes = useStyles();
  const { handleViewCv } = useContext(DialogsAndToastsContext);
  const [loading, setLoading] = useState(false);

  const localHandleSelect = (e) => {
    handleSelect(id);
  }

  const localRemoveSelected = (e) => {
    handleRemove();
  }

  const localHandleViewCv = async (cvPath, title) => {
    try {
      setLoading(true);
      await handleViewCv(cvPath, title);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  return (
    <div className={classes.container}>
      <Grid container direction='row' className={classes.profile} alignItems='stretch' alignContent='stretch'>
        <Grid item>
          <Avatar
            displayName={profile.name}
            photo={profile.photo}
            size={SIZE.SMALL}
            style={{ marginRight: 14 }}
          />
        </Grid>

        <Grid item style={{ flex: 1 }}>
          <Grid container justify='space-between'>
            <Grid item>
              <Hidden smDown>
                <h2 className={classes.candidateName}>{profile.name} - {profile.title}</h2>
              </Hidden>

              <Hidden mdUp>
                <h2 className={classes.candidateName}>{profile.name}</h2>
                <h2>{profile.title}</h2>
              </Hidden>
            </Grid>
            <Grid item>
              <span style={{ fontSize: 12, color: '#999' }}>
                {profile.applicationType=='shared'? "Listed in shared with me profiles": null}
                {profile.applicationType=='company'? "Listed in company profiles": null}
              </span>
            </Grid>
          </Grid>

          {profile.description && false
            ? <Hidden smDown>
              <div className={classes.description}>
                {profile.description}
              </div>
            </Hidden> : null}

          <Divider className={classes.divider} />

          <Grid container direction='row' justify='space-between' alignItems='center'>
            <Grid item>
              {profile.edited && profile.edited.toDate
                ? <Hidden smDown>
                  <>
                    <span className={classes.editLabel}>
                      Last edit: <b className={classes.value}>{moment(profile.edited.toDate()).format('DD MMMM YYYY')}</b>
                    </span>
                  </>
                </Hidden>
                : null}
            </Grid>
            <Grid item>
              <Button variant='text' size='small' color='primary' onClick={() => { localHandleViewCv(profile.cvPath, `${profile.name} - ${profile.title}`)} }>
                {loading ? <CircularProgress className={classes.circularIcon} /> : <>View CV</>}
              </Button>
              {!selected
                ? <Button variant='outlined' size='small' color='primary' className={classes.margin} data-id={id} onClick={localHandleSelect}>SELECT THIS PROFILE</Button>
                : <Button variant='outlined' size='small' color='primary' className={classes.margin} data-id={id} onClick={localRemoveSelected}>UNSELECT</Button>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}
