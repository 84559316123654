import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { updateJob } from '../../actions/job';
import useForm from '../../hooks/use-form';
import JobDescription from '../job-description';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as copy from 'clipboard-copy';
import { AuthContext } from '../../contexts/auth';

import {
  MODES,
  APPLICATION_TYPE,
  LOCATION_TYPE_NAME,
  LOCATION_TYPE,
  RATE_TYPE,
  RATE_TYPE_NAME,
  JOB_TYPE,
  JOB_TYPE_NAME,
  CURRENCY_TYPES
} from '../../constants';

const schema = yup.object().shape({
  title: yup
    .string()
    .min(3, 'Title needs to be at least 3 characters long'),
  description: yup
    .string()
    .min(10, 'Description needs to be at least 10 characters long'),
  jobType: yup
    .array()
    .min(1, 'You need to pick at least one job type')
    .max(2)
    .ensure(),
  location: yup
    .string(),
  locationType: yup
    .array()
    .min(1, 'You need to pick at least one location type')
    .max(2)
    .ensure(),
  rateCurrency: yup
    .string(),
  rateType: yup
    .string()
    .when('type', (type, schema) => {
      if (type.indexOf(APPLICATION_TYPE.CONSULTANT) > -1) {
        return schema
          .required('You need to pick a rate type when hire type includes "Consultant')
          .min(1, 'You need to pick a rate type when hire type includes "Consultant"');
      }
      return schema;
    })
    .ensure()
});

const useStyles = makeStyles(theme => ({
  divider: {
    marginLeft: 16,
    marginRight: 16,
    maxWidth: 1,
    backgroundColor: theme.palette.accent.main
  },
  error: {
    color: 'red'
  },
  jobDetailSubtitle: {
    fontWeight: 700,
    color: '#5e6e89',
    fontSize: '1rem'
  }
}));

function getInitialValues (jobData) {
  return {
    title: jobData.title,
    description: jobData.description || '',
    type: jobData.type || [],
    location: jobData.location || '',
    locationType: jobData.locationType || [],
    rateCurrency: jobData.rateCurrency || 'SEK',
    rateType: jobData.rateType || [],
    jobType: jobData.jobType || '',
    partTypePercent: jobData.partTypePercent || ''
  };
}

export default function CreateJobDetailsEdit ({ jobDocument, match, mode, setMode }) {
  const jobData = jobDocument ? jobDocument.data() : {};
  const id = jobDocument.id;
  const { account } = useContext(AuthContext);
  const [values, onValueChange, setValues, isValid, errors] = useForm(getInitialValues(jobData), schema);
  const [rateCurrency, setRateCurrency] = useState({ key: values.rateCurrency, title: values.rateCurrency });
  const classes = useStyles();
  const accountData = account ? account.data() : {};

  // ACL
  const inJobList = accountData && accountData.jobs && accountData.jobs[match.params.companyId].indexOf(match.params.jobId) !== -1;
  const companyRole = accountData && accountData.currentCompanyRole;
  const isJobEditor = inJobList && companyRole == 'manager';

  const handleCancel = () => {
    setValues(getInitialValues(jobData));
    setMode(MODES.VIEWING);
  };

  const validateThenUpdateJob = async () => {
    const { title } = values;
    if (!isValid) {
      return;
    }
    setMode(MODES.SAVING);
    await updateJob(match.params.companyId, id, {
      ...values,
      title: title.charAt(0).toUpperCase() + title.slice(1)
    });
    setMode(MODES.VIEWING);
  };

  const handleAutocompleteChange = (e, value, type) => {
    e.persist();

    const persistValues = { ...values };

    switch (type) {
      case 'rateCurrency':
        setRateCurrency(value);
        break;
      default:
        //console.log(type);
        break;
    }

    if (type != 'rateCurrency' || value!=null){
      persistValues[type] = value.key;
      setValues(persistValues);
    } else {
      setRateCurrency({key: "SEK", title: "SEK"});
      persistValues[type] = {key: "SEK", title: "SEK"};
      setValues(persistValues);
    }
  }

  return (
    <>
      <Paper elevation={0} variant='outlined'>
        <Grid container direction='row' className='px-4 py-4' style={{ padding: '30px 30px 0 30px' }}>
          <Grid key='description' item xs={12} sm={12} md lg>
            {mode === MODES.EDITING ? (
              <TextField
                fullWidth
                variant='outlined'
                name='title'
                label='Role Title'
                value={values.title}
                onChange={onValueChange}
                required
                helperText={errors.has('title') && errors.get('title').error.message}
                error={errors.has('title')}
              />
            ) : null}

            {mode === MODES.EDITING || mode === MODES.SAVING ? (
              <TextField
                name='description'
                rows='10'
                variant={mode === MODES.EDITING ? 'outlined' : 'standard'}
                multiline
                fullWidth
                value={values.description}
                label={mode === MODES.EDITING || mode === MODES.SAVING ? 'Description' : ''}
                onChange={onValueChange}
                disabled={mode === MODES.VIEWING || mode === MODES.SAVING}
                required
                margin='normal'
                placeholder='Please enter the role description here'
                helperText={errors.has('description') && errors.get('description').error.message}
                error={errors.has('description')}
              />) : <JobDescription description={jobData.description || ''} />}
          </Grid>

          <Hidden smDown>
            <Grid key='divider' className={classes.divider} item xs sm md lg xl />
          </Hidden>

          <Grid key='details' className={classes.fullWidthMobile} item style={{ minWidth: 240 }} xs={12} sm={12} md={4}>
            <Grid key='details' container direction='column'>
              <Grid key='details-role' item>
                <h1>Role Details</h1>
                <Divider />
              </Grid>

              {jobData.created && (
                <Grid key='details-created-date' item>
                  <Grid container justify='space-between'>
                    <Grid item>
                      <h2 className={classes.jobDetailSubtitle}>Publish Date:</h2>
                    </Grid>
                    <Grid item>{moment(jobData.created.seconds * 1000).format('LL')}</Grid>
                  </Grid>
                  <Divider />
                </Grid>
              )}

              <Grid key='details-location-type' item>
                <Grid container justify='space-between'>
                  <Grid item>
                    <h2 className={classes.jobDetailSubtitle}>Location:</h2>
                  </Grid>
                  <Grid item>
                    <FormControl
                      required
                      error={errors.has('locationType')}
                      component='fieldset'
                      name='locationType'
                      style={{ marginTop: 8, marginBottom: 16 }}
                    >
                      {mode === MODES.EDITING || mode === MODES.SAVING
                        ? <TextField
                          fullWidth
                          variant='outlined'
                          name='location'
                          label='Location'
                          value={values.location}
                          onChange={onValueChange}
                          required
                          helperText={errors.has('location') && errors.get('location').error.message}
                          error={errors.has('location')}
                          /> : <div style={{ textAlign: 'right', marginTop: -8 }}>{values.location}<br /></div>}
                      {mode === MODES.EDITING || mode === MODES.SAVING
                        ? <FormGroup aria-label='locationType' name='locationType'>
                          <FormControlLabel
                            name='locationType'
                            control={<Checkbox />}
                            value={LOCATION_TYPE.ON_LOCATION}
                            label='On-site'
                            labelPlacement='start'
                            onChange={onValueChange}
                            checked={values.locationType && values.locationType.indexOf(LOCATION_TYPE.ON_LOCATION) > -1}
                            disabled={mode === MODES.VIEWING || mode === MODES.SAVING}
                          />
                          <FormControlLabel
                            name='locationType'
                            control={<Checkbox />}
                            value={LOCATION_TYPE.REMOTE}
                            label='Remote'
                            labelPlacement='start'
                            onChange={onValueChange}
                            checked={values.locationType && values.locationType.indexOf(LOCATION_TYPE.REMOTE) > -1}
                            disabled={mode === MODES.VIEWING || mode === MODES.SAVING}
                          />
                          {errors.has('locationType') &&
                            <FormHelperText>{errors.get('locationType').error.message}</FormHelperText>}
                        </FormGroup> : <div>
                          {values.locationType.map((value, i) => {
                            return (
                              <span key={value}>{LOCATION_TYPE_NAME[value]}{i + 1 < values.locationType.length ? <>, </> : null}</span>
                            );
                          })}
                        </div>}
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>

              <Grid key='job-type' item>
                <Grid container justify='space-between' spacing={2} alignContent='flex-end'>
                  <Grid item>
                    <h2 className={classes.jobDetailSubtitle}>Job Type:</h2>
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
                    {mode !== MODES.EDITING && mode !== MODES.SAVING
                      ? <div style={{ marginTop: -5 }}>
                        <span>{JOB_TYPE_NAME[values.jobType]}</span>
                        {values.jobType && mode !== MODES.EDITING && mode !== MODES.SAVING && values.jobType === JOB_TYPE.PART_TIME ? <span>, {values.partTypePercent}%</span> : null}
                      </div> : null}

                    <FormControl
                      required
                      error={errors.has('jobType')}
                      component='fieldset'
                      style={{ marginTop: 8, marginBottom: 16 }}
                    >

                      {mode === MODES.EDITING || mode === MODES.SAVING
                        ? <RadioGroup aria-label='rate' name='jobType' value={values.jobType}>
                          <FormControlLabel
                            control={<Radio />}
                            value={JOB_TYPE.FULL_TIME}
                            label='Full time'
                            labelPlacement='start'
                            onChange={onValueChange}
                            disabled={mode === MODES.VIEWING || mode === MODES.SAVING}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={JOB_TYPE.PART_TIME}
                            label='Part time'
                            labelPlacement='start'
                            onChange={onValueChange}
                            disabled={mode === MODES.VIEWING || mode === MODES.SAVING}
                          />
                          {errors.has('jobType') &&
                            <FormHelperText>{errors.get('jobType').error.message}</FormHelperText>}
                        </RadioGroup> : null}
                    </FormControl>
                    {values.jobType == JOB_TYPE.PART_TIME
                      ? <>
                        {mode === MODES.EDITING || mode === MODES.SAVING
                          ? <TextField
                            fullWidth
                            variant='outlined'
                            name='partTypePercent'
                            label='%'
                            value={values.partTypePercent}
                            onChange={onValueChange}
                            required
                            helperText={errors.has('partTypePercent') && errors.get('partTypePercent').error.message}
                            error={errors.has('partTypePercent')}
                          />
                          : null}
                      </>
                      : null}

                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid key='rate' item>
                <Grid container justify='space-between' spacing={2} alignContent='flex-end'>
                  <Grid item>
                    <h2 className={classes.jobDetailSubtitle}>Rate:</h2>
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
                    {mode !== MODES.EDITING && mode !== MODES.SAVING
                      ? <div style={{ textAlign: 'right', marginTop: -5 }}><span>{values.rateCurrency}</span> / <span>{RATE_TYPE_NAME[values.rateType]}</span></div>
                      : null}

                    {mode === MODES.EDITING || mode === MODES.SAVING

                      ? <FormControl
                        required
                        error={errors.has('rateCurrency')}
                        component='fieldset'
                        style={{ maxWidth: '100%', width: '100%' }}
                        >
                        <Autocomplete
                          id='rateCurrency'
                          options={CURRENCY_TYPES}
                          value={rateCurrency}
                          className={classes.autocomplete}
                          getOptionLabel={(option) => option.title}
                          onChange={(e, v) => handleAutocompleteChange(e, v, 'rateCurrency')}
                          style={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label='Currency' variant='outlined' />}
                        />

                        {errors.has('rateCurrency') &&
                          <FormHelperText>{errors.get('rateCurrency').error.message}</FormHelperText>}
                      </FormControl> : null}

                    <FormControl
                      required
                      error={errors.has('rateType')}
                      component='fieldset'
                      style={{ marginTop: 8, marginBottom: 16 }}
                    >

                      {mode === MODES.EDITING || mode === MODES.SAVING
                        ? <RadioGroup aria-label='rate' name='rateType' value={values.rateType}>
                          <FormControlLabel
                            control={<Radio />}
                            value={RATE_TYPE.PER_YEAR}
                            label='Per year'
                            labelPlacement='start'
                            onChange={onValueChange}
                            disabled={mode === MODES.VIEWING || mode === MODES.SAVING}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={RATE_TYPE.PER_MONTH}
                            label='Per month'
                            labelPlacement='start'
                            onChange={onValueChange}
                            disabled={mode === MODES.VIEWING || mode === MODES.SAVING}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={RATE_TYPE.PER_DAY}
                            label='Per day'
                            labelPlacement='start'
                            onChange={onValueChange}
                            disabled={mode === MODES.VIEWING || mode === MODES.SAVING}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={RATE_TYPE.PER_HOUR}
                            label='Per hour'
                            labelPlacement='start'
                            onChange={onValueChange}
                            disabled={mode === MODES.VIEWING || mode === MODES.SAVING}
                          />
                          {errors.has('rateType') &&
                            <FormHelperText>{errors.get('rateType').error.message}</FormHelperText>}
                        </RadioGroup> : null}
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid key='details-role-link' item>
                <Grid container justify='space-between'>
                  <Grid item>
                    <h2 className={classes.jobDetailSubtitle}>Job link:</h2>
                  </Grid>
                  <Grid item>
                    <br />
                    {jobData.dynamicLink}
                    <br />
                    <Button
                      variant='outlined'
                      onClick={() => copy(jobData.dynamicLink)}
                      style={{ marginTop: 10 }}
                    >
                      COPY LINK
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid key='actions' className='px-4 py-4' container justify='space-between' style={{ marginTop: 16, padding: 20 }}>
          <Grid key='actions-cancel' item>
            {mode === MODES.EDITING && (
              <Button onClick={handleCancel} variant='contained'>
                CANCEL
              </Button>
            )}
          </Grid>

          <Grid key='actions-edit-save' item style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {accountData && accountData.currentCompanyRole != 'viewer' && (isJobEditor || companyRole == 'administrator') && mode === MODES.VIEWING && (
              <Button onClick={(e) => setMode(MODES.EDITING)} variant='contained' color='primary'>
                EDIT
              </Button>
            )}

            {mode === MODES.EDITING && (
              <>
                <Button
                  onClick={(e) => validateThenUpdateJob()}
                  variant='contained'
                  color='primary'
                  disabled={!isValid}
                >
                  SAVE
                </Button>
                {errors && Array.from(errors.entries()).map(e => <div key={e[0]} className={classes.error}>{e[1].error.message}</div>)}
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
