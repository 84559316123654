import React, { useCallback, useState, useEffect, useRef } from 'react';
import {useReactToPrint} from 'react-to-print';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { Document, Page, pdfjs } from 'react-pdf';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { detect } from 'detect-browser';
import moment from 'moment';
import SnackbarContentWrapper from '../components/snackbar-content-wrapper';
import {getResourceUrl} from "../actions/account";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const DialogsAndToastsContext = React.createContext();

const dialogTitleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey[500]
  },
  gdprNotice: {
    position: 'absolute',
    left: 0,
    top: 0
  }
});

const DialogTitle = withStyles(dialogTitleStyles)(props => {
  const { children, classes, onClose, onPrint, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <Typography component='h2' variant='h6'>{children}</Typography>
        </Grid>

        <Grid item>
          <IconButton
            aria-label='print'
            className={classes.closeButton}
            onClick={onPrint}
            style={{ padding: 0 }}
          >
            <PrintIcon />
          </IconButton>

          {onClose ? (
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={onClose}
              style={{ padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(theme => ({
  gdprNotice: {
    position: 'absolute',
    left: 0,
    top: 0,
    borderBottom: '1px solid black',
    width: '100%',
    textAlign: 'center'
  }
}));

class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <DialogContent
        dividers
        style={{
          display: 'flex',
          padding: 0,
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <Document
          file={this.props.url}
          style={{ flex: 1 }}
          onLoadSuccess={this.props.onSuccess}
          renderMode={this.props.renderMode}
        >
          {this.props.pages.map(i => (
            <Page
              renderAnnotationLayer={false}
              pageNumber={i} key={i}
              width={this.props.pageWidth}
            />
          ))}
        </Document>
        {this.props.printMode
          ? <div className={this.props.gdprNotice}>
            SKILLDROP This document contains personal data, please destroy after use. Printed {moment().format('LL')}.
          </div> : null}
      </DialogContent>
    );
  }
};

export const DialogsAndToastsProvider = ({ children }) => {
  const classes = useStyles();
  const browser = detect();
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  const [toast, setToast] = useState(null);
  const [cvData, setCVData] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [printMode, setPrintMode] = useState(false);
  const { numPages } = pdfData || { numPages: 1 };
  const pageWidth = 710;  // Print optimized size of A4
  const renderMode = 'canvas';
  const pages = [];
  for (let i = 1; i <= numPages; i++) {
    pages.push(i);
  }

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  // Chrome - cannot print if timeout is used. Cannot render canvas without timeout. Chrome must render svg.
  // Safari - cannot use window.print with firebase subscriptions running - use document.execCommand. Cannot print without timeout. Very slow with SVG, use canvas.
  useEffect(() => {
    if (printMode) {
      window.addEventListener(
        'afterprint',
        () => {
          setPrintMode(false);
        },
        {
          once: true
        }
      );
      switch (browser && browser.name) {
        case 'safari':
          setTimeout(() => document.execCommand('print', false, null), 500);
          break;
        default:
          window.print()
      }
    }
  }, [printMode, browser])

  const showCVInDialog = (link, title) => {
    setCVData({ link, title });
  };

  const handleCloseCvDialog = () => {
    setCVData(null);
  };

  const showToast = props => {
    setToast(props);
  };

  const hideToast = () => {
    setToast(null);
  };

  const handleViewCv = async (storagePath, title) => {
      const url = await getResourceUrl(storagePath);
      showCVInDialog(url, title);
  }

  return (
    <DialogsAndToastsContext.Provider
      value={{
        showToast,
        handleViewCv
      }}
    >
      {children}

      {cvData && (
        <Dialog
          onClose={handleCloseCvDialog}
          open={!!cvData}
          fullScreen={isSmOrSmaller}
          maxWidth='md'
        >
          <DialogTitle
            id='customized-dialog-title'
            onClose={handleCloseCvDialog}
            onPrint={handlePrint}
          >
            {cvData.title || ''}
          </DialogTitle>
          <ComponentToPrint
            url={cvData.link}
            onSuccess={setPdfData}
            pageWidth={pageWidth}
            renderMode={renderMode}
            printMode={true}  // TODO - make dynamic
            pages={pages}
            gdprNotice={classes.gdprNotice}
            ref={componentRef}
          />
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={!!toast}
        autoHideDuration={6000}
        onClose={hideToast}
      >
        <SnackbarContentWrapper
          onClose={hideToast}
          variant={(toast && toast.variant) || 'success'}
          message={(toast && toast.message) || 'Account saved'}
        />
      </Snackbar>
    </DialogsAndToastsContext.Provider>
  );
};
