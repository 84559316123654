export const ONBOARDING_STATUS = {
  CHECK_FOR_TEAM: 0,
  USAGE_INFO: 1,
  COMPANY_USAGE: 2,
  CREATE_COMPANY: 3,
  ADD_COLLEAGUES: 4,
  WRAP_IT_UP: 5,
  REQUEST_SENT: 10,
  CONTACT_ADMIN: 20,
  ALMOST_DONE: 98,
  ONBOARDING_FINISHED: 99,
}

export const USAGE_TYPE = {
  PERSONAL: 'personal',
  COMPANY_W: 'company-w',
  COMPANY_R: 'company-r'
}

export const COMPANY_STATUS_INFO = {
  COMPANY_NEW: 'company-new',
  COMPANY_OLD: 'company-old'
}
