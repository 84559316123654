import React from 'react';
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '../avatar';
import Confirm from '../confirm';

export default function CompanyRowInvite ({ row, isSelf, companyId, mayEdit, handleDeleteInvite }) {
  const handleDeleteClick = () => handleDeleteInvite(companyId, row.email)
  return (
    <TableRow selected={isSelf}>
      <TableCell size='small'><Avatar photo={row.photo} displayName={row.displayName} /></TableCell>
      <TableCell><Tooltip title={row.email}><span>{row.displayName}</span></Tooltip></TableCell>
      <Hidden xsDown>
        <TableCell><Tooltip title={row.email}><span>{row.email}</span></Tooltip></TableCell>
        <TableCell>{`Pending invite (${row.role})`}</TableCell>
      </Hidden>
      <TableCell align='right'>
        {!isSelf && mayEdit ? (
          <>
            <Confirm title={`Are you sure you want to remove the invite for ${row.displayName}?`}>
              {confirm => (
                <IconButton aria-label='delete' size='small' onClick={confirm(handleDeleteClick)}>
                  <Delete />
                </IconButton>
              )}
            </Confirm>
          </>) : null}
      </TableCell>
    </TableRow>
  );
}
