import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebaseApp from '../firebase-app';
import { useDocument } from 'react-firebase-hooks/firestore';
import Paper from '../components/paper';

export default function WithDocument (Component, path) {
  return function FireDocument (props) {
    const completedPath = path
      .replace('%companyId%', props.match.params.companyId || props.companyId)
      .replace('%jobId%', props.match.params.jobId || props.jobId)
      .replace('%accountId%', props.match.params.accountId || props.accountId)
      .replace('%candidateId%', props.match.params.candidateId || props.candidateId)
      .replace('%profileId%', props.match.params.profileId || props.profileId);

    const [document, loading, error] = useDocument(firebaseApp.db.doc(completedPath), {
      snapshotListenOptions: { includeMetadataChanges: true }
    });

    if (!document && loading) {
      return <CircularProgress />;
    }

    if (!document && error) {
      return (
        <Paper>
          <h1>Doh, we had a mishap</h1>
          {error.message}
        </Paper>
      );
    }

    return <Component document={document} {...props} />;
  };
}
