import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: props => ({
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
    marginBottom: 32,
    maxWidth: props.maxWidth || 1200,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.accent.main
  })
}));

export default function PaperSheet ({ children, styles = {}, ...otherProps }) {
  const classes = useStyles(styles);

  return (
    <Paper className={classes.root} elevation={0} {...otherProps}>
      {children}
    </Paper>
  );
}
