import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { useCollection } from 'react-firebase-hooks/firestore';
import firebaseApp from '../firebase-app';
import firebase from 'firebase';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    color: 'rgb(255, 255, 255) !important',
    borderLeft: '1px solid #fff',
    //borderRight: '1px solid #fff',
    borderRadius: 0,
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    textTransform: 'uppercase',
    '& .MuiInputBase-root': {
      color: 'rgb(255, 255, 255) !important',
      width: 38,
    },
    '& .MuiSelect-select': {
      marginLeft: 5,
      fontSize: 0,
      width: 40
    },
    '& .MuiSelect-icon': {
      color: 'rgb(255, 255, 255) !important'
    }
  },
  rootEmpty: {
    color: 'rgb(255, 255, 255) !important',
    borderLeft: '1px solid #fff',
    //borderRight: '1px solid #fff',
    borderRadius: 0,
    marginLeft: 5,
    '& > div': {
      padding: '10.5px 32px 10.5px 16px',
      fontSize: 16
    }
  },
  currentCompanyLink: {
    color: '#fff',
    textDecoration: 'none'
  }
}));

export default function CompanySwitcher ({ companies, currentCompany, changeCompany }) {
  const classes = useStyles();
  const company = (currentCompany || companies[0]);

  // eslint-disable-next-line no-unused-vars
  const [companyProfiles, loading, error] = useCollection(companies
    ? firebaseApp.db.collection('companies').where(firebase.firestore.FieldPath.documentId(), 'in', companies)
    : null
  );

  const onValueChange = (event) => {
    changeCompany(event.target.value);
  };

  return (
    <>
      {companyProfiles && companyProfiles.docs && companyProfiles.docs.length > 1
        ?
        <FormControl className={classes.root} variant='outlined' margin='dense'>
          <Link to={`/companies/${company}/details`} className={classes.currentCompanyLink}>
            {companyProfiles && companyProfiles.docs.map((item, id) => (
              <>{item.id == company ? item.data().name : null}</>
            ))}
          </Link>
          <Select
            name='company'
            labelId='companyLabel'
            placeholder='Country'
            fullWidth
            onChange={onValueChange}
            required
            margin='dense'
          >
            {companyProfiles && companyProfiles.docs.map((item, id) => (
              <MenuItem key={item.id} value={item.id}>{item.data().name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        : <div className={classes.rootEmpty}>
          {companyProfiles && companyProfiles.docs.map((item, id) => (
            <div key={item.id} value={item.id}>
              <Link to={`/companies/${company}/details`} className={classes.currentCompanyLink}>
                {item.data().name}
              </Link>
            </div>
          ))}
        </div>}
    </>
  );
}
