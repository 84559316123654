import { useEffect, useState } from 'react';
// eslint-disable-next-line
import yup from 'yup';

function convertKeysToObject (initialValues) {
  const keys = Object.keys(initialValues);
  return keys.reduce(function (accumulator, current) {
    accumulator[current] = false;
    return accumulator;
  }, {});
}

/**
 * @param {*} initialValues
 * @param {yup.Schema} schema
 */
export default function useForm (initialValues, schema) {
  const [values, setValues] = useState(initialValues);
  const [touchedFields, setTouchedFields] = useState(convertKeysToObject(initialValues));
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState(new Map());

  useEffect(() => {
    validateAll(true);
  // eslint-disable-next-line
  }, [values]);

  const validateAll = (onlyTouched) => {
    schema && schema.validate(values, { abortEarly: false })
      .then((result) => {
        setIsValid(true);
        setErrors(new Map());
      })
      .catch((errors) => {
        setIsValid(false);
        if (errors && errors.inner) {
          const newErrors = new Map();
          errors.inner.forEach(error => {
            if (error.path.includes('[')) {
              const fieldName = error.path.replace(/\[.*]/ig, '');
              if (onlyTouched && touchedFields[fieldName]) {
                const index = Number(/\[(\d)]/ig.exec(error.path)[1]);
                if (newErrors.has(fieldName)) {
                  newErrors.get(fieldName)[index] = error;
                } else {
                  newErrors.set(fieldName, {
                    error,
                    items: { [index]: error }
                  });
                }
              }
            } else {
              const fieldName = error.path;
              if (onlyTouched && touchedFields[fieldName]) {
                newErrors.set(error.path, { error });
              }
            }
          });
          setErrors(newErrors);
        } else {
          setErrors(new Map());
        }
      });
  };

  const onValueChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = value;
    switch (type) {
      case 'checkbox':
        if (checked) {
          newValue = [...values[name], value];
        } else {
          newValue = [...values[name].filter((v) => v !== value)];
        }
        break;
      case 'file': {
        newValue = e.target.files;
        break;
      }
      default:
        break;
    }
    setTouchedFields({
      ...touchedFields,
      [name]: true
    });
    setValues({ ...values, [name]: newValue });
  };

  const clear = (e) => {
    setTouchedFields(convertKeysToObject(initialValues));
    setValues(initialValues);
  };

  return [values, onValueChange, setValues, isValid, errors, validateAll, clear];
}
