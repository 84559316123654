import React, { useState, useContext } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckIcon from '@material-ui/icons/Check';
import useForm from '../../hooks/use-form';
import { createCandidate } from '../../actions/candidate';
import Paper from '../paper';
import { AuthContext } from '../../contexts/auth';
import { MODES } from '../../constants';
import LocationAutocomplete from '../location-autocomplete';

import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';
import Typography from '@material-ui/core/Typography';

const initialValues = {
  displayName: '',
  location: '',
  photo: '',
  profiles: []
};

const schema = yup.object().shape({
  displayName: yup.string()
    .min(3, 'Name needs to be at least 3 characters long'),
  photo: yup.mixed()
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  }
}));

export default function CompanyCreateCandidate ({ match }) {
  const { showToast } = useContext(DialogsAndToastsContext);
  const theme = useTheme();
  const classes = useStyles();

  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  const { company } = useContext(AuthContext);
  const [mode, setMode] = useState(MODES.EDITING);
  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors, validateAll, clear] = useForm(initialValues, schema);
  const [redirect, setRedirect] = useState();

  const handleFileUploadProgress = (progress) => {
    console.log(progress);
  }

  const handleFileUploadError = (error) => {
    console.log(error);
  }

  const handleFileUploadSuccess = (success) => {
    console.log(success);
  }

  const redirectBack = () => {
    setRedirect('/share/our-people/');
  }

  const validateThenCreateCandidate = async () => {
    if (isValid) {
      setMode(MODES.SAVING);
      try {
        const candidateId = await createCandidate(
          {
            ...values
          },
          company.id,
          handleFileUploadProgress,
          handleFileUploadError,
          handleFileUploadSuccess
        );
        setRedirect(`/share/our-people/${candidateId}/create-profile`);
      } catch (e) {
        console.log(e);
        showToast({ message: e.message, variant: 'error' });
        setMode(MODES.EDITING);
      }
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Paper className={classes.root}>
      <Typography component='h1' className={classes.formTitle}>Add new Person</Typography>
      <p style={{ marginBottom: 10 }}>
        Adding a new Person in "Our people" does <b>not</b> add them as users in your Skilldrop account. It merely creates a bucket
        for you to upload profiles to.
      </p>

      <Divider />

      <form>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <Typography component='h3' className={classes.formSubtitle}>Name*</Typography>
            <TextField
              name='displayName'
              value={values.displayName}
              label='Full Name'
              variant='outlined'
              onChange={onValueChange}
              required
              helperText={errors.has('displayName') && errors.get('displayName').error.message}
              error={errors.has('displayName')}
              style={isSmOrSmaller ? null : { maxWidth: '100%' }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <Typography component='h3' className={classes.formSubtitle}>Location</Typography>
            <LocationAutocomplete
              onValueChange={(e, v) => {
                setValues({ ...values, location: v })
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <Typography component='h2' className={classes.formSubtitle} style={{ marginTop: 25 }}>Upload a photo (optional)</Typography>

            <Button
              variant='contained'
              component='label'
              color='primary'
              fullWidth={isSmOrSmaller}
              disabled={mode === MODES.SAVING}
              style={{ paddingLeft: 40, paddingRight: 40 }}
            >
              Upload photo
              {!!values.photo &&
                <CheckIcon style={{ position: 'absolute', right: 10 }} />}
              <input
                name='photo'
                type='file'
                accept='.jpg,.png,.gif'
                onChange={onValueChange}
                style={{ display: 'none' }}
              />
            </Button>

            <small style={{ marginTop: 15, marginBottom: 15, display: 'block' }}>
              Note: If you attach a photo to your profile, it will be visible when you share your CV. Best practices on
              whether or not to include a photo differs between countries and industries so you may or may not want
              to attach a photo depending on where you are sending your profile.
            </small>
          </Grid>
        </Grid>

        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 15 }}>
          <Grid item xs={12} sm md />

          <Grid item xs={12} sm={4} md={4} style={{ textAlign: 'right' }}>
            <Button
              variant='outlined'
              color='primary'
              className={classes.backButton}
              onClick={redirectBack}
            >CANCEL
            </Button>
          </Grid>

          <Grid item xs={12} sm={3} md={4}>
            <Button
              onClick={validateThenCreateCandidate}
              variant='contained'
              color='primary'
              disabled={!isValid || mode === MODES.SAVING}
              fullWidth
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
