import React from 'react';
import Confirm from '../confirm';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '../avatar';
import Hidden from '@material-ui/core/Hidden';

export default function CompanyRowMember ({ row, isSelf, companyId, mayEdit, handleDeleteMember, handleEditMemberClicked }) {
  const handleDeleteClick = () => handleDeleteMember(companyId, row.id, row.displayName);
  return (
    <TableRow selected={isSelf}>
      <TableCell size='small'><Avatar photo={row.photo} displayName={row.displayName} /></TableCell>
      <TableCell><Tooltip title={row.email}><span>{row.displayName}</span></Tooltip></TableCell>
      <Hidden xsDown>
        <TableCell><Tooltip title={row.email}><span>{row.email}</span></Tooltip></TableCell>
        <TableCell>{row.role}</TableCell>
      </Hidden>
      <TableCell align='right'>
        {!isSelf && mayEdit ? (
          <>
            <IconButton aria-label='edit' size='small' onClick={() => handleEditMemberClicked(row, companyId)}>
              <Edit />
            </IconButton>
            <Confirm
              title={`Are you sure you want to remove ${row.displayName} from the company?`}
              description='The user will also be removed from any jobs that the user has access to.'
            >
              {confirm => (
                <IconButton aria-label='delete' size='small' onClick={confirm(handleDeleteClick)}>
                  <Delete />
                </IconButton>
              )}
            </Confirm>
          </>) : null}
      </TableCell>
    </TableRow>
  );
}
