import React, { useContext, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WithDocument from '../../containers/document';
import PrivateRoute from '../../private-route';
import CompanyCreateCandidate from './company-create-candidate';
import OurPeople from './our-people';
import SharedWithMe from './shared-with-me';
import MyProfile from './my-profile';
import { AuthContext } from '../../contexts/auth';
import ReplaceLink from '../replace-link';
import SceneWrapper from '../scene-wrapper';
import CreateProfileForm from './create-profile-form';
import EditProfileForm from './edit-profile-form';
import { createProfile, updateProfile } from '../../actions/account';
import { createProfile as createCandidateProfile, updateProfile as updateCandidateProfile } from '../../actions/candidate';
import LinkButton from '../link-button';
import firebaseApp from '../../firebase-app';
import Button from '@material-ui/core/Button';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';
import { STANDARD_PRODUCT_ID } from '../stripe/consts';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

import {
  USAGE_TYPE
} from '../../constants/onboarding';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox
} from 'react-instantsearch-dom';

export const EditOurPeopleProfile = WithDocument(EditProfileForm, 'companies/%companyId%/candidates/%candidateId%');

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.down('sm')]: {
    searchBoxWrapper: {
      display: 'none'
    },
    actionPanel: {
      justifyContent: 'flex-end !important'
    }
  },
  searchBoxWrapper: {
    '& .ais-SearchBox': {
      marginTop: 0,
      marginBottom: 0
    },
    '& .ais-SearchBox-input': {
      border: 0,
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
      background: 'transparent',
      borderRadius: 0,
      minHeight: 31
    },
    '& .ais-SearchBox-input:focus': {
      borderBottom: '1px solid #000',
      outline: 'none'
    },
    '& .MuiSvgIcon-root': {
      color: '#457db6'
    }
  },
  searchIcon: {
    padding: '3px 8px'
  },
  mainActionPanel: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiGrid-grid-sm-4': {
        padding: '5px !important'
      }
    }
  }
}));

export default function Share ({ match, location }) {
  const [redirect, setRedirect] = useState();
  // Wait while loading company and account
  // Redirect to Our people if we have a company. Redirect to My Profile if we do not have a company.
  const { account, currentUser, company, companyLoading } = useContext(AuthContext);
  const { showToast } = useContext(DialogsAndToastsContext);
  const [searchVisible, setSearchVisible] = useState(false);
  const accountData = account ? account.data() : {};
  const classes = useStyles();
  let searchClient = null;

  const EmptyRedirect = () => {
    if (companyLoading) {
      return null;
    }
    if (company && accountData.currentCompanyRole != 'viewer') {
      return <Redirect to={`${match.path}/our-people/`} />;
    }
    if (company && accountData.currentCompanyRole == 'viewer') {
      return <Redirect to={`${match.path}/shared-with-me/`} />;
    }

    return <Redirect to={`${match.path}/my-profile/`} />;
  };

  const getTab = () => {

    if (location.pathname.includes('our-people')) {
      return 0;
    }
    if (location.pathname.includes('shared-with-me')) {
      return 1;
    }
    //if (location.pathname.includes('my-profile')) {
    //  return 2;
    //}

    return 0;
  };

  const handleSubmitMyProfile = async (values) => {
    try {
      const profile = await createProfile(
        account.id,
        values,
        progress => console.log(progress),
        error => console.log(error),
        success => console.log('CV uploaded', success)
      );

      await localStorage.setItem('profileCreated', profile);

      setRedirect(`${match.url}/my-profile/`);
      setRedirect(null);
    } catch (e) {
      // do nothing, handled by form
    }
  }

  const handleUpdateMyProfile = async (values, candidateId, profileId) => {
    try {
      await updateProfile(
        candidateId,
        profileId,
        values,
        progress => console.log(progress),
        error => console.log(error),
        success => console.log('CV uploaded', success)
      );

      setRedirect(`${match.url}/my-profile/`);
      setRedirect(null);
    } catch (e) {
      // do nothing, handled by form
    }
  }

  const handleSubmitOurPeopleProfile = async (values, candidateId) => {
    try {
      const profile = await createCandidateProfile(
        candidateId,
        company.id,
        values,
        progress => console.log(progress),
        error => console.log(error),
        success => console.log('CV uploaded', success)
      );

      return profile;
    } catch (e) {
      // do nothing, handled by form
      let message = e.message;

      if (message == 'The caller does not have permission' || message == 'Missing or insufficient permissions.') {
        message = 'You have reached maximum ammount of profiles you can create. Please upgrade your plan!';
      }
      showToast({
        message: message,
        variant: "error"
      });
    }
  }

  const handleUpdateOurPeopleProfile = async (values, candidateId, profileId) => {
    try {
      await updateCandidateProfile(
        candidateId,
        company.id,
        profileId,
        values
      );
      setRedirect(`${match.url}/our-people/`);
      setRedirect(null);
    } catch (e) {
      // do nothing, handled by form
      throw e;
    }
  }

  const handleAddPerson = async (evt) => {
    try {
      evt.persist();
      const stripeDoc = await firebaseApp.db.doc(`companies/${company.id}/private_data/stripe`).get();
      const stripeDocData = stripeDoc.data() || {};

      if ((stripeDocData.planId == STANDARD_PRODUCT_ID && stripeDocData.personCount >= 2) && false) { // Remove in next iteration
        showToast({ message: 'Please upgrade your plan if you want to add more people to your account!', variant: 'error' });
      } else {
        setRedirect(`${match.url}/create-candidate`);
        setRedirect(null);
      }
    } catch (e) {
      // do nothing
      throw e;
    }
  }

  const toggleSearch = (evt) => {
    setSearchVisible(!searchVisible);
  }

  if (companyLoading) {
    return null;
  }

  const apiKey = window.location.href.indexOf('/shared-with-me')!==-1 && account ? account.data().algoliaSharedWithMeKey : account.data().algoliaApiKey;
  const indexName = window.location.href.indexOf('/shared-with-me')!==-1 ? `shared_${process.env.REACT_APP_ALGOLIA_API_INDEX}` : process.env.REACT_APP_ALGOLIA_API_INDEX;

  searchClient = (account && apiKey) ? algoliasearch(
    'K1O9RCLEC1',
    apiKey
  ) : null;

  return (
    <>
      {redirect &&
        <Redirect to={redirect} />}

      {indexName && apiKey ? 
        <InstantSearch
          indexName={indexName}
          searchClient={searchClient}
        >
        
          <SceneWrapper id='share-scene'>
            {window.location.href.indexOf('create-profile') === -1 &&
              window.location.href.indexOf('create-candidate') === -1 &&
              window.location.href.indexOf('my-profile') === -1 &&
              window.location.href.split('/').length < 8
              ? <>
                <h1>Share CV</h1>
                <Grid container className={classes.mainActionPanel} justify='space-between' alignItems='center' spacing={2}>
                  {currentUser && !currentUser.isAnonymous ?
                  <Grid item xs={12} sm={8} xs={9}>
                    <Tabs
                      value={getTab()}
                      aria-label='Pick what to share'
                    >
                      {accountData && accountData.usageType != USAGE_TYPE.PERSONAL && accountData.currentCompanyRole!='viewer' && <Tab label='Managed profiles' component={ReplaceLink} to={`${match.url}/our-people/`} />}
                      {accountData && accountData.usageType != USAGE_TYPE.PERSONAL && <Tab label='Shared with me' component={ReplaceLink} to={`${match.url}/shared-with-me/`} />}
                    </Tabs>
                  </Grid> : null}

                  {(getTab() === 0 && company) || getTab() === 1
                    ? <>
                      {currentUser && !currentUser.isAnonymous ?
                        <Grid className={classes.actionPanel} item xs={12} sm={4} xs={3} style={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
                          <div className={classes.searchBoxWrapper} style={{ marginRight: 10 }}>
                            {searchVisible ? <SearchBox autoFocus searchAsYouType={false} showLoadingIndicator onReset={toggleSearch} /> : <Button className={classes.searchIcon} onClick={toggleSearch}><SearchIcon /></Button>}
                          </div>
                          {getTab() === 0 && <LinkButton to='#' onClick={handleAddPerson} title='+ Add' />}
                        </Grid>
                      : null}
                      </>
                    : null}
                </Grid>
                <Divider />
              </>
              : null}

            <Switch>
              <PrivateRoute exact path={`${match.path}/our-people/`} component={OurPeople} match={match} />
              <PrivateRoute path={`${match.path}/shared-with-me/:expandedId?/:autoOpenLinkId?`} component={SharedWithMe} match={match} />
              <PrivateRoute exact path={`${match.path}/my-profile/`} component={MyProfile} match={match} />
              <PrivateRoute
                exact
                path={`${match.path}/my-profile/:candidateId/create-profile`}
                component={CreateProfileForm}
                match={match}
                handleSubmitProfile={handleSubmitMyProfile}
              />
              <PrivateRoute
                exact
                path={`${match.path}/my-profile/:candidateId/:profileId`}
                component={EditProfileForm}
                match={match}
                handleSubmitProfile={handleUpdateMyProfile}
                document={account}
              />
              <PrivateRoute exact path={`${match.path}/create-candidate`} component={CompanyCreateCandidate} />
              <PrivateRoute
                exact
                path={`${match.path}/our-people/:candidateId/create-profile`}
                component={CreateProfileForm}
                companyId={company ? company.id : null}
                match={match}
                handleSubmitProfile={handleSubmitOurPeopleProfile}
              />
              <PrivateRoute
                path={`${match.path}/our-people/:candidateId/:profileId`}
                company={company}
                companyId={company ? company.id : null}
                account={account}
                component={EditOurPeopleProfile}
                match={match}
                handleSubmitProfile={handleUpdateOurPeopleProfile}
              />
              <EmptyRedirect
                path={`${match.path}`}
              />
            </Switch>
          </SceneWrapper>
        </InstantSearch>
      :
        <>
          <SceneWrapper id='share-scene'>
            {window.location.href.indexOf('create-profile') === -1 &&
              window.location.href.indexOf('create-candidate') === -1 &&
              window.location.href.indexOf('my-profile') === -1 &&
              window.location.href.split('/').length < 8
              ? <>
                <h1>Share CV</h1>
                <Grid container className={classes.mainActionPanel} justify='space-between' alignItems='center' spacing={2}>
                  <Grid item xs={12} sm={8} xs={9}>
                    <Tabs
                      value={getTab()}
                      aria-label='Pick what to share'
                    >
                      {accountData && accountData.usageType != USAGE_TYPE.PERSONAL && <Tab label='Managed profiles' component={ReplaceLink} to={`${match.url}/our-people/`} />}
                      {accountData && accountData.usageType != USAGE_TYPE.PERSONAL && <Tab label='Shared with me' component={ReplaceLink} to={`${match.url}/shared-with-me/`} />}
                    </Tabs>
                  </Grid>

                  {(getTab() === 0 && company) || getTab() === 1
                    ? <>
                      <Grid className={classes.actionPanel} item xs={12} sm={4} xs={3} style={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
                        <div className={classes.searchBoxWrapper} style={{ marginRight: 10 }}>
                          {searchVisible ? <SearchBox autoFocus searchAsYouType={false} showLoadingIndicator onReset={toggleSearch} /> : <Button className={classes.searchIcon} onClick={toggleSearch}><SearchIcon /></Button>}
                        </div>
                        {getTab() === 0 && <LinkButton to='#' onClick={handleAddPerson} title='+ Add' />}
                      </Grid>
                    </> : null}
                </Grid>
                <Divider />
              </>
              : null}

            <Switch>
              <PrivateRoute exact path={`${match.path}/our-people/`} component={OurPeople} match={match} />
              <PrivateRoute path={`${match.path}/shared-with-me/:expandedId?/:autoOpenLinkId?`} component={SharedWithMe} match={match} />
              <PrivateRoute exact path={`${match.path}/my-profile/`} component={MyProfile} match={match} />
              <PrivateRoute
                exact
                path={`${match.path}/my-profile/:candidateId/create-profile`}
                component={CreateProfileForm}
                match={match}
                handleSubmitProfile={handleSubmitMyProfile}
              />
              <PrivateRoute
                exact
                path={`${match.path}/my-profile/:candidateId/:profileId`}
                component={EditProfileForm}
                match={match}
                handleSubmitProfile={handleUpdateMyProfile}
                document={account}
              />
              <PrivateRoute exact path={`${match.path}/create-candidate`} component={CompanyCreateCandidate} />
              <PrivateRoute
                exact
                path={`${match.path}/our-people/:candidateId/create-profile`}
                component={CreateProfileForm}
                companyId={company ? company.id : null}
                match={match}
                handleSubmitProfile={handleSubmitOurPeopleProfile}
              />
              <PrivateRoute
                path={`${match.path}/our-people/:candidateId/:profileId`}
                company={company}
                companyId={company ? company.id : null}
                account={account}
                component={EditOurPeopleProfile}
                match={match}
                handleSubmitProfile={handleUpdateOurPeopleProfile}
              />
              <EmptyRedirect
                path={`${match.path}`}
              />
            </Switch>
          </SceneWrapper>
        </>
      }
    </>
  );
}
