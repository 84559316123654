export const RATE_TYPES = {
  DAILY: 'D',
  HOURLY: 'H'
};

export const APPLICATION_TYPE = {
  CONSULTANT: 'C',
  RECRUITMENT: 'R'
};

export const APPLICATION_TYPE_NAME = {
  C: 'Contract',
  R: 'Recruitment'
};

export const LOCATION_TYPE = {
  ON_LOCATION: 'ON_LOCATION',
  REMOTE: 'REMOTE'
}

export const LOCATION_TYPE_NAME = {
  ON_LOCATION: 'On site',
  REMOTE: 'Remote'
}

export const RATE_TYPE = {
  PER_YEAR: 'PER_YEAR',
  PER_MONTH: 'PER_MONTH',
  PER_DAY: 'PER_DAY',
  PER_HOUR: 'PER_HOUR'
}

export const RATE_TYPE_NAME = {
  PER_YEAR: 'Year',
  PER_MONTH: 'Month',
  PER_DAY: 'Day',
  PER_HOUR: 'Hour'
}

export const JOB_TYPE = {
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME'
}

export const JOB_TYPE_NAME = {
  FULL_TIME: 'Full time',
  PART_TIME: 'Part time'
}

export const MODES = {
  VIEWING: 0,
  EDITING: 1,
  SAVING: 2
};

export const CURRENCY_TYPES = [
  { key: 'AED', title: 'AED' },
  { key: 'AFN', title: 'AFN' },
  { key: 'ALL', title: 'ALL' },
  { key: 'AMD', title: 'AMD' },
  { key: 'ANG', title: 'ANG' },
  { key: 'AOA', title: 'AOA' },
  { key: 'ARS', title: 'ARS' },
  { key: 'AUD', title: 'AUD' },
  { key: 'AWG', title: 'AWG' },
  { key: 'AZN', title: 'AZN' },
  { key: 'BAM', title: 'BAM' },
  { key: 'BBD', title: 'BBD' },
  { key: 'BDT', title: 'BDT' },
  { key: 'BGN', title: 'BGN' },
  { key: 'BHD', title: 'BHD' },
  { key: 'BIF', title: 'BIF' },
  { key: 'BMD', title: 'BMD' },
  { key: 'BND', title: 'BND' },
  { key: 'BOB', title: 'BOB' },
  { key: 'BRL', title: 'BRL' },
  { key: 'BSD', title: 'BSD' },
  { key: 'BTN', title: 'BTN' },
  { key: 'BWP', title: 'BWP' },
  { key: 'BYN', title: 'BYN' },
  { key: 'BZD', title: 'BZD' },
  { key: 'CAD', title: 'CAD' },
  { key: 'CDF', title: 'CDF' },
  { key: 'CHF', title: 'CHF' },
  { key: 'CLP', title: 'CLP' },
  { key: 'CNY', title: 'CNY' },
  { key: 'COP', title: 'COP' },
  { key: 'CRC', title: 'CRC' },
  { key: 'CUC', title: 'CUC' },
  { key: 'CUP', title: 'CUP' },
  { key: 'CVE', title: 'CVE' },
  { key: 'CZK', title: 'CZK' },
  { key: 'DJF', title: 'DJF' },
  { key: 'DKK', title: 'DKK' },
  { key: 'DOP', title: 'DOP' },
  { key: 'DZD', title: 'DZD' },
  { key: 'EGP', title: 'EGP' },
  { key: 'ERN', title: 'ERN' },
  { key: 'ETB', title: 'ETB' },
  { key: 'EUR', title: 'EUR' },
  { key: 'FJD', title: 'FJD' },
  { key: 'FKP', title: 'FKP' },
  { key: 'GBP', title: 'GBP' },
  { key: 'GEL', title: 'GEL' },
  { key: 'GGP', title: 'GGP' },
  { key: 'GHS', title: 'GHS' },
  { key: 'GIP', title: 'GIP' },
  { key: 'GMD', title: 'GMD' },
  { key: 'GNF', title: 'GNF' },
  { key: 'GTQ', title: 'GTQ' },
  { key: 'GYD', title: 'GYD' },
  { key: 'HKD', title: 'HKD' },
  { key: 'HNL', title: 'HNL' },
  { key: 'HRK', title: 'HRK' },
  { key: 'HTG', title: 'HTG' },
  { key: 'HUF', title: 'HUF' },
  { key: 'IDR', title: 'IDR' },
  { key: 'ILS', title: 'ILS' },
  { key: 'IMP', title: 'IMP' },
  { key: 'INR', title: 'INR' },
  { key: 'IQD', title: 'IQD' },
  { key: 'IRR', title: 'IRR' },
  { key: 'ISK', title: 'ISK' },
  { key: 'JEP', title: 'JEP' },
  { key: 'JMD', title: 'JMD' },
  { key: 'JOD', title: 'JOD' },
  { key: 'JPY', title: 'JPY' },
  { key: 'KES', title: 'KES' },
  { key: 'KGS', title: 'KGS' },
  { key: 'KHR', title: 'KHR' },
  { key: 'KMF', title: 'KMF' },
  { key: 'KPW', title: 'KPW' },
  { key: 'KRW', title: 'KRW' },
  { key: 'KWD', title: 'KWD' },
  { key: 'KYD', title: 'KYD' },
  { key: 'KZT', title: 'KZT' },
  { key: 'LAK', title: 'LAK' },
  { key: 'LBP', title: 'LBP' },
  { key: 'LKR', title: 'LKR' },
  { key: 'LRD', title: 'LRD' },
  { key: 'LSL', title: 'LSL' },
  { key: 'LYD', title: 'LYD' },
  { key: 'MAD', title: 'MAD' },
  { key: 'MDL', title: 'MDL' },
  { key: 'MGA', title: 'MGA' },
  { key: 'MKD', title: 'MKD' },
  { key: 'MMK', title: 'MMK' },
  { key: 'MNT', title: 'MNT' },
  { key: 'MOP', title: 'MOP' },
  { key: 'MRU', title: 'MRU' },
  { key: 'MUR', title: 'MUR' },
  { key: 'MVR', title: 'MVR' },
  { key: 'MWK', title: 'MWK' },
  { key: 'MXN', title: 'MXN' },
  { key: 'MYR', title: 'MYR' },
  { key: 'MZN', title: 'MZN' },
  { key: 'NAD', title: 'NAD' },
  { key: 'NGN', title: 'NGN' },
  { key: 'NIO', title: 'NIO' },
  { key: 'NOK', title: 'NOK' },
  { key: 'NPR', title: 'NPR' },
  { key: 'NZD', title: 'NZD' },
  { key: 'OMR', title: 'OMR' },
  { key: 'PAB', title: 'PAB' },
  { key: 'PEN', title: 'PEN' },
  { key: 'PGK', title: 'PGK' },
  { key: 'PHP', title: 'PHP' },
  { key: 'PKR', title: 'PKR' },
  { key: 'PLN', title: 'PLN' },
  { key: 'PYG', title: 'PYG' },
  { key: 'QAR', title: 'QAR' },
  { key: 'RON', title: 'RON' },
  { key: 'RSD', title: 'RSD' },
  { key: 'RUB', title: 'RUB' },
  { key: 'RWF', title: 'RWF' },
  { key: 'SAR', title: 'SAR' },
  { key: 'SBD', title: 'SBD' },
  { key: 'SCR', title: 'SCR' },
  { key: 'SDG', title: 'SDG' },
  { key: 'SEK', title: 'SEK' },
  { key: 'SGD', title: 'SGD' },
  { key: 'SHP', title: 'SHP' },
  { key: 'SLL', title: 'SLL' },
  { key: 'SOS', title: 'SOS' },
  { key: 'SPL', title: 'SPL' },
  { key: 'SRD', title: 'SRD' },
  { key: 'STN', title: 'STN' },
  { key: 'SVC', title: 'SVC' },
  { key: 'SYP', title: 'SYP' },
  { key: 'SZL', title: 'SZL' },
  { key: 'THB', title: 'THB' },
  { key: 'TJS', title: 'TJS' },
  { key: 'TMT', title: 'TMT' },
  { key: 'TND', title: 'TND' },
  { key: 'TOP', title: 'TOP' },
  { key: 'TRY', title: 'TRY' },
  { key: 'TTD', title: 'TTD' },
  { key: 'TVD', title: 'TVD' },
  { key: 'TWD', title: 'TWD' },
  { key: 'TZS', title: 'TZS' },
  { key: 'UAH', title: 'UAH' },
  { key: 'UGX', title: 'UGX' },
  { key: 'USD', title: 'USD' },
  { key: 'UYU', title: 'UYU' },
  { key: 'UZS', title: 'UZS' },
  { key: 'VEF', title: 'VEF' },
  { key: 'VND', title: 'VND' },
  { key: 'VUV', title: 'VUV' },
  { key: 'WST', title: 'WST' },
  { key: 'XAF', title: 'XAF' },
  { key: 'XCD', title: 'XCD' },
  { key: 'XDR', title: 'XDR' },
  { key: 'XOF', title: 'XOF' },
  { key: 'XPF', title: 'XPF' },
  { key: 'YER', title: 'YER' },
  { key: 'ZAR', title: 'ZAR' },
  { key: 'ZMW', title: 'ZMW' },
  { key: 'ZWD', title: 'ZWD' }
];

export const CURRENCY_TYPE_NAMES = {
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BRL: 'BRL',
  BSD: 'BSD',
  BTN: 'BTN',
  BWP: 'BWP',
  BYN: 'BYN',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  EUR: 'EUR',
  FJD: 'FJD',
  FKP: 'FKP',
  GBP: 'GBP',
  GEL: 'GEL',
  GGP: 'GGP',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  IMP: 'IMP',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JEP: 'JEP',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KPW: 'KPW',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRU: 'MRU',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SLL: 'SLL',
  SOS: 'SOS',
  SPL: 'SPL',
  SRD: 'SRD',
  STN: 'STN',
  SVC: 'SVC',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRY: 'TRY',
  TTD: 'TTD',
  TVD: 'TVD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  USD: 'USD',
  UYU: 'UYU',
  UZS: 'UZS',
  VEF: 'VEF',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XCD: 'XCD',
  XDR: 'XDR',
  XOF: 'XOF',
  XPF: 'XPF',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMW: 'ZMW',
  ZWD: 'ZWD'
}
