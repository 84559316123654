import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import firebaseApp from '../../firebase-app';
import { submitExternalCv } from '../../actions/application';
import useForm from '../../hooks/use-form';
import { MODES } from '../../constants';
import JobDescription from '../job-description';
import SceneWrapper from '../scene-wrapper';
import Paper from '../paper';
import { AuthContext } from '../../contexts/auth';

const initialState = {
  name: '',
  title: '',
  cvPath: null,
  phone: '',
  email: '',
  rate: '',
  description: '',
  motivation: ''
};
const schema = yup.object().shape({
  name: yup.string()
    .min(3, 'Name needs to be at least 3 characters long')
    .required('Name is required'),
  title: yup.string()
    .min(3, 'Name needs to be at least 3 characters long')
    .required('Name is required'),
  cv: yup.mixed().required('CV is required'),
  phone: yup.string(),
  email: yup.string().email(),
  rate: yup.number(),
  description: yup.string()
    .required('Description is required')
});

export default function SubmitCV ({ document, match }) {
  const [mode, setMode] = useState(MODES.EDITING);
  const [user] = useAuthState(firebaseApp.auth);
  const [values, onValueChange, setValues, isValid, errors] = useForm({ ...initialState, email: user.email }, schema);
  const [redirectToList, setRedirectToList] = useState(false);
  const { company } = useContext(AuthContext);

  const data = document.data();
  const currentCompany = company ? company.id : '-';

  const handleCancel = () => {
    setValues(initialState);
    setRedirectToList(true);
  }

  const handleSubmit = async () => {
    setMode(MODES.SAVING);
    try {
      await submitExternalCv(document, match.params.companyId, { ...values, byUser: user.uid, byCompany: currentCompany, jobTitle: data.title, jobCompanyId: match.params.companyId });
      setRedirectToList(true);
    } catch (e) {
      console.error(e.message, e);
      setMode(MODES.EDITING);
    }
  }

  if (redirectToList) {
    return <Redirect to={`/companies/${match.params.companyId}/create-job/${document.id}/applications`} />;
  }

  return (
    <SceneWrapper>
      <Paper>
        <h1>{data.title}</h1>

        <Divider />

        <h1>Role Description</h1>
        <JobDescription description={data ? data.description : ''} />

        <Divider />

        <h1>Submit Contract</h1>

        <Grid container style={{ alignSelf: 'center' }} direction='column' alignItems='center'>
          <Grid item style={{ alignSelf: 'stretch', marginBottom: 25 }}>
            <Button
              variant='contained'
              component='label'
              disabled={mode === MODES.SAVING}
            >
              Upload cv
              <input
                name='cv'
                type='file'
                onChange={onValueChange}
                style={{ display: 'none' }}
              />
            </Button>
          </Grid>

          <Grid item style={{ alignSelf: 'stretch' }}>
            <FormControl
              required
              error={errors.has('name')}
              helperText={errors.has('name') && errors.get('name').error.message}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.name}
                name='name'
                label='Name'
                onChange={onValueChange}
                required
                variant='outlined'
                disabled={mode === MODES.SAVING}
                helperText={errors.has('name') && errors.get('name').error.message}
                error={errors.has('name')}
              />
            </FormControl>

            <FormControl
              required
              error={errors.has('title')}
              helperText={errors.has('title') && errors.get('title').error.message}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.title}
                name='title'
                label='Title'
                onChange={onValueChange}
                variant='outlined'
                required
                disabled={mode === MODES.SAVING}
                helperText={errors.has('title') && errors.get('title').error.message}
                error={errors.has('title')}
              />
            </FormControl>

            <FormControl
              required
              error={errors.has('email')}
              helperText={errors.has('email') && errors.get('email').error.message}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.email}
                name='email'
                label='Email-address'
                onChange={onValueChange}
                variant='outlined'
                required
                disabled={mode === MODES.SAVING}
                helperText={errors.has('email') && errors.get('email').error.message}
                error={errors.has('email')}
              />
            </FormControl>

            <FormControl
              required
              error={errors.has('phone')}
              helperText={errors.has('phone') && errors.get('phone').error.message}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.phone}
                name='phone'
                label='Phone number'
                onChange={onValueChange}
                required
                variant='outlined'
                disabled={mode === MODES.SAVING}
                helperText={errors.has('phone') && errors.get('phone').error.message}
                error={errors.has('phone')}
              />
            </FormControl>

            <FormControl
              required
              error={errors.has('rate')}
              helperText={errors.has('rate') && errors.get('rate').error.message}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.rate}
                name='rate'
                label='Rate'
                onChange={onValueChange}
                required
                variant='outlined'
                disabled={mode === MODES.SAVING}
                helperText={errors.has('rate') && errors.get('rate').error.message}
                error={errors.has('rate')}
              />
            </FormControl>

            <FormControl
              required
              error={errors.has('description')}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                value={values.description}
                name='description'
                label='Description'
                fullWidth
                rows='4'
                onChange={onValueChange}
                variant='outlined'
                multiline
                required
                disabled={mode === MODES.SAVING}
                error={errors.has('description')}
              />
            </FormControl>

            <FormControl
              required
              error={errors.has('motivation')}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                name='motivation'
                value={values.motiviation}
                label='Motivate why this candidate is perfect for this job'
                fullWidth
                variant='outlined'
                onChange={onValueChange}
                required
                multiline
                rows='4'
                helperText={errors.has('motivation') && errors.get('motivation').error.message}
                error={errors.has('motivation')}
              />
            </FormControl>

          </Grid>

          <Grid container spacing={1} style={{ marginTop: 16 }} justify='space-between'>
            <Grid item xs={12} sm={4} md={1}>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                onClick={handleCancel}
              >Cancel
              </Button>
            </Grid>

            <Grid item xs={12} sm={8} md={2}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={!isValid}
              >SEND
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </SceneWrapper>
  );
}
