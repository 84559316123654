import React from 'react';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const colors = {
  primary: '#4579b4',
  secondary: '#5e6e89',
  tertiary: '#060d28',
  accent: '#e8e9ea',
  accentLight: '#f9f9f9',
  accentText: '#646678',
  lightText: '#b4bbc8',
  darkText: '#808080',
  background: '#F3F3F5',
  darkBlue: '#141d40'
};

const spacing = (i = 1) => i * 5;

const themeBeforeResponsiveFonts = createMuiTheme({
  spacing: spacing(),
  typography: {
    fontFamily: [
      'Quicksand',
      // 'Gilroy',
      // 'Roboto',
      'sans-serif'
    ].join(','),
    h1: {
      color: colors.secondary
    },
    h2: {
      color: colors.secondary
    },
    h3: {
      color: colors.secondary
    },
    h4: {
      color: colors.secondary
    },
    h5: {
      color: colors.secondary
    },
    h6: {
      color: colors.secondary
    }
  },
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    contrast: {
      main: colors.tertiary
    },
    accent: {
      main: colors.accent
    },
    accentLight: {
      main: colors.accentLight
    },
    lightText: {
      main: colors.lightText
    },
    darkText: {
      main: colors.darkText
    },
    background: {
      main: colors.background
    },
    accentText: {
      main: colors.accentText
    },
    darkBlue: {
      main: colors.darkBlue
    }
  },
  overrides: {
    Link: {
      root: {
        textDecoration: 'none'
      }
    },
    MuiButton: {
      root: {
        borderRadius: spacing(4),
        paddingLeft: spacing(6),
        paddingRight: spacing(6),
        paddingTop: 8,
        paddingBottom: 8
      },
      contained: {
        boxShadow: 'none'
      }
    },
    MuiDivider: {
      root: {
        marginTop: spacing(4),
        marginBottom: spacing(4)
      },
      vertical: {
        marginLeft: 32,
        marginRight: 32,
        marginTop: 0,
        marginBottom: 0,
        height: 'auto'
      }
    },
    MuiTabs: {
      root: {
        minHeight: 0
      },
      indicator: {
        backgroundColor: colors.secondary,
        height: 1
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        minHeight: 0,
        padding: 0,
        // fontSize: '0.9rem!important',
        color: `${colors.secondary}!important`,
        minWidth: '0px !important',
        marginRight: spacing(3)
        // '&$selected': {
        //   color: 'red',
        // },
      }
    },
    MuiFormControlLabel: {
      root: {
        color: colors.secondary
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: 16
      }
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        border: `1px solid ${colors.accent}`,
        marginTop: -1,
        '&:before': {
          backgroundColor: 'none'
        }
      }
    },
    MuiToolbar: {
      regular: {
        minHeight: '67px!important'
      }
    },
    MuiTable: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
});

const theme = responsiveFontSizes(themeBeforeResponsiveFonts, { factor: 3 });

export default function ThemeProvider ({ children }) {
  return (
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  );
}
