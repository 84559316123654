import React from 'react';
import Paper from '../paper';
import SceneWrapper from '../scene-wrapper';
import CustomVerifyNewPassword from '../utils/custom-verify-new-password';

export default function VerifyScene ({ location }) {
  return (
    <SceneWrapper>
      <Paper style={{ alignSelf: 'center', width: 640, maxWidth: '100%', minHeight: 400 }}>
        <CustomVerifyNewPassword />
      </Paper>
    </SceneWrapper>
  );
}
