import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import queryString from 'query-string';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { handleLogin, SIGN_IN_OPTIONS } from '../../actions/account';
import firebaseApp from '../../firebase-app';
import { AuthContext } from '../../contexts/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

export function Login ({ marginTop }) {
  const uiConfig = {
    ...SIGN_IN_OPTIONS,
    callbacks: {
      ...SIGN_IN_OPTIONS.callbacks,
      signInSuccessWithAuthResult: (res) => {
        const asyncHandler = async () => {
          await handleLogin(res);
        };
        asyncHandler();
        // Avoid redirects after sign-in.
        return false;
      }
    }
  };
  return (
    <div style={{ marginTop: marginTop || 0 }}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp.auth} />
    </div>
  );
}

export default function NotLoggedIn ({ viewCvPressed, location, loginAnon }) {
  const { account, accountLoading } = useContext(AuthContext);
  const { mode } = queryString.parse(location.search)
  const [wantsToLogin, setWantsToLogin] = useState(mode === 'select');

  if (account || accountLoading) {
    return null;
  }

  return (
    <div>
      {wantsToLogin &&
        <Login />}

      {!wantsToLogin &&
        <>
          <Button
            color='primary'
            variant='contained'
            fullWidth
            disabled={viewCvPressed}
            onClick={loginAnon}
          >{viewCvPressed ? <CircularProgress style={{ width: 24, height: 24, color: '#fff' }} /> : 'VIEW CV'}
          </Button>

        </>}
    </div>
  );
}
