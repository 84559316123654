import React, { useContext, useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import {
  getSubscriptionPlan,
  generateCheckoutSession,
  generateCustomerPortal
} from '../../actions/stripe';

import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';
import Confirm from '../confirm';

import {
  useStripe
} from '@stripe/react-stripe-js';

/** Utils */
import {
  Product
} from './consts';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  verticalMiddle: {
    verticalAlign: 'middle'
  },
  textMuted: {
    color: '#656565'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  },
  plr35: {
    paddingLeft: 35,
    paddingRight: 35
  },
  billingPortal: {
    padding: '20px 0 40px 0',
    display: 'flex'
  },
  productSubtitle: {
    fontWeight: 300,
    display: 'block'
  }
}));

const SubscribeToProduct = ({ companyEmail, customerId, companyId, companyPlan, activeUsers, companyPlanStatus }) => {
  const classes = useStyles();
  const { showToast } = useContext(DialogsAndToastsContext);
  const [handlingPayment, setHandlingPayment] = useState(false);
  const [firebasePlans, setFirebasePlans] = useState(false);
  const [sessionGenerate, setSessionGenerate] = useState(false);

  const stripe = useStripe();

  const retrieveProductPlans = async () => {
    if (!firebasePlans) {
      const subscriptionPlans = await getSubscriptionPlan();
      const data = subscriptionPlans.data.data;
      setFirebasePlans(data.firebaseData);
    }
  }

  useEffect(() => {
    retrieveProductPlans();
  }, [])

  const hostedCheckoutGenerateSession = async (companyId, productId) => {
    setSessionGenerate(productId);

    try {
      const response = await generateCheckoutSession(companyId, productId, customerId);

      if (response.data && response.data.success){
        const sessionId = response && response.data && response.data.data && response.data.data.id;

        stripe.redirectToCheckout({
          sessionId: sessionId
        })
      }
    } catch (e) {
      showToast({ message: `Something went wrong: ${e.message}`, variant: 'error' })
    }

    setSessionGenerate(false);
  }

  const hostedCustomerPortal = async(companyId, productId) => {
    setSessionGenerate(productId);
    let session;

    try {
      session = await generateCustomerPortal(companyId, productId, customerId);
    } catch (e) {
      showToast({ message: `Something went wrong: ${e.message}`, variant: 'error' })
    }

    setSessionGenerate(false);

    if (session) {
      window.location.href = session.data.url;
    }
  }

  /**
   * List product plans
   * @param productPlans - array of product plans created in Stripe account that a user can subscribe to
   */
  const displayProductPlans = () => {
    if (firebasePlans) {
      const buttonText = (companyPlanStatus == false) ? 'Please Wait' : companyPlanStatus;

      return (
        <React.Fragment>
          {companyPlan && companyPlan !='free' &&
            <>
              {firebasePlans.map((product) => {
                if (companyPlan == product.id){
                  return (
                    <Grid item xs={12} md={12} lg={12} key={product.id}>
                      <Grid container className={classes.plr35}>
                        <Grid item xs={12} md={12} lg={12} key={product.id}>
                          <p>Current plan: {product.name}</p>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} key={product.id} justify="space-between" className={classes.billingPortal}>
                          <div>Manage your billing and view payment history in the billing portal:</div>
                          <Button color='primary' size='small' variant='contained' onClick={() => hostedCustomerPortal(companyId, product.id)}>GO TO BILLING PORTAL</Button>
                         </Grid>
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </>
          }
          {firebasePlans.map((product) => {
            const price = product.price;

            return (
              <Grid item xs={12} md={4} lg={4} key={product.id}>

                <div className='px-4'>
                  <Paper elevation={0} variant='outlined'>
                    <Box pb={2} style={{ textAlign: 'center' }}>
                      <Typography component='div' className='bg-light text-muted font-weight-normal' style={{ fontWeight: 700, color: '#fff', backgroundColor: '#4579B4F5', padding: '20px 5px', marginBottom: '20px' }}>
                        <Box p={1} mb={1}>
                          <Typography className='text-muted font-weight-bold' style={{ fontWeight: 700, color: '#fff', fontSize: 24 }}>
                            {product.name}
                          </Typography>
                          {product.subtitle && <small className={classes.productSubtitle}>{product.subtitle}</small>}
                        </Box>
                      </Typography>
                      <Typography className='text-muted font-weight-bold' style={{ fontSize: 44, fontWeight: 700, padding: '15px 0', color: '#555' }}>
                        {price} {product.monthly ? <><small style={{ fontSize: '0.4em', fontWeight: 300, display: 'block' }}>Monthly</small></> : <small style={{ display: 'block', height: 25 }}></small>}
                      </Typography>

                      {product.info ?
                        <List className={classes.textMuted} dense={false}>
                          {product.info.map((item, i) => {
                            return (

                              <ListItem key={i}>
                                <ListItemText
                                  primary={(
                                    <div>
                                      {item.title
                                        ? <CheckCircleIcon className={classes.verticalMiddle} style={{ marginRight: 10 }} />
                                        : <p style={{ marginBottom: 26 }} />}
                                      {item.title}
                                      {item.description ? <LightTooltip placement='right' className={classes.verticalMiddle} style={{ marginLeft: 10 }} disableFocusListener disableTouchListener title={item.description}>
                                        <InfoIcon fontSize='small' />
                                      </LightTooltip> : null}
                                    </div>
                                  )}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      : null}

                    {(!companyPlan || companyPlan == 'free') &&
                      <>
                        <Divider />
                        {sessionGenerate == product.id ?
                          <Button color='primary' variant='contained' disabled={true}>
                            <CircularProgress className={classes.circularIcon}  />
                          </Button>
                        :<>
                          {product.id != companyPlan ?
                            <Button color='primary' variant='contained' onClick={() => { product.id == 'free' ? hostedCustomerPortal(companyId, product.id) : hostedCheckoutGenerateSession(companyId, product.id)}}>Activate</Button>
                          : <Button color='primary' variant='contained' onClick={() => hostedCustomerPortal(companyId, product.id)}>Selected</Button>}
                        </>}
                    </>
                  } 
                  </Box>
                </Paper>
              </div>
            </Grid>

            );
          })}
        </React.Fragment>
      );
    }
  }

  return (
    <Grid container justify={!firebasePlans ? 'center' : 'flex-end'} direction='row' spacing={3}>
      {!firebasePlans ?
        <div style={{ textAlign: 'center' }}>

          <CircularProgress color='inherit' style={{ margin: '10px auto' }} />
          <p>Please wait</p>
        </div>
      :
        <>{displayProductPlans()}</>
       }
      <div>
        <Backdrop className={classes.backdrop} open={handlingPayment}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </Grid>
  );
};

export default SubscribeToProduct;
