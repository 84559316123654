import uuidv4 from 'uuid/v4';
import path from 'path';
import { createProfile } from './account';
import firebaseApp from '../firebase-app';

export async function updateApplication (application, data) {
  try {
    return await application.ref.update(data);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function submitExternalCv (jobDocument, companyId, values, handleProgress, handleError, handleSuccess) {
  const collection = jobDocument.ref.collection('applications');
  if (values.cv instanceof FileList) {
    const file = values.cv[0];
    const storageRef = firebaseApp.storage.ref();
    const uid = uuidv4();
    const fileEnding = path.extname(file.name);
    const fullPath = `companies/${companyId}/jobs/${jobDocument.id}/${uid}${fileEnding}`;
    const uploadTask = storageRef.child(fullPath).put(file);
    uploadTask.on('state_changed',
      handleProgress,
      handleError,
      handleSuccess
    );
    await uploadTask;
    values.cvPath = fullPath;
  }
  delete values.cv;
  return collection.add(values);
}

export async function setTag (application, tag) {
  try {
    return application.ref
      .collection('private_data')
      .doc('private_document')
      .set({ tag }, { merge: true });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function removeApplication (application) {
  try {
    return application.ref.delete();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function submitApplication (job, account, values, profileToLink, handleUploadProgress, handleUploadError, handleUploadSuccess) {
  try {
    const batch = firebaseApp.db.batch();
    const jobData = job.data();

    if (!profileToLink) {
      // Create new profile with data from values and account, set in profileToLink
      values.profileId = await createProfile(
        account.id,
        {
          title: jobData.title,
          description: ''
        },
        handleUploadProgress,
        handleUploadError,
        handleUploadSuccess
      );
    }

    // Await batch
    return batch.commit();
    // return application
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function submitProfile (jobDocument, profile, values) {
  const collection = jobDocument.ref.collection('applications');

  profile.byUser = values.byUser;
  profile.jobId = jobDocument.id;
  profile.jobCompanyId = values.jobCompanyId;
  profile.jobTitle = values.jobTitle;
  profile.jobDescription = values.jobDescription;

  profile.byUser = values.byUser;
  profile.byCompany = values.byCompany;
  profile.motivation = values.motivation;
  profile.rate = values.rate;
  profile.phone = values.phone;
  profile.email = values.email;

  return collection.add(profile);
}

export async function updateRating (application, value) {
  try {
    if (value >= 0 && value <= 3) {
      application.ref.collection('private_data').doc('private_document').set({
        rating: value
      }, { merge: true });
    }
  } catch (e) {
    console.error(e);
  }
}

export async function submitComment (application, value) {
  try {
    application.ref.collection('private_data').doc('private_document').set({
      comment: value
    }, { merge: true });
  } catch (e) {
    console.error(e);
  }
}
