export function runCookieConsent () {
  window.cookieconsent.run({
    notice_banner_type: 'interstitial',
    consent_type: 'express',
    palette: 'light',
    language: 'en',
    website_name: 'Skilldrop',
    change_preferences_selector: '#changePreferences',
    cookies_policy_url: 'https://app.skilldrop.com/cookie-policy'
  });
}

export function reopenCookieConsent () {
  document.cookie = 'cookie_consent_user_accepted=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'cookie_consent_level=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  window.location.reload();
}
