import React, { useState, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SceneWrapper from '../scene-wrapper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CreateJobDetailsApplications from './create-job-details-applications';
import Avatar, { SIZE } from '../avatar';
import CreateJobDetailsEdit from './create-job-details-edit';
import DialogAddUser from './dialog-add-user';
import { MODES } from '../../constants';
import { useDocument } from 'react-firebase-hooks/firestore';
import { markAsDone, reopenJob } from '../../actions/job';
import ReplaceLink from '../replace-link';
import { AuthContext } from '../../contexts/auth';

export default function CreateJobDetails ({ document, match, location }) {
  const { account } = useContext(AuthContext);
  const jobData = document.data();
  const [mode, setMode] = useState(!jobData.description ? MODES.EDITING : MODES.VIEWING);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [jobPrivateRef] = useDocument(
    document ? document.ref.collection('private_data').doc('private_document') : null);
  const jobPrivate = jobPrivateRef && jobPrivateRef.data();
  const accountData = account && account.data();

  // ACL
  const inJobList = accountData && accountData.jobs && accountData.jobs[match.params.companyId].indexOf(match.params.jobId) !== -1;
  const companyRole = accountData && accountData.currentCompanyRole;
  const isJobEditor = inJobList && companyRole == 'manager';

  const [jobStatusPrivateRef] = useDocument(
    document ? document.ref.collection('private_data').doc('job_status') : null);
  const jobStatusPrivate = jobStatusPrivateRef ? jobStatusPrivateRef.data() : {};

  const inJobAllowedList = jobPrivate && jobPrivate['accounts'] && jobPrivate['accounts'][account.id] !== undefined;

  const handleOnAddUserClicked = () => {
    setAddUserOpen(true);
  };

  const handleAddUserCloseClicked = () => {
    setAddUserOpen(false);
  };

  const handleMarkAsDone = () => {
    markAsDone(match.params.companyId, document.id);
  };

  const handleReopenJob = () => {
    reopenJob(match.params.companyId, document.id);
  };

  return (
    <SceneWrapper>
      <Grid container style={{ width: '100%' }} justify='space-between'>
        <Grid item>
          {mode === MODES.VIEWING && <h1>{jobData.title}</h1>}
          {mode === MODES.EDITING && <h1>Create a new job opportunity</h1>}

          <Tabs
            value={location.pathname.endsWith('/edit') ? 0 : 1}
            aria-label='Pick job details or applications tab'
          >
            <Tab label='Job Description' component={ReplaceLink} to={`${match.url}/edit`} />
            {(inJobList || companyRole == 'administrator') && mode === MODES.VIEWING && (
              <Tab
                label={`Applications (${jobData.applicationCount})`}
                component={ReplaceLink}
                to={`${match.url}/applications`}
              />
            )}
          </Tabs>
        </Grid>

        <Grid item>
          <Grid container direction='column'>
            <Grid container justify='flex-end'>
              {jobStatusPrivate && !jobStatusPrivate.done && accountData && accountData.currentCompanyRole != 'viewer' && (inJobAllowedList || accountData.currentCompanyRole == 'administrator') &&
                <Button size='small' onClick={handleMarkAsDone} variant='text'>Mark job as done</Button>}

              {jobStatusPrivate && jobStatusPrivate.done && accountData && accountData.currentCompanyRole != 'viewer' && (inJobAllowedList || accountData.currentCompanyRole == 'administrator') &&
                <Button size='small' onClick={handleReopenJob} variant='text'>Reopen job</Button>}

              {(!inJobAllowedList || (accountData && accountData.currentCompanyRole == 'viewer')) && <span style={{ display: 'block', height: 30 }}></span>}
            </Grid>

            <Grid container justify='flex-end' alignItems='center'>
              {jobPrivate && jobPrivate.accounts
                ? Object.keys(jobPrivate.accounts).map(id => {
                  const account = jobPrivate.accounts[id];
                  return <Avatar size={SIZE.BIG} key={id} displayName={account.displayName} photo={account.photo} />
                })
                : null}

              {accountData && accountData.currentCompanyRole != 'viewer' && (isJobEditor || companyRole == 'administrator') &&
              <Button variant='contained' color='primary' size='small' onClick={handleOnAddUserClicked}>
                ADD USER
              </Button>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 16, marginBottom: 24 }} />

      <Switch>
        <Route path={`${match.path}/edit`}>
          <CreateJobDetailsEdit
            jobDocument={document}
            match={match}
            mode={mode}
            setMode={setMode}
          />
        </Route>
        <Route path={`${match.path}/applications`}>
          <CreateJobDetailsApplications jobDocument={document} match={match} jobPrivate={jobPrivate} />
        </Route>
      </Switch>

      <DialogAddUser open={addUserOpen} onClose={handleAddUserCloseClicked} companyId={match.params.companyId} jobId={match.params.jobId} />
    </SceneWrapper>
  );
}
