import React, { useState, useContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useAuthState } from 'react-firebase-hooks/auth';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import firebaseApp from '../../firebase-app';
import Paper from '../paper';
import { updateAccount, changePhoto } from '../../actions/account';
import { AuthContext } from '../../contexts/auth';
import Avatar, { SIZE } from '../avatar';
import SceneWrapper from '../scene-wrapper';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';
import LinkButton from '../link-button';
import Onboarding from './onboarding';
import { ONBOARDING_STATUS } from '../../constants/onboarding';

const useStyles = makeStyles(theme => ({
  uploadButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    alignSelf: 'center'
  }
}));

export default function Account () {
  const { showToast } = useContext(DialogsAndToastsContext);
  const { currentUser, account, accountLoading } = useContext(AuthContext);
  const [companies, setCompanies] = useState([]);
  const [user] = useAuthState(firebaseApp.auth);
  const accountData = account ? account.data() || {} : {};
  const [values, setValues] = useState(null);
  const classes = useStyles();
  const [showVerification, setShowVerification] = useState(false);

  useEffect(() => {
    if (currentUser && !currentUser.isAnonymous && !currentUser.emailVerified) {
      setShowVerification(true);

      let reloadUserInterval;
      let checkEmailVerifiedInterval;

      reloadUserInterval = setInterval(() => {
        currentUser.reload();
      }, 5000);

      checkEmailVerifiedInterval = setInterval(() => {
        if (currentUser && currentUser.emailVerified) {
          clearInterval(reloadUserInterval);
          clearInterval(checkEmailVerifiedInterval);
          setShowVerification(false);
        }
      }, 500);

      return () => {
        clearInterval(reloadUserInterval);
        clearInterval(checkEmailVerifiedInterval);
      };
    }
  }, [currentUser]);

  useEffect(() => {
    const companyIds = account && account.exists ? account.data().companies || [] : [];
    Promise.all(companyIds.map(id => {
      return firebaseApp.db.doc(`companies/${id}`).get();
    }))
      .then(companies => {
        setCompanies(companies);
      });
  }, [account]);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    let newValue = value;
    if (type === 'checkbox') {
      if (checked) {
        newValue = [...values[name], value];
      } else {
        newValue = [...values[name].filter(v => v !== value)];
      }
    }
    setValues({ ...values, [name]: newValue });
  }

  const validateThenUpdateAccount = async () => {
    const { displayName, email } = values;
    if (!displayName || !email) {
      return;
    }

    if (displayName !== user.displayName) {
      await user.updateProfile({ displayName });
    }

    if (email !== user.email) {
      try {
        await user.updateEmail(email);
      } catch (e) {
        console.error('Need recent login?');
      }
    }

    await updateAccount(user.uid, {
      phone: values.phone || null,
      displayName,
      email
    });

    showToast({
      variant: 'success',
      message: 'Account saved'
    });
  }

  const handleChangePhoto = async (event) => {
    try {
      await changePhoto(
        account.id,
        event.target.files[0],
        () => {},
        (message) => showToast({
          variant: 'error',
          message
        }),
        () => {}
      );
      showToast({
        message: 'Photo updated'
      });
    } catch (error) {
      showToast({
        variant: 'error',
        message: error.message
      });
    }
  }

  const resendVerificationEmail = async () => {
    await currentUser.sendEmailVerification();
    showToast({
      message: 'Verification email has been sent'
    });
  }

  if (accountLoading) {
    return <SceneWrapper />;
  } else if (!values) {
    setValues({
      displayName: accountData.displayName || '',
      email: accountData.email || '',
      phone: accountData.phone || ''
    });
    return <SceneWrapper />;
  }

  return (
    <SceneWrapper id='account'>
      <Paper style={{ alignSelf: 'center', width: '100%', maxWidth: 630, marginTop: 35, borderRadius: 8, border: '1px solid rgb(232, 233, 234)' }}>

      {!showVerification && !accountData.needsOnboarding ?
        <>
          <Avatar
            size={SIZE.MEGA}
            displayName={accountData ? accountData.displayName : ''}
            photo={accountData ? accountData.photo : null}
            style={{ alignSelf: 'center' }}
          />
          <Button
            variant='outlined'
            component='label'
            className={classes.uploadButton}
            size='small'
          >
            Change photo
            <input
              name='logo'
              type='file'
              accept='.jpg,.png,.gif'
              onChange={handleChangePhoto}
              style={{ display: 'none' }}
            />
          </Button>
        </>
      : null}

        {currentUser.isAnonymous ? (
          <>
            <Divider />
            <p>
              You are using Skilldrop as an anonymous user. Your data is tied to this device and browser.
              Please create a free account or login with an existing user if you want to preserve your data
              and be able to use it on multiple devices. Your current data will be automatically merged to your account.
            </p>

            <LinkButton
              title='Proceed'
              to='/login'
            />
            <Divider />
          </>
        ) : null}

        {showVerification ? (
          <>
            <h1>Please verify your email</h1>
            <p>
              We sent a verification email to:
              <br /><br />
              <strong>{accountData.email}</strong>
              <br />
            </p>
            <Button
              onClick={resendVerificationEmail}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Resend verification email
            </Button>
            <br />
            <p>
              It can take a few minutes for the verification email to land in your inbox. If you haven't received it in 5 minutes, try resending it.
            </p>
          </>
        ) :
          <>
            {!showVerification && !accountData.needsOnboarding ?
              <>
                {!currentUser.isAnonymous ? (
                  <form style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', marginTop: 16 }}>
                    <TextField
                      variant='outlined'
                      value={values.displayName}
                      name='displayName'
                      fullWidth
                      label='Name'
                      onChange={handleChange}
                      required
                      margin='normal'
                    />

                    <TextField
                      variant='outlined'
                      value={values.email}
                      name='email'
                      fullWidth
                      label='Email'
                      onChange={handleChange}
                      required
                      margin='normal'
                    />

                    <TextField
                      variant='outlined'
                      value={values.phone}
                      name='phone'
                      fullWidth
                      label='Phone'
                      onChange={handleChange}
                      margin='normal'
                    />
                    <Grid container spacing={1} style={{ marginTop: 32 }} justify='flex-end'>
                      <Grid item />
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Button
                          onClick={validateThenUpdateAccount}
                          variant='contained'
                          color='primary'
                          fullWidth
                          style={{ marginTop: 8 }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>) : null}

                {companies.map(companyDoc => {
                  const company = companyDoc.data();
                  return (
                    <React.Fragment key={companyDoc.id}>
                      <Divider />
                      <Grid container alignItems='center'>
                        <Grid item>
                          <Avatar size={SIZE.BIG} displayName={company.name} photo={company.logo} />
                        </Grid>
                        <Grid item>
                          <Link to={`/companies/${companyDoc.id}/details`} className='document-link'>{company.name}</Link>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </>
            :
              <>
              {!currentUser.isAnonymous &&
                <Onboarding showToast={showToast} currentUser={currentUser} account={account} user={user} accountData={accountData} />}
              </>
            }
          </>
        }
      </Paper>
    </SceneWrapper>
  );
}
