import React, { useState, useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Paper from '../paper';
import { AuthContext } from '../../contexts/auth';
import get from 'lodash.get';
import { makeStyles } from '@material-ui/core/styles';

import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';
import {useDocument} from "react-firebase-hooks/firestore";
import firebaseApp from "../../firebase-app";
import CompanyRowMember from "./company-row-member";

const useStyles = makeStyles(theme => ({
  tableHead: {
    backgroundColor: theme.palette.accent.main
  },
  tableHeadCell: {
    color: theme.palette.contrast.main
  },
  membersTableHead: {
    backgroundColor: theme.palette.contrast.main
  },
  memberTableHeadCell: {
    color: '#fff'
  }
}));

export default function CompanyIntegration ({ match }) {
  const classes = useStyles();
  const tableRows = [];
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const { account } = useContext(AuthContext);
  const { companyId } = match.params;
  const [companyPrivateDocRef] = useDocument(firebaseApp.db.doc(`companies/${companyId}/private_data/private_document`));
  const companyPrivateDoc = companyPrivateDocRef ? companyPrivateDocRef.data() : { accounts: {} };
  const userRole = get(companyPrivateDoc, `accounts[${account ? account.id : ''}].role`, 'viewer');
  const mayView = userRole === 'administrator';

  return (
    <div>
      <Table size='small'>
        <TableHead className={classes.membersTableHead}>
          <TableRow>
            <TableCell className={classes.memberTableHeadCell}>API Key</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{companyPrivateDoc.apiKey || ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
