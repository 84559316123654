import React, { useContext, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import get from 'lodash.get';

import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../snackbar-content-wrapper';
import firebaseApp from '../../firebase-app';
import SceneWrapper from '../scene-wrapper';
import ReplaceLink from '../replace-link';

import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PrivateRoute from '../../private-route';
import CompanyUsers from './company-users';
import CompanySettings from './company-settings';
import CompanyIntegration from './company-integration';

const useStyles = makeStyles(theme => ({
  tableHead: {
    backgroundColor: theme.palette.accent.main
  },
  tableHeadCell: {
    color: theme.palette.contrast.main
  },
  membersTableHead: {
    backgroundColor: theme.palette.contrast.main
  },
  memberTableHeadCell: {
    color: '#fff'
  },
  flexAlignMiddle: {
    alignSelf: 'baseline'
  }
}));

export default function CompanyDetails ({ match, location }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackbarData, setSnackbarData] = useState({});
  const { account, companyLoading } = useContext(AuthContext);
  const { companyId } = match.params;

  // eslint-disable-next-line no-unused-vars
  const [companyRef, companyRefLoading, companyError] = useDocument(firebaseApp.db.doc(`companies/${companyId}`), { includeMetadataChanges: true });

  if (companyError) {
    setTimeout(() => window.location.href = '/account', 600);
  }

  const [companyPrivateDocRef] = useDocument(firebaseApp.db.doc(`companies/${companyId}/private_data/private_document`));
  const companyPrivateDoc = companyPrivateDocRef ? companyPrivateDocRef.data() : { accounts: {} };

  // eslint-disable-next-line no-unused-vars
  const [companyInvitesRef, companyInvitesLoading, companyInvitesError] = useCollection(
    firebaseApp.db.collection(`companies/${companyId}/invites`)
  );
  // const companyInvites = companyInvitesRef ? companyInvitesRef.docs : [];
  const company = companyRef ? companyRef.data() : {};
  const classes = useStyles();

  const userRole = get(companyPrivateDoc, `accounts[${account ? account.id : ''}].role`, 'viewer');
  const mayEdit = userRole === 'administrator';
  const apiIntegrationEnabled = process.env.REACT_APP_ENABLE_API_INTEGRATION === '1';

  const EmptyRedirect = () => {
    if (companyLoading) {
      return null;
    }
    if (company) {
      console.log('redirect to our-people');
      return <Redirect to={`${match.url}/company-users/`} />;
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const getTab = () => {
    if (location.pathname.includes('company-users')) {
      return 0;
    }
    if (location.pathname.includes('billing')) {
      return 1;
    }
    if (location.pathname.includes('my-profile')) {
      return 2;
    }
    if (location.pathname.includes('integration')) {
      return 2;
    }
    return 0;
  };

  return (
    <>

      <SceneWrapper>
        <Grid container alignItems='center'>
          <Grid item className={classes.flexAlignMiddle}>
            <h1>{company.name}</h1>
          </Grid>
        </Grid>
        <Grid container justify='space-between' alignItems='center'>
          <Grid item>
            <Tabs
              value={getTab()}
              aria-label='Pick what to share'
            >
              <Tab label='Users' component={ReplaceLink} to={`${match.url}/company-users/`} />
              {mayEdit
                ? <Tab label='Billing' component={ReplaceLink} to={`${match.url}/billing/`} /> : null}
              {mayEdit && apiIntegrationEnabled
                ? <Tab label='Integration' component={ReplaceLink} to={`${match.url}/integration/`} /> : null}
            </Tabs>
          </Grid>
        </Grid>
        <Divider />
        <Switch>
          <PrivateRoute exact path={`${match.path}/company-users/`} component={CompanyUsers} match={match} />
          <PrivateRoute exact path={`${match.path}/billing/`} component={CompanySettings} match={match} />
          <PrivateRoute exact path={`${match.path}/integration/`} component={CompanyIntegration} match={match} />
          <EmptyRedirect
            path={`${match.path}`}
          />
        </Switch>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <SnackbarContentWrapper
            onClose={handleSnackbarClose}
            variant={snackbarData.variant}
            message={snackbarData.message}
          />
        </Snackbar>

      </SceneWrapper>
    </>
  );
}

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
