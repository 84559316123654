import React, { useState } from 'react';
import P from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Confirm (props) {
  const [open, setOpen] = useState(false);
  const [callback, setCallback] = useState(false);

  const show = cb => ev => {
    ev.preventDefault();
    setOpen(true);
    setCallback(() => cb);
  };

  const hide = () => {
    setOpen(false);
    setCallback(null);
  };

  const confirm = () => {
    callback();
    hide();
  };

  return (
    <>
      {props.children(show)}

      <Dialog
        open={open}
        onClose={hide}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        {!!props.description &&
          <DialogContent dividers>
            <DialogContentText id='alert-dialog-description'>{props.description}</DialogContentText>
          </DialogContent>}
        <DialogActions>
          <Button onClick={hide} variant='contained'>
            {props.cancelText}
          </Button>
          <Button onClick={confirm} color='primary' autoFocus variant='contained'>
            {props.confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

Confirm.prototypes = {
  children: P.func.isRequired,
  cancelText: P.string,
  confirmText: P.string,
  description: P.string,
  title: P.string
};

Confirm.defaultProps = {
  title: 'Are you sure?',
  description: null,
  confirmText: 'Yes',
  cancelText: 'Cancel'
};
