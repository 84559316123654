import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import firebaseApp from '../firebase-app';
import { AuthContext } from '../contexts/auth';
import LinkButton from './link-button';
import Avatar, { SIZE } from './avatar';
import { reopenCookieConsent } from '../actions/cookie-consent';
import { changeSelectedCompany } from '../actions/company';
import CompanySwitcher from './company-switcher';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1
  },
  toolbar: {
    display: 'flex'
  },
  appBar: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 100,
    backgroundImage: 'radial-gradient(circle at 8% 50%, #1a254d, #060d28)',
    color: '#dedfea',
    paddingLeft: 0,
    paddingRight: 0
  },
  list: {
    width: 250,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.contrast.main,
    color: '#dedfea',
    flex: 1
  },
  versionNumber: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.contrast.main,
    color: '#dedfea',
    textAlign: 'right'
  },
  fullList: {
    width: 'auto'
  },
  divider: {
    backgroundColor: '#dedfea80',
    marginTop: 0,
    marginBottom: 0
  },
  dividerWithMargin: {
    backgroundColor: '#dedfea80'
  },
  iconRoot: {
    minWidth: 0,
    color: '#dedfea'
  },
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listItemGutters: {
    padding: 0
  },
  link: {
    color: '#dedfea',
    flex: 1,
    textDecoration: 'none',
    fontSize: '1rem',
    display: 'block',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  chevronRight: {
    marginRight: 2
  },
  companyTitle: {
    marginTop: 20,
    fontWeight: 700
  },
  hasNotification: {
    position: 'absolute',
    width: 5,
    height: 5,
    backgroundColor: '#f00',
    opacity: 0.9,
    display: 'block',
    borderRadius: '50%',
    bottom: 0
  },
  chipSecondary: {
    borderColor: '#f88',
    color: '#fff',
    fontSize: '11px',
    height: '16px',
    background: '#f88',
    lineHeight: '16px',
    marginLeft: 10,
    marginTop: -1
  }
}));

function ListItemLink ({ title, link, pill }) {
  const classes = useStyles();
  return (
    <ListItem
      button classes={{
        gutters: classes.listItemGutters
      }}
    >
      <Link to={link} className={classes.link}>
        {title}
        {pill &&
          <Chip
            className={classes.chipSecondary}
            variant="outlined"
            size="small"
            label={pill}
            color="secondary"
          />
        }
      </Link>
      <ListItemIcon classes={{
        root: classes.iconRoot
      }}
      >
        <ChevronRightIcon className={classes.chevronRight} />
      </ListItemIcon>
    </ListItem>
  );
}

export default function TopBar (props) {
  const [drawerState, setDrawerState] = React.useState(false);
  const { currentUser, account, accountLoading, company, modules } = useContext(AuthContext);
  const classes = useStyles();
  const accountData = account && account.data();
  const companyData = company && company.data();
  const companyId = company ? company.id : '-';

  const handleMenuOpen = event => {
    setDrawerState(true);
  };

  const handleMenuClose = () => {
    setDrawerState(false);
  };

  const handleLogOut = () => {
    handleMenuClose();
    firebaseApp.auth.signOut();
  }

  const handleCompanyChange = (companyId) => {
    changeSelectedCompany(companyId, account.id);
  }

  const renderNotLoggedIn = () => {
    return (
      <>
        <Typography style={{ marginTop: 8 }}>
          Login or create an account to access more features.
        </Typography>

        <Divider className={classes.divider} style={{ marginTop: 12 }} />

        <List>
          <ListItemLink title='Login' link='/login' />
        </List>
      </>
    );
  }

  const renderLoggedIn = () => {
    return (
      <>
        <List>
          <ListItem
            button key='account' classes={{
              root: classes.listItemRoot,
              gutters: classes.listItemGutters
            }}
          >
            {accountData
              ? <Grid container justify='space-between' alignItems='center' alignContent='stretch' style={{ flexWrap: 'nowrap' }}>
                <Grid item style={{ flex: 1 }}>
                  <Link to='/account' className={classes.link}>{accountData.displayName}</Link>
                </Grid>
                <Grid item>
                  <Link to='/account'>
                    <Avatar size={SIZE.MEDIUM} photo={accountData.photo} />
                  </Link>
                </Grid>
              </Grid> : null}
          </ListItem>
          <ListItem
            button key='my-profiles' classes={{
              root: classes.listItemRoot,
              gutters: classes.listItemGutters
            }}
          >
            <Link to='/share/my-profile' className={classes.link}>My profiles</Link>
          </ListItem>
          {((companyData && companyData.requestsPending) || (accountData && accountData.invites && accountData.invites.length > 0) ) &&
          <ListItem
            button key='company' classes={{
              root: classes.listItemRoot,
              gutters: classes.listItemGutters
            }}
          >
            <Link to='/notifications' className={classes.link}>
              <Grid container>
                <Grid item style={{ flex: 1 }}>
                  Notifications
                </Grid>
                <Grid item>
                  {((companyData && companyData.requestsPending) || (accountData && accountData.invites && accountData.invites.length > 0) )
                  && <span style={{ position: 'relative', height: 14, width: 16, display: 'block' }}><div className={classes.hasNotification}></div></span>}
                </Grid>
              </Grid>
            </Link>
          </ListItem>
          }
          {!company ?
            <ListItem
              button key='create-company' classes={{
                root: classes.listItemRoot,
                gutters: classes.listItemGutters
              }}
            >
              <Link to='/companies/create-company' className={classes.link}>Create Company</Link>
            </ListItem>
          : null}
        </List>

        <Divider className={classes.divider} />
        {companyData ?
          <>
            <Typography className={classes.companyTitle}>{companyData.name}</Typography>
            <List>
              {[
                {
                  title: 'Managed profiles',
                  link: company && accountData.currentCompanyRole!='viewer' ? '/share' : null
                },
                {
                  title: 'Shared Profiles',
                  link: '/share/shared-with-me/'
                },
                {
                  title: 'Jobs',
                  link: modules.hire ? `/companies/${companyId}/jobs` : null,
                  pill: 'BETA'
                },
                {
                  title: 'Users',
                  link: company && accountData.currentCompanyRole!='viewer' ? `/companies/${company.id}/details` : null
                },
                {
                  title: 'Billing',
                  link: company && accountData.currentCompanyRole=='administrator' ? `/companies/${company.id}/details/billing` : null
                },
                {
                  title: 'Integration',
                  link: company && accountData.currentCompanyRole=='administrator' ? `/companies/${company.id}/details/integration` : null
                },
              ].map((item) => item.link ? <ListItemLink key={item.link} pill={item && item.pill} title={item.title} link={item.link} /> : null)}
            </List>
          </>
        : null}

        <Divider className={classes.divider} />

        {!currentUser.isAnonymous ?
          <List>
            <ListItem
              button key='logout' onClick={handleLogOut} classes={{
                root: classes.listItemRoot,
                gutters: classes.listItemGutters
              }}
            >
              <ListItemText primary='Logout' />
            </ListItem>
          </List>
        :
        <List>
          <ListItemLink title='Login' link='/login' />
        </List>}
      </>
    );
  }

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className='logo'>
            <span className='logo-skill'>SKILL</span><span className='logo-drop'>DROP</span>
          </div>

          <div style={{ flex: 1 }} />

          <Hidden xsDown>
            {currentUser && !currentUser.isAnonymous && modules.hire &&
              <LinkButton key='hire' title='Jobs' pill='Beta' to={`/companies/${companyId}/jobs`} light buttonProps={{ style: { minWidth: 130 } }} />}

            {currentUser && !currentUser.isAnonymous && company && accountData && accountData.currentCompanyRole!='viewer' &&
              <LinkButton key='share' title='Profiles' to='/share' light buttonProps={{ style: { minWidth: 130, marginLeft: 16, marginRight: 16 } }} />}

            {accountData && accountData.companies && accountData.companies.length > 0 &&
              <CompanySwitcher
                changeCompany={handleCompanyChange}
                companies={accountData.companies}
                currentCompany={accountData.currentCompany}
              />}

            {(!account && !accountLoading) || (currentUser && currentUser.isAnonymous) &&
              <LinkButton
                key='learn-more'
                title='Learn more about skilldrop'
                to='https://skilldrop.com'
                light
                buttonProps={{ style: { marginRight: 16, display: 'block' } }}
              />}
          </Hidden>

          <IconButton
            edge='end'
            aria-label='Application menu'
            aria-haspopup='true'
            onClick={handleMenuOpen}
            color='inherit'
            size='small'
          >
            {((companyData && companyData.requestsPending) || (accountData && accountData.invites && accountData.invites.length > 0) )
            && <div className={classes.hasNotification}></div>}
            {accountData && currentUser && !currentUser.isAnonymous ?
              <Avatar size={SIZE.MEDIUM} photo={accountData.photo} />
            : null}
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor='right' open={drawerState} onClose={handleMenuClose}>
        <div
          className={classes.list}
          role='presentation'
          onClick={handleMenuClose}
          onKeyDown={handleMenuClose}
        >
          <div className='logo' style={{ marginBottom: 16 }}>
            <span className='logo-skill'>SKILL</span><span className='logo-drop'>DROP</span>
          </div>

          <Divider className={classes.divider} />

          {currentUser && !currentUser.isAnonymous && renderLoggedIn()}
          {(!currentUser || currentUser.isAnonymous) && renderNotLoggedIn()}
        </div>

        <div
          className={classes.versionNumber}
          role='presentation'
          onClick={handleMenuClose}
          onKeyDown={handleMenuClose}
        >
          <List>
            <ListItemLink title='Cookie policy' link='/cookie-policy' />
            <ListItemLink title='Privacy policy' link='/privacy-policy' />
            <ListItemLink title='Terms of Service' link='/terms-of-service' />
            <Button onClick={reopenCookieConsent} variant='text' color='secondary' style={{ width: '100%' }}>Cookie preferences</Button>
          </List>
          <Divider className={classes.dividerWithMargin} style={{ marginTop: 0 }} />
          {process.env.REACT_APP_VERSION}
        </div>
      </Drawer>
    </>
  );
}
