import firebaseApp from '../firebase-app';

export async function updateCompanyPayment (companyId, token) {
  try {
    const companyRef = firebaseApp.db.doc(`companies/${companyId}`);
    return await companyRef.update({ stripeToken: token });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getSubscriptionPlan () {
  try {
    return await firebaseApp.functions.httpsCallable(
      'getSubscriptionPlans'
    )({

    });
  } catch (e) {
    console.error(e);
    throw (e);
  }
}

export async function generateCheckoutSession(companyId, planId, clientReferenceId) {
  const stripeSessionRef = firebaseApp.db.collection(`companies/${companyId}/checkout_sessions`);
  console.log(`companies/${companyId}/checkout_sessions`);

  const sessionDoc = await firebaseApp.functions.httpsCallable(
    'createCheckoutSessionCallable'
  )({
    companyId: companyId,
    mode: 'subscription',
    payment_method_types: ['card'],
    success_url: 'https://' + process.env.REACT_APP_BASE_LINK + '/companies/' + companyId +'/details/billing/',
    cancel_url: 'https://' + process.env.REACT_APP_BASE_LINK + '/companies/' + companyId +'/details/billing/',
    client_reference_id: clientReferenceId,
    line_items: [
      {
        price: planId,
        quantity: 1
      }
    ]
  });

  return sessionDoc;
}


export async function generateCustomerPortal(companyId, planId, clientReferenceId) {
  const sessionDoc = await firebaseApp.functions.httpsCallable(
    'createPortalLink'
  )({
    companyId,
    clientReferenceId,
    returnUrl: 'https://' + process.env.REACT_APP_BASE_LINK + '/companies/' + companyId +'/details/billing/',
  });

  return sessionDoc;
}
