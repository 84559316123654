import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';
import firebaseApp from '../../firebase-app';
import { handleLogin } from '../../actions/account';
import SceneWrapper from '../scene-wrapper';
import Paper from '../paper';
import LoggedIn from './logged-in';
import LoggedOut from './logged-out';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinkButton from '../link-button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35,
    border: '1px solid rgb(232, 233, 234)'
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  buttonContainer: {
    '& button': {
      border: '1px solid rgba(69, 121, 180, 0.5)'
    },
    '& button:hover': {
      border: '1px solid #4579b4'
    },
    '& a': {
      padding: '5px 30px'
    }
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  }
}));

export default function AcceptPersonalProfileSharedWithMe ({ location, match }) {
  const classes = useStyles();
  const { showToast } = useContext(DialogsAndToastsContext);
  const { accountId, profileId, linkId } = match.params;
  const { companyId, candidateId } = match.params;
  const [redirect, setRedirect] = useState();
  const [senderName, setSenderName] = useState(null);
  const [expired, setExpired] = useState(null);
  const [viewCvPressed, setViewCvPressed] = useState(false);

  useEffect(() => {
    const fetchExpiresAndDisplayName = async () => {
      try {
        const res = await firebaseApp.functions.httpsCallable(
          'getValidityAndSenderNameForSharedLink'
        )(
          companyId
            ? {
              companyId,
              candidateId,
              profileId,
              linkId
            }
            : {
              accountId,
              profileId,
              linkId
            }
        );
        if (res && res.data && res.data.success) {
          setSenderName(res.data.displayName);
        } else {
          setExpired(true);
        }
      } catch (e) {
        showToast({
          variant: 'error',
          message: 'Could not verify link'
        })
      }
    };
    fetchExpiresAndDisplayName();
  }, [accountId, companyId, candidateId, profileId, linkId, showToast]);

  const addToSharedWithMe = async () => {
    if (!viewCvPressed) {
      setViewCvPressed(true);
    }
    let res;
    if (companyId) {
      res = await firebaseApp.functions.httpsCallable(
        'acceptCandidateProfileSharedWithMe'
      )({
        companyId,
        candidateId,
        profileId,
        linkId
      });
    } else {
      res = await firebaseApp.functions.httpsCallable(
        'acceptPersonalProfileSharedWithMe'
      )({
        accountId,
        profileId,
        linkId
      });
    }
    if (res && res.data && res.data.success) {
      setTimeout(() => {
        setRedirect(`/share/shared-with-me/${linkId}/${linkId}`);
      }, 1000);
    } else if (res && res.data && res.data.message == 'Profile already exists') {
      setTimeout(() => {
        setRedirect(`/share/shared-with-me/${linkId}/${linkId}`);
      }, 1000);
    } else {
      alert('Something went wrong');
      setViewCvPressed(false);
    }
  };

  const loginAnon = async () => {
    try {
      setViewCvPressed(true);
      const res = await firebaseApp.auth.signInAnonymously();
      await handleLogin(res);
      await addToSharedWithMe();
    } catch (e) {
      // TODO show error toast
      alert(e);
    }
  };

  if (expired) {
    return (
      <SceneWrapper>
        <Paper className={classes.root}>
          <Typography component='h1' className={classes.formTitle}>CV link expired</Typography>
          <Divider />
          <p>The temporary link you are trying to access has expired.</p>
          <p>
            You can read more on how Skilldrop improves management of personal
            data on our website.
          </p>
          <Grid container spacing={1} style={{ marginTop: 32 }} justify='flex-end'>
            <Grid item />
            <Grid item xs={12} sm={4} md={4} lg={4} className={classes.buttonContainer}>
              <LinkButton to='https://skilldrop.com' title='GO TO WEBSITE' round />
            </Grid>
          </Grid>
        </Paper>
      </SceneWrapper>
    );
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (!senderName) {
    return (
      <SceneWrapper>
        <Paper className={classes.root}>
          <CircularProgress />
          <p>Opening Link to CV</p>
        </Paper>
      </SceneWrapper>
    );
  }

  return (
    <SceneWrapper>
      <Paper className={classes.root}>
        <Typography component='h1' className={classes.formTitle}>CV from {senderName}</Typography>

        <Divider />

        <h2>CVs contain personal data</h2>
        <ul>
          <li>Do not save the CV file to your computer.</li>
          <li>
            If you print the CV, make sure to mark the date the printout must be
            destroyed. Store printouts in a location that is systematically
            culled.
          </li>
        </ul>

        <Divider />

        <LoggedOut
          addToSharedWithMe={addToSharedWithMe}
          loginAnon={loginAnon}
          location={location}
          viewCvPressed={viewCvPressed}
        />

        <LoggedIn
          addToSharedWithMe={addToSharedWithMe}
          location={location}
          viewCvPressed={viewCvPressed}
        />
      </Paper>
    </SceneWrapper>
  );
}
