import React from 'react';
import P from 'prop-types';

export default function JobDescription ({ description = '' }) {
  const __html = description.replace(/\n/ig, '<br>');

  return (
    <div
      dangerouslySetInnerHTML={{ __html }}
    />
  );
}

JobDescription.propTypes = {
  description: P.string.isRequired
};
