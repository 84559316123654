import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LinkButton from '../components/link-button';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1.5)
  },
  subTitle: {
    marginTop: 0
  },
  noMyProfileContainer: props => ({
    background: `transparent url('${props.image}') no-repeat`,
    backgroundSize: 'contain',
    backgroundPosition: 'right',
    margin: '0 auto',
    width: props.width || 700,
    height: props.height || 'auto',
    minHeight: 160,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 200
    },
    [theme.breakpoints.down('xs')]: {
      background: 'none'
    }
  }),
  numberContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  numberRow: {
    display: 'flex',
    marginBottom: theme.spacing(1)
  },
  number: {
    width: 20,
    flexShrink: 0,
    height: 20,
    borderRadius: 20,
    backgroundColor: '#8575b6',
    display: 'inline-block',
    textAlign: 'center',
    marginRight: theme.spacing(3),
    color: '#fff'
  }
}));

export default function NoDataInformation ({ width, height, image, title, subTitle, to, bullets, actionTitle }) {
  const classes = useStyles({ image, width, height });
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const subtitleArray = subTitle.split('<br>');

  return (
    <div className={classes.noMyProfileContainer}>
      <h1 className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
      <p className={classes.subTitle}>
        {subtitleArray.map((item)=>{
          return <>{item} <br /></>
        })}
      </p>
      <div className={classes.numberContainer}>
        {bullets && bullets.map((bullet, index) => (
          <div className={classes.numberRow} key={index}>
            <div className={classes.number}>{index + 1}</div>
            <div dangerouslySetInnerHTML={{ __html: bullet }} />
          </div>
        ))}
      </div>

      {to && actionTitle &&
        <LinkButton
          to={to}
          title={actionTitle}
          light
          round
          buttonProps={{
            variant: 'contained',
            color: 'primary',
            fullWidth: isExtraSmall
          }}
        />}
    </div>
  );
}
