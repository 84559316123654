import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import SubmitCv from '../create-job/submit-cv';
import SubmitProfile from '../create-job/submit-profile';

import SubmitApplication from './submit-application';
import JobList from '../scenes/job-list';
import CreateJobDetails from '../create-job/create-job-details';
import WithDocument from '../../containers/document';
import CreateJobForm from '../create-job/create-job-form';
import CompanyDashboard from './company-dashboard';
import CompanyDetails from './company-details';
import PrivateRoute from '../../private-route';
import CompanyAcceptInvite from './company-accept-invite';
import CompanyAcceptJobInvite from './company-accept-job-invite';
import SceneWrapper from '../scene-wrapper';

const CreateJobDetailsWithDocument = WithDocument(CreateJobDetails, 'companies/%companyId%/jobs/%jobId%');
const SubmitCvWithDocument = WithDocument(SubmitCv, 'companies/%companyId%/jobs/%jobId%');
const SubmitProfileWithDocument = WithDocument(SubmitProfile, 'companies/%companyId%/jobs/%jobId%');

const CreateJobScene = (props) => <SceneWrapper><CreateJobForm {...props} /></SceneWrapper>;

export default function Company ({ match }) {
  if (match.params.companyId === '-') {
    return <Redirect to='/companies/create' />;
  }

  return (
    <Switch>
      <PrivateRoute path={`${match.path}/submit-application/:jobId`} component={SubmitApplication} match={match} />
      <Route exact path={`${match.path}/accept-company-invite/:inviteCode`} component={CompanyAcceptInvite} />
      <PrivateRoute exact path={`${match.path}`} component={CompanyDashboard} match={match} />
      <Route exact path={`${match.path}/jobs/:jobId/accept-job-invite/:inviteCode/:inviteEmail`} component={CompanyAcceptJobInvite} />
      <PrivateRoute exact path={`${match.path}/create-job`} component={CreateJobScene} match={match} />
      <PrivateRoute path={`${match.path}/create-job/:jobId`} component={CreateJobDetailsWithDocument} match={match} />
      <PrivateRoute path={`${match.path}/submit-cv/:jobId`} component={SubmitCvWithDocument} match={match} />
      <PrivateRoute path={`${match.path}/submit-profile/:jobId`} component={SubmitProfileWithDocument} match={match} />
      <PrivateRoute path={`${match.path}/details`} component={CompanyDetails} match={match} />
      <PrivateRoute path={`${match.path}/jobs`} component={JobList} />
    </Switch>
  );
}
