import React, { useEffect, useState } from 'react';
import firebaseApp from '../firebase-app';

export const AuthContext = React.createContext();
const LOADING_AUTH_USER = 'LOADING_AUTH_USER';

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(LOADING_AUTH_USER);
  const [account, setAccount] = useState(null);
  const [accountLoading, setAccountLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companyOneId, setCompanyOneId] = useState(null);

  useEffect(() => {
    if (currentUser && currentUser !== LOADING_AUTH_USER) {
      setAccountLoading(true);
      const accountPath = `accounts/${currentUser.uid}`;
      return firebaseApp.db.doc(accountPath).onSnapshot(
        accountSnap => {
          const accountData = accountSnap.data();
          if (accountData) {
            setAccount(accountSnap);
            setAccountLoading(false);
            if (accountData.currentCompany) {
              setCompanyOneId(accountData.currentCompany);
            } else if (accountData.companies && accountData.companies.length) {
              setCompanyOneId(accountData.companies[0]);
            } else if (!accountData.companies || accountData.companies.length === 0) {
              setCompanyOneId(null);
            }
          }
        },
        e => {
          console.error('Could not load account', e);
          setAccountLoading(false);
        }
      );
    } else if (!currentUser) {
      setCompanyOneId(null);
      setAccountLoading(false);
    } else if (currentUser === LOADING_AUTH_USER) {
      setCompanyOneId(null);
      setAccountLoading(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (companyOneId) {
      setCompanyLoading(true);
      const companyPath = `companies/${companyOneId}`;
      return firebaseApp.db.doc(companyPath).onSnapshot(
        companySnap => {
          setCompany(companySnap);
          setCompanyLoading(false);
        },
        e => {
          console.error('Could not load company', e);
          setCompanyLoading(false);
        }
      );
    }
    setCompany(null);
  }, [companyOneId]);

  useEffect(() => {
    firebaseApp.auth.onAuthStateChanged(user => {
      if (currentUser && currentUser !== LOADING_AUTH_USER && !user) {
        firebaseApp.analytics().logEvent('logout');
        firebaseApp.analytics().setUserId(null);
      }
      setCurrentUser(user);
    });
  }, [currentUser]);

  if (currentUser !== LOADING_AUTH_USER) {
    const value = {
      isAnonymous: currentUser ? currentUser.isAnonymous : false,
      currentUser,
      company,
      companyLoading: companyLoading || accountLoading,
      account,
      accountLoading,
      modules: {
        hire:
          company &&
          company.data().modules &&
          company.data().modules.hire === true,
        share: true
      }
    };
    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
  }

  return null;
};
