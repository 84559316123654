import React, { useContext, useState } from 'react';
import { Accordion } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Avatar, { SIZE } from './avatar';
import Confirm from './confirm';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { DialogsAndToastsContext } from '../contexts/dialogs-and-toasts';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  expansionPanelRoot: {
    border: 0,
    borderRadius: 4,
    boxShadow: 'none',
    marginTop: '16px !important',
    marginBottom: '16px !important',
    border: '1px solid #e8e9ea',
    '&.expanded': {
      margin: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    '& > a': {
      textDecoration: 'none',
      color: '#000'
    }
  },
  expansionPanelSummaryRoot: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  expansionPanelSummaryContent: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '&.expanded': {
      marginBottom: 14
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiGrid-container': {
      }
    }
  },
  expansionPanelDetails: {
    display: 'block',
    flexDirection: 'column',
    padding: theme.spacing(4),
    paddingTop: 0,
    '& > div > .MuiGrid-container': {
      position: 'relative',
      '& .MuiGrid-item': {
        [theme.breakpoints.down('sm')]: {
          '& .MuiIconButton-sizeSmall': {
            position: 'absolute',
            top: 0,
            right: 0
          }
        }
      }
    },
    '& .MuiGrid-container .MuiAvatar-circle': {
      [theme.breakpoints.down('sm')]: {
      }
    }
  },
  avatar: {

  },
  circularIcon: {
    width: '20px !important',
    height: '20px !important'
  },
  candidateName: {
    margin: 0,
    '& small': {
      marginTop: 10,
      fontWeight: 300,
      opacity: 0.8,
      display: 'block'
    }
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.lightText.main
  },
  profileHeader: {
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: theme.palette.accentLight.main,
    padding: theme.spacing(1)
  },
  expansionIcon: {
    borderWidth: 0,
    borderRadius: 100,
    color: theme.palette.lightText.main,
    backgroundColor: theme.palette.background.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: theme.spacing(1)
  },
  deleteButton: {
    color: theme.palette.lightText.main
  },
  noLink: {
    textDecoration: 'none'
  },
  expiryDate: {
    color: '#5e6e89',
    '& b': {
      marginLeft: 15
    }
  }
}));


export default function AlgoliaCandidate (props) {
  const classes = useStyles();
  const bindAlgoliaProfile = props.bindAlgoliaProfile;
  const { handleViewCv, showToast } = useContext(DialogsAndToastsContext);
  const [loading, setLoading] = useState(false);

  const localBindFirestoreProfle = () => {
    bindAlgoliaProfile(props.id);
  }

  const localHandleViewCv = async (cvPath, title) => {
    try {
      setLoading(true);
      await handleViewCv(cvPath, title);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const expires = props && new Date(props.expires).toDateString();

  return (
    <Accordion
      key={props.id}
      onChange={props.setExpanded}
      expanded={props.expanded}
      classes={{
        root: classes.expansionPanelRoot,
        expanded: 'expanded'
      }}
    >
      <div
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent,
          expanded: 'expanded',
          flexDirection: 'row'
        }}
        style={{ padding: 15 }}
      >
        <Grid container direction='row' alignItems='center' alignContent='center' justify="space-between">
          <Grid item className={classes.avatar} md={6}>
            <Grid container direction='row' alignItems='center' alignContent='center'>
              <Grid item style={{ marginRight: 20 }}>
                <Link to='#' className={classes.noLink} onClick={localBindFirestoreProfle}>
                  <Avatar
                    displayName={props.name}
                    photo={props.photo}
                    size={SIZE.MEGA}
                    style={{ margin: 0 }}
                  />
                </Link>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Link to='#' className={classes.noLink} onClick={localBindFirestoreProfle}>
                  <h1 className={classes.candidateName}>
                    {props.name}
                    <small>{props.title}</small>
                  </h1>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.avatar} md={6}>
            <Grid container direction='row' alignItems='center' alignContent='center'>
              <Hidden smDown>
                <Grid item md={5} className={classes.expiryDate}>
                </Grid>
                <Grid item md={5} className={classes.expiryDate}>
                  <span style={{ marginRight: 10 }}>Expires: <b>{expires}</b></span>
                </Grid>
              </Hidden>
              <Grid item md={2} align="right">
                {props ? <Button variant='text' size='small' color='primary' variant='outlined' onClick={() => { localHandleViewCv(props.cvPath, `${props.name}`)}}>
                  {loading ? <CircularProgress className={classes.circularIcon} /> : <>View CV</>}
                </Button> : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Accordion>
  );
}
