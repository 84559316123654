import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import firebase from '../firebase-app';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  smallAvatar: {
    margin: 4,
    width: 16,
    height: 16,
    fontSize: 16,
    backgroundColor: theme.palette.darkBlue.main
  },
  mediumAvatar: {
    margin: 4,
    width: 24,
    height: 24,
    fontSize: 14,
    backgroundColor: theme.palette.darkBlue.main
  },
  bigAvatar: {
    margin: 4,
    width: 32,
    height: 32,
    fontSize: 16,
    backgroundColor: theme.palette.darkBlue.main
  },
  biggerAvatar: {
    margin: 4,
    width: 48,
    height: 48,
    backgroundColor: theme.palette.darkBlue.main
  },
  megaAvatar: {
    margin: 4,
    width: 56,
    height: 56,
    backgroundColor: theme.palette.darkBlue.main
  }
}));

export const SIZE = {
  SMALL: 'smallAvatar',
  MEDIUM: 'mediumAvatar',
  BIG: 'bigAvatar',
  BIGGER: 'biggerAvatar',
  MEGA: 'megaAvatar'
}

/**
 * @param {Object} param0
 * @param {string} param0.displayName
 * @param {string} param0.photo
 */

function CustomAvatar ({ displayName, photo, size = SIZE.BIG, ...otherProps }) {
  const classes = useStyles();
  const [url, setUrl] = useState(photo && photo.startsWith('http') ? photo : null);

  useEffect(() => {
    if (photo && photo.startsWith('http')) {
      setUrl(photo);
    } else if (photo) {
      (async () => {
        const storageRef = firebase.storage.ref();
        const url = await storageRef.child(photo).getDownloadURL();
        setUrl(url);
      })();
    }

    return () => setUrl(null);
  }, [photo]);

  if (document) {
    const initials = displayName ? (displayName || '').toUpperCase().split(' ').map(p => p.substr(0, 1)) : '';

    if (url) {
      return (
        <Tooltip title={displayName || ''}>
          <Avatar
            alt={displayName}
            src={url}
            {...otherProps}
            className={classes[size]}
          />
        </Tooltip>
      );
    }

    return (
      <Avatar
        className={classes[size]}
        {...otherProps}
      >
        {initials}
      </Avatar>
    );
  }

  return null;
}

export default CustomAvatar;
