import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AuthContext } from '../contexts/auth';
import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import firebaseApp from '../firebase-app';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import FilterResult from './filter-result';
import { getAlgoliaProfiles } from '../actions/account';

const useStyles = makeStyles(theme => ({
  profileChooserRoot: props => ({
    display: 'block',
    width: '100%',
    position: 'relative',
    zIndex: 1000
  }),
  relativePanel: {
    position: 'relative',
    zIndex: 1000,
    padding: 0,
    background: '#fff'
  },
  filterItem: {
    width: '100%',
    margin: '10px 0',
    '& a': {
      float: 'right'
    }
  },
  textMuted: {
    color: '#ccc'
  }
}));

export default function ProfileChooser ({ myProfiles, displayName, handleChange, handleRemove, selectedProfile, fullWidth }) {
  const classes = useStyles();
  //const companyData = (companyProfiles ? companyProfiles.docs : []);
  const { account } = useContext(AuthContext);

  const profilesCollection = [];
  const [filterProfiles, setFilterProfiles] = useState([]);
  const [showInvisiblePixel, setShowInvisiblePixel] = useState(false);

  const [sharedProfiles, setSharedProfiles] = useState([]);
  const [companyProfiles, setCompanyProfiles] = useState([]);
  //const [sharedProfilesCollection, loading, error] = useCollection(
  //  firebaseApp.db.collection(`accounts/${account.id}/sharedProfiles`)
  //);

  //const sharedProfiles = sharedProfilesCollection && sharedProfilesCollection.docs || {};
  useEffect(() => {
    const getResults = async () => {
      const profiles = await getAlgoliaProfiles();
      const profilesData = profiles && profiles.data;

      setSharedProfiles(profilesData.sharedResults.hits);
      setCompanyProfiles(profilesData.companyResults.hits);

    }

    getResults();
  }, []);

  if (sharedProfiles && Object.keys(sharedProfiles).length > 0){
    Object.keys(sharedProfiles).map((item) => {
      const sharedProfile = sharedProfiles[item];

      profilesCollection[sharedProfiles[item].id] = sharedProfile;
      profilesCollection[sharedProfiles[item].id].name = sharedProfile.name;
      profilesCollection[sharedProfiles[item].id].title = sharedProfile.title || "";

      profilesCollection[sharedProfiles[item].id].onDeleteCascade = false;
      profilesCollection[sharedProfiles[item].id].applicationType = 'shared';
      profilesCollection[sharedProfiles[item].id].profileId = sharedProfiles[item].id;

      delete profilesCollection[sharedProfiles[item].id].applications;

      return true;
    });
  }

  Object.keys(myProfiles).map((item) => {
    profilesCollection[item] = myProfiles[item];
    profilesCollection[item].name = displayName;
    profilesCollection[item].title = "";

    profilesCollection[item].onDeleteCascade = false;
    profilesCollection[item].applicationType = 'personal';
    profilesCollection[item].profileId = item;

    delete profilesCollection[item].applications;

    return true;
  });

  companyProfiles.map((person) => {
    const personData = person;
    const personId = person.id;

    if (personData && personData.profiles){
      Object.keys(personData.profiles).map((profile) => {
        profilesCollection[profile] = personData.profiles[profile];
        profilesCollection[profile].name = personData.name;

        profilesCollection[profile].id = personId;
        profilesCollection[profile].profileId = profile;
        profilesCollection[profile].onDeleteCascade = true;
        profilesCollection[profile].applicationType = 'company';
        profilesCollection[profile].photo = personData.photo;

        delete profilesCollection[profile].applications;

        return true;
      });

    }
    return true;
  });

  const filterOption = (e) => {
    const target = e.target;
    const value = target.value.toLowerCase();
    if (value.length > 1) {
      const tempFilter = Object.keys(profilesCollection).filter((item) => {

        return profilesCollection[item].name.toLowerCase().indexOf(value) !== -1 ||
          profilesCollection[item].title.toLowerCase().indexOf(value) !== -1;
      });
      setFilterProfiles(tempFilter);
      setShowInvisiblePixel(true);
    } else {
      setFilterProfiles([]);
      setShowInvisiblePixel(false);
    }
  };

  const localRemoveSelected = () => {
    handleRemove();
  }

  const selectProfile = (id) => {
    const selectedProfile = profilesCollection[id];
    handleChange(selectedProfile);
    setFilterProfiles([]);
  }

  return (
    <FormControl id="algolia-profile-chooser" className={classes.profileChooserRoot} variant='outlined' margin='dense' fullWidth>
      {Object.keys(profilesCollection).length > 0
        ? <>
          <div>
            {filterProfiles.length > 0 ? null
              : <>
                {!showInvisiblePixel
                  ? <p className={classes.textMuted} style={{ marginBottom: 10 }}>You have <b className='action-link'>{Object.keys(profilesCollection).length}</b> profiles in your Skilldrop account - <b><Link className='action-link' to='/share/my-profile/'>add new profile</Link></b></p>
                  : null}
              </>}

          </div>
          <div className={classes.relativePanel} style={{ padding: (showInvisiblePixel ? '10px' : null), margin: (showInvisiblePixel ? '-10px -10px 0 -10px' : null), width: (showInvisiblePixel ? 'calc(100% + 20px)' : null) }}>
            {showInvisiblePixel
              ? <>
                {filterProfiles.length > 0
                  ? <p className={classes.textMuted} style={{ marginBottom: 10, marginTop: 0 }}>We found <b className='action-link'>{filterProfiles.length}</b> profile{filterProfiles.length > 1 ? <>s</> : null} that match your search criteria.</p>
                  : <p className={classes.textMuted} style={{ marginBottom: 10, marginTop: 0 }}>There are no matching profiles - <b><Link className='action-link' to='/share/my-profile/'>add new profile</Link></b></p>}
              </>
              : null}

            <InputLabel id='profileLabel' style={{ display: 'none' }} htmlFor='outlined-age-native-simple'>Choose Profile*</InputLabel>
            <Grid container style={{ alignSelf: 'center' }} direction='column' alignItems='left'>
              <Grid item sm={12} md={12} lg={fullWidth ? 12 : 8}>
                <TextField
                  label='Search for profiles'
                  variant='outlined'
                  fullWidth
                  style={{ background: '#fff' }}
                  onChange={(e) => filterOption(e)}
                />
              </Grid>
            </Grid>
            {filterProfiles.length > 0
              ? <div id='filter-results'>
                {Object.keys(filterProfiles).map((i) => {
                  return (

                    <FilterResult
                      style={{ background: '#fff' }}
                      key={i}
                      id={filterProfiles[i]}
                      profile={profilesCollection[filterProfiles[i]]}
                      handleSelect={selectProfile}
                      handleRemove={localRemoveSelected}
                    />
                  );
                })}
              </div>
              : null}
            {showInvisiblePixel && false
              ? <>
                {filterProfiles.length > 0
                  ? <p style={{ maarginBottom: 0, background: '#fff', padding: 10, marginTop: 20, borderStyle: 'solid', borderWidth: 1, borderRadius: 4, borderColor: '#e8e9ea' }}>We found <b>{filterProfiles.length}</b> profile{filterProfiles.length > 1 ? <>s</> : null} that match your search criteria.</p>
                  : <p style={{ maarginBottom: 0, background: '#fff', padding: 10, marginTop: 20, borderStyle: 'solid', borderWidth: 1, borderRadius: 4, borderColor: '#e8e9ea' }}>There are no matching profiles - <b><Link className='action-link' to='/share/my-profile/'>add new profile</Link></b></p>}
              </>
              : null}
          </div>
        </>
        : <p style={{ padding: '10px 0 0 0', marginBottom: 0 }}>There are no profiles at the moment. Do you want to <Link className='action-link' to='/share/my-profile/'>create one</Link>?</p>}
      {showInvisiblePixel
        ? <span
          class='invisible-pixel' style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.2)',
            zIndex: 1
          }}
        /> : null}
    </FormControl>
  );
}
