import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import firebaseApp from '../../firebase-app';
import Paper from '../paper';
import { handleLogin, SIGN_IN_OPTIONS } from '../../actions/account';
import SceneWrapper from '../scene-wrapper';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CustomRegister from '../utils/custom-register';

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import GoogleLogin from '../social-login/google-login';
import MiscrosoftLogin from '../social-login/ms-login';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  customLogin: {
    background: 'transparent',
    width: '100%',
    padding: 15,
    borderRadius: 4,
    border: '1px solid #d7d7d7',
    '& > span': {
      justifyContent: 'end',
      color: '#333'
    }
  }
}));

export default function Register ({ location }) {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const [showCustomRegister, setShowCustomRegister] = useState(false);

  const { to } = queryString.parse(location.search);

  // eslint-disable-next-line no-unused-vars
  const uiConfig = {
    ...SIGN_IN_OPTIONS,
    callbacks: {
      ...SIGN_IN_OPTIONS.callbacks,
      signInFailure: async function (error) {
        await SIGN_IN_OPTIONS.callbacks.signInFailure(error);
        setRedirect(to || '/account');
        return true;
      },
      signInSuccessWithAuthResult: (res) => {
        const asyncHandler = async () => {
          await handleLogin(res);
          setRedirect(to || '/share');
        };
        asyncHandler();
        // Avoid redirects after sign-in.
        return false;
      }
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <SceneWrapper>
      <Paper style={{ alignSelf: 'center', width: 640, maxWidth: '100%', minHeight: 400 }}>
        <Typography component='h1' className={classes.formTitle}>Create New Account</Typography>

        {showCustomRegister ? null
          : <>
            <h2>Recommended</h2>
            <p>Secure and quick signup with your Microsoft or Google account.</p>
            <Grid container style={{ margin: '0 -5px' }}>
              <GoogleLogin firebaseApp={firebaseApp} firebaseAuth={firebaseApp.auth} fullWidth />
              <MiscrosoftLogin firebaseApp={firebaseApp} firebaseAuth={firebaseApp.auth} fullWidth />
            </Grid>

            <p className='firebaseui-tos firebaseui-tospp-full-message'>
              <small>
                By continuing, you are indicating that you accept our <a href='/terms-of-service' className='firebaseui-link firebaseui-tos-link' target='_blank'>Terms of Service</a> and <a href='/privacy-policy' className='firebaseui-link firebaseui-pp-link' target='_blank'>Privacy Policy</a>.
              </small>
            </p>
            <Divider />
          </>}

        {showCustomRegister
          ? <CustomRegister />
          : <div className=''>
            <h2>Traditional</h2>
            <p style={{ marginBottom: 15 }} />
            <Button className={classes.customLogin} onClick={() => setShowCustomRegister(true)}>Sign up with email and password</Button>
          </div>}

        <p style={{ marginTop: 15 }}>
          <small>
            Existing user? <Link to='/login'>Login</Link> | <Link to='/reset-password'>Forgot your password?</Link>
          </small>
        </p>

      </Paper>
    </SceneWrapper>
  );
}
