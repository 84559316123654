import React, { useState, useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Paper from '../paper';
import { AuthContext } from '../../contexts/auth';
import get from 'lodash.get';

import CompanyDialogAddUser from './company-dialog-add-user';
import CompanyDialogEditUser from './company-dialog-edit-user';
import CompanyRowInvite from './company-row-invite';
import CompanyRowMember from './company-row-member';
import CompanyRowRequest from './company-row-request';

import { addInvite, editMember, deleteMember, deleteInvite, handleJoinRequest } from '../../actions/company';
import { makeStyles } from '@material-ui/core/styles';
import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import firebaseApp from '../../firebase-app';

import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

const useStyles = makeStyles(theme => ({
  tableHead: {
    backgroundColor: theme.palette.accent.main
  },
  tableHeadCell: {
    color: theme.palette.contrast.main
  },
  membersTableHead: {
    backgroundColor: theme.palette.contrast.main
  },
  memberTableHeadCell: {
    color: '#fff'
  }
}));

export default function CompanyUsers ({ match }) {
  const classes = useStyles();
  const tableRows = [];
  const requestTableRows = [];

  const [addUserOpen, setAddUserOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const { account } = useContext(AuthContext);
  const { companyId } = match.params;
  const [companyPrivateDocRef] = useDocument(firebaseApp.db.doc(`companies/${companyId}/private_data/private_document`));
  const [companyStripeDocRef] = useDocument(firebaseApp.db.doc(`companies/${companyId}/private_data/stripePriv`));
  const companyPrivateDoc = companyPrivateDocRef ? companyPrivateDocRef.data() : { accounts: {} };
  const companyStripeDoc = companyStripeDocRef ? companyStripeDocRef.data() : { accounts: {} };
  const userRole = get(companyPrivateDoc, `accounts[${account ? account.id : ''}].role`, 'viewer');
  const mayEdit = userRole === 'administrator';
  const { showToast } = useContext(DialogsAndToastsContext);

  // eslint-disable-next-line no-unused-vars
  const [companyInvitesRef, companyInvitesLoading, companyInvitesError] = useCollection(
    firebaseApp.db.collection(`companies/${companyId}/invites`)
  );
  const companyInvites = companyInvitesRef ? companyInvitesRef.docs : [];

  const [companyRequestsRef, companyRequestsLoading, companyRequestsError] = useCollection(
    firebaseApp.db.collection(`companies/${companyId}/requests`)
  );
  const companyRequests = companyRequestsRef ? companyRequestsRef.docs : [];

  const handleAddUserClicked = () => {
    setAddUserOpen(true);
  };

  const handleAddUserCloseClicked = () => {
    setAddUserOpen(false);
  };

  const handleEditMemberClicked = (row, companyId) => {
    setEditData({ ...row, companyId })
  };

  const handleEditMemberCloseClicked = () => {
    setEditData(null);
  };

  const handleEditMember = async (companyId, displayName, id, role) => {
    try {
      await editMember(companyId, id, role)
      showToast({ message: `${displayName} is now ${role}`, variant: 'success' })
    } catch (e) {
      showToast({ message: e.message, variant: 'error' })
    }
  }

  const handleDeleteMember = async (companyId, id, displayName) => {
    try {
      await deleteMember(companyId, id);
      showToast({ message: `${displayName} is no longer a company member `, variant: 'success' })
    } catch (e) {
      showToast({ message: e.message, variant: 'error' })
    }
  }

  const handleDeleteInvite = async (companyId, email) => {
    try {
      await deleteInvite(companyId, email);
      showToast({ message: `Invite to ${email} removed`, variant: 'success' })
    } catch (e) {
      showToast({ message: e.message, variant: 'error' })
    }
  }

  const handleAddInvite = async (companyId, account, displayName, email, role) => {
    try {
      await addInvite(companyId, account, displayName, email, role)
      showToast({ message: `Invite sent to ${email}`, variant: 'success' })
    } catch (e) {
      showToast({ message: e.message, variant: 'error' })
    }
  }

  const handleRejectRequest = async (uid, role) => {
    const response = handleJoinRequest({ requestId: uid, action: 'reject', role});
    console.log(response);
  }

  const handleApproveRequest = async (uid, role) => {
    const response = handleJoinRequest({ requestId: uid, action: 'accept', role});
    console.log(response);
  }

  if (companyInvitesError) {
    console.log('Error loading company invites', companyInvitesError);
    return (
      <Paper>
        <h1>Doh, we had a mishap</h1>
        {companyInvitesError.message}
      </Paper>
    );
  }

  Object.keys(companyPrivateDoc.accounts).forEach(id => {
    const companyAccount = companyPrivateDoc.accounts[id];
    tableRows.push({
      id,
      displayName: companyAccount.displayName,
      email: companyAccount.email,
      role: companyAccount.role,
      photo: companyAccount.photo
    });
  });
  companyInvites.forEach(invite => {
    const data = invite.data();
    tableRows.push({
      isInvite: true,
      displayName: data.name,
      email: data.email,
      role: data.role
    });
  });

  companyRequests.forEach(request => {
    const data = request.data();
    requestTableRows.push({
      isInvite: true,
      id: request.id,
      displayName: data.name,
      email: data.email,
      role: data.role
    });
  });


  tableRows.sort((a, b) => {
    if (a.displayName > b.displayName) {
      return 1;
    }

    if (a.displayName < b.displayName) {
      return -1;
    }

    return 0;
  });

  const isMoreThanOneOwnerInList = tableRows.filter(r => !r.isInvite && r.role === 'administrator').length > 1;

  return (
    <div>
      {requestTableRows && requestTableRows.length > 0 &&
        <Table size='small' style={{ marginBottom: 35 }}>
          <TableHead className={classes.membersTableHead}>
            <TableRow>
              <TableCell className={classes.memberTableHeadCell}><PersonOutlineIcon /></TableCell>
              <TableCell className={classes.memberTableHeadCell}>Name</TableCell>
              <Hidden xsDown>
                <TableCell className={classes.memberTableHeadCell}>Email</TableCell>
                <TableCell className={classes.memberTableHeadCell}></TableCell>
              </Hidden>
              <TableCell className={classes.memberTableHeadCell}>User Role</TableCell>
              <TableCell className={classes.memberTableHeadCell} align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestTableRows.map(row => {
              const isSelf = account ? row.id === account.id : true;
              return (
                <CompanyRowRequest
                  key={row.id}
                  row={row}
                  isSelf={isSelf}
                  companyId={companyId}
                  mayEdit={mayEdit && (row.role === 'viewer' || row.role === 'manager' || (userRole === 'administrator' && isMoreThanOneOwnerInList))}
                  handleApproveRequest={handleApproveRequest}
                  handleRejectRequest={handleRejectRequest}
                />
              );
            })}
          </TableBody>
        </Table>
      }

      <Table size='small'>
        <TableHead className={classes.membersTableHead}>
          <TableRow>
            <TableCell className={classes.memberTableHeadCell}><PersonOutlineIcon /></TableCell>
            <TableCell className={classes.memberTableHeadCell}>Name</TableCell>
            <Hidden xsDown>
              <TableCell className={classes.memberTableHeadCell}>Email</TableCell>
              <TableCell className={classes.memberTableHeadCell}>User type</TableCell>
            </Hidden>
            <TableCell className={classes.memberTableHeadCell} align='right'>Manage member</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map(row => {
            const isSelf = account ? row.id === account.id : true;
            if (row.isInvite) {
              return (
                <CompanyRowInvite
                  key={row.email}
                  row={row}
                  isSelf={isSelf}
                  companyId={companyId}
                  mayEdit={mayEdit}
                  handleDeleteInvite={handleDeleteInvite}
                />
              );
            }
            return (
              <CompanyRowMember
                key={row.id}
                row={row}
                isSelf={isSelf}
                companyId={companyId}
                mayEdit={mayEdit && (row.role === 'viewer' || row.role === 'manager' || (userRole === 'administrator' && isMoreThanOneOwnerInList))}
                handleDeleteMember={handleDeleteMember}
                handleEditMemberClicked={handleEditMemberClicked}
              />
            );
          })}
        </TableBody>
      </Table>

      {mayEdit
        ? <Grid container justify='flex-end'>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              onClick={handleAddUserClicked}
              style={{ marginTop: 8 }}
            >
              Add user
            </Button>
          </Grid>
        </Grid> : null}

      {mayEdit
        ? <CompanyDialogAddUser
          open={addUserOpen}
          onClose={handleAddUserCloseClicked}
          companyId={match.params.companyId}
          addInvite={handleAddInvite}
        /> : null}

      {mayEdit && !!editData
        ? <CompanyDialogEditUser
          open={!!editData}
          onClose={handleEditMemberCloseClicked}
          companyId={match.params.companyId}
          editMember={handleEditMember}
          data={editData}
        /> : null}
    </div>
  )
}
