import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import LoggedOutRegister from './logged-out-register';
import firebase from 'firebase/app';
import firebaseApp from '../../firebase-app';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {
  handleLogin,
  anonymousMoveShared,
  getAnonymousUserData
} from '../../actions/account';

const useStyles = makeStyles((theme) => ({
  page: {
    margin: '0 auto',
    maxWidth: 630,
    width: '100%',
    borderRadius: 8,
    overflow: 'hidden',
    '& .MuiStepper-horizontal': {
      background: 'transparent'
    },
    '& .MuiStepper-horizontal .MuiStep-root': {
      background: 'transparent'
    },
    '& .makeStyles-mainContent-23': {
      borderRadius: 10,
      overflow: 'hidden'
    }
  },
  root: {
    background: 'transparent',
    '& .MuiStepConnector-vertical .MuiStepConnector-lineVertical': {
      border: 0,
      minHeight: 0
    }
  },
  inner: {
    background: '#fff',
    '& .Mui-disabled span': {
      color: '#e0e0e0 !important'
    },
    '& .MuiStepIcon-root': {
      color: '#e0e0e0 !important'
    },
    '& .MuiStepIcon-active > span': {
      color: '#5e6e89 !important'
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#5e6e89 !important'
    }
  },
  innerContent: {
    margin: 0
  },
  innerTitle: {
    padding: 15,
    '& .MuiSvgIcon-root': {
      width: 10,
      height: 10
    },
    '& .MuiStepLabel-iconContainer': {
      padding: 0
    },
    '& .MuiSvgIcon-root .MuiStepIcon-text': {
      fontSize: 0
    }
  },
  mainContent: {
    background: '#fff'
  },
  textArea: {
    border: '1px solid rgba(0, 0, 0, 0.37)',
    borderRadius: 3,
    '& :focus': {
      border: '1px solid rgba(0, 0, 0, 0.87)'
    }
  },
  disabled: {
    color: '#5e6e89 !important'
  },
  enabled: {
    color: '#5e6e89 !important'
  },
  autocomplete: {
    '& .MuiInputBase-root': {
      padding: '6px 30px 7px 7px !important'
    }
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  fullWidthButton: {
    margin: '0 !important',
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: '100%'
      }
    }
  }
}));

export default function CustomRegister () {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [localUserData, setLocalUserData] = useState(null);
  const [radioValue, setRadioValue] = useState('female');
  const [disabled, setDisabled] = useState(false);

  const handleChange = (e) => {
    const tempUserData = localUserData;
    tempUserData.tempUserData = e.target.value;

    setRadioValue(e.target.value);
    setLocalUserData(tempUserData);
  }

  const finalizeStep = () => {

  }

  const registerUser = async (userData) => {
    try {
      setDisabled(true);

      // Try to create account and assign data
      const res = await firebaseApp.functions.httpsCallable('createAccountCallable')({
        phone: userData.phone,
        displayName: userData.displayName,
        email: userData.email,
        password: userData.password
      });

      if (!res.data.success) {
        throw new Error(res.data.message);
      }

      // Check if there was anonymous data attached to the account
      const { annonUserUid, anonData, sharedProfiles } = await getAnonymousUserData();
      if (annonUserUid){
        // We need to delete data at this point to avoid issues with firestore.rules
        await firebaseApp.db.doc(`accounts/${annonUserUid}`).delete();
      }

      // We will try not to login with provided data, if account was created successfully 
      const additionalUserInfo = await firebase.auth().signInWithEmailAndPassword(userData.email, userData.password);
      res.data.additionalUserInfo = additionalUserInfo;

      // Check if there was anonymous data and move shared profiles if needed
      if (annonUserUid){
        await anonymousMoveShared(anonData, sharedProfiles, res.data.user);
      }

      // If all went well, let's attach data to a context
      await handleLogin(res.data);
      setRedirect('/account');

    } catch (e) {
      setActiveStep(0);
      setDisabled(false);

      if (e.message == 'TOO_SHORT') {
        e.message = 'The provided phone number is too short.'
      } else if (e.message == 'TOO_LONG') {
        e.message = 'The provided phone number is too long.'
      }

      setErrorMessage(e.message);
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const renderRadioButtons = () => {
    return (
      <div>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Gender</FormLabel>
          <RadioGroup aria-label='usage' name='usage' value={radioValue} onChange={handleChange}>
            <FormControlLabel value='personal' control={<Radio />} disabled={disabled} label='Female' />
            <p style={{ paddingLeft: 30, color: '#999', fontSize: 12, marginTop: 0 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec porta nisi, quis pellentesque diam. Donec malesuada lobortis purus id dapibus. Mauris scelerisque, nibh in ornare interdum, neque purus hendrerit ligula, sed sollicitudin eros erat vitae tortor. </p>

            <FormControlLabel value='company' control={<Radio />} disabled={disabled} label='Male' />
            <p style={{ paddingLeft: 30, color: '#999', fontSize: 12, marginTop: 0 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec porta nisi, quis pellentesque diam. Donec malesuada lobortis purus id dapibus. Mauris scelerisque, nibh in ornare interdum, neque purus hendrerit ligula, sed sollicitudin eros erat vitae tortor. </p>

            <FormControlLabel value='share' control={<Radio />} disabled={disabled} label='Other' />
            <p style={{ paddingLeft: 30, color: '#999', fontSize: 12, marginTop: 0 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec porta nisi, quis pellentesque diam. Donec malesuada lobortis purus id dapibus. Mauris scelerisque, nibh in ornare interdum, neque purus hendrerit ligula, sed sollicitudin eros erat vitae tortor. </p>

          </RadioGroup>
        </FormControl>
        <Button disabled={disabled} onClick={finalizeStep}>Register</Button>
      </div>
    );
  }

  return (
    <div>
      <div className={classes.page}>
        <div className={classes.mainContent}>
          {activeStep == 0 ? <LoggedOutRegister errorMessage={errorMessage} registerUser={registerUser} loggingIn={disabled} /> : null}
          {activeStep == 1 ? renderRadioButtons() : null}
        </div>
      </div>
    </div>
  );
}
