import React, { useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import SceneWrapper from '../scene-wrapper';
import Candidate from '../candidate';
import { AuthContext } from '../../contexts/auth';
import NoDataInformation from '../../components/no-data-information';
import AlgoliaProfiles from './algolia-profiles';
import Paper from '../paper';

function OurPeople ({ location, match }) {
  const { isAnonymous, company, companyLoading, account } = useContext(AuthContext);
  const [expanded, setExpanded] = React.useState(false);
  const accountData = account && account.data() || {};

  const [value, loading, error] = '';

  if (isAnonymous) {
    return (
      <div style={{ marginTop: 100 }}>
        <NoDataInformation
          title='Do you have <b>employee CVs to share?</b>'
          actionTitle='Create free account'
          subTitle='Skilldrop improves control over personal data!'
          image='/app-img/empty-our-people.png'
          to={`/login?to=${location.pathname}`}
        />
      </div>
    );
  }

  if (!companyLoading && !company) {
    return (
      <div style={{ marginTop: 100 }}>
        <NoDataInformation
          title='Do you have <b>candidate CVs to share?</b>'
          actionTitle='Create a company account'
          subTitle='Upload them here and start sharing!'
          to='/companies/create'
          bullets={[
            'Create a <b>free</b> company account',
            'Add candidate profiles',
            'Share links'
          ]}
          image='/app-img/empty-our-people.png'
        />
      </div>
    );
  }

  if (company && value && value.docs.length === 0) {
    return (
      <div style={{ marginTop: 100 }}>
        <NoDataInformation
          title='Do you have <b>candidate CVs to share?</b>'
          actionTitle='Create a profile'
          subTitle='Upload them here and start sharing!'
          to='/share/create-candidate'
          bullets={[
            'Add candidate profiles',
            'Share links'
          ]}
          image='/app-img/empty-our-people.png'
        />
      </div>
    );
  }

  if (!value && loading) {
    return (
      <CircularProgress />
    );
  }

  if (!value && error) {
    return (<SceneWrapper>BOo {error.message}</SceneWrapper>);
  }

  return (
    <div>
      {company && accountData && accountData.currentCompanyRole!='viewer' ?
        <>
          {account && account.algoliaApiKey != ''
            ? <AlgoliaProfiles
              currentUser={account}
              company={company}
              match={match}
              />
            : <>
              {value && value.docs.map(doc => (
                <Candidate
                  canDelete
                  companyId={company ? company.id : null}
                  key={doc.id}
                  id={doc.id}
                  expanded={expanded === doc.id}
                  match={match}
                  setExpanded={(e) => {
                    if (expanded === doc.id) {
                      setExpanded(false);
                    } else {
                      setExpanded(doc.id);
                    }
                  }}
                  {...doc.data()}
                />
              ))}
            </>}
          </>
        :
        <>
          <Paper>
            <h1>Doh, we had a mishap</h1>
            You're not allowed to view this page.
          </Paper>
        </>}
    </div>
  );
}

export default OurPeople;
