import firebase from 'firebase/app';
import get from 'lodash.get';
import uuidv4 from 'uuid/v4';
import path from 'path';
import firebaseApp from '../firebase-app';
import moment from 'moment';
import { SHARE_PROFILE_LINK_EXPIRE_IN_DAYS } from '../constants/config';

export const SIGN_IN_OPTIONS = {
  signInSuccessUrl: '/account',
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  autoUpgradeAnonymousUsers: true,
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        // Forces account selection even when one account is available
        prompt: 'select_account'
      }
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: true
    }
  ],
  tosUrl: '/terms-of-service',
  privacyPolicyUrl: '/privacy-policy',
  callbacks: {
    signInFailure: async function (error) {
      // For merge conflicts, the error.code will be
      // 'firebaseui/anonymous-upgrade-merge-conflict'.
      if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
        return true;
      }
      try {
        // The credential the user tried to sign in with.
        var cred = error.credential;
        // Copy data from anonymous user to permanent user
        const anonUser = firebaseApp.auth.currentUser;
        const account = await firebaseApp.db.doc(`accounts/${anonUser.uid}`).get();
        const data = account.data();

        // Mark anon user for triggered delete (running anonuser.delete from client does nothing...)
        await firebaseApp.db.doc(`accounts/${anonUser.uid}`).update({ delete: true });

        // Switch over to new user
        await firebase.auth().signInWithCredential(cred);
        const toUser = firebaseApp.auth.currentUser;
        const update = {};
        Object.keys(data.sharedWithMe).forEach(sharedWithMeId => {
          update[`sharedWithMe.${sharedWithMeId}`] = data.sharedWithMe[sharedWithMeId];
        });
        await firebaseApp.db.doc(`accounts/${toUser.uid}`).update(update);
        return true;
      } catch (e) {
        console.log(e);
        throw new Error(e);
      }
    }
  }
};

export async function getAnonymousUserData() {
  const anonUser = firebaseApp.auth.currentUser;

  if (anonUser){
    const anonAccount = await firebaseApp.db.doc(`accounts/${anonUser.uid}`).get();
    const annonUserUid = anonUser && anonUser.uid;
    const anonData = anonAccount && anonAccount.data();
    const sharedProfiles = await anonAccount.ref.collection('sharedProfiles').get();

    return { annonUserUid, anonData, sharedProfiles }
  } else {
    return {}
  }
}

export async function anonymousMoveShared(anonUserData, sharedProfiles, account) {
  try {
    for (const sharedProfile of sharedProfiles.docs) {
      const sharedProfileData = sharedProfile.data();
      await firebaseApp.db.doc(`accounts/${account.uid}/sharedProfiles/${sharedProfile.id}`).set(sharedProfileData);
    }

    return true;
  } catch (e){
    console.log(e);
    throw new Error(e);
  }
}

export async function handleLogin (res) {
  try {
    let account = {};
    if (
      !res.user.metadata.lastSignInTime ||
      res.user.metadata.lastSignInTime === res.user.metadata.creationTime
    ) {
      if (res.additionalUserInfo) firebaseApp.analytics().logEvent('sign_up', { method: res.additionalUserInfo.providerId });
      account = {
        companies: [],
        created: firebase.firestore.FieldValue.serverTimestamp(),
        phone: res.user.phoneNumber,
        photo: res.user.photoURL || '',
        displayName: res.user.displayName
      };

      const { currentUser } = firebaseApp.auth;
      if (!currentUser.emailVerified && currentUser.email) {
        firebaseApp.auth.currentUser.sendEmailVerification();
      }
    }
    account.lastSignInTime = res.user.metadata.lastSignInTime;
    account.email = res.user.email;

    account.emailVerified = res.user.emailVerified;
    account.providerData = { ...get(res, 'user.providerData[0]', get(res, 'user.providerData', {})) };
    firebaseApp.analytics().setUserId(res.user.uid);
    firebaseApp.analytics().logEvent('login');
    await setAccount(res.user.uid, account);
    return account;
  } catch (e){
    console.warn(e);
    return {};
  }
}

export async function setAccount (accountId, document) {
  const collection = firebaseApp.db.collection('accounts');
  try {
    return await collection.doc(accountId).set(document, { merge: true });
  } catch (e) {
    console.warn(e);
  }
}

export async function updateAccount (accountId, data) {
  try {
    const collection = firebaseApp.db.collection('accounts');
    const res = await collection.doc(accountId).update(data);
    await updateMailchimpMember(data);
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function createProfile (accountId, values, handleProgress, handleError, handleSuccess) {
  const doc = firebaseApp.db.doc(`/accounts/${accountId}`);
  try {
    const file = values.cv[0];
    const storageRef = firebaseApp.storage.ref();
    const profileId = uuidv4();
    const fileUuid = uuidv4();
    const fileEnding = path.extname(file.name);
    const fullPath = `accounts/${accountId}/profiles/${profileId}-${fileUuid}${fileEnding}`;
    const uploadTask = storageRef.child(fullPath).put(file);
    uploadTask.on('state_changed',
      handleProgress,
      handleError,
      handleSuccess
    );
    await uploadTask;
    await doc.update({
      [`profiles.${profileId}`]: {
        title: values.title,
        description: values.description,
        edited: firebase.firestore.FieldValue.serverTimestamp(),
        applications: {},
        links: {},
        cvPath: fullPath
      }
    });
    return profileId;
  } catch (e) {
    console.error(e);
  }
}

export async function updateProfile (accountId, profileId, values) {
  const doc = firebaseApp.db.doc(`/accounts/${accountId}`);
  try {
    await doc.update({
      [`profiles.${profileId}.title`]: values.title,
      [`profiles.${profileId}.description`]: values.description,
      [`profiles.${profileId}.edited`]: firebase.firestore.FieldValue.serverTimestamp()
    });
    return profileId;
  } catch (e) {
    console.error(e);
  }
}

export async function shareProfile (accountId, profileId, values) {
  const linkId = uuidv4();
  const profileRef = firebaseApp.db.doc(`accounts/${accountId}`);

  const accountDoc = await profileRef.get();
  const accountData = accountDoc.data() || {};
  const profile = accountData.profiles[profileId];

  const nameSeparated = accountData.displayName.split(' ');
  let name = nameSeparated[0];

  if (nameSeparated.length > 1) {
    name += ' ' + nameSeparated[1].charAt(0) + '.';
  }

  const profileTitle = profile.title;

  const socialMediaInfo = {
    socialTitle: name + ' - ' + profileTitle,
    socialDescription: profile.description,
    socialImageLink: accountData.photo
  }

  const link = await firebaseApp.createDynamicLink(
    `/share/accept-personal-profile-shared-with-me/${accountId}/${profileId}/${linkId}`, socialMediaInfo
  );

  await profileRef.update({
    [`profiles.${profileId}.links.${linkId}`]: {
      title: values.title,
      link,
      expires: moment().utc().add(SHARE_PROFILE_LINK_EXPIRE_IN_DAYS, 'days').toDate(),
      created: moment().toDate()
    }
  });

  return link;
}

export function deleteProfileLink (accountId, profileId, linkId) {
  const candidate = firebaseApp.db.doc(`accounts/${accountId}`);
  return candidate.update({
    [`profiles.${profileId}.links.${linkId}`]: firebase.firestore.FieldValue.delete()
  });
}

export async function deleteProfile (accountId, profileId) {
  const res = await firebaseApp.functions.httpsCallable('deleteProfile')({
    ownerDocPath: `accounts/${accountId}`,
    profileId
  });
  if (res && res.data && res.data.success) {
    return res.data;
  }
  throw new Error(res.data);
}

export function extendProfileLink (accountId, profileId, linkId, newExpires) {
  const candidate = firebaseApp.db.doc(`accounts/${accountId}`);
  return candidate.update({
    [`profiles.${profileId}.links.${linkId}.expires`]: newExpires
  });
}

export async function deleteSharedWithMeLink (accountId, linkId) {
  const res = await firebaseApp.functions.httpsCallable('deleteSharedWithMeItem')({
    accountId,
    linkId
  });
  if (res && res.data && res.data.success) {
    return res.data;
  }
  throw new Error(res.data);
}

export async function changePhoto (accountId, file, handleProgress, handleError, handleSuccess) {
  const storageRef = firebaseApp.storage.ref();
  const fileUuid = uuidv4();
  const fileEnding = path.extname(file.name);
  const fullPath = `accounts/${accountId}/${fileUuid}${fileEnding}`;
  const uploadTask = storageRef.child(fullPath).put(file);
  uploadTask.on('state_changed',
    handleProgress,
    handleError,
    handleSuccess
  );
  const uploadResult = await uploadTask;
  const photo = await uploadResult.ref.getDownloadURL();
  return firebaseApp.db.doc(`accounts/${accountId}`).update({ photo, photoPath: fullPath });
}

export async function checkActionCode (authCode) {
  const auth = firebaseApp.auth;
  try {
    return await auth.checkActionCode(authCode);
  } catch (e) {
    throw new Error(e);
  }
}

export async function verifyResetNewPassword (password, code) {
  const auth = firebaseApp.auth;
  try {
    await auth.verifyPasswordResetCode(code);
    return await auth.confirmPasswordReset(code, password);
  } catch (e) {
    throw new Error(e);
  }
}

export async function verifyNewUserEmail (code) {
  const auth = firebaseApp.auth;
  try {
    return await auth.applyActionCode(code);
  } catch (e) {
    throw new Error(e);
  }
}

export async function handleRecoverEmail (authCode) {
  const auth = firebaseApp.auth;
  let restoredEmail = null;

  try {
    const response = await auth.checkActionCode(authCode);
    restoredEmail = response.data.email;
    await auth.applyActionCode(authCode);
    await auth.sendPasswordResetEmail(restoredEmail);

    return response;
  } catch (e) {
    throw new Error(e);
  }
}

export async function getAlgoliaProfiles () {
  const res = await firebaseApp.functions.httpsCallable('getAlgoliaProfileResults')();

  if (res && res.data) {
    return res.data;
  }

  throw new Error(res.data);
}

export async function updateMailchimpMember(account, tag) {
  try {
    // Update member data in Mailchimp
    const firstLastName = account.displayName ? account.displayName.split(' ') : [];
    const firstName = firstLastName.length > 1 ? firstLastName[0] : '';
    const lastName = firstLastName.length > 1 ? firstLastName[1] : '';

    const data = {
      email: account.email,
      firstName: firstName,
      lastName: lastName
    };
    if (tag) {
      data['tag'] = tag;
    }
    await firebaseApp.functions.httpsCallable('ext-auth-mailchimp-sync-updateMember')(data);
  } catch (e) {
    console.error(e);
  }
}

export async function getResourceUrl (storagePath) {
  const res = await firebaseApp.functions.httpsCallable('getResourceUrl')({
    path: storagePath
  });
  if (res && res.data && res.data.success) {
    return res.data.url;
  } else {
    throw new Error('Oops, something went wrong. Please try again later.');
  }
}
