import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './contexts/auth';
import { DialogsAndToastsContext } from './contexts/dialogs-and-toasts';

export default function PublicRoute ({ component: RouteComponent, path, ...rest }) {
  const { currentUser } = useContext(AuthContext);
  const { showToast } = useContext(DialogsAndToastsContext);

  return (
    <>
      <Route
        {...rest}
        render={routeProps => {
          if (currentUser && !currentUser.isAnonymous && !currentUser.emailVerified && path !== '/account') {
            showToast({
              message: 'You need to verify your email address to access that feature'
            });
            return <Redirect to='/account' />
          } else if (currentUser && !currentUser.isAnonymous){
            return <Redirect to='/account' />
          }

          return <RouteComponent {...rest} {...routeProps} />
        }}
      />
    </>
  );
};
