import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkActionCode } from '../../actions/account';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  },
  redText: {
    fontSize: 11,
    color: '#f00'
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

export default function LoggedOutVerifyNewUserEmailChange ({ errorMessage, verifyUserEmail, loggingIn, props, setErrorMessage }) {
  const classes = useStyles();

  const searchParams = new URLSearchParams(window.location.search);
  const oobCode = searchParams.get('oobCode');
  const [verifying, setVerifying] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chkAC = await checkActionCode(oobCode);

        setUserEmail(chkAC.data.email);
        setVerifying(true);
        verifyUserEmail(oobCode);
      } catch (e) {
        setErrorMessage(e.message);
      }
    };

    fetchData();
  }, [oobCode, setErrorMessage, verifyUserEmail]);

  const verifyHandler = (e) => {
    e.preventDefault();

    verifyUserEmail(oobCode);
  }

  return (
    <div>
      <form action='/verify-change' method='post'>
        <p>
          Please wait. Trying to verify email for your account <b>{userEmail}</b>.
        </p>
        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm md />
          {false ? <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={verifyHandler}
              variant='contained'
              color='primary'
              disabled={verifying}
              fullWidth
            >
              {!verifying ? <>Verify email</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid> : null}
        </Grid>

      </form>
    </div>
  );
}
