import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import firebaseApp from '../../firebase-app';
import { submitProfile } from '../../actions/application';
import useForm from '../../hooks/use-form';
import { MODES } from '../../constants';
import JobDescription from '../job-description';
import SceneWrapper from '../scene-wrapper';
import Paper from '../paper';
import { AuthContext } from '../../contexts/auth';
import get from 'lodash.get';
import { useCollection } from 'react-firebase-hooks/firestore';
import ProfileChooser from '../algolia-profile-chooser';
import FilterResult from '../filter-result';

const initialState = {
  phone: '',
  email: '',
  rate: '',
  motivation: ''
};

const schema = yup.object().shape({
  phone: yup.string(),
  email: yup.string().email(),
  rate: yup.number()
});

export default function SubmitProfile ({ document, match }) {
  const [mode, setMode] = useState(MODES.EDITING);
  const [user] = useAuthState(firebaseApp.auth);
  const [values, onValueChange, setValues, isValid, errors] = useForm({ ...initialState, email: user.email }, schema);
  const [redirectToList, setRedirectToList] = useState(false);
  const data = document.data();

  const { account, company } = useContext(AuthContext);
  const accountData = account ? account.data() : {};
  const sharedProfiles = account ? get(accountData, 'sharedWithMe', {}) : [];
  const myProfiles = account ? get(accountData, 'profiles', {}) : [];
  const displayName = accountData.displayName;

  // eslint-disable-next-line no-unused-vars
  const [companyProfiles, loading, error] = useCollection(company
    ? firebaseApp.db.collection(`companies/${company.id}/candidates`)
    : null
  );
  const [selectedProfile, setSelectedProfile] = useState({});

  const currentCompany = company ? company.id : '-';

  const handleCancel = () => {
    setValues(initialState);
    setRedirectToList(true);
  }

  const handleSubmit = async () => {
    setMode(MODES.SAVING);
    try {
      await submitProfile(document, selectedProfile, { ...values, byUser: user.uid, byCompany: currentCompany, jobCompanyId: match.params.companyId, byProfile: selectedProfile, jobTitle: data.title, jobDescription: data.description });
      setRedirectToList(true);
    } catch (e) {
      console.error(e.message, e);
      setMode(MODES.EDITING);
    }
  }

  const handleProfileChange = (profile) => {
    setSelectedProfile(profile);
  }

  const removeSelected = () => {
    setSelectedProfile({});
  }

  if (redirectToList) {
    return <Redirect to={`/companies/${match.params.companyId}/create-job/${document.id}/applications`} />;
  }

  return (
    <SceneWrapper>
      <Paper>
        <h1>{data.title}</h1>

        <Divider />

        <h1>Role Description</h1>
        <JobDescription description={data ? data.description : ''} />

        <Divider />

        <h1>Submit Contract</h1>

        <Grid container style={{ alignSelf: 'center' }} direction='column' alignItems='center'>

          <Grid item style={{ alignSelf: 'stretch' }}>
            {selectedProfile.name
              ? <FilterResult
                id={0}
                selected
                profile={selectedProfile}
                handleRemove={removeSelected}
                />
              : <ProfileChooser
                sharedProfiles={sharedProfiles}
                companyProfiles={companyProfiles}
                myProfiles={myProfiles}
                displayName={displayName}
                handleChange={handleProfileChange}
                handleRemove={removeSelected}
                fullWidth
                />}

            <FormControl
              required
              error={errors.has('phone')}
              helperText={errors.has('phone') && errors.get('phone').error.message}
              style={{ marginTop: 16, marginBottom: 16, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.phone}
                name='phone'
                label='Phone number'
                onChange={onValueChange}
                required
                variant='outlined'
                disabled={mode === MODES.SAVING}
                helperText={errors.has('phone') && errors.get('phone').error.message}
                error={errors.has('phone')}
              />
            </FormControl>

            <FormControl
              required
              error={errors.has('email')}
              helperText={errors.has('email') && errors.get('email').error.message}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.email}
                name='email'
                label='Email'
                onChange={onValueChange}
                required
                variant='outlined'
                disabled={mode === MODES.SAVING}
                helperText={errors.has('email') && errors.get('email').error.message}
                error={errors.has('email')}
              />
            </FormControl>

            <FormControl
              required
              error={errors.has('rate')}
              helperText={errors.has('rate') && errors.get('rate').error.message}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.rate}
                name='rate'
                label='Rate'
                onChange={onValueChange}
                required
                variant='outlined'
                disabled={mode === MODES.SAVING}
                helperText={errors.has('rate') && errors.get('rate').error.message}
                error={errors.has('rate')}
              />
            </FormControl>

            <FormControl
              required
              error={errors.has('motivation')}
              style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            >
              <TextField
                name='motivation'
                value={values.motiviation}
                label='Motivate why this candidate is perfect for this job'
                fullWidth
                variant='outlined'
                onChange={onValueChange}
                required
                margin='dense'
                multiline
                rows='4'
                helperText={errors.has('motivation') && errors.get('motivation').error.message}
                error={errors.has('motivation')}
              />
            </FormControl>

          </Grid>

          <Grid container spacing={1} style={{ marginTop: 16 }} justify='space-between'>
            <Grid item xs={12} sm={4} md={1}>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                onClick={handleCancel}
              >Cancel
              </Button>
            </Grid>

            <Grid item xs={12} sm={8} md={2}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={!isValid || !selectedProfile.name}
              >SEND
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </SceneWrapper>
  );
}
