import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import firebaseApp from '../../firebase-app';
import { AuthContext } from '../../contexts/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoggedIn ({ viewCvPressed, addToSharedWithMe }) {
  const { account } = useContext(AuthContext);

  if (!account) {
    return null;
  }

  return (
    <div>
      <Grid container justify='space-between' alignContent='stretch' alignItems='stretch' style={{ marginBottom: 12 }}>
        <Grid item xs={12} sm={9}>
          <h2>Add CV to your shared folder</h2>
          <p>{account.data().email}</p>
        </Grid>

        <Grid item xs={12} sm={3}>
        </Grid>
      </Grid>

      <Button
        fullWidth
        onClick={addToSharedWithMe}
        variant='contained'
        color='primary'
        disabled={viewCvPressed}
      >{viewCvPressed ? <CircularProgress style={{ width: 24, height: 24, color: '#fff' }} /> : 'VIEW CV'}
      </Button>
    </div>
  );
}
