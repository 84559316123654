import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import useForm from '../../hooks/use-form';
import { MODES } from '../../constants';
import * as yup from 'yup';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';

import { isoCountries } from '../utils/countries';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginRight: theme.spacing(2)
  },
  uploadButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const schema = yup.object().shape({
  name: yup.string()
    .min(3, 'Title needs to be at least 3 characters long'),
  email: yup.string().email(),
  taxId: yup.string(),
  website: yup.string().url(),
  country: yup.string()
    .min(3, 'Country name is required'),
  state: yup.string()
    .min(3, 'State name is required'),
  city: yup.string()
    .min(3, 'City name is required'),
  zip: yup.number()
    .min(5, 'ZIP is required'),
  address: yup.string()
    .min(5, 'Address is required')
});

export default function CompanyDetailsEditForm ({ id, currentValues, handleSaveClicked, handleCancelClicked, mode }) {
  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors, validateAll, clear] = useForm(currentValues, schema);
  const classes = useStyles();

  return (
    /* Fix IE form getting mashed together with data table */
    <form style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        fullWidth
        variant='outlined'
        name='name'
        label='Company name'
        value={values.name}
        onChange={onValueChange}
        margin='normal'
        disabled={mode === MODES.SAVING}
        required
        helperText={errors.has('name') && errors.get('name').error.message}
        error={errors.has('name')}
      />

      <TextField
        fullWidth
        variant='outlined'
        name='taxId'
        label='Tax id'
        value={values.taxId}
        onChange={onValueChange}
        margin='normal'
        disabled={mode === MODES.SAVING}
        required
        helperText={errors.has('taxId') && errors.get('taxId').error.message}
        error={errors.has('taxId')}
      />

      <TextField
        fullWidth
        variant='outlined'
        name='phone'
        label='Phone number'
        value={values.phone}
        onChange={onValueChange}
        margin='normal'
        disabled={mode === MODES.SAVING}
        helperText={errors.has('phone') && errors.get('phone').error.message}
        error={errors.has('phone')}
      />

      <TextField
        fullWidth
        variant='outlined'
        name='website'
        label='Website'
        value={values.website}
        onChange={onValueChange}
        margin='normal'
        disabled={mode === MODES.SAVING}
        helperText={errors.has('website') && errors.get('website').error.message}
        error={errors.has('website')}
      />

      <Divider />

      <TextField
        fullWidth
        variant='outlined'
        name='address'
        label='Address'
        value={values.address}
        onChange={onValueChange}
        margin='normal'
        disabled={mode === MODES.SAVING}
        required
        helperText={errors.has('address') && errors.get('address').error.message}
        error={errors.has('address')}
      />

      <TextField
        fullWidth
        variant='outlined'
        name='address2'
        label='Address 2'
        value={values.address2}
        onChange={onValueChange}
        margin='normal'
        disabled={mode === MODES.SAVING}
        required
        helperText={errors.has('address2') && errors.get('address2').error.message}
        error={errors.has('address2')}
      />

      <TextField
        fullWidth
        variant='outlined'
        name='zip'
        label='Zip'
        value={values.zip}
        onChange={onValueChange}
        margin='normal'
        disabled={mode === MODES.SAVING}
        required
        helperText={errors.has('zip') && errors.get('zip').error.message}
        error={errors.has('zip')}
      />

      <TextField
        fullWidth
        variant='outlined'
        name='city'
        label='City'
        value={values.city}
        onChange={onValueChange}
        margin='normal'
        disabled={mode === MODES.SAVING}
        required
        helperText={errors.has('city') && errors.get('city').error.message}
        error={errors.has('city')}
      />

      <TextField
        fullWidth
        variant='outlined'
        name='state'
        label='State'
        value={values.state}
        onChange={onValueChange}
        margin='normal'
        disabled={mode === MODES.SAVING}
        required
        helperText={errors.has('state') && errors.get('state').error.message}
        error={errors.has('state')}
      />

      <FormControl variant='outlined' margin='normal' fullWidth>
        <InputLabel id='countryLabel' htmlFor='outlined-age-native-simple'>Country*</InputLabel>
        <Select
          name='country'
          value={values.country}
          label='Country'
          labelId='countryLabel'
          placeholder='Country'
          fullWidth
          variant='outlined'
          onChange={onValueChange}
          required
          margin='normal'
          error={errors.has('country')}
        >
          {Object.keys(isoCountries).map((item) => (
            <MenuItem key={item} value={isoCountries[item]}>{isoCountries[item]}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant='contained'
        component='label'
        className={classes.uploadButton}
        disabled={mode === MODES.SAVING}
      >
        Upload logo
        <input
          name='logo'
          type='file'
          onChange={onValueChange}
          style={{ display: 'none' }}
        />
      </Button>

      <div style={{ margin: '10px 0' }}>
        <Button
          color='primary'
          variant='outlined'
          onClick={handleCancelClicked}
          className={classes.cancelButton}
          disabled={mode === MODES.SAVING}
        >Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => handleSaveClicked(id, values)}
          disabled={mode === MODES.SAVING || !isValid}
        >Save
        </Button>
      </div>
    </form>
  );
}
