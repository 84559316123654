import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Hidden from '@material-ui/core/Hidden';
import Avatar, { SIZE } from '../avatar';
import Confirm from '../confirm';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  container: {
    borderWidth: 1,
    borderColor: theme.palette.accent.main,
    borderStyle: 'solid',
    borderRadius: 4,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: 'white'
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  leftButton: {
    marginLeft: theme.spacing(-1)
  },
  profile: {
    padding: 0,
    paddingTop: theme.spacing(2)
  },
  description: {
    color: theme.palette.lightText.main
  },
  margin: {
    marginLeft: theme.spacing(1)
  },
  value: {
    color: theme.palette.secondary.main
  },
  candidateName: {
    marginBottom: theme.spacing(1)
  },
  candidateTitle: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    color: theme.palette.darkText.main,
    fontSize: 14
  },
  iconButton: {
    marginTop: theme.spacing(-2),
    color: theme.palette.lightText.main
  }
}));

export default function SharedWithMeItem ({ handleViewCv, handleDeleteClick, expanded, profileKey, accountId, displayName, photo, profile }) {
  const classes = useStyles();
  const [deleting, setDeleting] = useState(false);

  const handleDeleteClickLocal = () => {
    handleDeleteClick();
    setDeleting(true);
  }

  return (
    <div className={classes.container}>
      <Grid container direction='row' className={classes.profile} alignItems='stretch' alignContent='stretch'>
        {!deleting
          ? <>
            <Grid item>
              <Avatar
                displayName={displayName}
                photo={photo}
                size={SIZE.SMALL}
                style={{ marginRight: 14 }}
              />
            </Grid>

            <Grid item style={{ flex: 1 }}>
              <Grid container justify='space-between'>
                <Grid item>
                  <Hidden smDown>
                    <h1 className={classes.candidateName}>{displayName} - {profile.title}</h1>
                  </Hidden>

                  <Hidden mdUp>
                    <h1 className={classes.candidateName}>{displayName}</h1>
                    <h2>{profile.title}</h2>
                  </Hidden>
                </Grid>
                <Grid>
                  <Confirm title={`Are you sure you want to remove shared profile "${profile.title}?"`}>
                    {confirm => (
                      <IconButton aria-label='delete' size='small' onClick={confirm(handleDeleteClickLocal)} className={classes.iconButton}>
                        <Delete />
                      </IconButton>
                    )}
                  </Confirm>
                </Grid>
              </Grid>

              {profile.description
                ? <Hidden smDown>
                  <div className={classes.description}>
                    {profile.description}
                  </div>
                </Hidden> : null}

              <Divider className={classes.divider} />

              <Grid container direction='row' justify='space-between' alignItems='center'>
                <Grid item>
                  <>
                    Expires: <b className={classes.value}>{moment(profile.expires.toDate()).format('DD MMMM YYYY')}</b>
                  </>
                </Grid>
                <Grid item>
                  <Button variant='text' size='small' color='primary' onClick={() => handleViewCv(profile.cvPath, `${profile.displayName} - ${profile.title}`)}>VIEW CV</Button>
                </Grid>
              </Grid>
            </Grid>
          </>
          : <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <CircularProgress />
          </div>}
      </Grid>
    </div>
  );
}
