import firebase from 'firebase/app';
import get from 'lodash.get';
import uuidv4 from 'uuid/v4';
import path from 'path';
import firebaseApp from '../firebase-app';
import moment from 'moment';
import {updateMailchimpMember} from './account';

// Check if we already have some
export async function queryExistingCompanies() {
  // Then call the needed function to retrieve
  // Companies that meet requirement
  const existingCompanies = await firebaseApp.functions.httpsCallable('getCompaniesByHostName')();

  return existingCompanies;
}

export async function queryExistingInvites() {
  // Then call the needed function to retrieve
  // Invites that meet requirement
  const existingInvites = await firebaseApp.functions.httpsCallable('getCompanyInvitesByEmail')();

  return existingInvites;
}


// If we find adequate company, let's make sure that
// You can send the request to join
export async function sendCompanyRequest(companyId) {
  try {
    const requestResponse = await firebaseApp.functions.httpsCallable('sendCompanyJoinRequest')({
      companyId,
      hash: uuidv4()
    });

    if (requestResponse.data.success){
      chooseStep(10, { usageType: 'company-w', companyStatus: 'company-old' });
    }

    return requestResponse;
  } catch (e){
    return {
      data: {
        success: false,
        message: "Something went wrong. Please contact support for help."
      }
    }
  }
}

export async function acceptCompanyInvite(invite) {
  try {
    await firebaseApp.functions.httpsCallable('acceptCompanyInvite')({
      code: invite.code,
      companyId: invite.companyId
    });

    await chooseStep(98);
  } catch (e) {
    throw new Error(e.message);
  }
}

export async function rejectCompanyInvite(invite) {
  try {
    await firebaseApp.functions.httpsCallable('rejectCompanyInvite')({
      code: invite.code,
      companyId: invite.companyId
    });
  } catch (e) {
    console.log(e);
  }

}

// Pass to the next step
export async function continueWithoutCompanyRequest() {
  await chooseStep(1);
}

// Choose account usage type
export async function chooseAccountUsage(usageType) {
  try {
    const user = firebaseApp.auth.currentUser;
    const account = firebaseApp.db.doc(`accounts/${user.uid}`);

    await account.update({ usageType: usageType });

    // Update member data in Mailchimp
    await updateMailchimpMember(user, usageType);
    // Update analytics
    firebaseApp.analytics().setUserProperties({usage_type: usageType});

    switch (usageType){
      case 'personal':
          chooseStep(5);
        break;
      case 'company-w':
          chooseStep(2);
        break;
      case 'company-r':
          chooseStep(2);
        break;
    }

  } catch (e) {

  }
}

export async function chooseCompanyStatus(companyStatus) {
  switch (companyStatus){
    case 'company-new':
        chooseStep(3);
      break;
    case 'company-old':
        contactAdmin();
      break;
  }
}

export async function companyCreated() {
  chooseStep(4);
}

export async function contactAdmin() {
  chooseStep(20);
}

export async function almostFinished() {
  chooseStep(98);
}

export async function goBack(step, preserveStep = false){

  console.log(preserveStep)
  console.log(step);
  if (step >= 1 && !preserveStep){
    chooseStep(step-1);
  } else if (step >= 0) {
    chooseStep(step);
  }
}

// All Finished, let's wrap it up!
export async function finishOnboarding() {
  chooseStep(99);
}

// Helper functions for choosing onboarding step
async function chooseStep(step, additionalData = null){
  // TODO
  const user = firebaseApp.auth.currentUser;
  const account = firebaseApp.db.doc(`accounts/${user.uid}`);
  const accountData = {
    onboardingProcess: step,
    needsOnboarding: step == 99 ? false : true
  };

  if (additionalData){
    Object.assign(accountData, additionalData);
  }

  return await account.update(accountData);
}

// We use this function just in case steps are not working
async function disableOnboarding(){
  // TODO
  const user = firebaseApp.auth.currentUser;
  const account = firebaseApp.db.doc(`accounts/${user.uid}`);

  return await account.update({ needsOnboarding: false });
}

export async function redirectFurther(action){
  await chooseStep(99);

  switch (action){
    case 'sharedProfiles':
        window.location.href = '/share/shared-with-me/';
      break;
    case 'companyProfiles':
        window.location.href = '/share/our-people/';
      break;
    case 'presonalProfiles':
        window.location.href = '/share/my-profile/';
      break;
  }
}
