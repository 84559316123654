import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { createJob } from '../../actions/job';
import { LOCATION_TYPE, RATE_TYPE, JOB_TYPE, CURRENCY_TYPES } from '../../constants';
import Paper from '../paper';
import { AuthContext } from '../../contexts/auth';
import useForm from '../../hooks/use-form';
import * as copy from 'clipboard-copy';

// Styling
const useStyles = makeStyles((theme) => ({
  page: {
    margin: '0 auto',
    maxWidth: 630,
    borderRadius: 8,
    overflow: 'hidden',
    width: '100%',
    '& .MuiStepper-horizontal': {
      background: 'transparent'
    },
    '& .MuiStepper-horizontal .MuiStep-root': {
      background: 'transparent'
    },
    '& .makeStyles-mainContent-23': {
      borderRadius: 10,
      overflow: 'hidden'
    }
  },
  root: {
    background: 'transparent',
    '& .MuiStepConnector-vertical .MuiStepConnector-lineVertical': {
      border: 0,
      minHeight: 0
    }
  },
  inner: {
    background: '#fff',
    '& .Mui-disabled span': {
      color: '#e0e0e0 !important'
    },
    '& .MuiStepIcon-root': {
      color: '#e0e0e0 !important'
    },
    '& .MuiStepIcon-active > span': {
      color: '#5e6e89 !important'
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#5e6e89 !important'
    }
  },
  innerContent: {
    margin: 0
  },
  innerTitle: {
    padding: 15,
    '& .MuiSvgIcon-root': {
      width: 10,
      height: 10
    },
    '& .MuiStepLabel-iconContainer': {
      padding: 0
    },
    '& .MuiSvgIcon-root .MuiStepIcon-text': {
      fontSize: 0
    }
  },
  mainContent: {
    background: '#fff'
  },
  textArea: {
    border: '1px solid rgba(0, 0, 0, 0.37)',
    borderRadius: 3,
    '& :focus': {
      border: '1px solid rgba(0, 0, 0, 0.87)'
    }
  },
  disabled: {
    color: '#5e6e89 !important'
  },
  enabled: {
    color: '#5e6e89 !important'
  },
  autocomplete: {
    '& .MuiInputBase-root': {
      padding: '6px 30px 7px 7px !important'
    }
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  fullWidthButton: {
    margin: '0 !important',
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: '100%'
      }
    }
  }
}));

// Autocomplete values
const locations = [
  { key: LOCATION_TYPE.ON_LOCATION, title: 'On-site' },
  { key: LOCATION_TYPE.REMOTE, title: 'Remote' }
];

const rateTypes = [
  { key: RATE_TYPE.PER_YEAR, title: 'Per year' },
  { key: RATE_TYPE.PER_MONTH, title: 'Per month' },
  { key: RATE_TYPE.PER_DAY, title: 'Per day' },
  { key: RATE_TYPE.PER_HOUR, title: 'Per hour' }
];

const jobTypes = [
  { key: JOB_TYPE.FULL_TIME, title: 'Full time' },
  { key: JOB_TYPE.PART_TIME, title: 'Part time' }
];

// Validation
const schema = yup.object().shape({
  title: yup
    .string()
    .min(3, 'Title needs to be at least 3 characters long'),
  location: yup
    .string()
    .min(3),
  locationType: yup
    .array()
    .min(1, 'You need to pick at least one location type')
    .max(2)
    .ensure(),
  jobType: yup
    .string()
    .min(1, 'You need to pick at least one job type')
    .ensure()

});

const intialValues = {
  title: '',
  location: '',
  locationType: [],
  description: '',
  rateCurrency: '',
  rateType: '',
  jobType: '',
  partTypePercent: ''
};

export default function CreateJob ({ match }) {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const [values, onValueChange, setValues, isValid, errors] = useForm(intialValues, schema);
  const { account } = useContext(AuthContext);
  const [redirect, setRedirect] = useState();
  const [dynamicLink, setDynamicLink] = useState(null);
  const [jobId, setJobId] = useState(null);

  const [locationType, setLocationType] = useState([]);
  const [rateCurrency, setRateCurrency] = useState(null);
  const [jobType, setJobType] = useState(null);
  const [rateType, setRateType] = useState(null);

  const [activeStep, setActiveStep] = React.useState(0);
  const { showToast } = useContext(DialogsAndToastsContext);

  const validateThenCreateJob = async () => {
    const job = await createJob(
      match.params.companyId,
      {
        ...values,
        title: values.title.charAt(0).toUpperCase() + values.title.slice(1)
      },
      account
    );
    // Try to open stepper
    setDynamicLink(job.dynamicLink);
    setJobId(job.id);
    handleNext();
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRedirect = () => {
    setRedirect(`/companies/${match.params.companyId}/create-job/${jobId}/edit`);
  };

  const handleAutocompleteChange = (e, value, type) => {
    e.persist();

    const persistValues = { ...values };

    switch (type) {
      case 'locationType':
        setLocationType(value);
        break;
      case 'rateCurrency':
        setRateCurrency(value);
        break;
      case 'jobType':
        setJobType(value);
        break;
      case 'rateType':
        setRateType(value);
        break;
      default:
        console.log(type);
        break;
    }

    if (value != null) {
      if (type === 'locationType') {
        persistValues[type] = value.map(v => v.key);
      } else {
        persistValues[type] = value.key;
      }
    } else {
      if (type === 'locationType') {
        persistValues[type] = [];
      } else {
        persistValues[type] = '';
      }
    }

    setValues(persistValues);
  }

  const handleLocationBack = () => {
    setRedirect(`/companies/${match.params.companyId}/jobs/open`);
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const renderStepOne = () => {
    return (
      <Paper style={{ maxWidth: 630, width: '100%', border: '1px solid rgb(232, 233, 234)', alignSelf: 'center', marginBottom: 0 }}>
        <div style={{ maxWidth: 480, margin: '0 auto' }}>
          <Typography component='h2' className={classes.formTitle}>Create new application form</Typography>
        </div>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component='h3' className={classes.formSubtitle}>Role title</Typography>
            <TextField
              name='title'
              value={values.title}
              label='e.g. Senior Software Developer'
              fullWidth
              variant='outlined'
              onChange={onValueChange}
              required
              helperText={errors.has('title') && errors.get('title').error.message}
              error={errors.has('title')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component='h3' className={classes.formSubtitle}>Location</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name='location'
                  value={values.location}
                  label='City'
                  fullWidth
                  variant='outlined'
                  onChange={onValueChange}
                  required
                  helperText={errors.has('location') && errors.get('location').error.message}
                  error={errors.has('location')}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  required
                  error={errors.has('locationType')}
                  component='fieldset'
                  name='locationType'
                  style={{ maxWidth: '100%', width: '100%' }}
                >
                  <Autocomplete
                    multiple
                    id='locationType'
                    options={locations}
                    value={locationType}
                    className={classes.autocomplete}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, v) => handleAutocompleteChange(e, v, 'locationType')}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label='Location type' variant='outlined' />}
                  />

                  {errors.has('locationType') &&
                    <FormHelperText>{errors.get('locationType').error.message}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component='h3' className={classes.formSubtitle}>Allocation</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  required
                  error={errors.has('jobType')}
                  component='fieldset'
                  style={{ maxWidth: '100%', width: '100%' }}
                >
                  <Autocomplete
                    id='jobType'
                    options={jobTypes}
                    value={jobType}
                    className={classes.autocomplete}
                    getOptionLabel={(option) => option.title}
                    style={{ width: '100%' }}
                    onChange={(e, v) => handleAutocompleteChange(e, v, 'jobType')}
                    renderInput={(params) => <TextField {...params} label='Job Type' variant='outlined' />}
                  />

                  {errors.has('jobType') &&
                    <FormHelperText>{errors.get('jobType').error.message}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {values.jobType == JOB_TYPE.PART_TIME ? <TextField
                  name='partTypePercent'
                  value={values.partTypePercent}
                  label='%'
                  fullWidth
                  variant='outlined'
                  type='number'
                  max={100}
                  min={0}
                  onChange={onValueChange}
                  helperText={errors.has('partTypePercent') && errors.get('partTypePercent').error.message}
                  error={errors.has('partTypePercent')}
                /> : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: 32 }} justify='flex-end'>
          <Grid item xs={12} sm={4} md={4} lg={4} className={classes.fullWidthButton} style={{ textAlign: 'right', marginRight: 10 }}>
            <Button
              onClick={handleLocationBack}
              variant='outlined'
              color='primary'
              style={{ marginBottom: 10, padding: '5px 30px' }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Button
              fullWidth
              onClick={handleNext}
              disabled={!isValid}
              color='primary'
              variant='contained'
            >
              Proceed
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const renderStepTwo = () => {
    return (
      <Paper style={{ border: 0, alignSelf: 'center' }}>
        <div style={{ maxWidth: 480, margin: '0 auto' }}>
          <Typography component='h2' className={classes.formTitle}>Job description</Typography>
        </div>
        <FormControl
          required
          error={errors.has('description')}
          style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
        >
          <p>Information about the job and requirements</p>
          <TextareaAutosize
            name='description'
            className={classes.textArea}
            value={values.description}
            label='Description'
            fullWidth
            rowsMin={6}
            rowsMax={10}
            variant='outlined'
            style={{ fontFamily: 'Quicksand', padding: 3, fontSize: 15, lineHeight: 1.3, resize: 'none' }}
            onChange={onValueChange}
          />
        </FormControl>
        <Grid container spacing={1} style={{ marginTop: 16 }} justify='flex-end'>
          <Grid item xs={12} sm={4} md={4} lg={4} className={classes.fullWidthButton} style={{ textAlign: 'right', marginRight: 10 }}>
            <Button
              onClick={handleBack}
              variant='outlined'
              color='primary'
              style={{ marginBottom: 10, padding: '5px 30px' }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Button
              onClick={handleNext}
              variant='contained'
              color='primary'
              disabled={!isValid || !values.description}
              fullWidth
            >
              Proceed
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const renderStepThree = () => {
    return (
      <Paper style={{ border: 0, alignSelf: 'center' }}>
        <div style={{ maxWidth: 480, margin: '0 auto' }}>
          <Typography component='h2' className={classes.formTitle}>Application settings</Typography>
        </div>
        <Grid container spacing={1} style={{ marginTop: 16 }} justify='space-between'>
          <Typography component='h3' className={classes.formSubtitle}>Rate / Renumeration / Salary</Typography>
          <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                required
                error={errors.has('rateType')}
                component='fieldset'
                style={{ maxWidth: '100%', width: '100%' }}
              >
                <Autocomplete
                  id='rateType'
                  options={rateTypes}
                  value={rateType}
                  className={classes.autocomplete}
                  getOptionLabel={(option) => option.title}
                  style={{ width: '100%' }}
                  onChange={(e, v) => handleAutocompleteChange(e, v, 'rateType')}
                  renderInput={(params) => <TextField {...params} label='Rate types' variant='outlined' />}
                />
                {errors.has('rateType') &&
                  <FormHelperText>{errors.get('rateType').error.message}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                required
                error={errors.has('rateCurrency')}
                component='fieldset'
                style={{ maxWidth: '100%', width: '100%' }}
              >
                <Autocomplete
                  id='rateCurrency'
                  options={CURRENCY_TYPES}
                  value={rateCurrency}
                  className={classes.autocomplete}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, v) => handleAutocompleteChange(e, v, 'rateCurrency')}
                  style={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} label='Currency' variant='outlined' />}
                />

                {errors.has('rateCurrency') &&
                  <FormHelperText>{errors.get('rateCurrency').error.message}</FormHelperText>}
              </FormControl>
            </Grid>

          </Grid>
          <Grid container spacing={1} style={{ marginTop: 32 }} justify='flex-end'>
            <Grid item xs={12} sm={4} md={4} lg={4} className={classes.fullWidthButton} style={{ textAlign: 'right', marginRight: 10 }}>
              <Button
                onClick={handleBack}
                variant='outlined'
                color='primary'
                style={{ marginBottom: 10, padding: '5px 30px' }}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                onClick={validateThenCreateJob}
                variant='contained'
                color='primary'
                fullWidth
                disabled={!isValid ||
                  values.description == '' ||
                  values.rateType == '' ||
                  values.rateCurrency == ''}
              >
                Proceed
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const renderStepFour = () => {
    return (
      <Paper style={{ border: 0, alignSelf: 'center' }}>
        <div style={{ maxWidth: 480, margin: '0 auto' }}>
          <Typography component='h2' className={classes.formTitle}>Success!</Typography>
        </div>
        <div style={{ textAlign: 'center', margin: '25px 0 45px 0' }}>
          <img src='/app-img/check_icon.png' alt='Job created successfuly' />
        </div>
        <p style={{ textAlign: 'center' }}>Copy the link to the application from this job <br />and send it via email to anyone who should apply.</p>
        <div
          style={{ background: '#fff', border: '1px solid #e0e0e0', position: 'relative', borderRadius: 3, padding: '15px 10px', textAlign: 'center', marginBottom: 35, width: '90%', margin: '0 5% 45px 5%' }}
          onClick={() => { showToast({ message: 'Link successfuly copied' }); copy(dynamicLink); }}
        >
          {dynamicLink}
          <span
            style={{ color: '#c0c0c0', position: 'absolute', bottom: 0, right: 0, fontSize: 11, cursor: 'pointer' }}
          >
            copy link
          </span>
        </div>
        <Grid container spacing={1} style={{ marginTop: 16 }} justify='center'>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant='outlined'
              color='primary'
              fullWidth
              onClick={handleRedirect}
            >
              Go to job page
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <form>
      <div className={classes.page}>
        <Stepper activeStep={activeStep}>
          <Step key={0} className={classes.inner}>
            <StepLabel className={classes.innerTitle} />
          </Step>
          <Step key={1} className={classes.inner}>
            <StepLabel className={classes.innerTitle} />
          </Step>
          <Step key={2} className={classes.inner}>
            <StepLabel className={classes.innerTitle} />
          </Step>
          <Step key={3} className={classes.inner}>
            <StepLabel className={classes.innerTitle} />
          </Step>
        </Stepper>
        <div className={classes.mainContent}>
          {activeStep == 0 ? renderStepOne() : null}
          {activeStep == 1 ? renderStepTwo() : null}
          {activeStep == 2 ? renderStepThree() : null}
          {activeStep == 3 ? renderStepFour() : null}
        </div>
      </div>
    </form>
  );
}
