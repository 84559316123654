import React from 'react';
import Paper from '../paper';
import SceneWrapper from '../scene-wrapper';

export default function Account () {
  return (
    <SceneWrapper>
      <Paper style={{ alignSelf: 'center', width: '100%', maxWidth: 860 }}>
        <h1>Privacy Policy</h1>
        <p>
          Last Updated on 08 April 2020. This Privacy Policy is effective
          immediately for Users after that date.
        </p>
        <p>
          StackLab AB ("StackLab", "we," "us,"), a company incorporated and
          existing under the laws of the Kingdom of Sweden, registered under
          number 559251-4474 in the Swedish Bolagsverket, having its seat and
          registered office at Lokstallsgatan 21, 21646 Limhamn, Sweden, respect
          your privacy and is committed to protecting it through our compliance
          with this Privacy Policy.
        </p>
        <p>
          This Privacy Policy describes sets out the basis on which any personal
          data we collect from you, or that you provide to us, will be processed
          by us. Please read the following carefully to understand our views and
          practices regarding your personal data and how we will treat it. By
          visiting Skilldrop.io you are accepting and consenting to the
          practices described in this policy.
        </p>

        <h2>DEFINITIONS</h2>
        <h3>Content</h3>
        "Content" will include (but is not limited to) images, photos, audio,
        video, text and all other forms of information or data.
        <h3>Services</h3>
        "Services" include any and all services, which StackLab may offer to
        the User, through the Website, including but not limited to uploading
        and/or viewing a Curriculum Vitae (“CV”) uploaded on the Website
        <h3>User</h3>
        "User" or "you" or "your" refers to you, as a user of the Services. A
        user is someone who accesses or uses the Services for the purpose of
        sharing, displaying, hosting, publishing, transacting, uploading
        information and includes other persons jointly participating in using
        the Services, such as companies or other entities who sign up for a
        company account with StackLab.
        <h3>Website</h3>
        “Website” refers to skilldrop.io
        <h3>Your Content</h3>
        <p>
          "Your Content" or "User Content" is the content, submitted by you, as
          a User of the Website. It includes, but is not limited to what you
          upload, share or transmit to, through or in connection with the
          Services, including but not limited to CVs and User Account
          information.
        </p>

        <h2>COMPLIANCE</h2>
        <p>
          StackLab qualifies as a Data Controller under the Regulation (EU)
          2016/679 of The European Parliament and of The Council of 27 April
          2016 (General Data Protection Regulation (the “GDPR”)). As such, we
          aim to comply with all legal obligations which the GDPR implies on
          Data Controllers.
        </p>
        <p>
          StackLab strives to offer its visitors and Users the many advantages
          of Internet technology and to provide an interactive and personalized
          experience. For those purposes, we, from StackLab, may use personally
          identifiable information – like your name, e-mail address, address,
          telephone number, etc., which are subject to the terms of this Privacy
          Policy. We, and we suppose you too, do not tolerate spam. We will
          therefore never sell, barter, or rent your email address to any
          unauthorized third parties.
        </p>

        <h3>1. WHAT INFORMATION DO WE COLLECT FROM OUR USERS?</h3>
        <p>
          StackLab may collect and use information of and regarding its Users.
          We collect only data which is essential to our operations and enables
          us to provide you with better user experience.
        </p>
        <h3>1.1 Information you give us.</h3>
        <p>
          You may give us information about you by creating a User Account or by
          filling any other forms on the Website, such as a contact form, or by
          communicating with us in a different way, i.e. via telephone or
          e-mail.
        </p>
        <h3>1.2 Information we collect about you.</h3>
        <p>
          We collect two types of information from and about our Users,
          including information:
        </p>
        <ul>
          <li>by which you may be personally identified; and/or</li>
          <li>
            about your internet connection, the equipment you use to access our
            Services and your usage details (i.e. your Internet Service
            Provider).
          </li>
        </ul>
        <h3>1.3 The information we collect on or through our Services may include:</h3>
        <p>
          <i>(i) Personal information</i>: Your full name, email address and other
          information you may provide when creating a User Account, such as your
          telephone number. We collect this information in order to be able to
          identify you as a registered User of the Services.
        </p>
        <p>
          <i>(ii) Preferences</i>: Your preferences and settings such as time zone and
          language. We collect this information in order to provide you with a
          personalised experience.
        </p>
        <p>
          <i>(iii) Searches and other activities</i>: The search terms you have looked up and
          results you selected. We collect this information in order to monitor
          our performance and improve our services.
        </p>
        <p>
          <i>(iv) Browsing information</i>: How long you used our Services, which features
          you used, etc. We collect this information in order to monitor assess
          the performance of our services.
        </p>
        <p>
          <i>(v) Communications</i>: Between you and StackLab support staff regarding the
          Services. We collect this information in order to improve your
          customer service experience.
        </p>
        <p>
          <i>(vi) Transactional information</i>: If you make payments or purchases through
          our Services, we may collect and store information about your
          transaction. Please bear in mind that such information does to include
          any payment details, as all payments are processed by Stripe. We may
          only collection information relating to the sum paid to us, as well as
          rearding who has made such payment.
        </p>
        <p>
          The legal basis for the above processing is GDPR art. 6(1)(a) and (b),
          since the processing is either consented to voluntarily or is
          necessary in order to facilitate entering into a contract and/or the
          performance of such contract.
        </p>

        <h3>2. HOW WE GATHER INFORMATION FROM USERS?</h3>
        <p>
          2.1 How we collect and store information depends on the pages you are
          visiting, the activities in which you elect to participate and the
          services provided. For example, you may be asked to provide
          information when you register for access to certain portions of our
          Website, or when you make a payment.
        </p>
        <p>
          2.2 Like most websites, StackLab also collects information automatically
          and through the use of electronic tools that may be transparent to our
          visitors and Users. For example, we may log the name of your Internet
          Service Provider or use “cookies” technology to recognize you and hold
          information from your visit (for more information, please view our
          separate Cookie Policy). Among other things, the cookies may store
          your username and password, sparing you from having to re-enter that
          information each time you visit, or may control the number of times
          you encounter a particular part of the Website. As we use additional
          technologies, we may also gather information through other means. In
          certain cases, you can choose not to provide us with information, for
          example by setting your browser to refuse to accept cookies.
        </p>
        <p>
          2.3 Please note that we do not gather, collect or process any information
          for Users or any third parties through files, including CVs, uploaded
          as User Content on the Website. We may nevertheless analyze aggregate
          CV data in order to assess various market identifiers, such as
          assessing the demand for certain industries and other trends. Each
          User represents that by uploading a file, including a CV, they have
          received that file’s owner’s consent to process such file. You may not
          imply that publishing and/or uploading Your Content is in any way
          motivated, sponsored or endorsed by StackLab. StackLab shall not be
          responsible if you publish any Content, without having obtained the
          consent for such publishing of its direct owner, i.e. uploading a
          file, including a CV, without the permission of its proprietor.
        </p>

        <h2>
          3. WHAT WE DO WITH THE INFORMATION WE COLLECT? HOW LONG DO WE COLLECT IT
          FOR?
        </h2>
        <p>
          3.1 Like other websites, we collect information to enhance your visit and
          deliver more individualized content. We respect your privacy and do
          not share your information with anyone, except in cases when that
          proves necessary.
        </p>
        <p>
          3.2 We will retain the information we collect from you for a period of
          five years. After the expiry of this period we undertake to delete any
          information we have collected from you.
        </p>
        <p>
          3.3 Aggregate information and information that does not personally
          identify you, may be used in many ways. For example, StackLab may
          combine information about your usage patterns on the Website with
          similar information obtained from other users to help enhance our site
          and Services (e.g., to learn which pages are visited the most or what
          features Users find the most attractive). We may use tools such as
          Hubspot or Hotjar to analyze the behaviour of Users and to track page
          views. Any such tools may include information about you, personally,
          and may allow us to identify you individually. Yet, in any such case,
          this information will only be visible to us, if you have already
          decided to provide it voluntarily.
        </p>
        <p>
          3.4 We may use personally identifiable information, collected on the
          Website, to communicate with you about your User Account; our Terms of
          Service and Privacy Policy; services and products offered by or
          through the StackLab Website; and other topics we think you might
          find of interest. Personally identifiable information collected by
          StackLab may also be used for other purposes, including but not
          limited to Website administration, troubleshooting and other
          communications with you.
        </p>
        <p>
          3.5 Certain third parties who provide technical support for the operation
          of our Website – our Web hosting service for example – may have access
          to such information. We will use your information only as permitted by
          law. We may also disclose your information in response to a court
          order, at other times when we believe we are reasonably required to do
          so by the applicable law, in connection with the collection of amounts
          you may owe to us, and/or to law enforcement authorities, whenever we
          deem it appropriate or necessary. Please note we may not provide you
          with notice prior to disclosure in such cases.
        </p>

        <h2>4. DISCLOSURE</h2>
        <p>
          4.1 StackLab AB won’t transfer information about you to third parties for
          the purpose of providing or facilitating third-party advertising to
          you. We won’t sell information about you.
        </p>
        <p>
          4.2 We may share your account information with third parties in some
          circumstances, including: (1) with your consent; (2) to a service
          provider or partner who meets our data protection standards; (3) when
          we have a good faith belief it is required by law, such as pursuant to
          a subpoena or other legal process; (4) when we have a good faith
          belief that doing so will help prevent imminent harm to someone.
        </p>
        <p>
          4.3 If we are going to share your information in response to legal
          process, we’ll give you notice so you can challenge it (for example by
          seeking court intervention), unless we’re prohibited by law or believe
          doing so may endanger others. We will object to requests for
          information about users of our services that we believe are improper.
        </p>
        <p>
          4.4 Certain third parties who provide technical support for the operation
          of our Website and our Web hosting service for example – may have
          access to such information.
        </p>
        <p>
          4.5 If your personal data is transferred outside the EU/EEA, we will enter
          into EU standard contractual clauses approved by the European
          Commission prior to such transfer to ensure the required level of
          protection for the transferred personal data. If you require
          additional information and/or wish to obtain a copy of the standard
          contractual clauses (including relevant safeguards put in place), you
          can request this by contacting us as set out in section 10.
        </p>

        <h2>5. YOUR RIGHTS</h2>
        <h3>5.1 Right to withdraw your consent</h3>
        <p>
          You may withdraw your consent for collecting your personal information
          at any time. To do so, please contact us by filling out the contact
          form support@skilldrop.engagebayservice.com .
        </p>
        <h3>5.2 Right to change or review your information</h3>
        <p>
          If you would like to review, change or delete personal data we have
          collected from you or you had submitted or permanently delete your
          account, please contact us. For more information regarding the
          termination or deletion of your information, please refer to Section
          7: Termination of this Privacy Policy.
        </p>
        <p>
          If you find that your CV has been uploaded to the Website without your
          consent, please contact us immediately. We will remove such CV in due
          course and may take relevant action against the uploading User by
          reporting them to the competent authorities.
        </p>

        <h2>6. ACCESSING &amp; CORRECTING YOUR PERSONAL INFORMATION.</h2>
        <p>
          6.1 We take reasonable steps to accurately record the personal information
          that you provide to us, as well as any subsequent updates.
        </p>
        <p>
          6.2 We encourage you to review, update, and correct the personal
          information that we maintain about you. You may request that we delete
          personal data about you that is inaccurate, incomplete, irrelevant for
          legitimate purposes, or are being processed in a way which infringes
          any applicable legal requirements.
        </p>

        <h2>7. TERMINATION</h2>
        <p>
          7.1 As mentioned, you may at any time review or change the personal
          information we maintain about you by contacting StackLab. Upon your
          request, we will delete your contact information and personal data
          from our active databases.
        </p>
        <p>
          7.2 This Privacy Policy is effective until terminated by either party. If
          you no longer agree to be bound by this Privacy Policy, you must cease
          the use of StackLab Website and remove all Content that has been
          uploaded by you on the Website. If you are dissatisfied with StackLab
          Website, its’ content, or any of these terms, conditions, and
          policies, your sole legal remedy is to discontinue using the Website.
          StackLab reserves the right to terminate or suspend your access to
          and use of the Website, or parts of them, without notice, if we
          believe, in our sole discretion that such use is in violation of any
          applicable law, or harmful to our interests or the interests of
          another person or entity, or where StackLab has reasons to believe
          that their use is in violation of this Privacy Policy or the Terms of
          Use.
        </p>

        <h2>8. CHILDREN’S PRIVACY</h2>
        <p>
          StackLab does not knowingly collect personal information from
          children under the age of 18. If we learn that we have personal
          information on a child under the age of 18, we will delete that
          information from our servers. StackLab encourages parents to go
          online with their kids.
        </p>

        <h2>9. OTHER PROVISIONS</h2>
        <h3>9.1 Changes to This Policy</h3>
        <p>
          StackLab reserves the rights to change this Privacy Policy at any
          time. Please check this page periodically for changes. Your use of the
          services after any such amendment or change in the Privacy Policy
          shall be deemed as your express acceptance to such amended/changed
          Privacy Policy and an assent to be bound by such changed/amended
          Privacy Policy. Information collected prior to the time any change is
          posted will be used according to the rules and laws that applied at
          the time the information was collected.
        </p>
        <h3>9.2 Governing Law</h3>
        <p>
          This Privacy Policy and the use of the Website are governed by Swedish
          law. The parties undertake to first try to resolve the dispute with by
          negotiation. If the parties fail to reach an amicable resolution
          through negotiation, StackLab agrees to submit the dispute to the
          competent Court of the Kingdom of Sweden.
        </p>

        <h2>10. MISCELLANEOUS</h2>
        <p>
          10.1 StackLab is controlled and administered entirely within the European
          Union.
        </p>
        <p>
          10.2 If you are accessing StackLab Website from another jurisdiction,
          please be advised that you are transferring your personal information
          to StackLab and your data will be stored in the European Union and,
          by using the Website, you consent to that transfer and to abide by the
          applicable laws concerning your use of the Website and your agreements
          with us.
        </p>
        <p>
          10.3 This statement and the policies outlined in this Privacy Policy are
          not intended to and do not create any contractual or other legal
          rights in or on behalf of any party.
        </p>
        <p>
          10.4 StackLab may provide a translation of the English version of the
          Privacy Policy into other languages. You understand and agree that any
          translation of the Privacy Policy into other languages is only for
          your convenience and that the English version shall govern your rights
          and responsibilities. In case there are any inconsistencies between
          the English version of the Privacy Policy and its translated versions,
          the English version of the Terms shall prevail.
        </p>

        <h2>11 CONTACT</h2>
        <p>
          If you believe StackLab does not adhere to this Privacy Policy, in
          order to address a question, to resolve a complaint regarding the
          Website or StackLab Services, or to receive further information
          regarding the Services, please contact StackLab using one of the
          following methods:
        </p>
        <p>
          Address: Lokstallsgatan 21, 21646 Limhamn, Sweden
        </p>
        <p>
          Contact us: support@skilldrop.com
        </p>
      </Paper>
    </SceneWrapper>
  );
}
