import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './contexts/auth'
import { DialogsAndToastsContext } from './contexts/dialogs-and-toasts'

export default function PrivateRoute ({ component: RouteComponent, path, ...rest }) {
  const { currentUser, account } = useContext(AuthContext);
  const { showToast } = useContext(DialogsAndToastsContext);
  const accountData = account && account.data() || {};

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (currentUser && !currentUser.isAnonymous && !currentUser.emailVerified && path !== '/account') {
          showToast({
            message: 'You need to verify your email address to access that feature'
          });
          return <Redirect to='/account' />
        }

        if (currentUser && !currentUser.isAnonymous && accountData.needsOnboarding && path !== '/account'){
          return <Redirect to='/account' />
        }

        if (currentUser &&
          currentUser.isAnonymous &&
          (path.indexOf('/account') === -1 &&
            path.indexOf('/share/accept-') === -1 &&
            path.indexOf('/share/shared-') === -1)
        ){
          return <Redirect to='/login' />
        }

        return currentUser
          ? <RouteComponent {...rest} {...routeProps} />
          : <Redirect to='/login' />
      }}
    />
  );
};
