import React from 'react';
import P from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { detect } from 'detect-browser';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 5,
    padding: 0
  },
  buttonLight: {
    borderRadius: 5,
    borderColor: theme.palette.accent.main,
    padding: 0
  },
  buttonRound: {
    borderRadius: 40,
    padding: 0
  },
  buttonLightRound: {
    borderRadius: 40,
    borderColor: theme.palette.accent.main,
    padding: 0
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  linkLight: {
    textDecoration: 'none',
    color: '#fff',
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  chipSecondary: {
    borderColor: '#f88',
    color: '#fff',
    fontSize: '11px',
    height: '16px',
    background: '#f88',
    lineHeight: '16px'
  }
}));

export default function LinkButton ({ title, to, light, round, buttonProps, variant, pill, ...rest }) {
  const browser = detect();
  const classes = useStyles();
  let styleClass = classes.button;
  if (light && round) {
    styleClass = classes.buttonLightRound;
  } else if (light) {
    styleClass = classes.buttonLight;
  } else if (round) {
    styleClass = classes.buttonRound;
  }
  return (
    <Button
      variant={variant || 'outlined'}
      className={styleClass}
      {...buttonProps}
      // eslint-disable-next-line
      onClick={() => { if (browser && browser.name === 'ie') { event.target.children[0].children[0].click(); }}}
    >
      {to.startsWith('http')
        ? <a href={to} className={light ? classes.linkLight : classes.link} onClick={(e) => e.stopPropagation()}>{title}</a>
        : <Link to={to} className={light ? classes.linkLight : classes.link} {...rest}>{title}</Link>}

      {pill &&
        <Chip
          className={classes.chipSecondary}
          variant="outlined"
          size="small"
          label={pill}
          color="secondary"
        />
      }
    </Button>
  );
}

LinkButton.propTypes = {
  light: P.bool
};

LinkButton.defaultProps = {
  light: false
};
