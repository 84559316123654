import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '../paper';
import CompanyDialogAddUser from '../company/company-dialog-add-user';
import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import firebaseApp from '../../firebase-app';

import AddColleagueForm from './add-colleague-form';
import { addInvite, editMember, deleteMember, deleteInvite } from '../../actions/company';

export default function AddColleaguesComponent ({ match, localAddColleagues, companyId, showToast }) {

  const [colleagues, setColleagues] = useState([]);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const addColleague = (colleague) => {
    setColleagues([...colleagues, colleague])
  }

  // eslint-disable-next-line no-unused-vars
  const [companyInvitesRef, companyInvitesLoading, companyInvitesError] = useCollection(
    firebaseApp.db.collection(`companies/${companyId}/invites`)
  );
  const companyInvites = companyInvitesRef ? companyInvitesRef.docs : [];

  const handleAddUserClicked = () => {
    setAddUserOpen(true);
  };

  const handleAddUserCloseClicked = () => {
    setAddUserOpen(false);
  };

  const handleEditMemberClicked = (row, companyId) => {
    setEditData({ ...row, companyId })
  };

  const handleEditMemberCloseClicked = () => {
    setEditData(null);
  };

  const handleEditMember = async (companyId, displayName, id, role) => {
    try {
      await editMember(companyId, id, role)
      showToast({ message: `${displayName} is now ${role}`, variant: 'success' })
    } catch (e) {
      showToast({ message: e.message, variant: 'error' })
    }
  }

  const handleDeleteMember = async (companyId, id, displayName) => {
    try {
      await deleteMember(companyId, id);
      showToast({ message: `${displayName} is no longer a company member `, variant: 'success' })
    } catch (e) {
      showToast({ message: e.message, variant: 'error' })
    }
  }

  const handleDeleteInvite = async (companyId, email) => {
    try {
      await deleteInvite(companyId, email);
      showToast({ message: `Invite to ${email} removed`, variant: 'success' })
    } catch (e) {
      showToast({ message: e.message, variant: 'error' })
    }
  }

  const handleAddInvite = async (companyId, account, displayName, email, role) => {
    try {
      await addInvite(companyId, account, displayName, email, role)
      showToast({ message: `Invite sent to ${email}`, variant: 'success' })
    } catch (e) {
      showToast({ message: e.message, variant: 'error' })
    }
  }

  if (companyInvitesError) {
    console.log('Error loading company invites', companyInvitesError);
    return (
      <Paper>
        <h1>Doh, we had a mishap</h1>
        {companyInvitesError.message}
      </Paper>
    );
  }

  return (
    <div id="add-colleagues" style={{ margin: '30px 0' }}>
      {companyInvites && companyInvites.map((invite)=>{
        const inviteData = invite.data();
        return (
          <Grid container key={invite.id} alignItems="center" style={{ borderBottom: '1px solid #d7d7d7' }}>
            <Grid item md={7}>
              {inviteData.email}
            </Grid>
            <Grid item md={3}>
              {inviteData.role}
            </Grid>
            <Grid item md={2} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => handleDeleteInvite(companyId, inviteData.email)}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        );
      })}

      <Button
        variant='outlined'
        color='primary'
        onClick={handleAddUserClicked}
        style={{ marginTop: 30 }}
        size="small"
      >
        Add a colleague
      </Button>

      <CompanyDialogAddUser
        open={addUserOpen}
        onClose={handleAddUserCloseClicked}
        companyId={companyId}
        addInvite={handleAddInvite}
      />
    </div>
  );
}
