export type Product = {
  id: string;
  name: string;
  description: string;
  users: string;
  price: number;
};

export type TaxEnum = {
  enum: string;
  country: string;
}

/** Stripe product plan ids */
export const STANDARD_PRODUCT_ID = 'free';
export const PREMIUM_PRODUCT_ID = process.env.REACT_APP_PREMIUM_PLAN_ID;

export const TAX_ENUM = [
  { enum: 'au_abn', country: 'Australia' },
  { enum: 'ca_bn', country: 'Canada' },
  { enum: 'ca_qst', country: 'Canada' },
  { enum: 'es_cif', country: 'Spain' },
  { enum: 'eu_vat', country: 'Austria' },
  { enum: 'eu_vat', country: 'Belgium' },
  { enum: 'eu_vat', country: 'Bulgaria' },
  { enum: 'eu_vat', country: 'Cyprus' },
  { enum: 'eu_vat', country: 'Czech' },
  { enum: 'eu_vat', country: 'Germany' },
  { enum: 'eu_vat', country: 'Denmark' },
  { enum: 'eu_vat', country: 'Estonia' },
  { enum: 'eu_vat', country: 'Spain' },
  { enum: 'eu_vat', country: 'Finland' },
  { enum: 'eu_vat', country: 'France' },
  { enum: 'eu_vat', country: 'United Kingdom' },
  { enum: 'eu_vat', country: 'Greece' },
  { enum: 'eu_vat', country: 'Croatia' },
  { enum: 'eu_vat', country: 'Hungary' },
  { enum: 'eu_vat', country: 'Ireland' },
  { enum: 'eu_vat', country: 'Italy' },
  { enum: 'eu_vat', country: 'Lithuania' },
  { enum: 'eu_vat', country: 'Luxembourg' },
  { enum: 'eu_vat', country: 'Latvia' },
  { enum: 'eu_vat', country: 'Malta' },
  { enum: 'eu_vat', country: 'Netherlands' },
  { enum: 'eu_vat', country: 'Poland' },
  { enum: 'eu_vat', country: 'Portugal' },
  { enum: 'eu_vat', country: 'Romania' },
  { enum: 'eu_vat', country: 'Sweden', tax_rate: 25, type: 'excluded' },
  { enum: 'eu_vat', country: 'Slovenia' },
  { enum: 'eu_vat', country: 'Slovakia' },
  { enum: 'hk_br', country: 'Hong Kong' },
  { enum: 'in_gst', country: 'India' },
  { enum: 'jp_cn', country: 'Japan' },
  { enum: 'kr_brn', country: 'South Korea' },
  { enum: 'li_uid', country: 'Liechtenstein' },
  { enum: 'mx_rfc', country: 'Mexico' },
  { enum: 'my_itn', country: 'Malaysia' },
  { enum: 'no_vat', country: 'Norway' },
  { enum: 'nz_gst', country: 'New Zealand' },
  { enum: 'ru_inn', country: 'Russia' },
  { enum: 'sg_uen', country: 'Singapore' },
  { enum: 'th_vat', country: 'Thailand' },
  { enum: 'tw_vat', country: 'Taiwan' },
  { enum: 'us_ein', country: 'United States' },
  { enum: 'za_vat', country: 'South Africa' }
]
