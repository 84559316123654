import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCollection, useDocumentOnce } from 'react-firebase-hooks/firestore';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Application from '../application';
import Paper from '../paper';
import firebaseApp from '../../firebase-app';
import Rating from '@material-ui/lab/Rating';
import NoDataInformation from '../../components/no-data-information';

export default function Applications ({ match, jobDocument, jobPrivate }) {
  const [rating, setRating] = useState(0);
  const [applications, loading, error] = useCollection(
    jobDocument.ref.collection('applications')
  );
  const [tagsDoc] = useDocumentOnce(firebaseApp.db.doc('config/tags'));
  const tags = tagsDoc ? tagsDoc.data() : {};
  const jobData = jobDocument ? jobDocument.data() : {};

  const resetFilter = async (e) => {
    e.preventDefault();
    setRating(0);
  }

  const filterApplications = async (e, v) => {
    setRating(v);
  }

  if (!applications && loading) {
    return (<Paper>Loading</Paper>);
  }

  if (!applications && error) {
    return (<Paper>{error.message}</Paper>);
  }

  if (!loading && applications && applications.docs && applications.docs.length == 0) {
    return (<div style={{ marginTop: 100, marginBottom: 100 }}>
      <NoDataInformation
        title='No candidates have been submited yet'
        actionTitle='CREATE JOB'
        subTitle='Send the form link to the vendors who should <br>submit candidates or add profiles from your shared repository'
        bullets={[
          `Form link: <a href="${jobData.dynamicLink}">${jobData.dynamicLink}</a>`,
          `<a href="/companies/${match.params.companyId}/submit-profile/${jobDocument.id}">Add from profiles.</a>`,
          `<a href="/companies/${match.params.companyId}/submit-cv/${jobDocument.id}">Upload a CV.</a>`,
        ]}
        image='/app-img/skilldrop-company.png' 
      />
    </div>);
  }

  return (
    <React.Fragment>
      <Grid container style={{ maxWidth: 1115, paddingLeft: 27, minHeight: 32, marginBottom: 16 }}>
        <Grid item style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
          {jobPrivate && !jobPrivate.done &&
            <Link className='action-link' to={`/companies/${match.params.companyId}/submit-cv/${jobDocument.id}`}>Upload CV</Link>}
          {jobPrivate && !jobPrivate.done &&
            <Link className='action-link' to={`/companies/${match.params.companyId}/submit-profile/${jobDocument.id}`} style={{ marginLeft: 20 }}>Choose Profile</Link>}
        </Grid>

        <Hidden mdDown>
          <Grid item style={{ flex: 1, width: 200, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <b>RATE</b>
          </Grid>
        </Hidden>

        <Hidden smDown>
          <Grid item style={{ flex: 1, width: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Link to='#' style={{ marginRight: 16 }} onClick={resetFilter}>ALL</Link>

            <Rating name='filter-applications' defaultValue={0} value={rating} precision={0.5} max={3} onChange={filterApplications} />
          </Grid>
        </Hidden>

        <Hidden xsDown>
          <Grid item style={{ flex: 1, width: 200, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} />
        </Hidden>
      </Grid>

      {applications.docs.map(application => (
        <React.Fragment key={application.id}>
          {application && application.data()
            ? <Application
              key={application.id}
              currency={jobData.rateCurrency}
              application={application}
              showRating={rating}
              tags={tags ? tags.application : []}
              /> : null}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
