import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

import Paper from '../paper';
import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import * as yup from 'yup';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebaseApp from '../../firebase-app';
import useForm from '../../hooks/use-form';
import {
  MODES,
  RATE_TYPE_NAME,
  JOB_TYPE_NAME
} from '../../constants';
import JobDescription from '../job-description';
import { AuthContext } from '../../contexts/auth';
import { submitExternalCv, submitProfile } from '../../actions/application';
import { handleLogin, SIGN_IN_OPTIONS } from '../../actions/account';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';
import Typography from '@material-ui/core/Typography';
import get from 'lodash.get';

import ProfileChooser from '../algolia-profile-chooser';
import { useAuthState } from 'react-firebase-hooks/auth';
import Application from '../application';
import SceneWrapper from '../scene-wrapper';
import Switch from '@material-ui/core/Switch';
import FilterResult from '../filter-result';

const initialState = {
  name: '',
  cv: null,
  title: '',
  phone: '',
  email: '',
  rate: '',
  description: '',
  motivation: ''
};
const schema = yup.object().shape({
  name: yup.string()
    .min(3, 'Name needs to be at least 3 characters long')
    .required('Name is required'),
  cv: yup.mixed().required('CV is required'),
  phone: yup.string(),
  email: yup.string().email(),
  profileIdToLink: yup.string()
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1024,
    width: '100%',
    margin: '0 auto',
    '& h1': {
      marginBottom: 30
    },
    '& h2': {
      color: '#5e6e89',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 0.82,
      marginBottom: 30,
      marginTop: 0
    },
    '& h3': {
      fontWeight: 400
    },
    '& .MuiGrid-spacing-xs-2': {
      margin: '-5px 0',
      width: '100%'
    },
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  form: {
    maxWidth: 1024,
    width: '100%',
    margin: '0 auto',
    padding: 0,
    '& .MuiStepConnector-vertical .MuiStepConnector-lineVertical': {
      border: 0,
      minHeight: 0
    }
  },
  inner: {
    '& .Mui-disabled span': {
      color: '#e0e0e0 !important'
    },
    '& .MuiStepIcon-root': {
      color: '#e0e0e0 !important'
    },
    '& .MuiStepIcon-active > span': {
      color: '#5e6e89 !important'
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#5e6e89 !important'
    }
  },
  innerContent: {
    padding: 30,
    background: '#fff',
    margin: 0
  },
  innerTitle: {
    padding: 15
  },
  selectedProfile: {
    padding: 15,
    border: '1px solid #d7d7d7'
  },
  bordered: {
    borderBottom: '1px solid #d7d7d7',
    paddingBottom: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between'
  },
  borderedTitle: {
    paddingBottom: 5,
    marginBottom: 10
  },
  divider: {
    marginLeft: 16,
    marginRight: 16,
    maxWidth: 1,
    backgroundColor: theme.palette.accent.main
  },
  leftColumn: {

  }
}));

function getSteps () {
  return ['Upload CV / Resume / Profile', 'Supporting information', 'Contact details'];
}

export default function SubmitApplication ({ match }) {
  const classes = useStyles();
  const { account, currentUser, accountLoading, company } = useContext(AuthContext);
  const [mode, setMode] = useState(MODES.EDITING);
  const [activeStep, setActiveStep] = React.useState(0);
  const [user] = useAuthState(firebaseApp.auth);

  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors] = useForm({ ...initialState, email: user.email }, schema);
  const [selectedAction, setSelectedAction] = useState('apply-with-profile');
  const [job, jobLoading, jobError] = useDocument(
    firebaseApp.db.doc(`companies/${match.params.companyId}/jobs/${match.params.jobId}`)
  );
  const jobData = job ? job.data() : {};
  const steps = getSteps();
  const accountData = account ? account.data() : {};
  const companyId = account ? get(accountData, 'currentCompany') : '';
  const companies = account ? get(accountData, 'companies') : '';

  const sharedProfiles = account ? get(accountData, 'sharedWithMe', {}) : [];
  const myProfiles = account ? get(accountData, 'profiles', {}) : [];
  const displayName = accountData.displayName;
  const { showToast } = useContext(DialogsAndToastsContext);

  // eslint-disable-next-line no-unused-vars
  const [companyProfiles, loading, error] = useCollection(companyId
    ? firebaseApp.db.collection(`companies/${companyId}/candidates`)
    : (companies && companies.length > 0
      ? firebaseApp.db.collection(`companies/${companies[0]}/candidates`)
      : null)
  );
  const [selectedProfile, setSelectedProfile] = useState({});
  const currentCompany = company ? company.id : '-';

  // eslint-disable-next-line no-unused-vars
  const [activeApplications, activeApplicationsLoading, activeApplicationsError] = useCollection(user
    ? firebaseApp.db.collection(`companies/${match.params.companyId}/jobs/${match.params.jobId}/applications`).where('byUser', '==', currentUser.uid) : null
  );
  const [activePersonalApplications, activePersonalApplicationsLoading, activePersonalApplicationsError] = useCollection(user
    ? firebaseApp.db.collection(`companies/${match.params.companyId}/jobs/${match.params.jobId}/applications`).where('applicationType', '==', 'personal').where('byUser', '==', currentUser.uid) : null
  );

  const uiConfig = {
    ...SIGN_IN_OPTIONS,
    callbacks: {
      ...SIGN_IN_OPTIONS.callbacks,
      signInSuccessWithAuthResult: (res) => {
        const asyncHandler = async () => {
          await handleLogin(res);
        };
        asyncHandler();
        // Avoid redirects after sign-in.
        return false;
      }
    }
  };

  const handleUploadProgress = progress => {
    console.log(progress);
  }

  const handleUploadError = error => {
    console.log(error);
  }

  const handleUploadSuccess = () => {
    console.log('File uploaded successfully');
  }

  const handleSubmit = async () => {
    setMode(MODES.SAVING);
    try {
      let byCompany = currentCompany;
      if (selectedProfile.applicationType == 'personal'){
        byCompany = '-';
      }

      if (selectedAction == 'single-application') {
        await submitExternalCv(job, companyId, { ...values, byUser: user.uid, byCompany: currentCompany, jobTitle: jobData.title, jobCompanyId: match.params.companyId }, handleUploadProgress, handleUploadError, handleUploadSuccess);
      } else if (selectedAction == 'apply-with-profile') {
        await submitProfile(job, selectedProfile, { ...values, jobCompanyId: match.params.companyId, byUser: user.uid, byCompany: byCompany, byProfile: selectedProfile, jobTitle: jobData.title, jobDescription: jobData.description });
      }

      setValues(initialState);
      setSelectedProfile({});
      setSelectedAction('apply-with-profile');
      setActiveStep(0);

      setMode(MODES.EDITING);
      showToast({ message: 'Application successfully created.', variant: 'success' })
    } catch (e) {
      showToast({ message: 'Application couldn\'t be created.', variant: 'success' })
      console.error(e.message, e);
      setMode(MODES.EDITING);
    }
  }

  const handleProfileChange = (profile) => {
    console.log(profile);
    setSelectedProfile(profile);
  }

  if (jobLoading) {
    return (
      <div style={{ width: '100%', maxWidth: 1024, margin: 'auto', border: '1px solid #e8e9ea', borderRadius: 4, overflow: 'hidden' }}>
        <Paper style={{ width: '100%', maxWidth: 1024, margin: '0 auto' }}>
          Loading
        </Paper>
      </div>
    );
  }

  if (!jobLoading && jobError) {
    return (
      <Paper>
        <h1>Doh, we had a mishap</h1>
        {jobError.message}
      </Paper>
    );
  }

  if (job && !job.exists) {
    return (
      <div style={{ width: '100%', maxWidth: 1024, margin: 'auto', border: '1px solid #e8e9ea', borderRadius: 4, overflow: 'hidden' }}>
        <Paper style={{ width: '100%', maxWidth: 1024, margin: '0 auto' }}>
          This job is no longer open for applications.
        </Paper>
      </div>
    );
  }

  if (accountLoading) {
    return (
      <div style={{ width: '100%', maxWidth: 1024, margin: 'auto', border: '1px solid #e8e9ea', borderRadius: 4, overflow: 'hidden' }}>
        <Paper style={{ width: '100%', maxWidth: 1024, margin: '0 auto' }}>
          Loading
        </Paper>
      </div>
    );
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const removeSelected = () => {
    setSelectedProfile({});
  }

  const renderUserForm = () => {
    return (
      <>
        <Grid container spacing={2} margin={20}>
          <Grid item xs={12} sm={12}>
            {false ? <h3 style={{ width: '100%' }}>
              Apply with a skilldrop profile
              <div style={{ float: 'right', marginTop: '-10px' }}>
                <span style={{ color: '#c7c7c7', fontWeight: 300 }}>Apply with pdf</span>
                <Switch
                  checked={selectedAction == 'single-application'}
                  onChange={() => setSelectedAction('single-application')}
                  name='checkedA'
                  color='primary'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
            </h3> : null}

            {selectedProfile.name
              ? <FilterResult
                id={0}
                selected
                profile={selectedProfile}
                handleRemove={removeSelected}
              />
              : <ProfileChooser
                sharedProfiles={sharedProfiles}
                companyProfiles={companyProfiles}
                myProfiles={myProfiles}
                displayName={displayName}
                handleChange={handleProfileChange}
                removeSelected={removeSelected}
              />}
          </Grid>
        </Grid>

      </>
    );
  };

  const renderAnonymousForm = () => {
    return (
      <>
        {false ? <h3 style={{ width: '100%' }}>
          Upload a new CV
          <div style={{ float: 'right', marginTop: '-10px' }}>
            <span style={{ color: '#c7c7c7', fontWeight: 300 }}>Apply with pdf</span>
            <Switch
              checked={selectedAction == 'single-application'}
              onChange={() => setSelectedAction('apply-with-profile')}
              name='checkedA'
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        </h3> : null}
        <Grid container style={{ alignSelf: 'center' }} direction='column' alignItems='left'>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <FormControl
              required
              error={errors.has('cv')}
              style={{ marginTop: 8, marginBottom: 0, width: '100%' }}
            >
              <Button
                variant='contained'
                component='label'
                disabled={mode === MODES.SAVING}
              >
                Upload cv
                <input
                  name='cv'
                  type='file'
                  onChange={onValueChange}
                  style={{ display: 'none' }}
                />
              </Button>
            </FormControl>

            <FormControl
              required
              error={errors.has('name')}
              style={{ marginTop: 22, marginBottom: 14, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.name}
                name='name'
                label='Name'
                onChange={onValueChange}
                variant='outlined'
                required
                disabled={mode === MODES.SAVING}
                helperText={errors.has('name') && errors.get('name').error.message}
                error={errors.has('name')}
              />
            </FormControl>
            <FormControl
              required
              error={errors.has('title')}
              style={{ marginTop: 8, marginBottom: 14, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.title}
                name='title'
                label='Title'
                onChange={onValueChange}
                variant='outlined'
                required
                disabled={mode === MODES.SAVING}
                helperText={errors.has('title') && errors.get('title').error.message}
                error={errors.has('title')}
              />
            </FormControl>
            <FormControl
              required
              error={errors.has('description')}
              helperText={errors.has('description') && errors.get('description').error.message}
              style={{ marginTop: 0, marginBottom: 14, width: '100%' }}
            >
              <TextField
                value={values.description}
                name='description'
                label='Description'
                fullWidth
                rows='4'
                onChange={onValueChange}
                variant='outlined'
                margin='dense'
                multiline
                required
                disabled={mode === MODES.SAVING}
                error={errors.has('description')}
              />
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  }

  const renderPickAccount = () => {
    return (
      <>
        <h1 style={{ textAlign: 'center', marginBottom: 32 }}>How would you like us to handle your application?</h1>
        <Grid container spacing={4} justify='center'>
          <Grid item xs={12} sm={6} ml={3}>
            <h2 style={{ textAlign: 'center' }}>Sign in</h2>
            <ul>
              <li>Revoke your application at any time</li>
              <li>Manage your data from any device</li>
              <li>Reuse your CV when you want to apply for other jobs</li>
            </ul>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp.auth} />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderStepper = () => {
    return (
      <div className={classes.root} style={{ border: '1px solid #e8e9ea', borderRadius: 4, overflow: 'hidden' }}>
        <div className={classes.innerContent}>
          {!jobData.done ? <h2>Submit application</h2> : null}

          {activeStep == 0 ? renderStepOne() : null}
          {activeStep == 1 ? renderStepTwo() : null}
          {activeStep == 2 ? renderStepThree() : null}

          {activeStep > 0 || true
            ? <div className={classes.actionsContainer}>
              <Grid container spacing={1} style={{ marginTop: 16 }} justify='space-between'>
                {false ?<Grid item xs={12} sm={4} md={1}>
                  <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                </Grid> : null}
                <Grid item xs={12} sm={8} md={8} justify='space-between'>
                  {activeStep === steps.length - 1 || true
                    ? <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={handleSubmit}
                      className={classes.button}
                      style={{ width: 'auto', float: 'right' }}
                      >
                      Submit
                    </Button>
                    : <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={handleNext}
                      className={classes.button}
                      style={{ width: 'auto', float: 'right' }}
                      >
                      Next
                    </Button>}
                </Grid>
              </Grid>
            </div>
            : null}
        </div>

        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </Paper>
        )}
      </div>
    );
  }

  const renderStepOne = () => {
    return (
      <>
        <Grid container spacing={2} margin={20}>
          {selectedAction == 'single-application'
            ? renderAnonymousForm() : null}
          {selectedAction == 'apply-with-profile'
            ? renderUserForm() : null}
        </Grid>

        <Grid container spacing={2} margin={20}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <>
              <h3 style={{ marginTop: 20, marginBottom: 10 }}>Enter supporting details</h3>
              <FormControl
                required
                error={errors.has('rate')}
                style={{ marginTop: 8, marginBottom: 0, width: '100%' }}
              >
                <TextField
                  name='rate'
                  value={values.rate}
                  label={`Rate per ${RATE_TYPE_NAME[jobData.rateType].toLowerCase()}`}
                  fullWidth
                  variant='outlined'
                  onChange={onValueChange}
                  required
                  helperText={errors.has('rate') && errors.get('rate').error.message}
                  error={errors.has('rate')}
                />
              </FormControl>
              <Grid>
                <Box ml={1}><b>{jobData.currency}</b></Box>
              </Grid>
              <FormControl
                required
                error={errors.has('motivation')}
                style={{ marginTop: 14, marginBottom: 0, width: '100%' }}
              >
                <TextField
                  name='motivation'
                  value={values.motiviation}
                  label='Motivate why this candidate is perfect for this job'
                  fullWidth
                  variant='outlined'
                  onChange={onValueChange}
                  required
                  margin='dense'
                  multiline
                  rows='4'
                  helperText={errors.has('motivation') && errors.get('motivation').error.message}
                  error={errors.has('motivation')}
                />
              </FormControl>
            </>
          </Grid>
        </Grid>

        <Grid container spacing={2} margin={20}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <h3 style={{ marginTop: 20, marginBottom: 10 }}>Contact details</h3>
            <FormControl
              required
              error={errors.has('email')}
              style={{ marginTop: 8, marginBottom: 14, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.email}
                name='email'
                label='Email-address'
                onChange={onValueChange}
                variant='outlined'
                required
                disabled={mode === MODES.SAVING}
                helperText={errors.has('email') && errors.get('email').error.message}
                error={errors.has('email')}
              />
            </FormControl>
            <FormControl
              required
              error={errors.has('phone')}
              style={{ marginTop: 8, marginBottom: 0, width: '100%' }}
            >
              <TextField
                fullWidth
                value={values.phone}
                name='phone'
                label='Phone number'
                onChange={onValueChange}
                variant='outlined'
                required
                disabled={mode === MODES.SAVING}
                helperText={errors.has('phone') && errors.get('phone').error.message}
                error={errors.has('phone')}
              />
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  }

  const renderStepTwo = () => {
    return (
      <></>
    );
  }

  const renderStepThree = () => {
    return (
      <></>
    );
  }

  const renderApplications = () => {
    return (
      <>
        {activeApplications && activeApplications.docs.map(application => {
          return (
            <Application
              key={application.id}
              currency={jobData.rateCurrency}
              application={application}
              remove
              tags={[]}
            />
          );
        })}
        {false && activePersonalApplications && activePersonalApplications.docs.map(application => {
          return (
            <Application
              key={application.id}
              currency={jobData.rateCurrency}
              application={application}
              remove
              tags={[]}
            />
          );
        })}

      </>
    );
  }

  return (
    <SceneWrapper>
      {!jobData.done || (jobData.done && activeApplications && activeApplications.docs && activeApplications.docs.length > 0)
        ? <div style={{ width: '100%', maxWidth: 1024, margin: '0 auto' }}>
          <Paper style={{ padding: 30, width: '100%', maxWidth: 1024, margin: '0 auto' }}>
            <Grid container>
              <Grid className={classes.leftColumn} item xs={12} sm={12} md lg>
                <h1 style={{ marginBottom: 30 }}>{jobData.title}</h1>
                <JobDescription description={jobData ? jobData.description : ''} />
              </Grid>
              <Hidden smDown>
                <Grid key='divider' className={classes.divider} item xs sm md lg xl />
              </Hidden>
              <Grid item xs={12} sm={12} md={4}>
                <h1 className={classes.borderedTitle} style={{ marginBottom: 30 }}>Role details</h1>
                <p className={classes.bordered}>
                  <h3>Location:</h3><span>{jobData.location ? <>{jobData.location}</> : null}</span>
                </p>
                <p className={classes.bordered}>
                  <h3>Job type:</h3><span>{JOB_TYPE_NAME[jobData.jobType]}{jobData.partTypePercent != '' ? <span> {jobData.partTypePercent}%</span> : null}</span>
                </p>
                <p className={classes.bordered}>
                  <h3>Currency & rate type:</h3><span>{jobData.rateCurrency} / {RATE_TYPE_NAME[jobData.rateType]}</span>
                </p>

              </Grid>
            </Grid>
          </Paper>
          <div style={{ marginBottom: 35, marginTop: 35 }}>{renderApplications()}</div>

          {!account ? <Paper style={{ width: '100%', maxWidth: 1024, border: '1px solid #e8e9ea', borderRadius: 4, overflow: 'hidden' }}>
            {renderPickAccount()}
          </Paper> : null}
          {!jobData.done ? renderStepper()
            : <div style={{ width: '100%', maxWidth: 1024, margin: '0 auto', border: '1px solid #e8e9ea', borderRadius: 4, overflow: 'hidden' }}>
              <Paper style={{ width: '100%', maxWidth: 1024, margin: '0 auto' }}>
                <p style={{ marginBottom: 0 }}>This job has been closed, it’s not possible to access it anymore</p>
              </Paper>
            </div>}
        </div>
        : <div style={{ width: '100%', maxWidth: 1024, margin: '0 auto', border: '1px solid #e8e9ea', borderRadius: 4, overflow: 'hidden' }}>
          <Paper style={{ width: '100%', maxWidth: 1024, margin: '0 auto' }}>
            <p style={{ marginBottom: 0 }}>This job has been closed, it’s not possible to access it anymore</p>
          </Paper>
        </div>}
    </SceneWrapper>
  );
}
