import React, { useState, useEffect, useContext } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { useDocument } from 'react-firebase-hooks/firestore';
import firebaseApp from '../firebase-app';
import Avatar, { SIZE } from './avatar';
import Tag from './tag';
import { setTag, removeApplication, updateRating, submitComment } from '../actions/application';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { DialogsAndToastsContext } from '../contexts/dialogs-and-toasts';
import CircularProgress from '@material-ui/core/CircularProgress';

import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles(theme => ({
  applicationTitle: {
    '& small': {
      marginTop: 10,
      fontWeight: 300,
      opacity: 0.8,
      display: 'block'
    }
  },
  expansionPanel: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&:before': {
      display: 'none'
    }
  },
  expansionPanelSummary: {
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: 'center',
    minHeight: 80
  },
  applicationDetails: {
    flexDirection: 'column'
  },
  divider: {
    height: '50%',
    marginLeft: 16,
    marginRight: 16
  },
  circularIcon: {
    width: '20px !important',
    height: '20px !important'
  },
  expansionIcon: {
    borderWidth: 0,
    borderRadius: 100,
    color: theme.palette.lightText.main,
    backgroundColor: theme.palette.background.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: theme.spacing(1)
  },
  avatar: {
    marginRight: theme.spacing(4)
  },
  leftPaneNoP: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0 !important'
    }
  },
  rightPaneNoP: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0 !important'
    }
  }

}));

export default function Application ({ application, tags, currency, remove = false, showRating }) {
  const data = application.data();
  const classes = useStyles();
  const cvPath = data.cvPath;

  // eslint-disable-next-line no-unused-vars
  const [privateDoc, privateDocLoading, privateDocError] = useDocument(
    application.ref.collection('private_data').doc('private_document')
  );

  const applicationData = (application && application.data()) || {};

  const privateData = (privateDoc && privateDoc.data()) || {};
  const tag = (privateData && privateData.tag) || (tags && tags[0]);
  const rate = (privateData && privateData.rate);
  const privateRating = (privateData && privateData.rating) || 0;
  const { handleViewCv } = useContext(DialogsAndToastsContext);
  const [loading, setLoading] = useState(false);

  const handleTagSelected = tag => {
    setTag(application, tag);
  };

  const handleRemoveApplication = async (e) => {
    e.stopPropagation();
    removeApplication(application);
  }

  const handleRatingChange = async (e, v) => {
    e.stopPropagation();
    e.preventDefault();
    updateRating(application, v);
  }

  const localSubmitComment = async (e, v) => {
    e.persist();
    const comment = e.target.value;

    submitComment(application, comment);
  }

  const localHandleViewCv = async (cvPath, title) => {
    try {
      setLoading(true);
      await handleViewCv(cvPath, title);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  return (
    <>
      {privateData && (showRating <= privateData.rating || showRating == 0 || remove) ?
        <Accordion className={classes.expansionPanel}>
          <AccordionSummary
            className={classes.expansionPanelSummary}
            expandIcon=<ExpandMoreIcon className={classes.expansionIcon} />
          >
            <Grid container>
              <Grid item style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div className={classes.avatar}>
                  <Avatar
                    photo={data.photo}
                    size={SIZE.MEGA}
                    displayName={data.name}
                  />
                </div>
                <h1 className={classes.applicationTitle} style={{ flex: 1, margin: 0, padding: 0 }}>
                  {data.name}
                  <small>{data.title}</small>
                </h1>
              </Grid>

              <Hidden mdDown>
                <Grid item style={{ flex: 1, paddingRight: 32, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  {rate} {rate && currency}
                </Grid>
              </Hidden>

              <Hidden smDown>
                <Grid item style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {!remove
                    ? <>
                      {privateRating
                        ? <Rating style={{ color: 'rgb(48, 84, 125)' }} name={application.id} defaultValue={0} value={privateRating} precision={0.5} max={3} onChange={handleRatingChange} />
                        : <Rating style={{ color: 'rgb(48, 84, 125)' }} name={application.id} defaultValue={0} value={0} precision={0.5} max={3} onChange={handleRatingChange} />}
                    </> : null}
                </Grid>
              </Hidden>

              <Hidden xsDown>
                {!remove
                  ? <Grid item style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Tag
                      tag={tag || (tags && tags[0])}
                      tags={tags}
                      handleTagSelected={handleTagSelected}
                    />
                  </Grid>
                  : <Grid item style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      onClick={handleRemoveApplication}
                      classes={{
                        root: classes.buttonRoot,
                        label: classes.buttonLabel
                      }}
                    >
                      Remove application
                    </Button>
                  </Grid>}
              </Hidden>

            </Grid>
          </AccordionSummary>

          <AccordionDetails className={classes.applicationDetails}>

            <Grid container style={{ alignSelf: 'center' }} direction='row' alignItems='center'>
              <Grid xs={12} md={8} className={classes.leftPaneNoP} item style={{ alignSelf: 'stretch', paddingRight: 15 }}>
                <Grid container style={{ alignSelf: 'center', height: '100%' }} direction='column' alignItems='center'>
                  <Grid xs={12} md={12} item style={{ alignSelf: 'stretch', flexBasis: 0, marginBottom: 20, paddingRight: 15 }}>
                    <Typography component='h3' style={{ paddingBottom: 5, marginBottom: 5, borderBottom: '1px solid #d7d7d7' }}>Profile description</Typography>
                    <div>{data.description}</div>
                  </Grid>
                  <Grid xs={12} md={12} item style={{ alignSelf: 'stretch', flexBasis: 0, flexGrow: 1, marginBottom: 20, paddingRight: 15 }}>
                    <Typography component='h3' style={{ paddingBottom: 5, marginBottom: 5, borderBottom: '1px solid #d7d7d7' }}>Motivation</Typography>
                    <div>{data.motivation}</div>
                  </Grid>
                  <Grid xs={12} md={12} item style={{ alignSelf: 'stretch', flexBasis: 0, textAlign: 'right', paddingRight: 15 }}>
                    {cvPath &&
                      <Button color='primary' disabled={loading} variant='outlined' size='small' onClick={() => { localHandleViewCv(cvPath, `${applicationData.name} - ${applicationData.title}`) }}>
                        {loading ? <CircularProgress className={classes.circularIcon} /> : <>View CV</>}
                      </Button>}
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} md={4} item className={classes.rightPaneNoP} style={{ alignSelf: 'stretch', paddingLeft: 15 }}>
                <Typography component='h3' style={{ paddingBottom: 5, marginBottom: 5, borderBottom: '1px solid #d7d7d7' }}>Contact details</Typography>

                {data.message}
                {data.message &&
                  <Divider />}

                <ul style={{ paddingLeft: 20 }}>
                  <li>
                    <b style={{ marginRight: 16 }}>Name</b>
                    {data.name}
                  </li>
                  <li>
                    <b style={{ marginRight: 16 }}>Phone number</b>
                    {data.phone}
                  </li>
                  <li>
                    <b style={{ marginRight: 16 }}>Email-address</b>
                    {data.email}
                  </li>
                </ul>

                {!remove ? <>
                  <Typography component='h3' style={{ paddingBottom: 5, marginBottom: 5, borderBottom: '1px solid #d7d7d7' }}>Comment</Typography>
                  <FormControl
                    required
                    style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
                    onBlur={localSubmitComment}
                  >
                    <TextareaAutosize
                      name='comment'
                      className='form-control'
                      placeholder='Comment'
                      rowsMin={12}
                      rowsMax={12}
                      defaultValue={privateData.comment}
                      style={{ width: '100%', fontFamily: 'Quicksand', padding: 3, fontSize: 15, lineHeight: 1.3, resize: 'none' }}
                      variant='outlined'
                    />
                  </FormControl>
                </> : null}

              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        : null}
    </>
  );
}
