import React from 'react';
import { Link } from 'react-router-dom';

export default class ReplaceLink extends React.Component {
  render () {
    return (
      <Link replace {...this.props} />
    );
  }
}
