import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '../avatar';
import Confirm from '../confirm';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

export default function CompanyRowRequest ({ row, isSelf, companyId, mayEdit, handleApproveRequest, handleRejectRequest }) {
  const [requestRole, setRequestRole] = useState('manager');
  const approveRequest = () => handleApproveRequest(row.id, requestRole)
  const rejectRequest = () => handleRejectRequest(row.id, requestRole)

  const onRoleChange = (event) => {
    setRequestRole(event.target.value);
  }

  return (
    <TableRow selected={isSelf}>
      <TableCell size='small'><Avatar photo={row.photo} displayName={row.displayName} /></TableCell>
      <TableCell><Tooltip title={row.email}><span>{row.displayName}</span></Tooltip></TableCell>
      <Hidden xsDown>
        <TableCell><Tooltip title={row.email}><span>{row.email}</span></Tooltip></TableCell>
        <TableCell>{`Requested to join`}</TableCell>
      </Hidden>
      <TableCell>
        <Select
          value={requestRole}
          onChange={onRoleChange}
          label='Role'
          required
          inputProps={{
            name: 'role',
            id: 'role'
          }}
          fullWidth
        >
          <MenuItem value='administrator'>Administrator</MenuItem>
          <MenuItem value='manager'>Manager</MenuItem>
        </Select>
      </TableCell>
      <TableCell align='right'>
        <Button onClick={rejectRequest} color='primary' size='small'>
          Reject request
        </Button>
        <Button onClick={approveRequest} variant='contained' color='primary' size='small'>
          Approve request
        </Button>
      </TableCell>
    </TableRow>
  );
}
