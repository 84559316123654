import React, { useContext, useState } from 'react';
import Paper from '../paper';
import SceneWrapper from '../scene-wrapper';
import { AuthContext } from '../../contexts/auth';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

import {
  acceptCompanyInvite,
  rejectCompanyInvite,
} from '../../actions/onboarding';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 35
  },
  notification: {
    width: '100%',
    marginBottom: 15
  },
  rootPanel: {
    margin: '0 !important',
    cursor: 'default',
    width: '100%'
  },
  expansionPanelSummaryRoot: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: 8,
    margin: '0 !important',
    minHeight: '0 !important',
    cursor: 'default'
  },
  expansionPanelSummaryContent: {
    margin: '0 !important',
    cursor: 'default'
  },
  divider: {
    height: '60%',
    marginLeft: 16,
    marginRight: 16
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    paddingRight: 1,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 11
  },
  dateColumn: {
    width: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.secondary.main
  },
  stretchLink: {
    '& > a': {
      display: 'block',
      width: '100%',
      height: '100%',
      lineHeight: '2.1'
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={0} variant="outlined" {...props} />;
}

export default function Notifications ({ location }) {
  const { currentUser, account, accountLoading, company, modules } = useContext(AuthContext);
  const { showToast } = useContext(DialogsAndToastsContext);
  const classes = useStyles();
  const accountData = account && account.data();
  const companyData = company && company.data();
  const invites = accountData && accountData.invites;
  const requests = companyData && companyData.requestsPending;
  const [deleteInviteId, setDeleteInviteId] = useState(false);
  const [loading, setLoading] = useState(false);

  const approveInvite = async (inviteData) => {
    setLoading(true);
    try {
      await acceptCompanyInvite(inviteData);
    } catch (e) {
      showToast({ variant: 'error', message: e.message });
    }
    setLoading(false);
  }

  const rejectInvite = async (inviteData) => {
    try {
      setLoading(true);
      await rejectCompanyInvite(inviteData);
      setLoading(false);
    } catch (e) {
      showToast({ variant: 'error', message: e.message });
    }
  }

  return (
    <SceneWrapper>
      <h1 className={classes.title}>Notifications</h1>
      <Divider />
      <Grid container className={classes.mainActionPanel} justify='space-between' alignItems='center' spacing={2}>
        {requests && requests > 0 ?
          <ExpansionPanel className={classes.rootPanel}>
            <ExpansionPanelSummary
              defaultExpanded
              square
              TransitionProps={{
                timeout: 0
              }}
              classes={{
                root: classes.expansionPanelSummaryRoot,
                content: classes.expansionPanelSummaryContent
              }}
            >
              <div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.stretchLink}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ fontSize: 16, padding: '12px 0' }}>
                    You have {requests} requests to join your company {companyData.name}
                  </div>
                </div>
              </div>
            </ExpansionPanelSummary>
          </ExpansionPanel>
        : null}
        {invites && invites.length > 0 ?
          <>
            {invites && invites.map(invite => {
              return (
                <React.Fragment key={invite.code}>
                  {invite.code!=deleteInviteId ?
                  <ExpansionPanel className={classes.rootPanel}>
                    <ExpansionPanelSummary
                      defaultExpanded
                      square
                      TransitionProps={{
                        timeout: 0
                      }}
                      classes={{
                        root: classes.expansionPanelSummaryRoot,
                        content: classes.expansionPanelSummaryContent
                      }}
                    >
                      <div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.stretchLink}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                          <div style={{ fontSize: 16, padding: '12px 0' }}>
                            You have invitation from <b>{invite && invite.invitedBy}</b> to join <b>{invite && invite.companyName}</b>
                          </div>
                          <div>
                            {!loading ?
                              <>
                                <Button onClick={() => approveInvite(invite)}>Accept</Button>
                                <Button onClick={() => rejectInvite(invite)}>Reject</Button>
                              </>
                            :
                            <>
                              <CircularProgress />
                            </>}
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelSummary>
                  </ExpansionPanel>
                  :
                  <ExpansionPanel className={classes.rootPanel}>
                    <ExpansionPanelSummary
                      defaultExpanded
                      square
                      TransitionProps={{
                        timeout: 0
                      }}
                      classes={{
                        root: classes.expansionPanelSummaryRoot,
                        content: classes.expansionPanelSummaryContent
                      }}
                    >
                      <div style={{ minWidth: 240, flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.stretchLink}>
                        Deleting invite to {invite.to}
                      </div>
                    </ExpansionPanelSummary>
                  </ExpansionPanel>}
                </React.Fragment>
              );
            })}
          </>
        : null}

        {!invites && !requests ?
          <p>You don't have any notifications</p>
        : null}
      </Grid>
    </SceneWrapper>
  );
}
