import React, { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import Divider from '@material-ui/core/Divider';
import Paper from '../paper';
import useForm from '../../hooks/use-form';
import { MODES } from '../../constants';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

const schema = yup.object().shape({
  title: yup.string()
    .min(3, 'Title needs to be at least 3 characters long'),
  description: yup.string()
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 40
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    color: '#4579b4',
    border: '1px solid rgba(69, 121, 180, 0.5)'
  }
}));

export default function EditProfilePopup ({ companyId, candidateId, profileId, updateCandidateProfile, toggleEditProfile, profile }) {
  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState(MODES.EDITING);
  const classes = useStyles();
  const { showToast } = useContext(DialogsAndToastsContext);
  const data = profile;

  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors] = useForm(
    {
      title: data.title,
      description: data.description
    },
    schema
  );

  const validateThenSave = async () => {
    setMode(MODES.SAVING);
    if (isValid) {
      try {
        setMode(MODES.SAVING);
        console.log(values);
        console.log(document);
        await updateCandidateProfile(candidateId, companyId, profileId, values);
        showToast({
          message: 'Profile saved'
        });

        toggleEditProfile();
      } catch (e) {
        setMode(MODES.EDITING);
        showToast({
          variant: 'error',
          message: 'The profile could not be saved at this time'
        });
      }
    }
  }

  const redirectBack = () => {
    toggleEditProfile();
  }

  return (
    <Paper className={classes.root}>
      <Typography component='h1' className={classes.formTitle}>Edit profile</Typography>
      <p style={{ marginBottom: 10 }}>
        Please note that changes will be automatically distributed to all users this profile has been shared with.
      </p>

      <Divider />
      <form style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', alignItems: 'flex-end', marginTop: 16 }}>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component='h3' className={classes.formSubtitle}>Title*</Typography>
            <TextField
              name='title'
              value={values.title}
              label='Title'
              placeholder='Title'
              fullWidth
              variant='outlined'
              onChange={onValueChange}
              required
              margin='dense'
              helperText={errors.has('title') && errors.get('title').error.message}
              error={errors.has('title')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component='h3' className={classes.formSubtitle}>Profile description*</Typography>
            <TextField
              name='description'
              value={values.description}
              label='Profile description'
              fullWidth
              variant='outlined'
              onChange={onValueChange}
              required
              margin='dense'
              multiline
              rows='3'
              helperText={errors.has('description') && errors.get('description').error.message}
              error={errors.has('description')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 15 }}>
          <Grid item xs={12} sm md />

          <Grid item xs={12} sm={4} md={4} style={{ textAlign: 'right' }}>
            <Button
              variant='outlined'
              color='primary'
              className={classes.backButton}
              onClick={redirectBack}
              disabled={mode === MODES.SAVING}
            >CANCEL
            </Button>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={validateThenSave}
              variant='contained'
              color='primary'
              fullWidth
              disabled={!isValid || mode === MODES.SAVING}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
