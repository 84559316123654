import React, { useState, useEffect, useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebaseApp from '../../firebase-app';
import Paper from '../paper';
import { handleLogin, SIGN_IN_OPTIONS } from '../../actions/account';
import { acceptCompanyInvite } from '../../actions/company';
import { AuthContext } from '../../contexts/auth';
import SceneWrapper from '../scene-wrapper';
import { Button } from '@material-ui/core';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';
import Grid from '@material-ui/core/Grid'
import CustomAuth from '../utils/custom-auth';
import { makeStyles } from '@material-ui/core/styles';

import GoogleLogin from '../social-login/google-login';
import MiscrosoftLogin from '../social-login/ms-login';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35,
    border: '1px solid rgb(232, 233, 234)'
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  formInput: {
    marginTop: 8,
    marginBottom: 14,
    width: '100%'
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  }
}));

export default function CompanyAcceptInvite ({ match }) {
  const classes = useStyles();

  const [redirectToAccount, setRedirectToAccount] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { showToast } = useContext(DialogsAndToastsContext);

  // Log out any current user on first render of this page
  useEffect(() => {
    firebaseApp.auth.signOut();
  }, [])

  useEffect(() => {
    if (currentUser && !currentUser.isAnonymous && !currentUser.emailVerified) {
      let reloadUserInterval;
      let checkEmailVerifiedInterval;

      reloadUserInterval = setInterval(() => {
        currentUser.reload();
      }, 5000);

      checkEmailVerifiedInterval = setInterval(() => {
        if (currentUser && currentUser.emailVerified) {
          clearInterval(reloadUserInterval);
          clearInterval(checkEmailVerifiedInterval);
        }
      }, 500);

      return () => {
        clearInterval(reloadUserInterval);
        clearInterval(checkEmailVerifiedInterval);
      };
    }
  }, [currentUser]);

  const onAcceptCompanyInviteClicked = async () => {
    try {
      await acceptCompanyInvite(match.params.companyId, match.params.inviteCode);
      showToast({ message: 'Company invite accepted', variant: 'success' })
      setRedirectToAccount(true);
    } catch (e) {
      showToast({ message: `Company invite could not be accepted: ${e.message}`, variant: 'error' })
    }
  }

  return <Redirect to='/account' />;

  if (redirectToAccount) {
    return <Redirect to='/account' />;
  }

  if (!currentUser) {
    // eslint-disable-next-line no-unused-vars
    const uiConfig = {
      ...SIGN_IN_OPTIONS,
      signInSuccessUrl: match.path,
      callbacks: {
        ...SIGN_IN_OPTIONS.callbacks,
        signInSuccessWithAuthResult: (res) => {
          const asyncHandler = async () => {
            await handleLogin(res);
          };
          asyncHandler();
          // Avoid redirects after sign-in.
          return false;
        }
      }
    };
    return (
      <SceneWrapper>
        <Paper style={{ alignItems: 'stretch', alignSelf: 'center', maxWidth: 640 }}>
          <Typography component='h1' className={classes.formTitle}>Sign in to accept invitation to join company</Typography>
          <Typography>
            If you don't have a Skilldrop account, you can create one for free: <Link to='/register'>create account</Link> 
          </Typography>

          <Divider />
          <h2 style={{ fontWeight: 400, fontSize: 16, color: '#4579b4' }}>Recommended</h2>
          <p style={{ fontSize: 12 }}>Signup securely with your Google or Miscrosoft account.</p>
          <Grid container style={{ margin: '0 -5px' }}>
            <GoogleLogin firebaseApp={firebaseApp} firebaseAuth={firebaseApp.auth} fullWidth={false} />
            <MiscrosoftLogin firebaseApp={firebaseApp} firebaseAuth={firebaseApp.auth} fullWidth={false} />
          </Grid>
          <p className='firebaseui-tos firebaseui-tospp-full-message'>
            <small>
              By continuing, you are indicating that you accept our <a href='/terms-of-service' className='firebaseui-link firebaseui-tos-link' target='_blank'>Terms of Service</a> and <a href='/privacy-policy' className='firebaseui-link firebaseui-pp-link' target='_blank'>Privacy Policy</a>.
            </small>
          </p>

          <Divider />
          <h2 style={{ fontWeight: 400, fontSize: 16, color: '#4579b4' }}>Traditional</h2>
          <CustomAuth />

          <p style={{ marginTop: 15 }}>
            <small>
              New to Skilldrop? <Link to='/register'>Register new account</Link> | <Link to='/reset-password'>Forgot your password?</Link>
            </small>
          </p>

        </Paper>
      </SceneWrapper>
    );
  }

  if (!currentUser.emailVerified) {
    return (
      <SceneWrapper>
        <Paper style={{ alignItems: 'center', alignSelf: 'center', maxWidth: 640 }}>
          <Typography component='h1' className={classes.formTitle}>Please verify your email address.</Typography>
          <Typography>
            An email has been sent to {currentUser.email}.
            Click the link in that email to verify you email address to continue accepting
            your company invite.
          </Typography>
          <CircularProgress style={{ marginTop: 20 }} />
        </Paper>
      </SceneWrapper>
    );
  }

  return (
    <SceneWrapper>
      <Paper style={{ alignItems: 'center', alignSelf: 'center', maxWidth: 640 }}>
        <Typography component='h1' className={classes.formTitle}>You are now ready to accept company invitation</Typography>
        <Button variant='contained' onClick={onAcceptCompanyInviteClicked}>Accept invitation</Button>
      </Paper>
    </SceneWrapper>
  );
}
