import React from 'react';
import { useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import firebaseApp from '../firebase-app';

let lastLocationPath = '';

const useStyles = makeStyles(theme => ({
  root: {
    // Compensate for AppBar height
    marginTop: 67,
    display: 'flex',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(6),
    backgroundColor: theme.palette.background.main,
    flex: '1 1',
    alignSelf: 'stretch',
    justifyContent: 'center'
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      paddingTop: 30
    }
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      padding: theme.spacing(1),
      paddingTop: 30
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%'
  }
}));

export default function SceneWrapper ({ children, width, ...otherProps }) {
  const classes = useStyles();
  const match = useRouteMatch();
  const locationPath = match.path;

  if (locationPath !== lastLocationPath) {
    if (window.cookieconsent.consentDebugger.userConsent.acceptedLevels.tracking) {
      console.debug('Tracking screen view', locationPath);
    }
    firebaseApp.analytics().setCurrentScreen(locationPath);
    lastLocationPath = locationPath;
  }

  return (
    <div className={classes.root} {...otherProps}>
      <div className={classes.content} style={{ width: width || 1200 }}>
        {children}
      </div>
    </div>
  );
}
