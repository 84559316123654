import React, { useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Candidate from '../candidate';
import { AuthContext } from '../../contexts/auth';

export default function MyProfile ({ location, match }) {
  const { isAnonymous, account, accountLoading } = useContext(AuthContext);

  if (!account && accountLoading) {
    return (
      <CircularProgress />
    );
  }

  return (
    <div>
      <Candidate
        myProfile
        location={location}
        isAnonymous={isAnonymous}
        key={account.id}
        id={account.id}
        expanded
        match={match}
        {...account.data()}
      />
    </div>
  );
}
