import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: -24,
    marginRight: -24
  },
  dialogRoot: {
    overflowY: 'visible',
    width: '80%'
  },
  dialogTitle: {
    paddingRight: 24 + theme.spacing(6)
  },
  dialogContentRoot: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  table: {
    tableLayout: 'fixed'
  },
  title: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  link: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    direction: 'rtl',
    paddingRight: theme.spacing(1)
  },
  listItem: {
    borderBottomColor: theme.palette.lightText.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    marginBottom: theme.spacing(1),
    paddingBottom: 5
  },
  helpExpansionRoot: {
    margin: 0
  },
  helpExpansionContent: {
    alignItems: 'center'
  },
  shareDialogPaper: {
    overflowY: 'hidden'
  }
}));

export default function DialogShareProfile ({ displayName, profile, applications, handleClose, open }) {
  const classes = useStyles();

  const clearAndHandleClose = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        onClose={clearAndHandleClose}
        open={open}
        fullWidth
        maxWidth='md'
        classes={{
          paper: classes.shareDialogPaper
        }}
      >
        <DialogTitle id='simple-dialog-title' className={classes.dialogTitle}>
          Applications for {displayName} : {profile.title}
          <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {applications && Object.keys(applications).length === 0
            ? <div>
              <p>There are no active applications for this profile.</p>
            </div>
            : null}

          {applications && Object.keys(applications).length > 0
            ? <div>
              {Object.keys(applications).map(application => {
                return (
                  <div className={classes.listItem}>
                    <h3>{applications[application].jobTitle}</h3>
                    <div>
                      <b>Description:</b>
                      <br />{applications[application].jobDescription?.substring(0, 200)}...
                    </div>
                    <div>
                      <b>Motivation:</b>
                      <br />{applications[application].motivation}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <Link className={classes.link} to={`/companies/${applications[application].jobCompanyId}/submit-application/${applications[application].jobId}`}>Go to the job</Link>
                    </div>
                  </div>
                );
              })}
            </div>
            : null}

        </DialogContent>

      </Dialog>

    </>
  );
}
