import React, { useState } from 'react';
import CustomSharedSlideInCandidate from './custom-shared-slide-in-candidate';
import NoDataInformation from '../../components/no-data-information';

import {
  Pagination,
  connectHits
} from 'react-instantsearch-dom';
import SharedCandidate from '../algolia-shared-candidate';

function AlgoliaSharedProfiles ({ location, match, currentUser, handleDeleteClick }) {

  const [showCandidateData, setShowCandidateData] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);

  const CustomSharedHits = (props) => {
    const { hits } = props;
    if (hits && hits.length > 0){

      return (
        <>
          {hits.map(hit => {
            const doc = hit;

              return (
                <React.Fragment key={doc.id}>
                  {doc && match
                    ? <SharedCandidate
                      canDelete
                      key={doc.id}
                      id={doc.id}
                      match={match}
                      bindAlgoliaProfile={bindAlgoliaProfile}
                      {...doc}
                    /> : null}
                </React.Fragment>
              );
            })}
          <Pagination shape='rounded' />
        </>
       );
    } else {
      return (
        <div style={{ marginTop: 100 }}>
          <NoDataInformation
            height={200}
            width={660}
            title='When someone <b>shares a CV with you</b>'
            subTitle='Here is where you will be able to find it'
            image='/app-img/empty-shared-with-me.png'
          />
        </div>
      );
    }
  }

  const CustomHitsWrapper = connectHits(CustomSharedHits);

  const bindAlgoliaProfile = async (candidateId) => {
    setShowCandidateData(true);
    setCurrentCandidate(candidateId);
  }

  const closeDrawer = () => {
    setShowCandidateData(false);
    setCurrentCandidate(null);
  }

  const localHandleDelete = async (accountId, sharedProfileId) => {
    await handleDeleteClick(accountId, sharedProfileId);
    setShowCandidateData(false);
  }

  return (
    <>
      <div className='search-results'>
        <CustomHitsWrapper bindAlgoliaProfile={bindAlgoliaProfile} match={match} />
      </div>

      <CustomSharedSlideInCandidate
        canDelete
        candidateId={currentCandidate}
        accountId={currentUser.id}
        match={match}
        show={showCandidateData}
        toggleDrawer={closeDrawer}
        handleDeleteClick={localHandleDelete}
      />

    </>
  );
}

export default AlgoliaSharedProfiles;
