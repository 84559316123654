import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ListItemSecondaryAction } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import get from 'lodash.get';
import TextField from '@material-ui/core/TextField';

import Avatar, { SIZE } from '../avatar';
import useForm from '../../hooks/use-form';
import firebaseApp from '../../firebase-app';
import { addInvite, deleteMember, deleteInvite, touchInvite } from '../../actions/job';
import Confirm from '../confirm';
import { AuthContext } from '../../contexts/auth';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DialogsAndToastsContext } from '../../contexts/dialogs-and-toasts';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    overflowY: 'visible'
  },
  dialogContentRoot: {
    overflowY: 'visible'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const initialValues = {
  id: '',
  emails: []
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email(v => `${v.value} is not a valid email address`)
    .required()
});

export default function DialogAddUser ({ jobId, companyId, onClose, open }) {
  const { account } = useContext(AuthContext);
  const classes = useStyles();
  const [values, onValueChange, setValues, isValid, errors] = useForm(initialValues, schema);

  const [companyPrivateDocRef] = useDocument(
    firebaseApp.db.doc(`companies/${companyId}/private_data/private_document`)
  );
  const companyPrivateDoc = companyPrivateDocRef && companyPrivateDocRef.data();

  const [jobPrivateDocRef] = useDocument(
    firebaseApp.db.doc(`companies/${companyId}/jobs/${jobId}/private_data/private_document`)
  );
  const jobPrivateDoc = jobPrivateDocRef ? jobPrivateDocRef.data() : {};
  const [jobInvitesRef] = useCollection(
    firebaseApp.db.collection(`companies/${companyId}/jobs/${jobId}/invites`)
  );
  const jobInvites = jobInvitesRef ? jobInvitesRef.docs : [];
  const { showToast } = useContext(DialogsAndToastsContext);

  const handleClose = () => {
    onClose(values);
    setValues(initialValues);
  };

  const handleAdd = async () => {
    try {
      await addInvite(companyId, jobId, account, values.email, values.id);
      await touchInvite(companyId, jobId, values.email);
    } catch (e) {
      showToast({ variant: 'error', message: "The user you're trying to invite doesn't belong to your company." })
    }

    onClose(values);
  };

  const handleAutocompleteChange = (e, v) => {
    if (v !== undefined && v.value !== undefined) {
      const persistValues = { ...values };

      persistValues.email = v.value;
      persistValues.id = v.id;

      setValues(persistValues);
    }
  }

  const handleDeleteClick = async (idOrEmail, isInvite) => {
    if (!isInvite) {
      await deleteMember(companyId, jobId, idOrEmail);
    } else if (isInvite) {
      await deleteInvite(companyId, jobId, idOrEmail);
    }
  }

  let suggestions =
    companyPrivateDoc && companyPrivateDoc.accounts
      ? Object.keys(companyPrivateDoc.accounts).map((accountKey) => {
        return { id: accountKey, name: companyPrivateDoc.accounts[accountKey].displayName, value: companyPrivateDoc.accounts[accountKey].email, role: companyPrivateDoc.accounts[accountKey].role }
      })
      : [];

  suggestions = suggestions.filter((item) => {
    return item.role == 'viewer' || item.role == 'manager';
  });

  const userRole = get(jobPrivateDoc, `accounts[${account ? account.id : ''}].role`, 'viewer');
  const mayEdit = userRole === 'editor' || userRole === 'owner';

  const tableRows = [];
  if (jobPrivateDoc.accounts) {
    Object.keys(jobPrivateDoc.accounts).forEach(id => {
      const jobAccount = jobPrivateDoc.accounts[id];
      tableRows.push({
        id,
        displayName: jobAccount.displayName,
        email: jobAccount.email,
        photo: jobAccount.photo
      });
    });
  }

  jobInvites.forEach(invite => {
    const data = invite.data();
    tableRows.push({
      isInvite: true,
      displayName: data.name,
      email: data.email,
    });
  });
  tableRows.sort((a, b) => {
    if (a.displayName > b.displayName) {
      return 1;
    }
    if (a.displayName < b.displayName) {
      return -1;
    }
    return 0;
  });

  const suggestionsFiltered = [];

  suggestions.forEach(suggestion => {
    let pass = false;

    for (var i = 0, j = tableRows.length; i < j; i += 1) {
      if (tableRows[i].id === suggestion.id) {
        pass = true;
      }
    }

    if (!pass) {
      suggestionsFiltered.push(suggestion);
    }
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      classes={{ paper: classes.dialogRoot }}
    >
      <DialogTitle id='simple-dialog-title'>
        Add users
        <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        Share view/management access to this job with collegues and recruiting partners.
      </DialogContent>

      <DialogContent dividers>
        <h1>Have access</h1>
        <List>
          {tableRows.map(row => {
            const isSelf = account ? row.id === account.id : true;
            return (
              <ListItem button key={row.id || row.email}>
                <ListItemAvatar>
                  <Avatar photo={row.photo} displayName={row.displayName} size={SIZE.BIG} />
                </ListItemAvatar>
                <ListItemText primary={row.email} secondary={row.isInvite ? `Pending invite` : ``} />
                {!isSelf && mayEdit ? (
                  <ListItemSecondaryAction>
                    <Confirm
                      title={`Are you sure you want to remove ${row.isInvite ? row.email : row.displayName} from the job?`}
                    >
                      {confirm => (
                        <IconButton aria-label='delete' size='small' onClick={confirm(() => handleDeleteClick(row.isInvite ? row.email : row.id, row.isInvite))}>
                          <Delete />
                        </IconButton>
                      )}
                    </Confirm>
                  </ListItemSecondaryAction>) : null}
              </ListItem>
            );
          })}
        </List>
      </DialogContent>

      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <form>
          <Grid container direction='row' justify='space-between' alignItems='center'>
            <Grid item style={{ flex: 1, marginRight: 8 }}>

              {companyPrivateDoc && companyPrivateDoc.accounts
                ? <Autocomplete
                  id='invite-company-users'
                  options={suggestionsFiltered}
                  classes={{
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.value}
                  autoComplete={false}
                  onChange={handleAutocompleteChange}
                  renderOption={(option) => (
                    <>
                      {option.name} <small style={{ marginLeft: 10 }}><b>({option.value})</b></small>
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Invite someone'
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                  />
                : null}

            </Grid>
            <Grid item style={{ marginTop: 4 }}>
            </Grid>
          </Grid>
        </form>
        <p><small><em>Administrator roles have full access to all jobs and applications.</em></small></p>
      </DialogContent>

      <DialogActions>
        <Button disabled={!isValid} onClick={handleAdd} variant='contained' color='primary'>
          ADD
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogAddUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
