import React, { useState } from 'react';
import Candidate from '../algolia-candidate';
import CustomSlideInCandidate from './custom-slide-in-candidate';
import NoDataInformation from '../../components/no-data-information';

import {
  Hits,
  Pagination,
  connectHits
} from 'react-instantsearch-dom';

function AlgoliaProfiles ({ location, match, currentUser, company }) {

  const [showCandidateData, setShowCandidateData] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);

  const bindAlgoliaProfile = async (candidateId) => {
    setShowCandidateData(true);
    setCurrentCandidate(candidateId);
  }

  const closeDrawer = () => {
    setShowCandidateData(false);
    setCurrentCandidate(null);
  }

  const CustomHits = (props) => {
    const { hits, company } = props;
    if (hits && hits.length > 0){

      return (
        <>
          {hits.map(hit => {
            const doc = hit;

              return (
                <React.Fragment key={doc.id}>
                  {doc && match
                    ? <Candidate
                      canDelete
                      companyId={company ? company.id : null}
                      key={doc.id}
                      id={doc.id}
                      match={match}
                      bindAlgoliaProfile={bindAlgoliaProfile}
                      {...doc}
                    /> : null}
                </React.Fragment>
              );
            })}
            <Pagination shape='rounded' />
        </>
       );
    } else {
      return (
        <div style={{ marginTop: 100 }}>
          <NoDataInformation
            title='Do you have <b>candidate CVs to share?</b>'
            actionTitle='Create a profile'
            subTitle='Upload them here and start sharing!'
            to='/share/create-candidate'
            bullets={[
              'Add candidate profiles',
              'Share links'
            ]}
            image='/app-img/empty-our-people.png'
          />
        </div>
      );
    }
  }

  const CustomHitsWrapper = connectHits(CustomHits);

  const Hit = (props) => {
    const { hit } = props;
    const doc = hit;

    return (
      <>
        {doc && match
          ? <Candidate
            canDelete
            companyId={company ? company.id : null}
            key={doc.id}
            id={doc.id}
            match={match}
            bindAlgoliaProfile={bindAlgoliaProfile}
            {...doc}
          /> : null}
      </>
    );
  }

  return (
    <>
      <div className='search-results'>
        <CustomHitsWrapper company={company} />
      </div>

      <CustomSlideInCandidate
        canDelete
        candidateId={currentCandidate}
        companyId={company.id}
        match={match}
        show={showCandidateData}
        toggleDrawer={closeDrawer}
      />

    </>
  );
}

export default AlgoliaProfiles;
