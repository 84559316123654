import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import MuiPhoneNumber from 'material-ui-phone-number';

import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import useForm from '../../hooks/use-form';
import CircularProgress from '@material-ui/core/CircularProgress';

const initialValues = {
  displayName: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: ''
};

const schema = yup.object().shape({
  displayName: yup
    .string()
    .label('Name')
    .required()
    .min(3, 'You need to enter a longer name.'),
  email: yup
    .string()
    .email()
    .label('Name')
    .required()
    .min(3, 'You need to enter a longer email.'),
  phone: yup
    .string()
    .label('Name')
    .required()
    .min(3, 'You need to enter a valid phone.'),
  password: yup
    .string()
    .label('Password')
    .required()
    .min(2, 'Seems a bit short...'),
  confirmPassword: yup
    .string()
    .required()
    .label('Confirm password')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    })
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  },
  redText: {
    fontSize: 11,
    color: '#f00'
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

export default function LoggedOutRegister ({ errorMessage, registerUser, loggingIn }) {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors] = useForm(initialValues, schema);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const loginHandler = (e) => {
    e.preventDefault();
    registerUser(values);
  }

  const toggleTerms = (e) => {
    setAcceptTerms(!acceptTerms);
  }

  const handlePhoneChange = (value) => {
    setValues({ ...values, phone: value });
  }

  return (
    <div>
      <form action='/register' method='post'>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <TextField
              name='displayName'
              type='displayName'
              value={values.displayName}
              label='Name'
              placeholder='Name'
              fullWidth
              variant='outlined'
              required
              onChange={onValueChange}
              helperText={errors.has('displayName') && errors.get('displayName').error.message}
              error={errors.has('displayName')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <TextField
              name='email'
              type='email'
              value={values.email}
              label='Email'
              placeholder='Title'
              fullWidth
              variant='outlined'
              required
              onChange={onValueChange}
              helperText={errors.has('email') && errors.get('email').error.message}
              error={errors.has('email')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <MuiPhoneNumber
              name='phone'
              type='phone'
              value={values.phone}
              label='Phone'
              placeholder='Phone'
              fullWidth
              variant='outlined'
              required
              onChange={handlePhoneChange}
              helperText={errors.has('phone') && errors.get('phone').error.message}
              error={errors.has('phone')}
              defaultCountry='us'
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <TextField
              name='password'
              type='password'
              value={values.password}
              label='Password'
              placeholder='Password'
              fullWidth
              variant='outlined'
              required
              onChange={onValueChange}
              helperText={errors.has('password') && errors.get('password').error.message}
              error={errors.has('password')}
            />
            <TextField
              name='confirmPassword'
              type='password'
              value={values.confirmPassword}
              label='Confirm Password'
              placeholder='Password'
              fullWidth
              variant='outlined'
              required
              style={{ marginTop: 15 }}
              onChange={onValueChange}
              helperText={errors.has('confirmPassword') && errors.get('confirmPassword').error.message}
              error={errors.has('confirmPassword')}
            />

          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>
            <p className='firebaseui-tos firebaseui-tospp-full-message'>
              <Checkbox
                color='primary'
                onChange={toggleTerms}
              />
              <small>
                By continuing, you are indicating that you accept our <a href='/terms-of-service' className='firebaseui-link firebaseui-tos-link' target='_blank'>Terms of Service</a> and <a href='/privacy-policy' className='firebaseui-link firebaseui-pp-link' target='_blank'>Privacy Policy</a>.
              </small>
            </p>

          </Grid>
        </Grid>

        {errorMessage ? <p className={classes.redText}>{errorMessage}</p> : null}

        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm md />

          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={loginHandler}
              variant='contained'
              color='primary'
              disabled={!isValid || !acceptTerms}
              fullWidth
            >
              {!loggingIn ? <>Sign up</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid>
        </Grid>

      </form>
    </div>
  );
}
