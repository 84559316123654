import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import useForm from '../../hooks/use-form';
import CircularProgress from '@material-ui/core/CircularProgress';

const initialValues = {
  email: '',
  password: ''
};

const schema = yup.object().shape({
//  email: yup.string().email(),
//  password: yup.string().min(6, 'Password needs to be at least 6 characters long'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  },
  redText: {
    fontSize: 11,
    color: '#f00'
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

export default function LoggedOut ({ errorMessage, logUserIn, loggingIn }) {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors] = useForm(initialValues, schema);

  const loginHandler = (e) => {
    e.preventDefault();
    logUserIn(values.email, values.password);
  }

  return (
    <div>
      <form action='/login' method='post'>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <TextField
              name='email'
              type='email'
              value={values.email}
              label='Email'
              placeholder='Title'
              fullWidth
              variant='outlined'
              required
              onChange={onValueChange}
              helperText={errors.has('email') && errors.get('email').error.message}
              error={errors.has('email')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }} justify='space-between'>
          <Grid item xs={12} sm={12} md={12}>

            <TextField
              name='password'
              type='password'
              value={values.password}
              label='Password'
              placeholder='Title'
              fullWidth
              variant='outlined'
              required
              onChange={onValueChange}
              helperText={errors.has('password') && errors.get('password').error.message}
              error={errors.has('password')}
            />
          </Grid>
        </Grid>
        {errorMessage ? <p className={classes.redText}>{errorMessage}</p> : null}

        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm md />

          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={loginHandler}
              variant='contained'
              color='primary'
              disabled={!isValid}
              fullWidth
            >
              {!loggingIn ? <>Log in</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid>
        </Grid>

      </form>
    </div>
  );
}
