import React, {useContext, useCallback, useEffect, useState} from 'react';
import get from 'lodash.get';
import SharedWithMeItem from './shared-with-me-item';
import {AuthContext} from '../../contexts/auth';
import NoDataInformation from '../../components/no-data-information';
import firebaseApp from '../../firebase-app';
import {DialogsAndToastsContext} from '../../contexts/dialogs-and-toasts';
import {useCollection, useDocument} from 'react-firebase-hooks/firestore';
import AlgoliaSharedProfiles from './algolia-shared-profiles';
import {
  deleteSharedWithMeLink
} from '../../actions/account';

export default function SharedWithMe ({match}) {
  const {handleViewCv, showToast} = useContext(DialogsAndToastsContext);
  const {account} = useContext(AuthContext);
  const {expandedId, autoOpenLinkId} = match.params;
  const [viewItem, setViewItem] = useState(false);

  const isValidAccount = account && account.data();
  const hasAlgoliaKey = isValidAccount && account.data().algoliaSharedWithMeKey !== undefined;

  const [sharedProfiles, loading] = useCollection(
    isValidAccount && !hasAlgoliaKey ? firebaseApp.db.collection(`accounts/${account.id}/sharedProfiles`) : null
  );

  const [sharedProfile] = useDocument(
    autoOpenLinkId && hasAlgoliaKey ? firebaseApp.db.doc(`accounts/${account.id}/sharedProfiles/${autoOpenLinkId}`) : null
  );

  useEffect(() => {
    if (!viewItem && sharedProfile) {
      const sharedWithMeItem = sharedProfile.data();

      if (sharedWithMeItem) {
        handleViewCv(sharedWithMeItem.cvPath, `${sharedWithMeItem.displayName}`);
        setViewItem(true);
      }
    }
  }, [sharedProfile, viewItem]);


  const handleDeleteClick = async (profileKey, successMessage) => {
    try {
      await deleteSharedWithMeLink(account.id, profileKey);

      setTimeout( function(){
        window.location.reload();
        showToast({
          message: successMessage
        });
      }, 500);
    } catch (e) {
      showToast({
        variant: 'warning',
        message: 'Could not delete item'
      });
    }
  }

  if (loading) {
    return (
      <div style={{ marginTop: 100 }}>
      </div>
    );
  }

  if (!account || ((!sharedProfiles || !sharedProfiles.docs.length || sharedProfiles.docs.length === 0) && account && account.data() && account.data().algoliaSharedWithMeKey === undefined)) {
    return (
      <div style={{ marginTop: 100 }}>
        <NoDataInformation
          height={200}
          width={660}
          title='When someone <b>shares a CV with you</b>'
          subTitle='Here is where you will be able to find it'
          image='/app-img/empty-shared-with-me.png'
        />
      </div>
    );
  }

  return (
    <>
      {account && account.data() && account.data().algoliaSharedWithMeKey != '' && account.data().algoliaSharedWithMeKey !== undefined
        ? <AlgoliaSharedProfiles
            currentUser={account}
            match={match}
            handleDeleteClick={handleDeleteClick}
          />
        : <>
          {sharedProfiles && sharedProfiles.docs && sharedProfiles.docs.map(sharedProfile => {
            const doc = sharedProfile.data();

            return (
              <SharedWithMeItem
                key={sharedProfile.id}
                profileKey={sharedProfile.id}
                accountId={account.id}
                displayName={doc.displayName}
                photo={doc.photo}
                profile={doc}
                expanded={expandedId === sharedProfile.id}
                autoOpen={sharedProfile.id === autoOpenLinkId}
                handleViewCv={handleViewCv}
                handleDeleteClick={() => handleDeleteClick(sharedProfile.id, `Deleted shared CV "${doc.displayName} - ${doc.title}"`)}
              />
            );
          })}
        </>}
    </>
  );
}
