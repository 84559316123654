import firebase from 'firebase/app';
import uuidv4 from 'uuid/v4';
import path from 'path';
import firebaseApp from '../firebase-app';
import { isValidCountry } from '../components/utils/countries';
import * as crypto from 'crypto';

import {
  TAX_ENUM,
  STANDARD_PRODUCT_ID
} from '../components/stripe/consts';

const companyRoleToPriority = {
  viewer: 0,
  manager: 1,
  administrator: 2
};

const md5 = (contents) => crypto.createHash('md5').update(contents).digest("hex");

export async function createCompany (company, account) {
  try {
    const batch = firebaseApp.db.batch();
    const accountData = account.data();
    const apiKey = uuidv4();
    const apiKeyHash = md5(apiKey);
    if (!isValidCountry(company.country)) {
      throw new Error('Selected company is not in the allowed list');
    }

    const companyRef = firebaseApp.db.collection('companies').doc();
    batch.set(companyRef, {
      ...company,
      apiKeyHash: apiKeyHash,
      created: firebase.firestore.FieldValue.serverTimestamp()
    });

    const taxEnum = TAX_ENUM.find(item => item.country == company.country)

    if (taxEnum !== undefined && taxEnum !== null) {
      batch.update(companyRef, {
        taxEnum: taxEnum.enum
      });
    }

    const stripeRef = firebaseApp.db.collection(`companies/${companyRef.id}/private_data/`).doc('stripe');
    batch.set(stripeRef, {
      active: false,
      planId: STANDARD_PRODUCT_ID,
      personCount: 0
    });

    const stripeRefPriv = firebaseApp.db.collection(`companies/${companyRef.id}/private_data/`).doc('stripePriv');
    batch.set(stripeRefPriv, {
      active: false,
      cvs: 0,
      links: 0,
      planId: 'free',
    });

    const aclRef = firebaseApp.db.collection(`companies/${companyRef.id}/private_data/`).doc('private_document');
    batch.set(aclRef, {
      accounts: {
        [account.id]: {
          displayName: accountData.displayName,
          email: accountData.email,
          photo: accountData.photo || null,
          role: 'administrator',
        }
      },
      apiKey: apiKey
    });

    // Add company id to account so that it is easy to access from the account
    batch.update(
      account.ref,
      'companies',
      firebase.firestore.FieldValue.arrayUnion(companyRef.id)
    );

    batch.update(account.ref, {
      currentCompany: companyRef.id,
      usageType: 'company-w'
    });

    await batch.commit();
    return companyRef.id;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function updateCompany (companyId, values, handleProgress, handleError, handleSuccess) {
  try {
    const companyRef = firebaseApp.db.doc(`companies/${companyId}`);
    const taxEnum = TAX_ENUM.find(item => item.country == values.country)

    if (taxEnum !== undefined && taxEnum !== null) {
      values.taxEnum = taxEnum.enum
    }

    if (values.logo instanceof FileList) {
      const file = values.logo[0];
      const storageRef = firebaseApp.storage.ref();
      const uid = uuidv4();
      const fileEnding = path.extname(file.name);
      const fullPath = `companies/${companyId}/${uid}${fileEnding}`;
      const uploadTask = storageRef.child(fullPath).put(file);
      uploadTask.on('state_changed',
        handleProgress,
        handleError,
        handleSuccess
      );
      const uploadResult = await uploadTask;
      values.logo = await uploadResult.ref.getDownloadURL();
    }

    return await companyRef.update({ ...values });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function editMember (companyId, id, role) {
  const privateDocPath = `companies/${companyId}/private_data/private_document`;
  try {
    const privateDocRef = firebaseApp.db.doc(privateDocPath);
    return await privateDocRef.update({
      [`accounts.${id}.role`]: role
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteMember (companyId, id) {
  const privateDocPath = `companies/${companyId}/private_data/private_document`;
  try {
    const privateDocRef = firebaseApp.db.doc(privateDocPath);
    return await privateDocRef.update({
      [`accounts.${id}`]: firebase.firestore.FieldValue.delete()
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteInvite (companyId, email) {
  const invitePath = `companies/${companyId}/invites/${email}`;
  try {
    return await firebaseApp.db.doc(invitePath).delete();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function checkAccountIsMember (companyId, accountId, role = null) {
  const privateDocPath = `companies/${companyId}/private_data/private_document`;
  try {
    const privateDocRef = await firebaseApp.db.doc(privateDocPath).get();
    const privateDocData = privateDocRef.exists && privateDocRef.data();
    return (
      privateDocData &&
      privateDocData.accounts[accountId] &&
      (role === null || privateDocData.roles[accountId] === role)
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function checkEmailIsMemberOfCompany (companyId, email, role = null) {
  const privateDocPath = `companies/${companyId}/private_data/private_document`;
  try {
    const privateDocRef = await firebaseApp.db.doc(privateDocPath).get();
    const privateDocData = privateDocRef.exists && privateDocRef.data();
    const account = privateDocData && privateDocData.accounts.find(account => account.email === email);
    if (account && (role === null || companyRoleToPriority[account.role] >= companyRoleToPriority[role])) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function addInvite (companyId, byAccountRef, name, email, role) {
  try {
    const byAccount = byAccountRef.data();
    const rand = uuidv4();
    const invitedBy = {
      displayName: byAccount.displayName,
      photo: byAccount.photo || null
    };

    const inviteData = {
      companyId: companyId,
      name,
      email,
      role,
      code: rand,
      invitedBy: invitedBy
    };

    return await firebaseApp.functions.httpsCallable('onCompanyInviteCreatedCallable')(inviteData);

  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function acceptCompanyInvite (companyId, code) {
  return firebaseApp.functions.httpsCallable('acceptCompanyInvite')({
    code,
    companyId
  });
}

export function handleJoinRequest ({requestId, role, action}) {
  return firebaseApp.functions.httpsCallable('handleJoinRequest')({
    requestId,
    role,
    action
  });
}


export function changeSelectedCompany (companyId, accountId) {
  try {
    const accountRefPath = `/accounts/${accountId}`;
    const accountRef = firebaseApp.db.doc(accountRefPath);

    accountRef.update({
      currentCompany: companyId
    }).then(function () {
      if (window.location.href.indexOf('/share/') === -1) {
        window.location.href = '/share/our-people/';
      }
    });

  } catch (e) {
    console.error(e);
    throw e;
  }
}
