import React from 'react';
import { Link } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import CompanyCreate from './company-create';
import Paper from '../paper';
import firebaseApp from '../../firebase-app';
import Avatar, { SIZE } from '../avatar';

export default function Company ({ match }) {
  const { companyId } = match.params;
  const [companyRef, companyLoading, companyError] = useDocument(firebaseApp.db.doc(`companies/${companyId}`));
  const company = companyRef && companyRef.data();

  if (companyId === 'create' || (!company && !companyLoading)) {
    return (
      <CompanyCreate />
    );
  }

  if (!company && companyError) {
    return (
      <Paper>
        <h1>Doh, we had a mishap</h1>
        {companyError.message}
      </Paper>
    );
  }

  return (
    <Paper>
      {company ? <Grid container alignItems='center'>
        <Grid item>
          <Avatar size={SIZE.BIG} displayName={company.name} photo={company.logo} />
        </Grid>
        <Grid item>
          <h1 style={{ margin: 0, marginLeft: 4, marginRight: 80 }}>{company.name}</h1>
        </Grid>
      </Grid> : null}

      <Divider />

      <Link to={`${match.url}/details`} className='action-link margin'>Edit company details</Link>

      <div className='document-link'>2 Open jobs (16 Applicants)</div>
      <div className='document-link'>4 Candidates</div>
      <div className='document-link'>4 Employees</div>

    </Paper>
  );
}
