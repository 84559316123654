import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkActionCode } from '../../actions/account';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  },
  redText: {
    fontSize: 11,
    color: '#f00'
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

export default function LoggedOutVerifyNewUserEmailChange ({ errorMessage, handleRecoverEmail, loggingIn, props, disabledInputs, setErrorMessage }) {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const searchParams = new URLSearchParams(window.location.search);
  const oobCode = searchParams.get('oobCode');
  const [userEmail, setUserEmail] = useState('');
  const [fromUserEmail, setFromUserEmail] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chkAC = await checkActionCode(oobCode);

        setFromUserEmail(chkAC.data.fromEmail);
        setUserEmail(chkAC.data.email);
      } catch (e) {
        setErrorMessage(e.message);
      }
    };

    fetchData();
  }, [oobCode, setErrorMessage]);

  const recoverEmail = (e) => {
    e.preventDefault();

    handleRecoverEmail(oobCode);
  }

  return (
    <div>
      <form action='/verify-change' method='post'>
        <div>
          <div style={{ textAlign: 'center', margin: '25px 0 45px 0' }}>
            <img src='/app-img/error_icon.png' alt='Invalid link' />
          </div>
          <p>
            This page will help you recover your user email. Email to recover <b>{userEmail}</b>, current email <b>{fromUserEmail}</b>
          </p>
        </div>

        {errorMessage ? <p className={classes.redText}>{errorMessage}</p> : null}

        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm md />

          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={recoverEmail}
              variant='contained'
              color='primary'
              disabled={disabledInputs}
              fullWidth
            >
              {!disabledInputs ? <>Recover email</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid>
        </Grid>

      </form>
    </div>
  );
}
