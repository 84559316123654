import React, { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import useForm from '../../hooks/use-form';
import { createCompany } from '../../actions/company';
import SceneWrapper from '../scene-wrapper';
import Paper from '../paper';
import { AuthContext } from '../../contexts/auth';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';

import { isoCountries } from '../utils/countries';

const initialValues = {
  name: '',
  phone: '',
  website: '',
  logo: '',
  taxId: '',
  country: '',
  state: '',
  city: '',
  zip: '',
  address: '',
  address2: ''
};

const schema = yup.object().shape({
  name: yup.string()
    .min(3, 'Title needs to be at least 3 characters long'),
  website: yup.string().url(),
  taxId: yup.string(),
  country: yup.string()
    .min(3, 'Country name is required'),
  state: yup.string()
    .min(3, 'State name is required'),
  city: yup.string()
    .min(3, 'City name is required'),
  zip: yup.number()
    .min(5, 'ZIP is required'),
  address: yup.string()
    .min(5, 'Address is required')
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35,
    border: '1px solid rgb(232, 233, 234)'
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  formInput: {
    marginTop: 8,
    marginBottom: 14,
    width: '100%'
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

export default function CompanyCreateForm ({ match, localCreateNewCompany, goBack, onboardingStep, accountEmail, loading, setLoading }) {
  const classes = useStyles();
  const { account } = useContext(AuthContext);
  // eslint-disable-next-line no-unused-vars
  const [values, onValueChange, setValues, isValid, errors] = useForm(initialValues, schema);
  const [redirect, setRedirect] = useState();

  const validateThenCreateCompany = async () => {
    setLoading(true);
    if (isValid) {
      const companyId = await createCompany(
        {
          ...values,
          created: new Date()
        },
        account
      );
      //setRedirect(`/companies/${companyId}/details`);

      localCreateNewCompany(companyId);
    }

    setLoading(false);
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <form style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
      <TextField
        name='name'
        value={values.name}
        label='Full company name'
        placeholder='Full company name'
        fullWidth
        variant='outlined'
        onChange={onValueChange}
        required
        helperText={errors.has('name') && errors.get('name').error.message}
        error={errors.has('name')}
        className={classes.formInput}
      />

      <TextField
        name='accountEmail'
        defaultValue={accountEmail}
        label='Company email'
        placeholder='Email'
        disabled={true}
        fullWidth
        variant='outlined'
        onChange={onValueChange}
        required
        className={classes.formInput}
        style={{ background: '#fafafa' }}
      />

      <TextField
        name='taxId'
        value={values.taxId}
        label='Tax ID'
        placeholder='Tax ID'
        fullWidth
        variant='outlined'
        onChange={onValueChange}
        required
        helperText={errors.has('taxId') && errors.get('taxId').error.message}
        error={errors.has('taxId')}
        className={classes.formInput}
      />

      <TextField
        fullWidth
        variant='outlined'
        name='phone'
        label='Phone number'
        value={values.phone}
        onChange={onValueChange}
        margin='normal'
        helperText={errors.has('phone') && errors.get('phone').error.message}
        error={errors.has('phone')}
        className={classes.formInput}
      />

      <TextField
        fullWidth
        variant='outlined'
        name='website'
        label='Website'
        value={values.website}
        onChange={onValueChange}
        margin='normal'
        helperText={errors.has('website') && errors.get('website').error.message}
        error={errors.has('website')}
        className={classes.formInput}
      />
      <hr />

      <TextField
        name='address'
        value={values.address}
        label='Address'
        placeholder='Address'
        fullWidth
        variant='outlined'
        onChange={onValueChange}
        required
        helperText={errors.has('address') && errors.get('address').error.message}
        error={errors.has('address')}
        className={classes.formInput}
      />

      <TextField
        name='address2'
        value={values.address2}
        label='Address 2'
        placeholder='Address 2'
        fullWidth
        variant='outlined'
        onChange={onValueChange}
        required
        helperText={errors.has('address2') && errors.get('address2').error.message}
        error={errors.has('address2')}
        className={classes.formInput}
      />

      <TextField
        name='zip'
        value={values.zip}
        label='Zip'
        placeholder='Zip'
        fullWidth
        variant='outlined'
        onChange={onValueChange}
        required
        helperText={errors.has('zip') && errors.get('zip').error.message}
        error={errors.has('zip')}
        className={classes.formInput}
      />

      <TextField
        name='city'
        value={values.city}
        label='City'
        placeholder='City'
        fullWidth
        variant='outlined'
        onChange={onValueChange}
        required
        helperText={errors.has('city') && errors.get('city').error.message}
        error={errors.has('city')}
        className={classes.formInput}
      />

      <TextField
        name='state'
        value={values.state}
        label='State'
        placeholder='State'
        fullWidth
        variant='outlined'
        onChange={onValueChange}
        required
        helperText={errors.has('state') && errors.get('state').error.message}
        error={errors.has('state')}
        className={classes.formInput}
      />

      <FormControl variant='outlined'>
        <InputLabel id='countryLabel' htmlFor='outlined-age-native-simple'>Country*</InputLabel>
        <Select
          name='country'
          value={values.country}
          label='Country'
          labelId='countryLabel'
          placeholder='Country'
          fullWidth
          variant='outlined'
          onChange={onValueChange}
          required
          className={classes.formInput}
          error={errors.has('country')}
        >
          {Object.keys(isoCountries).map((item) => (
            <MenuItem key={item} value={isoCountries[item]}>{isoCountries[item]}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 15 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={() => goBack(onboardingStep)}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Back
            </Button>
          </Grid>
        <Grid item xs={12} sm={4} md={4} style={{ textAlign: 'right' }}>
          <Button
            onClick={validateThenCreateCompany}
            variant='contained'
            color='primary'
            fullWidth
            style={{ marginTop: 8 }}
            disabled={!isValid || loading}
          >
            {!loading ? <>Proceed</> : <CircularProgress className={classes.circularIcon} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
