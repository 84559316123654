import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import StarIcon from '@material-ui/icons/Star';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import firebaseApp from '../../firebase-app';
import CreateCompanyForm from '../onboarding/create-company-form';
import AddColleaguesComponent from '../onboarding/add-colleagues-component';

import {
  ONBOARDING_STATUS,
  USAGE_TYPE,
  COMPANY_STATUS_INFO
} from '../../constants/onboarding';

import {
  queryExistingCompanies,
  queryExistingInvites,
  continueWithoutCompanyRequest,
  sendCompanyRequest,
  finishOnboarding,
  chooseAccountUsage,
  chooseCompanyStatus,
  companyCreated,
  almostFinished,
  redirectFurther,
  acceptCompanyInvite,
  goBack
} from '../../actions/onboarding';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: 630,
    borderRadius: 8,
    padding: 40,
    marginTop: 35
  },
  formTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  formSubtitle: {
    fontSize: 18,
    marginBottom: 10
  },
  backButton: {
    padding: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '100%',
        marginBottom: 10
      }
    }
  },
  redText: {
    fontSize: 11,
    color: '#f00'
  },
  radioSubtitle: {
    marginTop: 0,
    marginLeft: 30,
    fontSize: 14,
    color: '#b1b1b1',
    marginBottom: 20
  },
  existingCompany: {
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
    marginBottom: 10
  },
  companyTitle: {
    margin: 0
  },
  companySubtitle: {
    margin: 0,
    color: '#c0c0c0',
  },
  radioWrapper: {
    '& .MuiRadio-root': {
      padding: '4px 9px 0 9px'
    },
    '& .MuiTypography-root': {
      color: '#000'
    }
  },
  onboardingLink: {
    color: '#4579b4',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '& span': {
      marginLeft: 5
    }
  },
  circularIcon: {
    width: '24px !important',
    height: '24px !important'
  }
}));

export default function Onboarding ({ showToast, currentUser, account, user, accountData }) {
  const classes = useStyles();
  const onboardingStep = accountData.onboardingProcess;
  const [existingCompanyValue, setExistingCompanyValue] = useState('');
  const [usageInfoValue, setUsageInfoValue] = useState(USAGE_TYPE.COMPANY_W);
  const [companyStatusInfoValue, setCompanyStatusInfoValue] = useState(COMPANY_STATUS_INFO.COMPANY_NEW);

  const [loading, setLoading] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(true);
  const [invites, setInvites] = useState([]);
  const [invitesLoading, setInvitesLoading] = useState(true);

  const [requestCompany, setRequestCompany] = useState(false);
  const [selectedInvite, setSelectedInvite] = useState(false);

  const [currentCompany, setCurrentCompany] = useState(accountData.currentCompany);
  const [onboardingLoading, setOnboardingLoading] = useState(false);

  // Handle radio button change
  const handleUsageInfoChange = (event) => {
    setUsageInfoValue(event.target.value);
  };

  const handleExistingCompanyValueChange = (event) => {
    setExistingCompanyValue(event.target.value);
  }

  const handleExistingInviteChange = (event) => {
    setSelectedInvite(event.target.value);
  }

  const handleCompanyStatusInfoValueChange = (event) => {
    setCompanyStatusInfoValue(event.target.value);
  }

  // Local functions to that serves as an adapter
  const localSendCompanyRequest = async () => {
    setLoading(true);
    const response = await sendCompanyRequest(existingCompanyValue);

    if (response.data && response.data.company){
      setRequestCompany(response.data.company);
    }

    if (response.success=='false'){
      showToast({
        variant: 'error',
        message: response.data.message
      });
    }

    setLoading(false);
  }

  const localChooseAccountUsage = async () => {
    setLoading(true);
    const response = await chooseAccountUsage(usageInfoValue);

    setLoading(false);
  }

  const localChooseCompanyStatus = async () => {
    setLoading(true);
    const response = await chooseCompanyStatus(companyStatusInfoValue);

    setLoading(false);
  }

  const localCreateNewCompany = async (companyId) => {
    setLoading(true);
    setCurrentCompany(companyId);
    const response = await companyCreated();

    setLoading(false);
  }

  const continueWithoutCompanyInvite = async () => {
    setInvites(null);
  }

  const localAcceptCompanyInvite = async () => {
    setLoading(true);
    const inviteData = invites.filter(obj => {
      return obj.id === selectedInvite
    });
    acceptCompanyInvite(inviteData[0]);

    setLoading(false);
  }

  const localAddColleagues = async () => {
  
  }

  // Onboarding step use Effect
  useEffect(() => {
    async function loadExistingCompanies() {
      if (onboardingStep==ONBOARDING_STATUS.CHECK_FOR_TEAM){
        const response = await queryExistingCompanies();
        const inviteResponse = await queryExistingInvites();

        if ((response.data && response.data.data && response.data.data.length > 0) || (inviteResponse.data && inviteResponse.data.data && inviteResponse.data.data.length > 0)){
          setCompanies(response.data.data);
          setCompaniesLoading(false);
          setInvites(inviteResponse.data.data);
          setInvitesLoading(false);
        } else {
          continueWithoutCompanyRequest();
        }
      }
    }

    loadExistingCompanies();
  }, [onboardingStep]);


  useEffect(() => {
    if (!companiesLoading && !invites && !companies){
      continueWithoutCompanyRequest();
    }
  }, [invites]);

  // This is where the magic happens
  switch (onboardingStep){
    case ONBOARDING_STATUS.CHECK_FOR_TEAM: return checkForTeam(); break;
    case ONBOARDING_STATUS.USAGE_INFO: return usageInfo(); break;
    case ONBOARDING_STATUS.COMPANY_USAGE: return companyUsage(); break;
    case ONBOARDING_STATUS.CREATE_COMPANY: return createCompany(); break;
    case ONBOARDING_STATUS.ADD_COLLEAGUES: return addColleagues(); break;
    case ONBOARDING_STATUS.WRAP_IT_UP: return wrapItUp(); break;
    case ONBOARDING_STATUS.REQUEST_SENT: return requestSent(); break;
    case ONBOARDING_STATUS.CONTACT_ADMIN: return contactAdmin(); break;
    case ONBOARDING_STATUS.ALMOST_DONE: return onboardingFinished(); break;
  }

  // Real components
  // STEP #0
  function checkForTeam(){
    return (
      <>
        <Typography component='h1' className={classes.formTitle}>Welcome {accountData.displayName}!</Typography>
        {companiesLoading ?
          <Grid container>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <CircularProgress />
            </Grid>
          </Grid>
        :
        <>
          {invites && invites.length > 0 ?
          <>
          You have pending invites
            <div>
              <Grid container>
                <RadioGroup className={classes.radioWrapper} aria-label="existing company" name="existing-company" value={selectedInvite} onChange={handleExistingInviteChange} style={{ width: '100%' }}>
                  {invites.map((invite, key)=> {
                    return (
                      <Grid container className={classes.existingCompany} key={key} alignItems="flex-end">
                        <Grid item md={8}>
                          <p className={classes.companyTitle}>
                            <b>{invite && invite.inviteNiceName} by {invite && invite.invitedBy}</b>
                          </p>
                        </Grid>
                        <Grid item md={4} style={{ textAlign: 'right' }}>
                          <FormControlLabel value={invite.id} labelPlacement="start" control={<Radio />} label="Accept invite" />
                        </Grid>
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
              <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
                <Grid item xs={12} sm={8} md={8} style={{ textAlign: 'right' }}>
                  <Button onClick={continueWithoutCompanyInvite}>
                    Continue without invite
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Button
                    onClick={localAcceptCompanyInvite}
                    variant='contained'
                    color='primary'
                    disabled={!selectedInvite || loading}
                    fullWidth
                  >
                    {!loading ? <>Proceed</> : <CircularProgress className={classes.circularIcon} />}
                  </Button>
                </Grid>
              </Grid>

            </div>
          </>
          :
          <>
            {companies && companies.length > 0 ?
              <p>
                Your email domain matches an existing company account on Skilldrop. You can request to be added as a user on any of those accounts.
              </p>
            : null}

            {companies && companies.length > 0 ?
            <div>
              <Grid container>
                <RadioGroup className={classes.radioWrapper} aria-label="existing company" name="existing-company" value={existingCompanyValue} onChange={handleExistingCompanyValueChange} style={{ width: '100%' }}>
                  {companies.map((company, key)=> {
                    return (
                      <Grid container className={classes.existingCompany} key={key} alignItems="flex-end">
                        <Grid item md={8}>
                          <p className={classes.companyTitle}><b>{company.name}</b></p>
                          <p className={classes.companySubtitle}>{company.address}</p>
                          <p className={classes.companySubtitle}>{company.city}</p>
                          <p className={classes.companySubtitle}>{company.email}</p>
                        </Grid>
                        <Grid item md={4} style={{ textAlign: 'right' }}>
                          <FormControlLabel value={company.id} labelPlacement="start" control={<Radio />} label="Request invite" />
                        </Grid>
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
              <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
                <Grid item xs={12} sm={8} md={8} style={{ textAlign: 'right' }}>
                  <Button onClick={continueWithoutCompanyRequest}>
                    Continue without invite
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Button
                    onClick={localSendCompanyRequest}
                    variant='contained'
                    color='primary'
                    disabled={loading}
                    fullWidth
                  >
                    {!loading ? <>Send request</> : <CircularProgress className={classes.circularIcon} />}
                  </Button>
                </Grid>
              </Grid>
            </div>
            :
              <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
                  <Button onClick={continueWithoutCompanyRequest}>
                    Continue without invite
                  </Button>
                </Grid>
              </Grid>}
          </>}
        </>}
      </>
    )
  }

  function usageInfo(){
    return (
      <>
        <Typography component='h1' className={classes.formTitle}>Almost done!</Typography>
        <p>
          How do you intend to use Skilldrop.
        </p>
        <div className={classes.radioButtonGroup}>
          <FormControl component="fieldset">
            <RadioGroup className={classes.radioWrapper} aria-label="usage info" name="usage-info" value={usageInfoValue} onChange={handleUsageInfoChange}>
              <FormControlLabel value={USAGE_TYPE.PERSONAL} control={<Radio />} label="Manage your own CV" />
              <p className={classes.radioSubtitle}>Select this if you are a freelancer or individual who want to use Skilldrop to distribute your own profile.</p>
              <FormControlLabel value={USAGE_TYPE.COMPANY_W} control={<Radio />} label="Manage and send CVs of the candidates you represent" />
              <p className={classes.radioSubtitle}>Select this if you need to upload, manage or distribute other people's CVs/profiles. Common for consulting and sourcing agents/brokers, recuiters, HR etc.</p>
              <FormControlLabel value={USAGE_TYPE.COMPANY_R} control={<Radio />} label="Evaluate and read CVs that other sends to you" />
              <p className={classes.radioSubtitle}>Select this if you are only on the hiring side of the recuitment process. Common for hiring managers.</p>
            </RadioGroup>
          </FormControl>
        </div>
        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={() => goBack(onboardingStep)}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={localChooseAccountUsage}
              variant='contained'
              color='primary'
              fullWidth
              disabled={loading}
            >
              {!loading ? <>Continue</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  function companyUsage(){
    return (
      <>
        <Typography component='h1' className={classes.formTitle}>Almost done!</Typography>
        <p>
          To be able to upload and send candidate CVs that are not your own, you need to have a business account. Business accounts have a free tier!
          {accountData.usageType==USAGE_TYPE.COMPANY_R ?
            <>
              <br/>
              <br/>
              <em>
                You need to have an organisation account to collect all shared CVs in Skilldrop. Don't worry, Skilldrop organisation accounts are free, but please check if your organisation already has a Skilldrop account before creating a new one
              </em>
            </>
          : null}
        </p>
        <div className={classes.radioButtonGroup}>
          <FormControl component="fieldset">
            <RadioGroup className={classes.radioWrapper} aria-label="usage info" name="usage-info" value={companyStatusInfoValue} onChange={handleCompanyStatusInfoValueChange}>
              <FormControlLabel value={COMPANY_STATUS_INFO.COMPANY_NEW} control={<Radio />} label="Our company doesn't have a Skilldrop account yet" />
              <p className={classes.radioSubtitle}>Let's set up a new free account!</p>
              <FormControlLabel value={COMPANY_STATUS_INFO.COMPANY_OLD} control={<Radio />} label="Our company already has a Skilldrop account" />
              <p className={classes.radioSubtitle}>Ask the company administrator to add you as a user to that account.</p>
            </RadioGroup>
          </FormControl>
        </div>
        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={() => goBack(onboardingStep)}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={localChooseCompanyStatus}
              variant='contained'
              color='primary'
              disabled={loading}
              fullWidth
            >
              {!loading ? <>Continue</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  function createCompany(){
    return (
      <>
        <Typography component='h1' className={classes.formTitle}>Create organisation account</Typography>
        <p>It's free!</p>
        <CreateCompanyForm localCreateNewCompany={localCreateNewCompany} goBack={goBack} loading={loading} setLoading={setLoading} onboardingStep={onboardingStep} accountEmail={accountData.email} />
      </>
    )
  }
  
  function addColleagues(){
    return (
      <>
        <Typography component='h1' className={classes.formTitle}>Add your colleagues</Typography>
        <p>We recommend adding at least one more admin to your account. The free tier of Skilldrop includes unlimited users.</p>

        <AddColleaguesComponent localAddColleagues={localAddColleagues} companyId={currentCompany} showToast={showToast} />

        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm={8} md={8}>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={almostFinished}
              variant='contained'
              color='primary'
              disabled={loading}
              fullWidth
            >
              {!loading ? <>Continue</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid>
        </Grid>

      </>
    )
  }

  function wrapItUp() {
    return (
      <>
        <Typography component='h1' className={classes.formTitle}>All set!</Typography>
        <p>
          You can create, upload and share your own profile now.
        </p>
        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={() => goBack(ONBOARDING_STATUS.USAGE_INFO, true)}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={() => redirectFurther('presonalProfiles')}
              variant='contained'
              color='primary'
              fullWidth
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  function requestSent() {
    return (
      <>
        <Typography component='h1' className={classes.formTitle}>Your request has been sent</Typography>
        {accountData.companyJoinRequest ?
          <>
            <p>
              We sent your request to be invited to:
            </p>
            <p>
              Account:
              <br/>
              <b>{accountData.companyJoinRequest.name}</b>
            </p>
            <p>
              Admin names:
              <br/>
              {accountData.companyJoinRequest.adminName}: <b>[{accountData.companyJoinRequest.email}]</b>
            </p>
          </>
        : null}
        <p>
          Until your request has been approved, you will only have limited access to Skilldrop. You can create and upload your own profile, but will not be able to manage or send other people's profiles.
        </p>
        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm={8} md={8}>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={finishOnboarding}
              variant='contained'
              color='primary'
              disabled={loading}
              fullWidth
            >
              {!loading ? <>Continue</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  function contactAdmin() {
    return (
      <>
        <Typography component='h1' className={classes.formTitle}>Next step</Typography>
        <p>
          If your company already has a Skilldrop account, please ask them to invite you.
        </p>
        <p>
          Until you have been invited to the company account, you will only be able to upload and share your own personal profile.
        </p>
        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={() => goBack(ONBOARDING_STATUS.COMPANY_USAGE, true)}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={finishOnboarding}
              variant='contained'
              color='primary'
              fullWidth
              disabled={loading}
            >
              {!loading ? <>Continue</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  function onboardingFinished() {
    return (
      <>
        <Typography component='h1' className={classes.formTitle}>All set!</Typography>
        <p>
          What would you like to do next?
        </p>
        <p>
          <Link to='#' className={classes.onboardingLink} onClick={() => redirectFurther('companyProfiles')}><StarIcon /><span>Create a profile for a candidate you represent</span></Link>
        </p>
        <p>
          <Link to='#' className={classes.onboardingLink} onClick={() => redirectFurther('sharedProfiles')}><StarIcon /><span>View the profiles others has shared with you</span></Link>
        </p>
        <Grid container spacing={1} justify='space-between' alignItems='center' style={{ marginTop: 35 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Button
              onClick={() => goBack(ONBOARDING_STATUS.ADD_COLLEAGUES, true)}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
            <Button
              onClick={finishOnboarding}
              variant='contained'
              color='primary'
              disabled={loading}
              fullWidth
            >
              {!loading ? <>None of the above</> : <CircularProgress className={classes.circularIcon} />}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>{onboardingFinished()}</>
  )
}
