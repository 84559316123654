import React, { useState } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import moment from 'moment';
import { useDocument } from 'react-firebase-hooks/firestore';
import { deleteJob, setTag } from '../actions/job';
import Tag from './tag';
import firebaseApp from '../firebase-app';
import Confirm from './confirm';

const useStyles = makeStyles(theme => ({
  rootPanel: {
    margin: '0 !important',
    cursor: 'default'
  },
  expansionPanelSummaryRoot: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: 8,
    margin: '0 !important',
    minHeight: '0 !important',
    cursor: 'default'
  },
  expansionPanelSummaryContent: {
    margin: '0 !important',
    cursor: 'default'
  },
  divider: {
    height: '60%',
    marginLeft: 16,
    marginRight: 16
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    paddingRight: 1,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 11
  },
  dateColumn: {
    width: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.secondary.main
  },
  stretchLink: {
    '& > a': {
      display: 'block',
      width: '100%',
      height: '100%',
      lineHeight: '2.1'
    }
  }
}));

const menuId = 'primary-search-account-menu';

export default function Job (props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const { id, companyId, tags, showIfOpen } = props;
  const [privateDoc, privateDocLoading, privateDocError] = useDocument(
    firebaseApp.db.doc(`companies/${companyId}/jobs/${id}/private_data/private_document`)
  );
  const privateData = (privateDoc && privateDoc.data()) || {};
  const tag = (privateData && privateData.tag) || (tags && tags[0]);

  const handleMenuOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteJob = (event) => {
    deleteJob(props.companyId, props.id);
  };

  const handleTagSelected = tag => {
    setTag(props.companyId, props.id, tag);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <Confirm
          title={`Are you sure you want to remove the job ${props.title}?`}
        >
          {confirm => (
            <IconButton aria-label='delete' onClick={confirm(() => handleDeleteJob())} size='small'>
              <Delete />Delete
            </IconButton>
          )}
        </Confirm>
      </MenuItem>
    </Menu>
  );

  if (privateDocLoading) {
    return null;
  }

  if (privateDocError) {
    return null;
  }

  if (!privateDocLoading && privateData.done && showIfOpen) {
    return null;
  }

  if (!privateDocLoading && !privateData.done && showIfOpen === false) {
    return null;
  }

  return (
    <ExpansionPanel className={classes.rootPanel}>
      <ExpansionPanelSummary
        defaultExpanded
        square
        TransitionProps={{
          timeout: 0
        }}
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent
        }}
      >
        <div style={{ minWidth: 240, flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.stretchLink}>
          <Link to={`../create-job/${props.id}/edit`} className='document-link'>
            {props.title}
          </Link>
          <Hidden xsDown>
            <Divider orientation='vertical' className={classes.divider} />
          </Hidden>
        </div>

        {props.applicationCount && props.applicationCount > 0
          ? <Hidden smDown>
            <div style={{ width: 150, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Link to={`../create-job/${props.id}/applications`} className='application-link'>
                Applications: <div className={classes.circle}>{props.applicationCount}</div>
              </Link>
              <Divider orientation='vertical' className={classes.divider} />
            </div>
          </Hidden> : null}

        {props.created
          ? <Hidden mdDown>
            <div className={classes.dateColumn}>
              Date Added: <b>{moment(props.created.seconds * 1000).format('DD MMMM YYYY')}</b>
              <Divider orientation='vertical' className={classes.divider} />
            </div>
          </Hidden> : null}

        <Hidden xsDown>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Tag
              tag={tag}
              tags={tags}
              handleTagSelected={handleTagSelected}
            />
            <IconButton aria-label='menu' onClick={handleMenuOpen}>
              <MoreHoriz />
            </IconButton>
          </div>
        </Hidden>
      </ExpansionPanelSummary>
      {renderMenu}
    </ExpansionPanel>
  );
}
